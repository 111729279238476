<div class="common__field-type">
  <div class="common__input-block"
      [ngClass]="{'common__input-block--textarea-multiline':!isLanding,
          'common__icon-textarea-multiline':field.resize,
          'common__input-no-editable': field.noEditable ,
          'common__show-alert': field.error,
          'common__input-block--poll-view':field.poll && !field.noEditable && field.sequentialView,
          'common__input-block--custom-field':isLanding && !field.sequentialView,
          'common__input-block--show-references': fieldTypeText.references && field.evaluatedValue.conceptDataId !== null,
          'field-type-text__sibila':field.analyzeWithSibila,
          'field-type-text__hasAudio':fieldTypeText.hasVoiceRecord}"
      [ngStyle]="{'min-height': fieldTypeText.minHeight}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__label-block common__label-block--one-icon"
          *ngIf="!(field.sequentialView && !field.noEditable)">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                      'common__input-text--no-editable':field.noEditable}">{{fieldTypeText.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__info-icon-block common__info-icon-block"
              [ngHide]="field.noEditable || !field.description">
              <tooltip-info [description]="field.description"
                [field]="field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="field.references && field.references.length && !field.noEditable">
              <tooltip-law [references]="field.references"
                [field]="field"></tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above" >
              </i>
          </div>
      </div>

      <div class="common__input-text-poll"
          *ngIf="field.sequentialView && !field.noEditable"
          [ngClass]="{'common__input-text-poll--error': field.error}">
          <p class="field-type-text__custom-sequential-label">
              {{fieldTypeText.titleField}}
              <span class="common__text-required common__text-required--sequential-view"
                    [ngShow]="field.required">*</span>
              <tooltip-info [ngShow]="!field.noEditable"
                            [description]="field.description"
                            [field]="field"
                            [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView"></tooltip-info>
              <tooltip-law [references]="field.references"
                          [field]="field"
                          [ngShow]="field.references?.length && !field.noEditable"
                          [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
              </tooltip-law>
          </p>
      </div>
      <div [ngClass]="{'field-type-text__custom-input-textarea-poll':field.poll && !field.noEditable && field.sequentialView}">
          <i class="lf-icon-text-field common__input-icon field-item__input-icon-custom-textarea"
            [ngShow]="field.poll && !field.noEditable && field.sequentialView"></i>

          <div class="field-type-text__icon-references field-type-text__icon-references--border"
              *ngIf="skin != 'icam-red' && field.type !== 'link' && field.evaluatedValue?.hasReferences && !field.poll"
              (click)="showReference($event)"
              matTooltip="{{'common.see-dependencies' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left">
              <i class="field-type-text__icon-references-icon lf-icon-connected-data"></i>
          </div>
          <div class="field-type-text__icon-references field-type-text__icon-references--less-top field-type-text__icon-references"
              *ngIf="skin === 'icam-red' && field.type !== 'link' && field.evaluatedValue?.hasReferences && !field.poll">
              <i class="lf-icon-connected-data "></i>
          </div>
          <i class="lf-icon-microphone field-type-text__mic-icon"
            [ngClass]="{'field-type-text__mic-icon--landing':isLanding,
              'field-type-text__mic-icon--no-editable':field.noEditable && !isLanding,
              'field-type-text__mic-icon--no-editable-landing':isLanding && field.noEditable}"
            [ngShow]="field.isVoiceRecord && (globalSkin === 'dark-blue' || globalSkin === 'gm-law')"
            (click)="openRecordVoice()">
          </i>
          <i class="common__input-icon common__input-icon-active"
              [ngClass]="{'lf-icon-expand': !field.expandedTextarea,
                  'lf-icon-collapse-panel': field.expandedTextarea}"
              (click)="openTextareaModal()"></i>
          <textarea [id]="'field-item-text-'+id"
              class="common__input-textarea"
              [ngClass]="{'common__input-textarea--resize':field.resize,
                    'common__input-content-alert': field.error,
                    'common__input-content-no-editable': field.noEditable ,
                    'common__input-textarea--custom':isLanding,
                    'common__input-textarea--custom-sequential':isLanding&& field.sequentialView,
                    'common__input-textarea--expanded': field.expandedTextarea}"
              [ngStyle]="{'height': fieldTypeText.minHeight}"
              [(ngModel)]="fieldTypeText.inputText"
              (blur)="changeInField($event)"
              (keypress)="keyChangeInField($event)"
              [readonly]="field.readonly || field.noEditable "
              [name]="field.from"
              [disabled]="field.noEditable">
          </textarea>
          <i class="common__delete-content-icon"
            [ngClass]="{'field-type-text__delete-content-icon-textarea':field.multiline,
              'lf-icon-close-round': isLanding,
              'lf-icon-close': !isLanding,
              'field-type-text__delete-content-icon-textarea--has-voice-record': fieldTypeText.hasVoiceRecord} "
            [ngHide]="fieldTypeText.inputText === '' || field.noEditable"
            (click)="resetInput()">
          </i>
          <div *ngIf="field.noEditable && field.isVoiceRecord && field.evaluatedValue.bitValue"
            class="field-type-text__transcription-block"
            [ngClass]="{'field-type-text__transcription-block--landing':isLanding}">
            <p class="field-type-text__transcription-text"
              [ngClass]="{'field-type-text__transcription-text--landing':isLanding}"
              [afTranslate]="'record-audio-modal.audio-transcription'"></p>
          </div>
      </div>
      <div class="field-item__blocker-box"
        *ngIf="fieldTypeText.field && fieldTypeText.field.error && fieldTypeText.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
    <div class="field-type-text__audio-box"
      [ngClass]="{'field-type-text__audio-box--no-editable':field.noEditable,
        'field-type-text__audio-box--landing': isLanding,
        'field-type-text__audio-box--landing-acceptation':fieldTypeText.acceptAceptation && isLanding}"
      *ngIf="fieldTypeText.hasVoiceRecord">
      <p class="field-type-text__record-info"
        [ngClass]="{'field-type-text__record-info--no-editable':field.noEditable,
        'field-type-text__record-info--landing':isLanding}">
        <span class="field-type-text__record-info field-type-text__record-info--text"
          [ngClass]="{'field-type-text__record-info--no-editable':field.noEditable,
          'field-type-text__record-info--landing':isLanding}"
          [afTranslate]="'record-audio-modal.recorder'"></span>
          {{fieldTypeText.voiceRecord.name}}
      </p>
      <div class="field-type-text__audio-block"
        [ngClass]="{'field-type-text__audio-block--accept-check': fieldTypeText.acceptAceptation && !fieldTypeText.loadFromBack}">
        <i class="field-type-text__audio-block-play"
          [ngClass]="{'lf-icon-stop' : startAudio && fieldTypeText.audioLoaded,
            'lf-icon-play': !startAudio,
            'lf-icon-refresh field-type-text__refresh-animation':startAudio && !fieldTypeText.audioLoaded,
            'field-type-text__audio-block-play--landing': isLanding}"
          (click)="playRecordVoice()"></i>
        <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--field">
          <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--active"
            [progressBar]="percentTime">
          </div>
        </div>
        <i class="lf-icon-download field-type-text__audio-block-download"
        [ngClass]="{'field-type-text__audio-block-download--landing': isLanding}"
          *ngIf="(!fieldTypeText.acceptAceptation || (fieldTypeText.loadFromBack && fieldTypeText.audioLoaded)) && !fieldTypeText.lockedSavedConcept"
          (click)="audioDownLoad()"></i>
        <i class="lf-icon-trash field-type-text__audio-block-delete"
          [ngClass]="{'field-type-text__audio-block-delete--landing': isLanding}"
          *ngIf="!fieldTypeText.acceptAceptation"
          (click)="resetInput()"></i>
      </div>
    </div>
  </div>
  <label class="field-type-text__accept-check-label"
    *ngIf="fieldTypeText.hasVoiceRecord"
    [ngClass]="{'field-type-text__accept-check-label--no-editable':field.noEditable,
    'field-type-text__accept-check-label--landing': isLanding}">
    <i class="record-audio-modal__accept-terms-icon"
      [ngClass]="{'lf-icon-check-full':fieldTypeText.acceptAceptation,
        'lf-icon-box-inactive':!fieldTypeText.acceptAceptation,
        'record-audio-modal__accept-terms-icon--no-editable':field.noEditable,
        'record-audio-modal__accept-terms-icon--error':field.errorInAcceptAceptation,
        'record-audio-modal__accept-terms-icon--landing': isLanding,
        'record-audio-modal__accept-terms-icon--landing-error':field.errorInAcceptAceptation && isLanding}"
        (click)="acceptAceptationTerms()"></i>
    <p class="record-audio-modal__accept-terms-text">
      <span class="record-audio-modal__accept-terms-text record-audio-modal__accept-terms-text--check"
        [ngClass]="{'record-audio-modal__accept-terms-text--no-editable':field.noEditable,
        'record-audio-modal__accept-terms-text--required':field.errorInAcceptAceptation,
        'record-audio-modal__accept-terms-text--check-landing': isLanding,
        'record-audio-modal__accept-terms-text--landing-error':field.errorInAcceptAceptation && isLanding}"
        >{{field.voiceRecordOkText}}</span>
      <span class="record-audio-modal__accept-terms-text record-audio-modal__accept-terms-text--required">*</span>
    </p>
  </label>
  <div class="field-type-text__footer"
      *ngIf="field.analyzeWithSibila && !isLanding">
      <div class="field-type-text__footer-button"
          [ngClass]="{'field-type-text__footer-button--disabled': fieldTypeText.inputText == ''}"
          (click)="analyzeWithSibila()">
          <i class="lf-icon-sibila field-type-text__footer-button-icon"></i>
          <span class="field-type-text__footer-button-text"
            [afTranslate]="'field-type-text.sibila'"></span>
      </div>
  </div>
</div>
