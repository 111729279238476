<div class="corporative-information-modal"
  [ngClass]="{'corporative-information-modal--show-notification': corporativeInformationModal.showNotificationModel}">
  <div class="common__modal-header-block"
      [ngShow]="!corporativeInformationModal.showNotificationModel">
      <i class="lf-icon-colour common__icon-header"></i>
      <span class="common__text-header"
          [afTranslate]="'public-link.edit-corporative-information'">_Editar información corporativa</span>
      <i class="lf-icon-close common__icon-close-modal corporative-information-modal__color"
          (click)="handleDismiss()"></i>
  </div>
  <wizard-steps *ngIf="corporativeInformationModal.steps.length !== 1"
      [steps]="corporativeInformationModal.steps"
      [state]="corporativeInformationModal.stepState">
  </wizard-steps>
  <div class="corporative-information-modal__body-block"
      [ngShow]="!corporativeInformationModal.showNotificationModel">
      <div class="corporative-information-modal__block-body-fields">
          <div class="corporative-information-modal__steps-block">
              <form class="corporative-information-modal__form-block"
                  [ngClass]="{'corporative-information-modal__form-block--configure-mail':corporativeInformationModal.showMoreData}"
                  autocomplete="off">
                  <div [ngShow]="corporativeInformationModal.stepState === 1"
                      class="corporative-information-modal__link-block">
                      <i class="lf-icon-information corporative-information-modal__icon"
                          [ngShow]="corporativeInformationModal.company.global || (!corporativeInformationModal.company.global && !corporativeInformationModal.noGlobal)"></i>
                      <div class="corporative-information-modal__info-text"
                          [ngShow]="corporativeInformationModal.company.global"
                          [afTranslate]="'corporative-information-modal.identity-global'">
                          __Se ha aplicado una personalización definida a nivel global. Puedes modificarla si lo deseas. Los cambios efectuados se guardarán como configuración específica para este concepto.
                      </div>
                      <div class="corporative-information-modal__info-text corporative-information-modal__info-text--with-global"
                          [ngShow]="!corporativeInformationModal.company.global && !corporativeInformationModal.noGlobal">
                          <div class="corporative-information-modal__custom-config"
                              [afTranslate]="'corporative-information-modal.identity-specific'">
                              __Se ha aplicado una personalización específica a este concepto. Si lo prefieres, puedes restaurar los valores de la configuración global.
                          </div>
                          <button class="common__button-border-none corporative-information-modal__custom-config-right"
                              (click)="glConfig()">
                              <i class="common__icon-button-underlined lf-icon-money-world"></i>
                              <span class="common__text-button-underlined"
                                  [afTranslate]="'corporative-information-modal.apply-global-identy'">_Aplicar identidad global</span>
                          </button>
                      </div>
                  </div>
                  <div [ngShow]="corporativeInformationModal.stepState === 1">
                      <field-type-logo
                          [field]="corporativeInformationModal.clientLogo"
                          [type]="'new'"
                          [preSelectItem]="corporativeInformationModal.allLogos"
                          [logoInfo]="corporativeInformationModal.company"
                          (itemSelected)="itemSelectedOn($event, 'logo')">
                      </field-type-logo>
                      <field-type-logo
                          [field]="corporativeInformationModal.clientFavicon"
                          [type]="'favicon'"
                          [logoInfo]="corporativeInformationModal.company"
                          [preSelectItem]="corporativeInformationModal.company.faviconImg"
                          (itemSelected)="itemSelectedOn($event, 'favicon')">
                      </field-type-logo>
                      <field-type-color class="global-customization__block-picker-color"
                          [field]="corporativeInformationModal.color"
                          [preSelectItem]="corporativeInformationModal.company.color"
                          (itemSelectedEmit)="itemSelectedOn($event, 'color')">
                      </field-type-color>
                      <field-type-color class="global-customization__block-picker-color"
                          [field]="corporativeInformationModal.textColor"
                          [preSelectItem]="corporativeInformationModal.company.textColor"
                          (itemSelectedEmit)="itemSelectedOn($event, 'textColor')">
                      </field-type-color>
                      <field-type-list-extended [field]="corporativeInformationModal.footerDesignType"
                          [preSelectItem]="corporativeInformationModal.company.footerDesignType"
                          [listValues]="corporativeInformationModal.footerDesignTypeList"
                          (itemSelected)="itemSelectedOn($event, 'footerDesignType')">
                      </field-type-list-extended>
                  </div>
                  <div [ngSwitch]="corporativeInformationModal.stepState">
                    <div *ngSwitchCase="2">
                        <div class="corporative-information-modal__block-company">
                            <div class="corporative-information-modal__configure-company-block">
                                <field-type-text-simple [ngShow]="corporativeInformationModal.showOtherFields"
                                    [field]="corporativeInformationModal.youtubeLink"
                                    [preSelectItem]="corporativeInformationModal.company.youtube"
                                    (itemSelected)="itemSelectedOn($event, 'youtube')">
                                </field-type-text-simple>
                                <field-type-text-simple [ngShow]="corporativeInformationModal.showOtherFields"
                                    [field]="corporativeInformationModal.twitterLink"
                                    [preSelectItem]="corporativeInformationModal.company.twitter"
                                    (itemSelected)="itemSelectedOn($event, 'twitter')">
                                </field-type-text-simple>
                                <field-type-text-simple [ngShow]="corporativeInformationModal.showOtherFields"
                                    [field]="corporativeInformationModal.facebookLink"
                                    [preSelectItem]="corporativeInformationModal.company.facebook"
                                    (itemSelected)="itemSelectedOn($event, 'facebook')">
                                </field-type-text-simple>
                                <field-type-text-simple [ngShow]="corporativeInformationModal.showOtherFields"
                                    [field]="corporativeInformationModal.linkedinLink"
                                    [preSelectItem]="corporativeInformationModal.company.linkedin"
                                    (itemSelected)="itemSelectedOn($event, 'linkedin')">
                                </field-type-text-simple>
                                <field-type-text-simple [ngShow]="corporativeInformationModal.showOtherFields"
                                    [field]="corporativeInformationModal.phoneReference"
                                    [preSelectItem]="corporativeInformationModal.company.phone"
                                    (itemSelected)="itemSelectedOn($event, 'phone')">
                                </field-type-text-simple>
                            </div>
                            <div class="corporative-information-modal__textarea-block">
                                <div class="corporative-information-modal__block-textarea">
                                    <rich-editor class="common__input-textarea-special"
                                        [tinyMceOptions]="corporativeInformationModal.tinyMceOptions"
                                        [modelText]="corporativeInformationModal.message"
                                        (emitValue)="corporativeInformationModal.message = $event.model">
                                    </rich-editor>
                                </div>
                            </div>
                        </div>

                        <div class="corporative-information-modal__buttons-checked">
                            <field-type-switch
                                [label]="'corporative-information-modal.show-logo'|afTranslate"
                                [checked]="corporativeInformationModal.company.showLogoInFooter"
                                (callback)="itemSelected('showLogoInFooter', $event.value)">
                            </field-type-switch>
                        </div>
                    </div>

                    <div class="corporative-information-modal__block-configure-mail"
                        *ngSwitchCase="3"
                        [ngClass]="{'corporative-information-modal__block-configure-mail--active':corporativeInformationModal.showMoreData}">
                            <p class="corporative-information-modal__information-mail"
                                [afTranslate]="'corporative-information-modal.explication-more-info-2'">
                                _Si quieres que las notificaciones al usuario del concepto público se remitan desde una cuenta de correo diferente a la del producto, completa los siguientes campos:
                            </p>
                            <div class="corporative-information-modal__margin-check">
                                <field-type-switch [label]="'corporative-information-modal.custom-email'|afTranslate"
                                    [checked]="corporativeInformationModal.company.customEmail"
                                    (callback)="itemSelected('customEmail', $event.value)">
                                </field-type-switch>
                            </div>
                            <field-type-list-extended *ngIf="skin==='dark-blue'"
                                [ngShow]="corporativeInformationModal.company.customEmail"
                                [field]="corporativeInformationModal.customEmailType"
                                [listValues]="corporativeInformationModal.customEmailTypeOptions"
                                [preSelectItem]="corporativeInformationModal.customTypeSelect"
                                (itemSelected)="selectcustomType($event.newValue)">
                            </field-type-list-extended>
                            <div [ngShow]="corporativeInformationModal.company.customEmail && corporativeInformationModal.customTypeSelect ==='0'">
                                <div class="corporative-information-modal__block-line-data">
                                    <div class="corporative-information-modal__block-fields-left">
                                        <field-type-text-simple [field]="corporativeInformationModal.sMTPServerField"
                                            [preSelectItem]="corporativeInformationModal.company.smtpServer"
                                            (itemSelected)="itemSelectedOn($event, 'smtpServer')">
                                        </field-type-text-simple>
                                        <span class="corporative-information-modal__error-name"
                                            [ngShow]="corporativeInformationModal.errorServer"
                                            [afTranslate]="'corporative-information-modal.char-50-error'">
                                                _El servidor de salida no puede exceder de 50 caracteres</span>
                                    </div>
                                    <div class="corporative-information-modal__block-fields-right">
                                        <field-type-number [field]="corporativeInformationModal.sMTPPortField"
                                            [preSelectItem]="corporativeInformationModal.company.smtpPort"
                                            (itemSelected)="itemSelectedOn($event, 'smtpPort')">
                                        </field-type-number>
                                        <span class="corporative-information-modal__error-name"
                                            [ngShow]="corporativeInformationModal.errorPort"
                                            [afTranslate]="'corporative-information-modal.char-5-error'">
                                                _El puerto no puede exceder de 5 caracteres</span>
                                    </div>
                                </div>
                                <div class="corporative-information-modal__block-line-data">
                                    <div class="corporative-information-modal__block-fields-left">
                                        <field-type-text-simple [field]="corporativeInformationModal.sMTPUserField"
                                            [preSelectItem]="corporativeInformationModal.company.smtpUser"
                                            (itemSelected)="itemSelectedOn($event, 'smtpUser')">
                                        </field-type-text-simple>
                                        <span class="corporative-information-modal__error-name"
                                            [ngShow]="corporativeInformationModal.errorUser"
                                            [afTranslate]="'corporative-information-modal.user-50-error'">
                                                _El usuario no puede exceder de 50 caracteres</span>
                                    </div>
                                    <div class="corporative-information-modal__block-fields-right">
                                        <field-type-password [field]="corporativeInformationModal.sMTPPasswordField"
                                            [preSelectItem]="corporativeInformationModal.company.smtpPassword"
                                            (itemSelected)="itemSelectedOn($event, 'smtpPassword')">
                                        </field-type-password>
                                        <span class="corporative-information-modal__error-name"
                                            [ngShow]="corporativeInformationModal.errorPass">
                                            {{corporativeInformationModal.errorLimitPassword}}</span>
                                    </div>
                                </div>
                                <div class="corporative-information-modal__block-line-data"
                                  [ngClass]="{'corporative-information-modal__block-line-data--some-errors': corporativeInformationModal.errorFrom && corporativeInformationModal.sMTPFromField.error}">
                                  <div class="corporative-information-modal__block-fields-left">
                                      <field-type-email [field]="corporativeInformationModal.sMTPFromField"
                                          [preSelectItem]="corporativeInformationModal.company.smtpFrom"
                                          (itemSelected)="itemSelectedOn($event, 'smtpFrom')">
                                      </field-type-email>
                                      <span class="corporative-information-modal__error-name"
                                          [ngShow]="corporativeInformationModal.errorFrom"
                                          [afTranslate]="'corporative-information-modal.mail-50-error'">_El correo electrónico no puede exceder de 50 caracteres</span>
                                  </div>
                              </div>
                                <div class="corporative-information-modal__buttons-checked">
                                    <field-type-switch
                                        [label]="'corporative-information-modal.secure-connection'|afTranslate"
                                        [checked]="corporativeInformationModal.company.smtpssl"
                                        (callback)="itemSelected('smtpssl', $event.value)">
                                    </field-type-switch>
                                </div>
                            </div>
                            <field-type-email *ngIf="corporativeInformationModal.company.customEmail && corporativeInformationModal.customTypeSelect ==='1' && !corporativeInformationModal.itsConfigurate"
                                [concept]="corporativeInformationModal.concept"
                                [field]="corporativeInformationModal.authMailField"
                                [preSelectItem]="corporativeInformationModal.mailAuth"
                                (itemSelected)="selectEmail($event.newValue)">
                            </field-type-email>
                            <div [ngShow]="corporativeInformationModal.company.customEmail && corporativeInformationModal.customTypeSelect ==='1'"
                                class="common__input-block common__input-block--fixed corporative-information-modal__bottom-box-especial"
                                [ngClass]="{'corporative-information-modal__bottom-box-especial--error':(corporativeInformationModal.itsConfigurate && corporativeInformationModal.mailAuth ==='' && corporativeInformationModal.company.oauthUser ==='') || corporativeInformationModal.authAccessTokenError || corporativeInformationModal.company.oauthReconfigNeeded}">
                                <div class="common__label-block">
                                    <span class="common__input-text"
                                        [afTranslate]="'corporative-information-modal.associate-custom-account'"></span>
                                    <span class="common__text-required">*</span>
                                </div>
                                <p class="corporative-information-modal__explain-text"
                                    [afTranslate]="'corporative-information-modal.associate-custom-account-explain'"></p>
                                <button class="common__button-border-none public-link__button-copy-link-init"
                                    [ngClass]="{'corporative-information-modal__button-bottom':corporativeInformationModal.itsConfigurate}"
                                    [disabled]="corporativeInformationModal.itsConfigurate"
                                    (click)="initAuth()">
                                    <i class="common__icon-button-underlined lf-icon-office365"></i>
                                    <span class="common__text-button-underlined"
                                       [afTranslate]="'corporative-information-modal.microsoft-config'">__configurar en microsoft</span>
                                </button>
                                <div *ngIf="corporativeInformationModal.itsConfigurate"
                                    class="corporative-information-modal__auth-user-box">
                                    <p class="corporative-information-modal__auth-user-info">
                                        <span [afTranslate]="'corporative-information-modal.associated-account'"></span>
                                        <span class="corporative-information-modal__auth-user-email">{{corporativeInformationModal.company.oauthUser}}</span>
                                    </p>
                                    <i class="lf-icon-trash corporative-information-modal__auth-user-delete"
                                        (click)="deleteOauth()"></i>
                                </div>
                        </div>
                    </div>
                    <div *ngSwitchCase="4">
                        <p class="corporative-information-modal__information-mail"
                        [afTranslate]="'corporative-information-modal.explication-title'">
                        _Por defecto, en la cabecera del formulario público se indicará el nombre del concepto. Si lo prefieres, puedes introducir a continuación un título diferente.
                        </p>
                        <div class="protection-data-modal__block-fields">
                            <div class="protection-data-modal__block-translations"
                                [ngClass]="{'protection-data-modal__block-translations--error': corporativeInformationModal.errorActive}"
                                [ngHide]="skin ==='icam-red'">
                                <button class="protection-data-modal__block-translation-option"
                                    [ngClass]="{'protection-data-modal__block-translation-option--selected': languageSelected.active && !languageSelected.error,
                                        'protection-data-modal__block-translation-option--selected-error': languageSelected.active && languageSelected.error,
                                        'protection-data-modal__block-translation-option--error': !languageSelected.active && languageSelected.error}"
                                    *ngFor="let languageSelected of corporativeInformationModal.languagesSelected"
                                    (click)="changeActiveLanguagesSelected(languageSelected)">
                                    <span [ngShow]="">{{corporativeInformationModal.languagesSelected.length < 6?languageSelected.text: languageSelected.appLanguage}}</span>
                                    <i class="lf-icon-close protection-data-modal__icon-close-language"
                                        [ngShow]="!languageSelected.defaultOption"
                                        (click)="removeActiveLanguage(languageSelected)"></i>
                                    <svg class="protection-data-modal__triangle-block"
                                        [ngClass]="{'protection-data-modal__triangle-block--selected': languageSelected.active,
                                            'protection-data-modal__triangle-block--selected-error': languageSelected.active && languageSelected.error,
                                            'protection-data-modal__triangle-block--error': !languageSelected.active && languageSelected.error}">
                                        <polygon points="0,0 21,30 0,30" />
                                    </svg>
                                </button>
                                <div ngbDropdown
                                    [ngHide]="corporativeInformationModal.languagesSelected.length === corporativeInformationModal.languagesApp?.length">
                                    <button class="protection-data-modal__select-language-button protection-data-modal__select-language-button"
                                        ngbDropdownToggle>
                                        <span [afTranslate]="'protection-data-modal.add-translation'">_Añadir traducción</span>
                                        <i class="lf-icon-translate protection-data-modal__icon-translate"></i>
                                    </button>
                                    <div class="protection-data-modal__menu-select-languages dropdown-menu"
                                        ngbDropdownMenu
                                        role="menu">
                                        <div class="common__block-option-list header-nav__block-option-list">
                                            <div class="common__language-item"
                                                *ngFor="let language of corporativeInformationModal.languagesApp"
                                                [ngShow]="notSelectLanguage(language)"
                                                (click)="selectLanguage(language.listValueId)">
                                                <span>{{language.text}}</span>
                                                <span>({{language.specific}})</span>
                                                <i class="lf-icon-check-round-full common__language-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <field-type-text-simple [field]="corporativeInformationModal.dataTreatment"
                                [preSelectItem]="corporativeInformationModal.dataTreatment.preSelectItem"
                                (itemSelected)="changeField($event.newValue)">
                            </field-type-text-simple>
                        </div>

                    </div>
                  </div>
              </form>
          </div>
          <div class="corporative-information-modal__buttons-block">
              <div class="corporative-information-modal__button-model-block">
                  <i class="lf-icon-visible corporative-information-modal__icon-vision"
                      [ngClass]="{'corporative-information-modal__button-model-block--disabled': corporativeInformationModal.notifyDisabled}"
                      (click)="showPreview()"></i>
                  <button class="corporative-information-modal__button-vision"
                      [ngClass]="{'corporative-information-modal__button-model-block--disabled': corporativeInformationModal.notifyDisabled}"
                      (click)="showPreview()"
                      [disabled]="corporativeInformationModal.notifyDisabled"
                      [afTranslate]="'corporative-information-modal.notify-model'">
                      _Ver modelo de notificación</button>
              </div>
              <button class="common__button common__button--clean common__button--clean"
                  (click)="handleDismiss('cancel')"
                  [afTranslate]="'common.cancel'">_Cancelar</button>
              <button *ngIf="corporativeInformationModal.stepState > 1"
                  class="common__button common__button--active common__button--active "
                  (click)="prevStep()"
                  [afTranslate]="'common.previous'">_Anterior</button>
              <button [ngShow]="corporativeInformationModal.stepState !== 4"
                  class="common__button common__button--active common__button--active"
                  (click)="nextStep()"
                  [afTranslate]="'common.next'">_Siguiente</button>
              <button [ngShow]="corporativeInformationModal.stepState === 4"
                  class="common__button common__button--active common__button--active"
                  (click)="saveCorporativeInformation()"
                  [afTranslate]="'common.save'"
                  >_Guardar</button>
          </div>
      </div>
  </div>
</div>
