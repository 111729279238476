<div class="concept">
  <concept-tab [list]="editConcept.conceptList"
      [activeConcept]="editConcept.activeConcept"
      [subMenu]="editConcept.fromForm"
      (launchAction)="launchAction($event)"
      [fullMenu]="editConcept.fullMenu">
  </concept-tab>
  <div class="concept-tab__line">
      <div class="edit-concept__content"
        [ngSwitch]="editConcept.activeConcept?.subTab">
          <customize-concept *ngSwitchCase="'form'"
              [concept]="editConcept.activeConcept"
              [pollObject]="editConcept.pollObject"
              [allowPublicShare]="editConcept.activeConcept?.allowPublicShare">
          </customize-concept>
          <process-edit *ngSwitchCase="'process'"
                        [concept]="editConcept.activeConcept">
          </process-edit>
          <templates-concept *ngSwitchCase="'template'"
              [concept]="editConcept.activeConcept">
          </templates-concept>
          <div *ngSwitchDefault>
              <concept-form-default
                  class="edit-concept__concept-form"
                  [concept]="editConcept.activeConcept"
                  (launchAction)="launchAction($event)"
                  [public]="editConcept.public">
              </concept-form-default>
          </div>
      </div>
  </div>
  <div class="concept__go-back">
      <button class="concept__go-back-button"
          (click)="goBack()">
          <i class="lf-icon-arrow-back concept__go-back-icon"></i>
          <span class="edit-concept__go-back-text"
              [afTranslate]="'common.return-to'">volver a </span>
          <span class="edit-concept__go-back-text">{{editConcept.parentFolderName}}</span>
      </button>
  </div>
  <div class="edit-concept__block-button-finish">
      <button type="submit"
          [ngShow]="editConcept.conceptInfo?.poll"
          class="common__button common__button--active"
          (click)="goPreview()"
          [afTranslate]="'common.preview'">Vista previa</button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="closeConceptSave()"
          [afTranslate]="'common.finish'">Finalizar</button>
  </div>
</div>
