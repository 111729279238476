<div class="task-concept common__block-concept"
    [ngClass]="{'common__block-concept--border': currentComponent === 'concept'}">
    <div class="common__task-header">
        <button class="lf-icon-sum common__button-checked"
            [ngShow]="taskConceptList.canCreate"
            (click)="editTask('nuevo')"
            matTooltip="{{'task-form.new-task' | afTranslate}}"
            matTooltipPosition="left"
            matTooltipClass="left"></button>
    </div>
    <div *ngIf="taskConceptList.processTask"
        class="task-concept__process-task">
        <h1 class="task-concept__process-task__heading">
            <i class="lf-icon-process-settings task-concept__process-list-icon"></i>
            <span [afTranslate]="'task-concept-list.process-task.title'">Tarea de proceso</span>
            <span class="task-concept__process-task__heading__creation">
                <span [ngClass]="{'common__line-through':taskConceptList.processTask.createdByIsDeleted}"
                    matTooltip="{{taskConceptList.processTask.createdBy +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                    matTooltipPosition="below"
                    matTooltipClass="below"
                    [matTooltipDisabled]="!taskConceptList.processTask.createdByIsDeleted">
                    {{taskConceptList.processTask.createdBy}}
                </span>,
                <span>{{taskConceptList.processTask.creationDate | convertDate:'without-time'}}</span>
            </span>
        </h1>
        <div class="task-concept__pr-task-process-block">
            <div class="task-concept__pr-task-process-column task-concept__pr-task-process-column--dest">
                <h2 class="task-concept__pr-task-process-title">
                    {{taskConceptList.processTask.title}}
                </h2>
                <div class="task-concept__pr-task-property-label task-concept__pr-task-property-label--recipient">
                    <span [afTranslate]="'task-concept-list.process-task.recipient'">Destinatario</span>:
                </div>
                <participants-list class="task-concept__participant-list-process"
                    *ngIf="taskConceptList.processTask.participants?.length"
                    [participants]="taskConceptList.processTask.participants"
                    [type]="'task-process-concept-list'">
                </participants-list>
            </div>
            <div class="task-concept__pr-task-process-column task-concept__pr-task-process-column--venc">
                <div class="common__table-display task-concept__table-display">
                    <div class="common__table-cell-tooltip">
                        <i class="task-concept__pr-task-property-icon-calendar lf-icon-calendar"></i>
                        <div class="task-concept__pr-task-property-label">
                            <span [afTranslate]="'task-concept-list.process-task.expiration'">Vencimiento</span>:
                        </div>
                        <div class="task-concept__pr-task-property-value">
                            {{taskConceptList.processTask.expirationDate | convertDate:'without-time'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="task-concept__pr-task-process-column task-concept__pr-task-process-column--state">
                <div class="task-concept__state-block">
                    <i class="task-concept__pr-task-property-icon-circle lf-icon-transition"></i>
                    <div class="task-concept__pr-task-property-block-state">
                        <span [afTranslate]="'task-concept-list.process-task.current-state'">Estado</span>:
                    </div>
                    <div class="common__cell-block common__cell-block--process-state">
                        <span-tooltip class="common__ellipsis task-concept__pr-task-property-value task-concept__pr-task-property-value--state"
                          [text]="concept.processStates?.current?.name"></span-tooltip>
                    </div>
                </div>
            </div>
            <div class="task-concept__column-actions">
                <ul class="common__options-row task-concept__options">
                    <li class="common__menu-options-row"
                        (click)="editTask('editar', taskConceptList.processTask)">
                        <i [ngClass]="!taskConceptList.canCreate?'lf-icon-visible':'lf-icon-editable-form'"
                            matTooltip="{{'common.edit' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"></i>
                    </li>
                    <li class="common__menu-options-row"
                        [ngShow]="taskConceptList.canCreate">
                        <i class="lf-icon-trash"
                            (click)="deleteTask(taskConceptList.processTask)"
                            matTooltip="{{'common.remove' | afTranslate}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"></i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="task-concept__table">
        <div class="common__table-header">
            <div class="task-concept__table-col task-concept__table-col--type-header">
                <filter-by-list [type]="'list'"
                    [item]="taskConceptList.typeFilter"
                    [list]="taskConceptList.typeList"
                    (filterElementEmit)="selectTypeOperation($event, 'type')">
                </filter-by-list>
            </div>
            <div class="task-concept__table-col task-concept__table-col--title-header">
                <div ngbDropdown
                    [autoClose]="'outside'">
                    <div ngbDropdownToggle
                        (click)="openFocus('Title')">
                        <p class="common__table-header-text common__cursor-pointer"
                        [afTranslate]="'task-concept.title'">Título</p>
                        <i class="common__filter-icon lf-icon-filter-1"
                          [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': taskConceptList.titleWordFilter.length}"></i>
                    </div>
                    <ul class="common__select-list-with-input"
                        ngbDropdownMenu role="menu">
                        <li class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
                            <input type="text"
                                class="common__dropdown-inner-input"
                                (keyup)="filterTask()"
                                placeholder="{{'documentation-report-list.search-title'| afTranslate}}"
                                [(ngModel)]="taskConceptList.titleWordFilter"
                                [focusWhen]="taskConceptList.openFocusTitle">
                            <i class="lf-icon-search common__search-icon"
                                [ngClass]="taskConceptList.titleWordFilter ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
                                (click)="resetFilter($event)"></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="task-concept__table-col  task-concept__table-col--creation-header">
                <p class="common__table-header-text common__cursor-pointer"
                [afTranslate]="'common.creation'"
                (click)="orderDate('creationDate')">Creación</p>
                <i class="common__order-by"
                    [ngClass]="{'lf-icon-arrow-down':(taskConceptList.dateOrder==='creationDate' && taskConceptList.orderOperationDates) || taskConceptList.dateOrder!=='creationDate',
                    'lf-icon-arrow-up': taskConceptList.dateOrder==='creationDate' && !taskConceptList.orderOperationDates,
                    'common__order-by--active': taskConceptList.dateOrder==='creationDate'}"
                    (click)="orderDate('creationDate')"></i>
            </div>
            <div class="task-concept__table-col task-concept__table-col--creator-header">
                <filter-by-list [type]="'specialListFind'"
                    [item]="taskConceptList.createdByFilter"
                    [list]="taskConceptList.createdByList"
                    [showMyOption]="taskConceptList.createdByFilter.showMyOption"
                    (filterElementEmit)="selectTypeOperation($event, 'createdBy')">
                </filter-by-list>
            </div>
            <div class="task-concept__table-col task-concept__table-col--responsible-header">
                <p class="common__table-header-text"
                [afTranslate]="'task-concept.recipient'">Destinatario</p>
            </div>
            <div class="task-concept__table-col task-concept__table-col--state-head">
                <filter-by-list [type]="'list'"
                    [item]="taskConceptList.stateFilter"
                    [list]="taskConceptList.stateList"
                    (filterElementEmit)="selectTypeOperation($event, 'state')">
                </filter-by-list>
            </div>
            <div class="task-concept__table-col task-concept__table-col--expiration-header">
                <p class="common__table-header-text common__cursor-pointer"
                [afTranslate]="'task-concept.expiration'"
                (click)="orderDate('expirationDate')">Vencimiento</p>
                <i class="common__order-by"
                    [ngClass]="{'lf-icon-arrow-down':(taskConceptList.dateOrder==='expirationDate' && taskConceptList.orderOperationDates) || taskConceptList.dateOrder!=='expirationDate',
                        'lf-icon-arrow-up': taskConceptList.dateOrder==='expirationDate' && !taskConceptList.orderOperationDates,
                        'common__order-by--active': taskConceptList.dateOrder==='expirationDate'}"
                    (click)="orderDate('expirationDate')"></i>
            </div>
            <div class="task-concept__table-col task-concept__table-col--action-header">
                <p class="common__table-header-text common__table-header-text--right"
                [afTranslate]="'common.actions'">Acciones</p>
            </div>
        </div>
        <div class="task-concept__table-body task-concept__table-body--title"
            [ngClass]="{'task-concept__table-body--show-title':taskConceptList.showHeader}">
            <div class="common__table-row"
                *ngFor="let task of taskConceptList.filterTaskList | afOrderBy:taskConceptList.dateOrder:taskConceptList.orderOperationDates">
                <div class="task-concept__table-col task-concept__table-col--type">
                    <div class="common__cell-block">
                        <i [ngShow]="!task.videoconference"
                            [class]="'task-concept__type-icon '+ (task.taskTypeId | taskTypeIcons:'task': task.repeatPattern)"
                            [ngClass]="{'task-concept__type-icon--meeting': task.taskTypeId === 4}"></i>
                        <i [ngShow]="task.videoconference"
                            class="task-concept__type-icon lf-icon-videoconference"></i>
                        <parragraph-tooltip [text]="task.type"
                          class="task-concept__text-type"></parragraph-tooltip>
                    </div>
                </div>
                <div class="task-concept__table-col task-concept__table-col--title"
                    (click)="editTask('editar', task)">
                    <div class="common__cell-block">
                        <parragraph-tooltip [text]="task.title"></parragraph-tooltip>
                    </div>
                </div>
                <div class="task-concept__table-col task-concept__table-col--creation">
                    <p class="task-concept__table-body-header">{{task.creationDate  | convertDate:'without-time'}}</p>
                </div>
                <div class="task-concept__table-col task-concept__table-col--creator">
                    <p class="task-concept__table-body-header"
                        [ngClass]="{'common__line-through':task.createdByIsDeleted}"
                        matTooltip="{{task.createdBy +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        [matTooltipDisabled]="!task.createdByIsDeleted">
                        {{task.createdBy}}
                      </p>
                </div>
                <div class="task-concept__table-col task-concept__table-col--responsible">
                    <participants-list *ngIf="task.participants.length"
                        [participants]="task.participants"
                        [type]="'task'">
                    </participants-list>
                </div>
                <div class="task-concept__table-col task-concept__table-col--state">
                    <span-tooltip [text]="task.state"
                      [class]="'task-concept__task-state task-concept__task-state--' + (task.taskStateId | colors)"></span-tooltip>
                </div>
                <div class="task-concept__table-col task-concept__table-col--expiration">
                    <div class="common__cell-block">
                        <p [id]="'task-concept-expirationdate-'+task.taskId"
                            class="common__col-text"
                            matTooltip="{{task.expirationDate | convertDate:'without-time'}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            [matTooltipDisabled]="showTooltip('expirationdate-'+task.taskId)">
                            <span [id]="'span-task-concept-expirationdate-'+task.taskId"
                                class="task-list__expiration-date">
                                <i class="task-concept__warning-dot-icon lf-icon-step-final"
                                    [ngShow]="(task.expirationDate | expired) && !(task.taskStateId === 5 || task.taskStateId === 4 || task.taskStateId === 6)"></i>
                                {{task.expirationDate | convertDate:'without-time'}}</span>
                        </p>
                    </div>
                </div>
                <div class="task-concept__table-col task-concept__table-col--menu">
                    <ul class="common__options-row">
                        <li class="common__menu-options-row"
                            (click)="editTask('editar', task )">
                            <i [ngClass]="(taskConceptList.canCreate && (task.taskStateId !== 5 && task.taskStateId !== 6)) || (task.taskStateId === 5 && !task.repeatPatternReadonly && task.repeatPattern !== null && task.repeatPattern.nextRepeat !== null)?'lf-icon-editable-form':'lf-icon-visible'"
                                [matTooltip]="editOrView(task)"
                                matTooltipPosition="below"
                                matTooltipClass="below"></i>
                        </li>
                        <li class="common__menu-options-row"
                            [ngShow]="taskConceptList.canCreate && (task.taskStateId !== 5 && task.taskStateId !== 6)">
                            <i class="lf-icon-trash"
                                (click)="deleteTask(task)"
                                matTooltip="{{'common.remove' | afTranslate}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"></i>
                        </li>
                    </ul>
                    <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                </div>
            </div>
        </div>
    </div>
</div>
