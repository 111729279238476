import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from "lodash";

@Component({
  selector: 'protection-data-modal',
  templateUrl: './protection-data-modal.component.html'
})
export class ProtectionDataModalComponent implements OnInit {
  constructor(
    private globalCfg:GlobalCfgFactory,
    private activeModal:NgbActiveModal,
    private translate:AfTranslateFactory
  ) { }

  @Input() configuration:any;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown
  skin:string = this.globalCfg.skin;
  protectionDataModal:any = {
    icon: 'lf-icon-shield',
    title: this.translate.instant('protection-data-modal.data-protection-information'),
    description: this.translate.instant('protection-data-modal.info-header-modal'),
    dataTreatment: {
      fieldId: 'data-treatment',
      label: this.translate.instant('protection-data-modal.processing-personal-data'),
      fieldTypeId: 'rich',
      isEditable: true,
      required: true
    }
  };

  ngOnInit(){
    let that = this.protectionDataModal;
    that.languagesApp = cloneDeep(this.configuration.languagesApp);
    that.languagesSelected = cloneDeep(this.configuration.selectedLanguages);
    that.dataTreatment.description = cloneDeep(this.configuration.languageSelected.textDescription);
    if(this.configuration?.aditionalInformation){
        that.icon = 'lf-icon-information';
        that.title = this.translate.instant('channel-configuration.additional-info');
        that.description = this.translate.instant('protection-data-modal.additional-info-description');
        that.dataTreatment.label = this.translate.instant('protection-data-modal.want-know');
    }
  }

  changeActiveLanguagesSelected(selectedLanguage:any, addSelected?:any){
    let that = this.protectionDataModal;
    that.languagesSelected.forEach((language:any) => {
        if(language.active){
            language.textDescription = that.dataTreatment.description;
            language.active = false;
        }
    });
    selectedLanguage.active = true;
    that.errorActive = selectedLanguage.active && selectedLanguage.error;
    that.dataTreatment.description = selectedLanguage.textDescription?selectedLanguage.textDescription:'';
    that.dataTreatment.error = selectedLanguage.error;
    if(addSelected){
        that.languagesSelected.push(selectedLanguage);
    }
  }

  removeActiveLanguage(selectedLanguage:any){
    let that = this.protectionDataModal;
    for(let i = that.languagesSelected.length-1; i >= 0; i--){
        let language = that.languagesSelected[i];
        if(language.listValueId === selectedLanguage.listValueId){
            that.languagesSelected.splice(i, 1);
            break;
        }
    }
    if(selectedLanguage.active){
        let newSelected = that.languagesSelected[that.languagesSelected.length-1];
        newSelected.active = true;
        that.dataTreatment.description = newSelected.textDescription;
        that.dataTreatment.error = newSelected.error;
        that.errorActive = newSelected.error;
    }
    that.languagesApp[selectedLanguage.indexListLanguage].selected = false;
  }

  selectLanguage(selectedLanguage:any, textDefault?:any){
    let that = this.protectionDataModal;
    for(let i = 0; i < that.languagesApp.length; i++){
        let language = that.languagesApp[i];
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage){
            language.selected = true;
            language.textDescription = textDefault?that.dataTreatment.description:'';
            language.defaultOption = textDefault?true:false;
            let newSelectedLanguage = cloneDeep(language);
            this.changeActiveLanguagesSelected(newSelectedLanguage, true);
            break;
        }
    }
    this.dropdown.close();
  }

  changeField(){
    let that = this.protectionDataModal;
    that.languagesSelected.forEach((language:any) => {
        if(language.active){
            language.textDescription = that.dataTreatment.description;
            language.error = that.errorActive && !language.textDescription;
            that.dataTreatment.error = language.error;
            that.errorActive = language.error;
        }
    })
  }

  notSelectLanguage(language:any){
    let that = this.protectionDataModal;
    for(let languageSelected of that.languagesSelected){
        if(language.listValueId === languageSelected.listValueId){
            return false;
        }
    }
    return true;
  }

  validateFields(){
    let that = this.protectionDataModal;
    let errors = 0;
    let listSelectedLanguages: any = [];
    that.dataTreatment.error = false;
    that.languagesSelected.forEach((language:any, index: any) => {
        if(language.active){
            language.textDescription = that.dataTreatment.description;
        }
        language.error = !language.textDescription;
        that.errorActive = language.active && language.error;
        errors += language.error?1:0;
        if(!errors){
            let languageSelected = {
                conceptId: this.configuration.concept.conceptId,
                language: language.appLanguage,
                numOrder: index+1,
                text: language.textDescription
            }
            listSelectedLanguages.push(languageSelected);
        }
    });
    if(!that.dataTreatment.description){
        errors++;
        that.dataTreatment.error = true;
    }
    if(!errors){
        this.handleAccept(listSelectedLanguages);
    }
  }

  handleAccept(listSelectedLanguages:any) {
    this.activeModal.close({
      result:'ok',
      selectedLanguages: listSelectedLanguages
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }
}
