<div [ngClass]="{'field-type-email__field-type--error': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField) && !fieldTypeEmail.field.sequentialView}">
  <label class="common__input-block field-type-email__input-block"
      [ngClass]="{'common__show-alert': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField),
          'common__show-alert-email': fieldTypeEmail.field.error && fieldTypeEmail.inputText,
          'common__input-no-editable':fieldTypeEmail.field.noEditable,
          'common__input-block--poll-view':fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable && fieldTypeEmail.field.sequentialView,
          'common__input-block--custom-field':isLanding && !fieldTypeEmail.field.sequentialView}"
      [for]="'field-item-mail-'+fieldTypeEmail.field.id"
      [attr.disabled]="fieldTypeEmail.field.noEditable?true:null">
      <div class="common__label-block common__label-block--one-icon"
          [ngHide]="fieldTypeEmail.field.sequentialView && !fieldTypeEmail.field.noEditable">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField),
                      'common__input-text--no-editable':fieldTypeEmail.field.noEditable}">{{fieldTypeEmail.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="fieldTypeEmail.field.required">*</span>
          <div class="common__info-icon-block"
              [ngHide]="fieldTypeEmail.field.noEditable || !fieldTypeEmail.field.description">
              <tooltip-info [description]="fieldTypeEmail.field.description"
                  [field]="fieldTypeEmail.field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeEmail.field.references?.length && !fieldTypeEmail.field.noEditable">
              <tooltip-law [references]="fieldTypeEmail.field.references"
                  [field]="fieldTypeEmail.field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="fieldTypeEmail.field.relatedInfo?.length && !fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelated(fieldTypeEmail.field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
      </div>
      <div class="common__input-text-poll common__input-text-poll--email"
          [ngShow]="fieldTypeEmail.field.sequentialView && !fieldTypeEmail.field.noEditable"
          [ngClass]="{'common__input-text-poll--error': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField)}">
          <p class="field-type-text__custom-sequential-label">{{fieldTypeEmail.titleField}}
              <span class="common__text-required common__text-required--bool"
                  [ngShow]="fieldTypeEmail.field.required && !fieldTypeEmail.field.noEditable">*</span>
              <tooltip-info [description]="fieldTypeEmail.field.description"
                  [field]="fieldTypeEmail.field"
                  [isSequentialPoll]="fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable && fieldTypeEmail.field.sequentialView">
              </tooltip-info>
              <tooltip-law [references]="fieldTypeEmail.field.references"
                  [field]="fieldTypeEmail.field"
                  [ngShow]="fieldTypeEmail.field.references?.length"
                  [isSequentialPoll]="fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable && fieldTypeEmail.field.sequentialView">
              </tooltip-law>
          </p>
      </div>
      <div [ngClass]="{'common__input-poll':fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable && fieldTypeEmail.field.sequentialView, 'common__show-alert': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField) && fieldTypeEmail.field.sequentialView}">
          <i class="lf-icon-mail common__input-icon field-item__input-icon-16px"
              [ngClass]="{'field-item__input-icon-custom-email':isLanding && !fieldTypeEmail.field.sequentialView}"></i>
          <input [id]="'field-item-mail-'+fieldTypeEmail.field.id"
              class="common__input-content"
              [ngClass]="{'common__input-content-alert': (fieldTypeEmail.field.error || fieldTypeEmail.field.errorField),
                  'common__input-content-no-editable':fieldTypeEmail.field.noEditable,
                  'common__input-content--poll':fieldTypeEmail.field.poll && !fieldTypeEmail.field.noEditable && fieldTypeEmail.field.sequentialView}"
              type="text"
              [(ngModel)]="fieldTypeEmail.inputText"
              (blur)="validateEmail()"
              [readonly]="fieldTypeEmail.field.noEditable"
              [disabled]="fieldTypeEmail.field.noEditable">
          <i class="common__delete-content-icon"
              [ngClass]="{'lf-icon-close-round': isLanding,
                'lf-icon-close': !isLanding}"
              [ngHide]="fieldTypeEmail.inputText === '' || fieldTypeEmail.field.noEditable"
              (click)="resetEmailText()"></i>
      </div>
      <p [ngShow]="(fieldTypeEmail.field.error && fieldTypeEmail.inputText) && !fieldTypeEmail.field.poll"
          class="field-type-email__alert-message"
          [afTranslate]="'field-type-email.invalid-email'">El email introducido no es válido
      </p>
    <div class="field-item__blocker-box"
      *ngIf="fieldTypeEmail.field.error && fieldTypeEmail.field.blocker && !concept?.errorInForm?.length">
      <div class="field-item__blocker-triangle"></div>
      <i class="lf-icon-warning field-item__blocker-icon"></i>
      <p class="field-item__blocker-text"
        [afTranslate]="'field-items.blocker-field'">campo bloqueante
      </p>
    </div>
  </label>
</div>
