import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'unique'})
export class UniquePipe implements PipeTransform {

  transform(list:any, type:string):any {
    let keys: any = [];
    let uniqueList: any = [];
    if(list){
      list.forEach((item:any) => {
        let key:any = item[type];
        if (keys.indexOf(key) === -1) {
            keys.push(key);
            uniqueList.push(item);
        }
      });
    }
    return uniqueList;
  }
}
