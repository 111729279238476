import { cloneDeep, isArray } from "lodash";

import { Injectable } from '@angular/core';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { RegisterMixtapeService } from '@shared/services/register-mixpanel/register-mixtape.service';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { ActivatedRoute } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class FieldTypeListParentService {
  subscribers: any = {};

  constructor(private context: ContextFactory,
    private registerService: RegisterMixtapeService,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService,
    private route: ActivatedRoute) { }

  openWatchers(child:any){
      this.subscribers.resetFieldTypeListBroadcast = this.broadcastService.subscribe("resetFieldTypeList", (data:any) => {
        this.resetField(child, data)
    });
  }

  configureChanges(child:any){
    if(child.listValues?.length){
      let list = this.configureListValue(child);
      child.fieldTypeList.filterList = list;
    }
    if(child.field){
      this.configureField(child);
      this.configureWidthField(child);
    }
  }

  configureWidthField(child: any){
    if(child.field.evaluatedValue?.hasReferences && !child.field.poll){
      child.fieldTypeList.classWidthField = 'label-block--two-icon';
    }
    if(child.field.multiple){
      child.fieldTypeList.classWidthField += '-bubble';
    }
  }

  paintFields(child:any, listValues:any){
    listValues.forEach((value:any) => {
      if(value.isDefaultValue){
        value.fontColor = '#ffffff';
        if(!value.color){
            value.color = 'rgb(119, 209, 102)';
        }
        value.background = value.color;
        value.circle=true;
        child.fieldTypeList.selectItemName =  value.text;
      }else{
          value.background = '#ffffff';
          value.fontColor = '#000000'
          value.circle=false;
      }
    });
  }

  resetField(child:any, data:any){
    if (!data) {
      if (child.field && child.field.multiple) {
        child.fieldTypeList.listElementSelect.forEach((element:any) => {
          this.addOrDeleteSelectedValue(null, element, child);
        });
        if (child.listValues) {
            child.listValues.forEach((value: any)=>{
              delete value.selected;
            })
        }
      }else {
          this.resetItem(child);
      }
    } else if (!child.field.multiple && data.fieldId === child.field.id && child.preSelectItem && child.preSelectItem.length > 0) {
        this.resetItem(child);
    }
  }

  addOrDeleteSelectedValue(e:any, listElement:any, child:any){
    if (e) {
        e.stopPropagation();
        e.preventDefault();
    }
    if(!listElement.disabled){
      if(child.field.isTree && child.field.multiple){
          if(!listElement.children.length){
              this.catchItem(listElement, child);
          }
      }else{
          this.catchItem(listElement, child);
      }
    }
  }

  // configureMultilineLastSelect(listElement: any, child: any){
  //   if(child.field.uniqueLastSelect && child.field.multiline){
  //     // child.fieldTypeList.listTreeLastSelect = [];
  //     // child.fieldTypeList.listTreeLastSelect.push(listElement);
  //     // this.getParentValues(child, listElement.parentId);
  //     // child.fieldTypeList.listTreeLastSelect = child.fieldTypeList.listTreeLastSelect.reverse();
  //   }
  // }

  catchItem(listElement: any, child: any){
    child.fieldTypeList.hiddenPillAllOption = false;
    if(child.field.typeList === 'checkAll' && listElement.listValueId === 'checkAll' && listElement.selected){
        child.fieldTypeList.markAll = false;
        listElement.selected = false;
        this.addOrRemoveAllValuesList(listElement, child);
    }else{
        if(listElement.selected){
          if(child.field.filials && listElement.children && listElement.children.length){
            this.addOrRemoveChildrenSelected(listElement, child, false);
          }
          this.removeSelected(listElement, child);

        }else{
          listElement.selected = true;
          if(child.field.typeList === 'checkAll'){
              this.configureSpecialSelectionList(listElement, child);
          }else{
              child.fieldTypeList.listElementSelect.push(listElement);
              if(child.field.filials && listElement.children && listElement.children.length){
                this.addOrRemoveChildrenSelected(listElement, child, true);
              }
          }
        }
        this.onItemSelected(child);
        // this.configureMultilineLastSelect(listElement, child);
    }
    if(child.field.uniqueLastSelect && child.field.multiline){
      this.configureShowTree(child);
    }
  }

  configureShowTree(child: any){
    child.fieldTypeList.treeSelectedElement = cloneDeep(child.field.listValues);
    child.fieldTypeList.treeSelectedElement.forEach((listValue:any) => {
      if(listValue.children?.length){
        this.recursiveConfigureTree(listValue);
      }
    });
  }

  recursiveConfigureTree(listValueParent: any){
    listValueParent.selectedTree = false;
    for(let i = 0; i < listValueParent.children.length; i++){
      let listValue: any = listValueParent.children[i];
      if(listValue.children?.length){
        this.recursiveConfigureTree(listValue);
      }
      if(listValue.selected || listValue.selectedTree){
        listValueParent.selectedTree = true;
      }
    };
  }

  removeSelected(listElement: any, child: any){
    for(let i = 0; i < child.fieldTypeList.listElementSelect.length; i++){
      if(child.field.typeList === 'checkAll' && listElement.listValueId !== 'all'){
          this.removeSelectedCheckAll(child);
      }
      if((listElement.listValueId && listElement.listValueId === child.fieldTypeList.listElementSelect[i].listValueId)
        || (listElement.referenceId && listElement.referenceId === child.fieldTypeList.listElementSelect[i].referenceId)){
          child.fieldTypeList.listElementSelect[i].selected = false;
          child.fieldTypeList.listElementSelect.splice(i, 1);
          break;
      }
    }
  }

  addOrRemoveChildrenSelected(listElement: any, child: any, selected:boolean=false){
    listElement.children.forEach((item:any) => {
      if(item.selected && selected){
        this.removeSelected(item, child);
      }
      item.selected = selected;
      if(item.selected){
        child.fieldTypeList.listElementSelect.push(item);
      }else{
        this.removeSelected(item, child);
      }
      if(item.children && item.children.length){
        this.addOrRemoveChildrenSelected(item, child, selected);
      }
    });
  }

  configureSpecialSelectionList(listElement: any, child: any){
      if(listElement.selected && listElement.listValueId !== 'checkAll'){
          for(let i = child.fieldTypeList.listElementSelect.length-1; i >= 0; i--){
              if(listElement.listValueId === 'all' && listElement.listValueId !== child.fieldTypeList.listElementSelect[i].listValueId ){
                  child.fieldTypeList.listElementSelect[i].selected = false;
                  child.fieldTypeList.listElementSelect.splice(i, 1);
              }else if(listElement.listValueId !== 'all' && child.fieldTypeList.listElementSelect[i].listValueId === 'all'){
                      child.fieldTypeList.listElementSelect[i].selected = false;
                      child.fieldTypeList.listElementSelect.splice(i, 1);
              }
          }
          if(listElement.listValueId === 'all'){
              child.fieldTypeList.hiddenPillAllOption = true;
              this.removeSelectedCheckAll(child);
          }
          child.fieldTypeList.listElementSelect.push(listElement);
      }else if(listElement.listValueId === 'checkAll'){
          for(let i = child.fieldTypeList.listElementSelect.length-1; i >= 0; i--){
              child.fieldTypeList.listElementSelect[i].selected = false;
              child.fieldTypeList.listElementSelect.splice(i, 1);
          }
          this.addOrRemoveAllValuesList(listElement, child);
      }
  }

  removeSelectedCheckAll(child: any){
      for(let i = 0; i < child.fieldTypeList.filterList.length; i++){
          if(child.fieldTypeList.filterList[i].listValueId === 'checkAll'){
              child.fieldTypeList.markAll = false;
              child.fieldTypeList.filterList[i].selected = false;
          }
      }
  }

  addOrRemoveAllValuesList(listElement: any, child: any){
      child.fieldTypeList.listElementSelect = [];
      for(let i = 0; i < child.fieldTypeList.filterList.length; i++){
          let listElementList : any = child.fieldTypeList.filterList[i];
          if(listElementList.listValueId !== 'all' && listElementList.listValueId !== 'checkAll'){
              listElementList.selected = listElement.selected?true:false;
              if(listElement.selected){
                  child.fieldTypeList.listElementSelect.push(listElementList);
              }
          }
      }
  }

  onItemSelected(child:any) {
    child.selectionAsArray? this.configureListArray(child) : this.configureListVarchar(child);
  }

  configureListArray(child:any) {
    let selection : any = child.fieldTypeList.listElementSelect.map((item:any)=>{ return item.listValueId; });
    let itemSelected={
      newValue: selection,
      showlist: child.fieldTypeList.showList,
      listSelected: child.fieldTypeList.listElementSelect
    }
    child.itemSelected.emit(itemSelected);
  }

  configureListVarchar(child: any){
    let nvarcharValue : any ='';
    for(let i = 0; i < child.fieldTypeList.listElementSelect.length; i++){
      let selected : any = child.fieldTypeList.listElementSelect[i];
      nvarcharValue += child.fieldTypeList.listElementSelect.length-1 === i? selected.listValueId : selected.listValueId+'|'
    }
    let itemSelected={
      newValue: nvarcharValue,
      listSelected: child.fieldTypeList.listElementSelect
    }
    child.itemSelected.emit(itemSelected);

  }

  resetItem(child: any) {
    child.fieldTypeList.selectItemName = '';
    child.fieldTypeList.selectedItemToList = {};
    child.fieldTypeList.references = [];
    child.fieldTypeList.listTreeLastSelect = [];
    let itemSelected={
      newValue: null,
      showlist: child.fieldTypeList.showList
    }
    child.itemSelected.emit(itemSelected);
  }

  getSelectValues(child: any){
    if(child.field.multiple){
        this.extractMultilineList(child);
    } else {
        this.getListValueSelected(child.listValues, child.preSelectItem, child);
        if (child.fieldTypeList.findItem) {
            child.fieldTypeList.selectItemName = child.fieldTypeList.findItem.text;
            child.fieldTypeList.selectedItemToList = child.fieldTypeList.findItem;
            this.configureUniqueLastSelect(child.fieldTypeList.findItem, child);
        }
    }
  }

  configureUniqueLastSelect(item: any, child: any){
    if(child.field.uniqueLastSelect && child.field.multiline){
      child.fieldTypeList.listTreeLastSelect = [];
      child.fieldTypeList.listTreeLastSelect.push(item);
      this.getParentValues(child, item.parentId);
      child.fieldTypeList.listTreeLastSelect = child.fieldTypeList.listTreeLastSelect.reverse();
    }
  }

  getParentValues(child: any, parentId: any){
    for(let i = 0; i < child.field.listValues.length; i++){
      let listValue: any = child.field.listValues[i];
        if(listValue.listValueId === parentId){
          child.fieldTypeList.listTreeLastSelect.push(listValue);
          break;
        }else if(listValue.children?.length){
          this.getChildrenValues(child, listValue.children, parentId);
        }
    }
  }

  getChildrenValues(child: any, childrenList:any, parentId: any){
    for(let i = 0; i < childrenList.length; i++){
        let childrenListValue: any = childrenList[i];
        if(childrenListValue.listValueId === parentId){
          child.fieldTypeList.listTreeLastSelect.push(childrenListValue);
          this.getParentValues(child, childrenListValue.parentId);
          break;
        }else if(childrenListValue.children?.length){
          this.getChildrenValues(child, childrenListValue.children, parentId);
        }
    }
  }

  extractMultilineList(child: any){
    child.fieldTypeList.listElementSelect = [];
    let items: any;
    if (isArray(child.preSelectItem)) {
        items = child.preSelectItem;
        child.selectionAsArray = true;
    } else {
      items = child.preSelectItem.toString().indexOf('|') !==-1? child.preSelectItem.split('|'): [child.preSelectItem];
    }
    items = items.filter((item:any, i:any) => {
      return items.indexOf(item) === i;
    })
    items.forEach((item:any) => {
      child.fieldTypeList.findItem = null;
      if(child.listValues){
        if(!child.field.noParseId){
            item = isNaN(item)?item:parseInt(item);
        }
        this.getListValueSelected(child.listValues, item, child);
        if(child.fieldTypeList.findItem !== null){
            child.fieldTypeList.listElementSelect.push(child.fieldTypeList.findItem);
        }
      }
    });
    if(child.field.uniqueLastSelect && child.field.multiline){
      this.configureShowTree(child);
    }
  }

  getListValueSelected(listValues:any, preSelectItem:any, child:any ){
    for(let i = 0; i < listValues.length; i++){
        let value : any = listValues[i];
        if(value.listValueId && (value.listValueId === preSelectItem)){
            value.selected = true;
            child.fieldTypeList.findItem = value;
        }else if((child.field.isTree && value.children && child.fieldTypeList.findItem === null)
              || (child.field.filials && value.children && value.children.length)){
            this.getListValueSelected(value.children, preSelectItem, child);
        }

        if(child.fieldTypeList.findItem !== null){
            break;
        }
    }
  }

  configureColors(child:any){
    child.listValues.forEach((value:any) => {
      if(child.fieldTypeList.selectItemName === value.text){
        value.value = true;
        if(!value.color){
            value.color = 'rgb(119, 209, 102)';
        }
        value.background = value.color ;
        value.fontColor = '#fff';
        value.circle = true;
        child.fieldTypeList.selectItemId = value.listValueId;
      } else if(!value.color) {
        value.background = '#fff' ;
        value.circle = false;
        value.fontColor = 'rgb(119, 209, 102)';
        value.color = 'rgb(119, 209, 102)';

      }
    });
  }

  selectedField(listValue:any, child:any){
    if(!child.field.noEditable){
      child.listValues.forEach((value:any)=> {
        if(value.listValueBaseId === listValue.listValueBaseId && child.fieldTypeList.selectItemId !== listValue.listValueId){
          value.background = value.color;
          value.fontColor = '#fff';
          value.circle = true;
        } else {
            value.circle = false;
            value.background = '#fff';
            value.fontColor = 'black';
        }
        if(value.listValueId ===listValue.listValueId && child.fieldTypeList.selectItemId !== listValue.listValueId){
            child.fieldTypeList.selectItemName = listValue.text;
            child.fieldTypeList.selectItemId = listValue.listValueId;
            value.value =listValue.text;
            this.selectItem(value, child);
        }else if(value.listValueId ===listValue.listValueId && child.fieldTypeList.selectItemId === listValue.listValueId){
            child.fieldTypeList.selectItemName= '';
            child.fieldTypeList.references = [];
            child.fieldTypeList.selectItemId = null;
            value.value =listValue.text;
            let itemSelected={
              newValue: null,
              showlist: child.fieldTypeList.showList
            }
            child.itemSelected.emit(itemSelected);
        }
        if(!value.listValueBaseId){
            if(!value.color){
                value.color = 'rgb(119, 209, 102)';
            }
            if(value.listValueId ===listValue.listValueId && child.fieldTypeList.selectItemId === listValue.listValueId){
                value.circle=true;
                value.fontColor = 'white';
                value.background = value.color;
            }else{
                value.circle=false;
                value.background = '#fff';
                value.fontColor = 'black';
            }
        }
      });
    }
  }

  selectItem( item:any, child:any, e:any = null){
    // if(e){
    //     e.stopPropagation();
    //     e.preventDefault();
    // }
    if(!item.disabled &&!child.field.noEditable){
        if(child.field.error){
          child.field.error = false;
        }
        this.configureUniqueLastSelect(item, child);
        child.fieldTypeList.selectItemName = item.text;
        child.fieldTypeList.selectedItemToList = item;
        let id : any = item.listValueId;
        child.fieldTypeList.references = [];
        if(item.referenceId){
            id = item.referenceId;
            child.fieldTypeList.references.push(item);
        }

        if(child.field.clean === true){
            child.fieldTypeList.selectItemName = '';
            child.fieldTypeList.selectedItemToList = {};
            child.fieldTypeList.listTreeLastSelect = [];
        }

        let listSimpleSequential : any = child.field.poll && child.field.sequentialView && !child.field.multiple && !child.field.isHighlight && !child.field.numericalScale && child.isLanding;
        if(child.field.numericalScale || listSimpleSequential){
            let id : any = null;
            if(!item.selected){
              this.allToFalse(child)

              item.selected = true;
              id = item.listValueId;
            }else{
              this.allToFalse(child)
            }
        }
        let itemSelected={
          newValue: id,
          showlist: child.fieldTypeList.showList
        }
        child.itemSelected.emit(itemSelected);
        // if(child.field.fieldTypeId === 'tree'){
        //   child.fieldTypeList.showList = false;
        // }
    }
  }

  allToFalse(child:any){
    child.fieldTypeList.filterList.forEach((element:any) => {
      element.selected = false
    });
  }

  selectedButtonField(listValue:any, child:any){
    if (listValue.numOrder < 4) {
      if (!child.field.noEditable) {
        child.listValues.forEach((value:any) => {
            value.selected = false;
            if(value.listValueId === listValue.listValueId && child.fieldTypeList.selectItemId !== listValue.listValueId){
                value.selected = true;
                child.fieldTypeList.showLessInfo = true;
                child.fieldTypeList.selectItemId = value.listValueId;
                let itemSelected={
                  newValue: value.listValueId,
                  showlist: child.fieldTypeList.showList
                }
                child.itemSelected.emit(itemSelected);
            }else if(value.listValueId === listValue.listValueId && child.fieldTypeList.selectItemId === listValue.listValueId){
                child.fieldTypeList.selectItemId = '';
                let itemSelected={
                  newValue: null,
                  showlist: child.fieldTypeList.showList
                }
                child.fieldTypeList.showLessInfo = false;
                child.itemSelected.emit(itemSelected);
            }
        });
      }
    }else{
      let resgisterItem : any ={
        'Distinct ID': this.context.view.extraData.idUsuarioFieldValue,
        'Client ID': "76863",
        'event':'enlace externo ICAM',
        'Centinela product': 'icam'
    }
    this.registerService.register(resgisterItem);
    window.open('https://biblioteca.icam.es/consultas-juridicas/', '_blank')
    }
  }

  createNewValue(e:any, child: any){
      e.stopPropagation();
      e.preventDefault();
      child.fieldTypeList.addNew = true;
  }

  insertNewValue(e:any, child:any){
    e.stopPropagation();
    child.fieldTypeList.listValueSelected && child.fieldTypeList.listValueSelected.listValueId? this.modifyListValue(child) : this.addNewValue(child);
  }

  modifyListValue(child:any){
    child.fieldTypeList.listValueSelected.text = child.fieldTypeList.newValue;
    this.apiService.patch('listvalues', child.fieldTypeList.listValueSelected.listValueId, child.fieldTypeList.listValueSelected)
    .then((data:any)=> {
        child.fieldTypeList.newValue = '';
        child.fieldTypeList.listValueSelected = null;
        child.fieldTypeList.addNew = false;
    });
  }

  addNewValue(child:any){
    if(child.fieldTypeList.newValue && child.fieldTypeList.newValue !=='' ){
      let newValue : any = {
          text: child.fieldTypeList.newValue,
          isUserValue: true
      }
      this.apiService.add('conceptobjects/addlistvalue/' + child.field.evaluationId + '/' + child.field.fieldId, newValue)
        .then((data:any)=> {
            child.listValues.push(data);
            child.fieldTypeList.newValue = '';
            child.fieldTypeList.listValueSelected = null;
            child.fieldTypeList.addNew = false;
            child.fieldTypeList.findDropdown = '';
            child.fieldTypeList.filterList = this.commonUtilsService.paintNewItemArray(child.listValues);
        });
    }
  }

  editListValueElement(e:any, listValue: any, child:any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeList.listValueSelected = listValue;
    child.fieldTypeList.newValue = listValue.text;
    child.fieldTypeList.addNew = true;
  }

  deleteListValue(e:any, listValue:any, child:any){
    e.stopPropagation();
    e.preventDefault();
    this.useInOther(listValue, child);
  }


  useInOther(listValue:any, child:any){
      this.apiService.get('listvalues/canremove/'+child.conceptObjectId , listValue.listValueId)
          .then((data:any)=>{
              if(data === true){
                  listValue.deleteError = false;
                  this.deleteValue(listValue, child);
              }else{
                  listValue.deleteError = true;
              }
          }, (errorData:any)=>{})
  }

  deleteValue(listValue: any, child:any){
      this.apiService.delete('listvalues', listValue.listValueId)
          .then((data:any)=> {
              let index : any = child.listValues.findIndex((item:any)=>{return listValue.listValueId === item.listValueId});
              child.listValues.splice(index, 1);
              child.fieldTypeList.filterList = this.commonUtilsService.paintNewItemArray(child.listValues);
          });
  }

  resetListValue(e:any, child:any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeList.newValue = '';
    child.fieldTypeList.listValueSelected = null;
    child.fieldTypeList.addNew = false;
  }

  changeList(child: any){
    child.fieldTypeList.filterList = child.fieldTypeList.findDropdown !== '' && child.listAll?.length? child.listAll: child.listValues;
  }

  showReference(e:any, child: any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeList.referencesClick = !child.fieldTypeList.referencesClick;
    child.showFieldReference.emit();

  }

  resetSearch(e:any, child:any){
    e.stopPropagation();
    e.preventDefault();
    child.fieldTypeList.findDropdown = '';
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any, child:any){
    e.stopPropagation();
    e.preventDefault();
    let relatedInfo={
      relatedInfo: fieldRelatedInfo
    }
    child.openRelatedInfo.emit(relatedInfo);
  }

  customBackground(color:any){
    let hex : any = parseInt(color.substring(1), 16);
    let rgb : any = {
        r: hex >> 16,
        g: (hex & 0x00FF00) >> 8,
        b: (hex & 0x0000FF)
    }
    return 'rgba('+rgb.r+','+rgb.g+','+rgb.b+',0.36)';
  }

  configureField(child:any){
    let field : any = child.field;
    if(field.isHighlight === true){
      field.multiple = false;
      field.isOpen = false;
    }
    if(child.listValues && child.listValues.length && child.listValues[0].numOrder){
        child.fieldTypeList.orderList = ['numOrder'];
    }else if(field.fieldId === 'productList' || field.fieldId === 'associatedImplantation' || field.fieldId === 'parentClient' || field.fieldId === 'associatedClient' || field.fieldId ==='task-client-id' || field.fieldId ==='user-languages' || field.fieldId === 'clientInfoDuplicate'){
        child.fieldTypeList.orderList = ['text'];
    }else if(child.orderBy === 'reverseId'){
        child.fieldTypeList.orderList = ['listValueId'];
        child.fieldTypeList.orderAsc = true;
    } else if (child.orderBy) {
        child.fieldTypeList.orderList = [child.orderBy];
    }
    child.fieldTypeList.titleField = field.extendedLabel && field.extendedLabel !== ''? field.extendedLabel : field.label;
    child.fieldTypeList.id = !field.id || field.generateId? this.commonUtilsService.createId() : child.field.fieldId;
    if(child.preSelectItem && child.preSelectItem !== null && child.listValues && child.listValues.length){
        child.fieldTypeList.findItem = null;
        this.getSelectValues(child);
    }else if(child.fieldTypeList?.selectItemName !== '' && !child.preSelectItem){
        child.fieldTypeList.selectItemName = '';
        child.fieldTypeList.selectedItemToList = {};
        child.fieldTypeList.listElementSelect = [];
        child.fieldTypeList.listTreeLastSelect = [];
    }

    if(child.fieldTypeList.selectItemName !=='' && field.isHighlight){
        this.configureColors(child);
    }

    if(field.isOpen || field.fieldTypeId ==='listp' || field.fieldTypeId ==='list'){
        child.fieldToFind = 'text';
        child.disabledSearch = false;
    }
    child.fieldTypeList.field = child.field;
    child.disabledSearch = !!(!child.fieldToFind || child.fieldToFind === '');
  }

  configureListValue(child:any){
    let list = child.listValues;
    if(child.fieldTypeList.selectItemName ==='' && list[0].color){
      this.paintFields(child, list);
    }
    if(child.fieldTypeList.selectItemName !== ''){
      list.forEach((value: any) => {
        if(value.referenceId && value.text === child.fieldTypeList.selectItemName){
          child.fieldTypeList.references.push(value)
        }
      });
    }
    return list;
  }

  toggleAll(event:any, child:any) {
    let that = child.fieldTypeList;
    that.markAll = !that.markAll;
    let filterListSearch: any = this.commonUtilsService.findByWord(that.filterList, that.findDropdown, [child.fieldToFind] );
    for (let i = 0; i < filterListSearch.length; i++) {
        let listElement = filterListSearch[i];
        if (!listElement.disabled && listElement.selected !== that.markAll) {
            this.addOrDeleteSelectedValue(event, listElement, child);
        }
    }
  }

  configureFieldTypeList(child:any){
    return {
      markAll: false,
      showList: false,
      showListMultiple: false,
      findItem: null,
      findDropdown: '',
      listValueSelected: null,
      orderAsc: false,
      listElementSelect: [],
      references: [],
      elementList: '',
      selectItemName: '',
      selectedItemToList: {},
      classWidthField:'label-block--one-icon',
      filterList: [],
      orderList: ['listValueId'],
      selectItemId:'',
      addNew:false,
      newValue:'',
      referencesClick: false,
      field: null,
      hiddenPillAllOption: false,
      listTreeLastSelect: []
    }
  }

  clickMenu(child: any){
    $(document).off('click', (event)=>{this.closeDropdown(event, child)});
    $(document).on('click', (event)=>{this.closeDropdown(event, child)});
  }

  closeDropdown(event:any, child: any){
    let dropdownElement: any = document.getElementById('dropdown-field-list-'+child.fieldTypeList.id);
    if(dropdownElement && dropdownElement.getAttribute('aria-expanded') !== 'true'){
        child.fieldTypeList.showList = false;
    }
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
