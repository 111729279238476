import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { BoxCommunicationChannelParentService } from '../utils/box-communication-channel-parent.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { DownloadService } from '@shared/services/download/download.service';
import { ExportServiceService } from '@shared/services/export-service/export-service.service';
import { DateConverterService } from '@shared/services/date-converter/date-converter.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Component({
  selector: 'box-communication-channel-default',
  templateUrl: './box-communication-channel-default.component.html'
})
export class BoxCommunicationChannelDefaultComponent implements OnChanges, OnDestroy {
  skin: any = this.globalCfg.skin;
  skinsAccepted: boolean = this.skin==='steel-blue' || this.skin==='gm-law' || this.skin==='dark-blue'
  isLanding: any = false;
  userPermissions: any = this.permissions.getPermissions();
  boxCommunicationChannel:any = {
    showCommentBox: false,
    showCommentList: true,
    commentList: [],
    voiceRecord: {},
    unreadAmountManager: 0,
    unreadAmountApplicant: 0,
    fullScreen: false,
    hasPermissions: this.userPermissions !== 'none',
    disabledButtons: false,
    pdfInfo: { typePdf: 'communication-channel' },
    configPdf: {}
  }
  constructor( private permissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private boxCommunicationChannelParent: BoxCommunicationChannelParentService,
    private modalService: ModalServiceService,
    private downloadService: DownloadService,
    private exportService: ExportServiceService,
    private dateConverterService: DateConverterService,
    private translate: AfTranslateFactory) {}

  @Input() concept : any = null;
  @Input() public : any = null;
  @Input() fullScreenBox : any = null;

  @Output() catchFullScreen = new EventEmitter();

  ngOnChanges(){
    this.boxCommunicationChannelParent.onChanges(this);
  }

  newComment(){
    this.boxCommunicationChannelParent.newComment(this)
  }

  cancelComment(comment:any){
    this.boxCommunicationChannelParent.cancelComment(comment, this)
  }

  createNewComment(text:any){
    this.boxCommunicationChannelParent.createNewComment(text, this)
  }

  closeComment(){
    this.boxCommunicationChannel.showCommentBox = false;
    this.boxCommunicationChannel.showCommentList = true;
    this.boxCommunicationChannel.hasVoiceRecord = false;
    this.boxCommunicationChannel.voiceRecord = {};
    this.boxCommunicationChannel.commentText = '';
  }

  maximizeScreen(keepClassBox: boolean=false){
    this.boxCommunicationChannelParent.maximizeScreen(keepClassBox, this)
  }

  changeComment(comment:any){
    this.boxCommunicationChannelParent.changeComment(comment, this)
  }

  markAsRead(comment:any){
    this.boxCommunicationChannelParent.markAsRead(comment, this)
  }

  openRecordVoice(){
    this.boxCommunicationChannelParent.recordVoice(this);
  }

  listenVoiceRecord(comment:any = null, isLanding: boolean = false){
    let voiceRecorder: any = comment !== null? comment : this.boxCommunicationChannel.voiceRecord
    this.boxCommunicationChannelParent.listenVoiceRecord(voiceRecorder, isLanding, this);
  }

  editTextArea(){
    this.boxCommunicationChannelParent.editTextArea(this);
  }

  downloadPdf(){
      let config: any = {
          conceptObjectId: this.concept.conceptObjectId,
          idPrint: 'pdf-historic-communication-channel',
          uploadFile: true,
          landscape: false,
          successCallback: (uploadResponse:any, blob:any) => {
              this.concept.documentation.documents.push(uploadResponse);
              this.concept.documentation.totalDocuments++;
              this.downloadService.triggerDownload(blob, uploadResponse.name);
              this.modalService.adviceModal(this.translate.instant('documentation-list.export-list-ok').replace('{0}', uploadResponse.name), 'info:accept:check-round');
          }
      };

      config.exportDate = this.dateConverterService.setTimezone(new Date());
      config.nameDocument = this.getNameDocument();
      this.boxCommunicationChannel.configPdf = config;
      let that = this;
      setTimeout(function () {
          let htmlPrint = $('#pdf-historic-communication-channel').html();
          config.htmlPrint = htmlPrint;
          that.exportService.toPdf(config);
      });
  }

  getNameDocument(){
    let name : any = 'sistemademensajeria';
    let nameOrigin : any = 'sistemademensajeria';
    let nameIdx : any = 0;
    let existingReport:any;
    do {
        if (nameIdx) {
            name = nameOrigin+' (' + nameIdx + ')';
        }
        existingReport = this.concept.documentation.documents.find((item:any)=>
          item.extension.toLowerCase() === 'pdf' && (item.name.substr(0, item.name.indexOf('.pdf'))).toLowerCase() === name.toLowerCase()
        );
        nameIdx++;
    } while (existingReport);

    return name + '.pdf';
  }

  ngOnDestroy(){
    this.boxCommunicationChannelParent.ngOnDestroy();
  }
}
