<div class="concept-form common__block-concept"
    [ngClass]="{'common__block-concept--border': currentComponent === 'concept' || currentComponent === 'editConcept'}"
    *ngIf="conceptForm.configuredVisibility">
    <states-process-default class="concept-form__block-states-process"
        *ngIf="conceptForm.concept?.conceptObjectId && conceptForm.concept?.processStates && conceptForm.hasProcesses"
        [type]="'processConcept'"
        [typeView]="'normal'"
        [concept]="conceptForm.concept"
        [item]="conceptForm.concept"
        [userPublic]="conceptForm.userPublic"
        (changeResponsible)="changeResponsible($event)"
        (changeLockedEdit)="changeLockedEdit($event)">
    </states-process-default>
    <div class="concept-form__block-form-concept"
      id="block-concept-form">
      <div class="concept-form__fields-block concept-form__fields-block--concept"
          [ngClass]="{'concept-form__fields-block--view': conceptForm.concept?.conceptObjectId === 'view',
                  'concept-form__fields-block--big-window': currentComponent === 'conceptWindow'}">
          <concept-steps-default *ngIf="conceptForm.viewConcept === 'steps' && conceptForm.filterContainers?.length > 1"
              [concept]="conceptForm.concept"
              [filterContainers]="conceptForm.filterContainers"
              [changeStepsConcept]="conceptForm.changeStepsConcept">
          </concept-steps-default>
          <div *ngFor="let fieldContainer of conceptForm.concept?.fieldContainers; let index = index; trackBy: trackContainer">
              <div [id]="'grupo-id-'+fieldContainer.fieldContainerId"
                *ngIf="(fieldContainer.show && fieldContainer.showWithProperty && !fieldContainer.hidden && fieldContainer.active) || (!fieldContainer.active && fieldContainer.hasFormu)"
                class="concept-form__container-fields"
                [ngShow]="fieldContainer.active"><!-- Para comprobar visibilidades -->
                <div class="concept-form__group-title-block">
                  <div class="concept-form__block-icons"
                      [ngShow]="fieldContainer.references?.length || fieldContainer.relatedInfo?.length">
                      <tooltip-law
                          [concept]="conceptForm.concept"
                          [references]="fieldContainer.references"
                          [type]="'container'"
                          class="concept-form__icon-group"></tooltip-law>
                      <i class="lf-icon-search-text concept-form__icons"
                          (click)="openRelatedInfo(fieldContainer.relatedInfo)"
                          [ngShow]="fieldContainer.relatedInfo?.length"
                          matTooltip="'common.related-info' | afTranslate"
                          matTooltipPosition="below"
                          matTooltipClass="below">
                      </i>
                  </div>
                  <div class="concept-form__tooltip-info"
                      [ngShow]="fieldContainer.showInfo && !conceptForm.openLaw">
                      <p class="concept-form__tooltip-inner-text"
                          *ngFor="let reference of fieldContainer.references">
                          {{reference.text}}
                      </p>
                  </div>
                  <i class="lf-icon-important concept-form__icon-arrow"
                      [ngShow]="fieldContainer.isHighlight"></i>
                  <span class="concept-form__text-title-group"
                      [ngClass]="{'concept-form__text-title-group--highlight': fieldContainer.isHighlight}">{{fieldContainer.name}}</span>
                  <div [ngHide]="conceptForm.concept?.productTypeId === 4 || conceptForm.concept?.poll || currentComponent === 'editConcept' || conceptForm.userPermissions === 'none'">
                      <i class="lf-icon-kebab-menu concept-form__icon-share-menu"></i>
                      <div class="concept-form__menu-share">
                          <i class="lf-icon-share concept-form__icon-share"
                              (click)="shareFieldContainer(fieldContainer)"
                              [matTooltip]="'share-document.share-fieldcontainer' | afTranslate"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                          </i>
                      </div>
                  </div>
              </div>
              <form class="concept-form__form-concept">
                  <div *ngFor="let field of fieldContainer.fields; trackBy: trackContainer">
                      <field-item *ngIf="(field.show && field.showWithProperty && fieldContainer.active) || (!fieldContainer.active && field.itsFormu && field.show && field.showWithProperty)"
                        [ngShow]="!field.hidden && fieldContainer.active"
                        [fieldInfo]="field"
                        [isPrivate]="fieldContainer.private"
                        [evaluatedConcepts]="conceptForm.concept?.evaluatedFields"
                        [concept]="conceptForm.concept"
                        [evaluationId]="evaluationId"
                        [conceptLocked]="conceptForm.conceptLocked"
                        [fieldContainer]="fieldContainer"
                        (createForm)="createNewForm($event)"
                        (evaluatedConceptChange)="changesInEvaluatedConcept($event)"
                        (evaluatedConceptChangeAdditional)="changesInEvaluatedConceptAdditional($event)"
                        (changeConceptFromField)="changeConceptFromField($event.template)"
                        (launchAction)="launchActionOn($event)">
                      </field-item>
                  </div>
                  <div *ngFor="let subContainers of fieldContainer.subContainers; let index = index; trackBy: trackContainer">
                      <div *ngIf="(subContainers.show && subContainers.showWithProperty && !subContainers.hidden && fieldContainer.active) || (!fieldContainer.active && subContainers.hasFormu)"
                        [ngShow]="fieldContainer.active"
                        class="concept-form__block-subcontainer"
                        [id]="'subgrupo-id-'+subContainers.fieldContainerId"
                        [ngClass]="{'concept-form__block-subcontainer--no-border':subContainers.hideContainer}">
                          <div class="concept-form__subcontainer"
                              *ngIf="!subContainers.poll && (!subContainers.multiple || currentComponent === 'editConcept')"
                              [ngClass]="{'concept-form__subcontainer--with-border':!subContainers.hideContainer}">
                              <div class="concept-form__subcontainer-block"
                                  [ngClass]="{'concept-form__hide-container': subContainers.hideContainer}">
                                  <div class="concept-form__block-icons"
                                      [ngShow]="subContainers.references.length || subContainers.relatedInfo.length">
                                      <tooltip-law
                                          class="concept-form__icon-subgroup concept-form__icon-subgroup--laws"
                                          [type]="'container'"
                                          [concept]="conceptForm.concept"
                                          [references]="subContainers.references">
                                      </tooltip-law>
                                      <i class="lf-icon-search-text concept-form__icons concept-form__icons--info-subcontainer"
                                          (click)="openRelatedInfo(subContainers.relatedInfo)"
                                          [ngShow]="subContainers.relatedInfo.length"
                                          matTooltip="{{'common.related-info' | afTranslate }}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above">
                                      </i>
                                  </div>
                                  <div class="concept-form__tooltip-info concept-form__tooltip-info--subgroup"
                                      [ngShow]="subContainers.showInfo && conceptForm.openLaw === false">
                                      <p class="concept-form__tooltip-inner-text"
                                          *ngFor="let subReference of subContainers.references">
                                          {{subReference.text}}
                                      </p>
                                  </div>
                                  <div class="concept-list__cell-block">
                                      <p [id]="'concept-form-sub-container-'+index+'-'+subContainers.fieldContainerId"
                                          class="concept-form__subcontainer-text concept-form__subcontainer-text--ellipsis"
                                          [ngClass]="{'concept-form__subcontainer-text--highlight': subContainers.isHighlight}"
                                          matTooltip="{{subContainers.name}}"
                                          matTooltipPosition="below"
                                          matTooltipClass="below"
                                          [matTooltipDisabled]="showTooltip('concept-form-sub-container-'+index+'-'+subContainers.fieldContainerId)">
                                          <i class="lf-icon-important concept-form__icon-arrow"
                                              [ngShow]="subContainers.isHighlight"></i>
                                          <span class="concept-form__text-title-group concept-form__text-title-group--subgroup"
                                              [ngClass]="{'concept-form__text-title-group--highlight': subContainers.isHighlight}"
                                              [id]="'span-concept-form-sub-container-'+index+'-'+subContainers.fieldContainerId">
                                              {{subContainers.name}}
                                          </span>
                                      </p>
                                      <div [ngHide]="conceptForm.concept?.productTypeId === 4 || conceptForm.concept?.poll || currentComponent === 'editConcept' || conceptForm.userPermissions === 'none'">
                                          <i class="lf-icon-kebab-menu concept-form__icon-share-menu"></i>
                                          <div class="concept-form__submenu-share">
                                              <i class="lf-icon-share concept-form__icon-share-submenu"
                                                  (click)="shareFieldContainer(subContainers)"
                                                  [matTooltip]="'share-document.share-fieldcontainer' | afTranslate"
                                                  matTooltipPosition="below"
                                                  matTooltipClass="below"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  <div class="concept-form__form-subcontainer-concept"
                                      [ngClass]="subContainers.hideContainer?'concept-form__form-subcontainer-concept--subgroup-hide':'concept-form__form-subcontainer-concept--subgroup'"
                                      *ngFor="let subField of subContainers.fields; trackBy: trackContainer">
                                        <field-item *ngIf="(subField.show && subField.showWithProperty && fieldContainer.active) || (!fieldContainer.active && subField.itsFormu && subField.show && subField.showWithProperty)"
                                            [ngShow]="!subField.hidden && fieldContainer.active"
                                            [fieldInfo]="subField"
                                            [isPrivate]="fieldContainer.private || subContainers.private"
                                            [evaluatedConcepts]="conceptForm.concept?.evaluatedFields"
                                            [concept]="conceptForm.concept"
                                            [evaluationId]="evaluationId"
                                            [conceptLocked]="conceptForm.conceptLocked"
                                            [fieldContainer]="subContainers"
                                            (evaluatedConceptChange)="changesInEvaluatedConcept($event)"
                                            (changeConceptFromField)="changeConceptFromField($event.template)"
                                            (createForm)="createNewForm($event)"
                                            (launchAction)="launchActionOn($event)">
                                        </field-item>
                                  </div>
                          </div>
                          <subcontainer-multiple-default
                              *ngIf="subContainers.multiple && currentComponent !== 'editConcept'"
                              [concept]="conceptForm.concept"
                              [subContainer]="subContainers"
                              [evaluatedMap]="conceptForm.evaluatedMap"
                              [subContainerMultiple]="conceptForm.concept?.subContainerMultiple"
                              [subcontainerDuplicated]="subContainers.subcontainerDuplicated"
                              [isPrivate]="(fieldContainer.private || subContainers.private)"
                              [evaluationProperties]="conceptForm.evaluationProperties"
                              (evaluatedConceptChange)="changesInMultipleConcept($event)"
                              (launchAction)="launchActionSubcontainer($event)"
                              [conceptLocked]="conceptForm.conceptLocked">
                          </subcontainer-multiple-default>
                      </div>
                  </div>
                  <div *ngIf="conceptForm.concept.conceptNamedKey === 'DiligenciasReforzadas' && ((skin === 'dark-blue' && fieldContainer.iD_Version0 === 6188765) || (skin === 'steel-blue' && fieldContainer.iD_Version0 === 8612))">
                      <field-type-access
                          [concept]="conceptForm.concept"></field-type-access>
                  </div>
              </form>
              </div>

          </div>
          <div class="common__buttons-block float-end common__buttons-block--margin"
              [ngClass]="{'common__buttons-block--margin-left-5':conceptForm.noPermissions}"
              [ngShow]="conceptForm.showSaveButtons || conceptForm.showClientButtons">
              <div [ngShow]="!conceptForm.conceptLockedState">
                  <button type="reset"
                          class="common__button common__button--clean"
                          (click)="clear()"
                          [ngShow]="!conceptForm.noPermissions">{{conceptForm.clearButton}}</button>
                  <div class="concept-form__block-button-lock"
                      ngbDropdown
                      [open]="conceptForm.openMenuBlockClient"
                      [ngShow]="conceptForm.concept?.conceptNamedKey === 'Cliente'"
                      [autoClose]="'outside'"
                      (openChange)="openOptionBlockClient(conceptForm.openMenuBlockClient)">
                      <button type="submit"
                          class="common__button common__button--icon-down common__button--red"
                          [ngClass]="{'concept-form__button-red-selected': conceptForm.openMenuBlockClient}"
                          [disabled]="conceptForm.noPermissions || (!conceptForm.enabledButtonManageBlock && conceptForm.concept?.clientStateId===2)"
                          ngbDropdownToggle>
                          <span [afTranslate]="conceptForm.concept?.clientStateId===2?'concept-form.manage-lock':'concept-form.lock'">_Bloquear</span>
                          <i class="lf-icon-angle-down concept-form__icon-down-button"></i>
                      </button>
                      <ul class="concept-form__list-menu-block dropdown-menu"
                          ngbDropdownMenu role="menu">
                          <li class="common__list-dropdown-option concept-form__block-button-option"
                              [ngClass]="{'concept-form__block-button-option--disabled': optionMenu.disabled}"
                              *ngFor="let optionMenu of conceptForm.menuBlockClientShow; let last = last">
                              <button class="common__button-option-list-dropdown concept-form__text-button-block"
                                  [ngClass]="{'common__button-option-list-dropdown--last': last,
                                    'concept-form__text-button-block--disabled': optionMenu.disabled}"
                                    [disabled]="optionMenu.disabled"
                                  (click)="launchActionBlockedConcept(optionMenu.action)">
                                  <i [class]="optionMenu.icon + ' common__icon-option-list-dropdown concept-form__text-button-block'"
                                    [ngClass]="{'concept-form__text-button-block--disabled': optionMenu.disabled}"></i>
                                  <span class="common__text-menu-option-dropdown"
                                      [afTranslate]="optionMenu.name"></span>
                              </button>
                          </li>
                      </ul>
                  </div>
                  <button type="submit"
                          class="common__button common__button--active"
                          [disabled]="conceptForm.viewEditConcept"
                          (click)="saveConcept()"
                          [ngShow]="!conceptForm.noPermissions">{{conceptForm.sendConceptButton}}</button>
              </div>
              <div [ngShow]="conceptForm.conceptLockedState">
                  <button type="submit"
                      class="common__button common__button--red"
                      (click)="launchActionBlockedConcept('reactivate')"
                      [disabled]="conceptForm.userPermissions === 'none'">
                      <span [afTranslate]="'concept-form.reactivate-relationship'">_Reactivar relación</span>
                  </button>
              </div>
          </div>
          <div class="common__buttons-block float-end"
              [ngShow]="conceptForm.showExitButton">
              <button type="reset"
                      class="common__button common__button--clean"
                      (click)="goBack()"
                      [afTranslate]="'common.exit'">Salir
              </button>
          </div>

          <!-- BOTONES DE PASOS -->
          <div class="concept-form__buttons-steps-block"
              *ngIf="conceptForm.viewConcept === 'steps' && conceptForm.filterContainers?.length > 1">
                <button class="concept-form__button-step"
                    [ngHide]="conceptForm.concept?.actualStep === 1"
                    (click)="sendChangeStep(conceptForm.concept?.actualStep-1)">
                    <div class="concept-form__circle-block-icon">
                        <i class="lf-icon-double-arrow-left concept-steps__icon-position"></i>
                    </div>
                    <p class="concept-form__button-step-text">{{conceptForm.concept?.actualStep-1}}</p>
                </button>
                <button class="concept-form__button-step concept-form__button-step--active"
                    [ngHide]="conceptForm.concept?.actualStep === conceptForm.filterContainers?.length || conceptForm.concept?.actualStepIsLast"
                    (click)="sendChangeStep(conceptForm.concept?.actualStep+1)">
                    <div  class="concept-form__circle-block-icon concept-form__circle-block-icon--active">
                        <i class="lf-icon-double-arrow-right concept-steps__icon-position concept-steps__icon-position--right"></i>
                    </div>
                    <p class="concept-form__button-step-text">{{conceptForm.concept?.actualStep+1}}</p>
                </button>
          </div>
      </div>
      <div class="common__concept-menu-fixed"
          *ngIf="!conceptForm.viewDesignConcept">
          <div class="common__concept-menu common__concept-menu-fixed-scroll"
              [ngClass]="{'common__concept-menu--without-permission': !conceptForm.showSaveButtons}"
              scrollConcept>
              <div class="concept-form__concept-block-menu-icon">
                  <button class="lf-icon-bookmarks-list concept-form__menu-icon concept-form__menu-icon--bookmark"
                      (click)="conceptForm.showFieldContainerNav = !conceptForm.showFieldContainerNav"
                      matTooltip="{{'common.go-to' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
              </div>
              <div class="concept-form__concept-block-menu-icon"
                  *ngIf="conceptForm.showSaveButtons">
                  <button class="lf-icon-eraser concept-form__menu-icon"
                      (click)="clear()"
                      matTooltip="{{'common.delete' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
              </div>
              <div class="concept-form__concept-block-menu-icon"
                  *ngIf="conceptForm.showSaveButtons">
                  <button class="lf-icon-save concept-form__menu-icon"
                      (click)="saveConcept()"
                      [disabled]="conceptForm.viewEditConcept"
                      matTooltip="{{'common.save' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </button>
              </div>
              <div class="concept-form__subgroup-menu"
                  [ngClass]="{'concept-form__subgroup-menu--without-permission': conceptForm.conceptLocked || conceptForm.noPermissions || conceptForm.productTypeId === 4}"
                  *ngIf="conceptForm.showFieldContainerNav">
                  <h5 class="concept-form__title-form-subgroup">
                      {{conceptForm.conceptSections}}
                  </h5>
                  <i class="lf-icon-close concept-form__close-icon"
                      (click)="conceptForm.showFieldContainerNav = !conceptForm.showFieldContainerNav"></i>
                  <div class="concept-form__subgroup-menu-block">
                      <ol class="concept-form__subgroup-menu-list"
                          [ngClass]="{'concept-form__subgroup-menu-list--steps': conceptForm.viewConcept === 'steps'}">
                          <div *ngFor="let fieldContainer of conceptForm.concept?.fieldContainers | onlyToShow:'conceptSteps'; let index = index"
                              class="concept-form__elements-repeat-list-field"
                              [ngClass]="{'concept-form__elements-repeat-list-field--steps': conceptForm.viewConcept === 'steps'}">
                              <div class="concept-form__block-list-field"
                                  (click)="selectStep(fieldContainer)">
                                  <div class="concept-form__subgroup-menu-line-bottom-link">
                                      <li class="concept-form__subgroup-menu-element-list">
                                          {{fieldContainer.name}}
                                      </li>
                                      <i class="lf-icon-arrow-right concept-form__icon-directions"></i>
                                  </div>
                                  <div class="concept-form__line-bottom-block"
                                      [ngClass]="{'concept-form__line-bottom-block--steps': conceptForm.viewConcept === 'steps'}"></div>
                              </div>
                              <!-- <div [ngShow]="conceptForm.productTypeId===4">
                                  <div *ngFor="let subContainers of fieldContainer.subContainers"
                                      class="concept-form__block-list-field"
                                      [ngClass]="{'concept-form__block-list-field--list': conceptForm.viewConcept !== 'steps'}"
                                      (click)="selectStep(subContainers)">
                                      <div class="concept-form__subgroup-menu-line-bottom-link">
                                          <i class="lf-icon-step-final concept-form__subcontainer-icon"></i>
                                          {{subContainers.name}}
                                          <i class="lf-icon-arrow-right concept-form__icon-directions concept-form__icon-directions--subcontainer"></i>
                                      </div>
                                      <div class="concept-form__line-bottom-block"></div>
                                  </div>
                              </div> -->
                          </div>
                      </ol>
                  </div>
              </div>
          </div>
      </div>
      <div class="concept-form__concept-box-right"
        *ngIf="!conceptForm.viewDesignConcept && !conceptForm.concept?.poll">
          <!-- <box-form *ngIf="conceptForm.concept?.productId === 'concurs002'"
              [ngShow]="hasLinksToForms && (conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'box-form')"
              [concept]="conceptForm.concept"
              (catchFullScreen)="hasFullScreen($event)">
          </box-form> -->
          <concept-legal-documentation-default *ngIf="conceptForm.concept?.references?.length"
                [ngShow]="conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'concept-legal-documentation'"
                [referencesList]="conceptForm.concept?.references"
                [concept]="conceptForm.concept"
                (catchFullScreen)="hasFullScreen($event)"
                (generateDocument)="generateDocument($event)">
          </concept-legal-documentation-default>
          <predefined-templates *ngIf="!conceptForm.lockedEvaluation && conceptForm.concept?.templateList?.length"
              [ngShow]="(conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'predefined-templates') && conceptForm.productTypeId !== 4"
              [templateList]="conceptForm.concept?.templateList"
              (catchFullScreen)="hasFullScreen($event)"
              (generateDocument)="generateDocument($event)">
          </predefined-templates>
          <box-comments-default *ngIf="(skin != 'icam-red' && conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'box-comments') || (skin === 'icam-red' && conceptForm.concept?.conceptNamedKey === 'Consulta')"
              [conceptObjectId]="conceptForm.concept?.conceptObjectId"
              (catchFullScreen)="hasFullScreen($event)">
          </box-comments-default>
          <box-communication-channel-default *ngIf="public && skin !== 'uk-steel-blue' && skin !== 'icam-red' && (conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'box-comunication-channel-full')"
              [concept]="conceptForm.concept"
              (catchFullScreen)="hasFullScreen($event)">
          </box-communication-channel-default>
          <box-queries *ngIf="skin === 'icam-red' && conceptForm.concept?.conceptNamedKey === 'Usuario'"
              [concept]="conceptForm.concept"
              (launchAction)="launchActionOn($event)">
          </box-queries>
          <icam-queries-default *ngIf="conceptForm.concept?.allowPublicShare && published"
              [concept]="conceptForm.concept"
              [fullScreenBox]="conceptForm.fullScreenBox"
              (catchFullScreen)="hasFullScreen($event)"
              (generateDocument)="generateDocument($event)">
          </icam-queries-default>
          <video-slider [ngShow]="(conceptForm.fullScreenBox === '' || conceptForm.fullScreenBox === 'box-comments') && conceptForm.productTypeId !== 4 && skin !== 'gm-law'"
              [videos]="conceptForm.concept?.videos">
          </video-slider>
      </div>
    </div>
</div>
