<!-- <button (click)="saveAsPng()"
[disabled]="reportGraph.disabledSaveAsPng">Capturar gráfica</button> -->
<div class="report-graphs"
    [ngClass]="{'report-graphs--tasks': reportGraph.configurateReport.type === 4}">
    <div class="report-graphs__residual-block"
        *ngIf="showResidualOption">
        <!-- <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
            *ngIf="reportGraph.configurateReport.type !== 6 && !(reportGraph.configurateReport.type === 1 && reportGraph.configurateReport.subType === 2)"
            [ngClass]="{'report-graphs__left-graphs--without-map': reportGraph.configurateReport.subType === 1}"> -->
          <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
            [ngClass]="{'report-graphs__left-graphs--without-map': reportGraph.configurateReport.subType === 1}">
            <donut-graph [graphData]="reportGraph.otherGraphsResidualData"
                [configurateReport]="reportGraph.configurateReport"
                [from]="'report-table'"
                [id]="'residual'">
            </donut-graph>
            <bar-graph [graphData]="reportGraph.otherGraphsResidualData"
                [configurateReport]="reportGraph.configurateReport"
                [id]="'residual'">
            </bar-graph>
        </div>
    </div>
    <div class="report-graphs__inherent-block-title"
      *ngIf="showResidualOption">
      <p class="report-graphs__inherent-block-title-text"
        [afTranslate]="'report-graph.inherent-risk'"></p>
    </div>

    <div class="report-graphs__top-block"
        *ngIf="reportGraph.configurateReport.type !== 10 && !(reportGraph.report.reportId === 205 || reportGraph.report.reportId === 206 || reportGraph.report.reportId === 207)">
        <!-- <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
          [ngShow]="reportGraph.otherGraphsData?.amount && reportGraph.configurateReport.type !== 6 && !(reportGraph.configurateReport.type === 1 && reportGraph.configurateReport.subType === 2)"
          [ngClass]="{'report-graphs__right-graphs--without-map': reportGraph.configurateReport.subType === 1}"> -->
        <div class="report-graphs__left-graph report-graphs__left-graph--inherent"
          [ngShow]="reportGraph.otherGraphsData?.amount"
          [ngClass]="{'report-graphs__right-graphs--without-map': reportGraph.configurateReport.subType === 1}">
          <donut-graph [graphData]="reportGraph.otherGraphsData"
              [configurateReport]="reportGraph.configurateReport"
              [from]="'report-table'"
              [id]="'inherent'"></donut-graph>
          <bar-graph [graphData]="reportGraph.otherGraphsData"
              [configurateReport]="reportGraph.configurateReport"
              [id]="'inherent'">
          </bar-graph>
        </div>
        <!-- <div class="report-graphs__right-graphs report-graphs__right-graphs--inherent"
            *ngIf="reportGraph.configurateReport.subType !== 1
                && reportGraph.configurateReport.type !== 4
                && !(reportGraph.configurateReport.type === 2 && reportGraph.configurateReport.excelCharts === 3)
                && reportGraph.configurateReport.type !== 6
                && !(reportGraph.configurateReport.type === 1 && reportGraph.configurateReport.subType === 2)"> -->
        <div class="report-graphs__right-graphs report-graphs__right-graphs--inherent"
            *ngIf="reportGraph.configurateReport.subType !== 1">
            <heat-map-graph [graphData]="reportGraph.graphData"></heat-map-graph>
        </div>
        <!-- <div class="report-graphs__legend-header"
            [ngShow]="reportGraph.configurateReport.type === 4">
            <div class="report-graphs__block-text-legend">
                <i class="lf-icon-calendar report-graphs__icon-calendar"></i>
                <p class="report-graphs__text-legend">
                    Inicio del proyecto - fecha actual
                </p>
            </div>
        </div>
        <div class="report-graphs__left-graph"
            *ngIf="reportGraph.configurateReport.type === 4">
            <bar-horizontal-graph [graphData]="reportGraph.graphTaskData"></bar-horizontal-graph>
        </div> -->
        <!-- <div class="report-graphs__left-graph"
            *ngIf="reportGraph.configurateReport.type === 6">
            <columns-graph [graphData]="reportGraph.graphData.graph">
            </columns-graph>
        </div> -->
        <!-- <div class="report-graphs__all-graph"
            *ngIf="reportGraph.configurateReport.type === 1 && reportGraph.configurateReport.subType === 2">
            <donut-graph [graphData]="reportGraph.donutCovidGraphsData"
                [configurateReport]="reportGraph.configurateReport"
                [from]="'report-table'">
            </donut-graph>
        </div> -->
        <!-- <div class="report-graphs__all-graph"
            *ngIf="reportGraph.configurateReport.type === 2 && reportGraph.configurateReport.excelCharts === 3">
            <stacked-graph [graphData]="reportGraph.stackedGraphData">
            </stacked-graph>
        </div> -->
    </div>
     <!-- <div class="report-graphs__bottom-block"
        *ngIf="reportGraph.configurateReport.type === 4">
            <div class="report-graphs__legend-header">
                <div class="report-graphs__block-text-legend">
                    <i class="lf-icon-calendar report-graphs__icon-calendar"></i>
                    <p class="report-graphs__text-legend"
                      [afTranslate]="'report-graph.calendar-legend'">
                       _Inicio del proyecto - fecha actual
                    </p>
                </div>
            </div>
        <scatter-plot-graph [graphData]="reportGraph.graphTaskAnualData">
        </scatter-plot-graph>
    </div> -->
    <div class="report-graphs__esg-heat-map-block"
        *ngFor="let data of reportGraph.esgHeatMapData">
        <div class="report-graphs__esg-heat-map-header">
            <p class="report-graphs__esg-heat-map-header-title">
                {{data.title}}
            </p>
        </div>
        <div  class="report-graphs__esg-heat-map-header-graph">
            <heat-map-graph [graphData]="data"></heat-map-graph>
        </div>
    </div>
</div>
