import * as d3 from 'd3'
import * as d3Colection from 'd3-collection';
import html2canvas from 'html2canvas';

import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { MatTooltip } from '@angular/material/tooltip';


@Component({
  selector: 'donut-graph',
  templateUrl: './donut-graph.component.html'
})
export class DonutGraphComponent implements OnChanges {
  skin: any = this.globalCfg.skin;
  donutGraph:any ={
    graphData: null
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private container: ElementRef) { }

  @Input() configurateReport: any =  null;
  @Input() graphData: any =  null;
  @Input() from: any =  null;
  @Input() id: any =  '';

  @ViewChild(MatTooltip)
  tooltip: MatTooltip

  ngOnChanges(): void {
    this.donutGraph.graphData = this.graphData;
    if(this.graphData && this.graphData.colors.length){
        if(!this.donutGraph.graphData.graphicId){
            this.donutGraph.graphData.graphicId = 0;
        }
        this.drawSvg();
    }
  }

  drawSvg(){
    // let element: any = $('.report-table');
    // let widthHeight = this.configurateReport.type === 1 && this.configurateReport.subType === 2? 105 : 70;
    // let innerRadius = this.configurateReport.type === 1 && this.configurateReport.subType === 2? 30 : 20;
    // let outerRadius = this.configurateReport.type === 1 && this.configurateReport.subType === 2? 50 : 33;
    // if(this.configurateReport.type === 'be'){
    //     this.donutGraph.scale = 5.2;
    // }else
    // if(this.configurateReport.subType === 1){
    //     this.donutGraph.scale = 4;
    // }else if(this.configurateReport.type === 'poll'){
    //     this.donutGraph.scale = 5.1;
    // }else{
    //     if(element.width() >= 1400){
    //         this.donutGraph.scale = 4;
    //     }else if( element.width() >= 1250 && element.width() < 1400){
    //         this.donutGraph.scale = 3.5;
    //     }else if( element.width() >= 1127 && element.width() < 1250){
    //         this.donutGraph.scale = 3;
    //     }else if( element.width() >= 1052 && element.width() < 1127){
    //         this.donutGraph.scale = 2.7;
    //     }else if( element.width() >= 980 && element.width() < 1052){//969
    //         this.donutGraph.scale = 2.5;
    //     }else if( element.width() >= 890 && element.width() < 980){//969
    //         this.donutGraph.scale = 2.2;
    //     }else{
    //         this.donutGraph.scale = 2;
    //     }
    // }
    // this.donutGraph.graph = {
    //     width: widthHeight * this.donutGraph.scale,
    //     height: widthHeight * this.donutGraph.scale,
    //     innerRadius: innerRadius * this.donutGraph.scale,
    //     outerRadius: outerRadius * this.donutGraph.scale,
    // }

    let widthHeight = 280;
    let innerRadius = 80;
    let outerRadius = 132;
    if(this.configurateReport.type === 'poll' || this.configurateReport.type === 'be'){
      widthHeight = 364;
      innerRadius = 104;
      outerRadius = 171.6;
    }
    this.donutGraph.graph = {
        width: widthHeight,
        height: widthHeight,
        innerRadius: innerRadius,
        outerRadius: outerRadius,
    }

    this.createSvg();

    if(this.configurateReport.type === 'poll' || this.configurateReport.type === 'be'){
        // this.donutGraph.graph.innerRadiusOut = (outerRadius * this.donutGraph.scale) + 4;
        this.donutGraph.graph.innerRadiusOut = outerRadius + 4;
        this.donutGraph.graph.outerRadiusOut = this.donutGraph.graph.innerRadiusOut + 4;
        this.generateTooltip();
        if(this.configurateReport.type === 'poll'){
            this.createDonutHover();
        }

    }
    this.createDonut();
  }

  createDonut(){
    let amountPercentage = 0;
    this.donutGraph.graphData.pie = d3.pie()
        .value((d:any)=> { return d.value; })


    if(this.configurateReport.type === 'be'){
      this.donutGraph.graph.g.selectAll()
            .data(this.donutGraph.graphData.pie(d3Colection.entries([1])))
            .enter()
            .append('path')
            .attr('d',
                d3.arc()
                    .innerRadius(this.donutGraph.graph.outerRadius)
                    .outerRadius(this.donutGraph.graph.outerRadius+2)
            )
            .style('fill','#F7F7F3');
    }

    this.donutGraph.graphData.pieData = this.donutGraph.graphData.pie(d3Colection.entries(this.donutGraph.graphData.data));
    this.donutGraph.graph.g.selectAll()
        .data(this.donutGraph.graphData.pieData)
        .enter()
        .append('path')
        .attr('class',()=>{ return this.configurateReport.type === 'be'? 'donut-graph__arc-steel-blue':''})
        .attr('id', (d:any) => { return 'bar-graph__portion-'+d.data.key })
        .attr('d',
            d3.arc()
                .innerRadius(this.donutGraph.graph.innerRadius)
                .outerRadius(this.donutGraph.graph.outerRadius)
        )
        .style('fill', (d:any)=>{ return(this.donutGraph.graphData.colors[d.data.key]) })
        .on('mouseover', (d:any)=>{ this.mouseover(d)})
        .on('mouseout', (d:any)=>{ this.mouseout(d)});

    this.donutGraph.graph.g.selectAll()
        .data(this.donutGraph.graphData.pieData)
        .enter()
        .append('text')
        .attr('class', 'donut-graph__arc-value')
        .attr("transform", (d:any) => {
            let arcCenter = (d3.arc().innerRadius(this.donutGraph.graph.innerRadius).outerRadius(this.donutGraph.graph.outerRadius)).centroid(d)
            return "translate(" +(arcCenter)  + ")";  })
            .style('text-anchor', 'middle')
        .text((d:any)=>{
            if(d.value){
                let percentageReturn = '';
                let percentage = (d.value * 100)/ this.donutGraph.graphData.amount;
                let amount = amountPercentage + Math.round((d.value * 100)/ this.donutGraph.graphData.amount);
                if(amount > 100){
                    amountPercentage += Math.floor((d.value * 100)/ this.donutGraph.graphData.amount);
                    percentageReturn = Math.floor(percentage)+ '%';
                }else{
                    amountPercentage += Math.round((d.value * 100)/ this.donutGraph.graphData.amount);
                    percentageReturn = Math.round(percentage)+ '%';
                }
                if(this.configurateReport.type === 'be'){
                    if(percentage >= 5){
                        return percentageReturn;
                    }

                }else if(this.configurateReport.type !== 'poll' || (this.configurateReport.type === 'poll' && percentage >= 5)){
                    return percentageReturn;
                }
            }
            return '';

        })
        .on('mouseover', (d:any) => this.mouseover(d))
        .on('mouseout', (d:any) => this.mouseout(d));
  }

  createSvg(){
    // this.donutGraph.graph.svg = d3.select('#donut-graph-'+ this.donutGraph.graphData.graphicId)
    this.donutGraph.graph.svg = d3.select(this.container.nativeElement.firstChild.firstChild)
        .append('svg')
        .attr('id', ()=>{
            return this.configurateReport.type === 'poll'? 'bar-graph__donut-block-'+ this.donutGraph.graphData.graphicId : 'bar-graph__donut-block'
        })
        // .attr('id', 'bar-graph__donut-block')
        .attr("viewBox", `0 0 ${this.donutGraph.graph.width} ${this.donutGraph.graph.height}`)
        // .attr('width',   this.donutGraph.graph.width)
        // .attr('height',  this.donutGraph.graph.height);

        this.donutGraph.graph.g = this.donutGraph.graph.svg.append('g')
          .attr('class', 'donut-graph__donut-graph-'+ this.donutGraph.graphData.graphicId)
          .attr("transform", "translate(" + this.donutGraph.graph.width / 2 + "," + this.donutGraph.graph.height / 2 + ")")
        if(!(this.configurateReport.type === 1 && this.configurateReport.subType === 2)){
          let area: any = this.from === 'report-table'? 161 : 207;
          let translate: any = this.from === 'report-table'? -80 : -102
          this.donutGraph.graph.g
            .append('foreignObject')
            .attr("x", translate)
            .attr("width", area)
            .attr("y", translate)
            .attr("height", area)
            .append('xhtml:div')
            .attr('id', 'info-donut-'+ this.donutGraph.graphData.graphicId)
            .attr('class', ()=>{
              let className: string = 'donut-graph__info-donut';
              if(this.configurateReport.type !== 'be' && this.configurateReport.type !== 'poll'){
                className += ' donut-graph__info-donut--graph'
              }
              return className;
            })
            .append('span')
            .attr('class', 'donut-graph__value-title')
            .text(this.donutGraph.graphData.graphicCaption);

          d3.select('#info-donut-'+ this.donutGraph.graphData.graphicId)
            .append('span')
            .attr('class', ()=>{
              let className:string = 'donut-graph__value-text';
              if(this.configurateReport.type !== 'be' && this.configurateReport.type !== 'poll'){
                className += ' donut-graph__value-text--graph';
              }
              return className;
            })
            .text(this.donutGraph.graphData.amount);
        }
  }

  generateTooltip(){
    // this.donutGraph.divTooltip = d3.select("#donut-graph-"+ this.donutGraph.graphData.graphicId)
    this.donutGraph.divTooltip = d3.select(this.container.nativeElement.firstChild.firstChild)
        .append("div")
        .attr("id", 'tooltip-donut-'+ this.donutGraph.graphData.graphicId)
        .attr("class","donut-graph__tooltip-donut")
        .style("opacity", 0);
  }

  mouseover(d:any){
    if(this.configurateReport.type === 'poll' || this.configurateReport.type === 'be'){
        let arcCenter = (d3.arc().innerRadius(this.donutGraph.graph.innerRadius).outerRadius(this.donutGraph.graph.outerRadius)).centroid(d)
        let valueTooltip = this.donutGraph.graphData.legend[parseInt(d.data.key)];
        if(this.configurateReport.numericalScale){
            valueTooltip = this.translate.instant('common.punctuation') + ' ' + valueTooltip;
        }
        let x = arcCenter[0];
        let y = arcCenter[1];
        let multiplicator = valueTooltip.length * 2;
        let divisorRest = 50;
        if(multiplicator > 54){
            divisorRest = (Math.ceil(multiplicator/54)*11)+50;
            multiplicator = 54;
        }
        let widthBlock = multiplicator * 3;
        if((widthBlock + 26) > 170){
            widthBlock = 144;
        }
        $('#tooltip-donut-'+ this.donutGraph.graphData.graphicId).width(widthBlock + 'px');
        let offsetX = (this.donutGraph.graph.width/2) + x - (widthBlock/2 + 14);
        let offsetY = ((this.donutGraph.graph.height/2) - divisorRest) + y;
        d3.select('#tooltip-donut-'+ this.donutGraph.graphData.graphicId)
        // vm.divTooltip
            .transition()
            .duration(200)
            .style("opacity", 1);
        d3.select('#tooltip-donut-'+ this.donutGraph.graphData.graphicId)
        // .html(valueTooltip)
            .style("left", offsetX + "px")
            .style("top", offsetY + "px")
            .text(valueTooltip);
        if(this.configurateReport.type === 'poll'){
            d3.select('#bar-graph__portion-out-'+d.data.key+'-'+ this.donutGraph.graphData.graphicId).style("opacity", 0.5);
        }
    }
  }

  mouseout(d:any){
    if(this.configurateReport.type === 'poll' || this.configurateReport.type === 'be'){
        d3.select('#tooltip-donut-'+ this.donutGraph.graphData.graphicId)
        // vm.divTooltip
            .transition()
            .duration(200)
            .style("opacity", 0);

        d3.select('#bar-graph__portion-out-'+d.data.key+'-'+ this.donutGraph.graphData.graphicId).style("opacity", 0);
    }
  }

  createDonutHover(){
    this.donutGraph.graphData.pie = d3.pie()
        .value((d:any)=>{ return d.value; })
    this.donutGraph.graphData.pieData = this.donutGraph.graphData.pie(d3Colection.entries(this.donutGraph.graphData.data))

    this.donutGraph.graph.g.selectAll()
        .data(this.donutGraph.graphData.pieData)
        .enter()
        .append('path')
        .attr('id', (d:any)=>{
            return 'bar-graph__portion-out-'+d.data.key+'-'+ this.donutGraph.graphData.graphicId;
        })
        .attr('d',
            d3.arc()
                .innerRadius(this.donutGraph.graph.innerRadiusOut)
                .outerRadius(this.donutGraph.graph.outerRadiusOut)
        )
        .style("opacity", 0)
        .style('fill', (d:any)=>{ return this.donutGraph.graphData.colors[d.data.key] });
  }

  saveAsPng(){
    let that = this;
    that.donutGraph.disabledSaveAsPng = true;
    that.donutGraph.disableTooltip = true;
    that.tooltip.hide();
    html2canvas(this.container.nativeElement.firstChild.firstChild)
      .then(function(canvas) {
          canvas.toBlob((blob:any)=>{
              let data = [new ClipboardItem({ [blob.type]: blob })];
              navigator.clipboard.write(data)
                  .then(()=>{}, (err)=>{})
          });
          that.donutGraph.copiedTooltip = true;
          that.donutGraph.disableTooltip = false;
          // if(!that.tooltip.isOpen()){
            that.tooltip.show();
          // }
          setTimeout(()=>{
              that.tooltip.hide();
              that.donutGraph.copiedTooltip = false;
              that.donutGraph.disabledSaveAsPng = false;
          }, 3000)
      })
  }

}
