<div class="field-type-txchk"
  [ngClass]="{'field-type-txchk--error': fieldTypeTxchk.field.error && !fieldTypeTxchk.preSelectItem,
      'field-type-txchk--disabled': fieldTypeTxchk.field.noEditable}">
  <label class="field-type-txchk__accept-terms-input"
      [ngShow]="fieldTypeTxchk.field.description"
      [ngClass]="{'field-type-txchk__accept-terms-input--custom': isLanding}">
      <i class="field-type-txchk__accept-terms-icon"
          [ngClass]="{'lf-icon-check-full':fieldTypeTxchk.preSelectItem,
              'lf-icon-box-inactive':!fieldTypeTxchk.preSelectItem,
              'field-type-txchk__accept-terms-icon--error':fieldTypeTxchk.field.error && !fieldTypeTxchk.preSelectItem,
              'field-type-txchk__accept-terms-icon--disabled':fieldTypeTxchk.field.noEditable,
              'field-type-txchk__accept-terms-icon--poll':fieldTypeTxchk.field.poll && !fieldTypeTxchk.field.noEditable && fieldTypeTxchk.field.sequentialView}"
          [attr.disabled]="fieldTypeTxchk.field.noEditable?true:null"
          (click)="changeInField()"></i>
  </label>
  <div class="field-type-txchk__accept-terms-text"
      [ngClass]="{'field-type-txchk__accept-terms-text--error-poll':fieldTypeTxchk.field.error && !fieldTypeTxchk.field.noEditable && fieldTypeTxchk.field.sequentialView,
        'field-type-txchk__accept-terms-text--disabled':fieldTypeTxchk.field.noEditable,
        'field-type-txchk__accept-terms-text--poll':fieldTypeTxchk.field.poll && !fieldTypeTxchk.field.noEditable && fieldTypeTxchk.field.sequentialView}">
      <span [innerHTML]="fieldTypeTxchk.field.description"></span>
      <div class="field-type-txchk__option-box field-type-txchk__option-box--required"
          [ngClass]="{'field-type-txchk__option-box--error':fieldTypeTxchk.field.error && !fieldTypeTxchk.preSelectItem}"
          [ngShow]="fieldTypeTxchk.field.required">
          <span class="field-type-txchk__asterisk">*</span>
          <span [afTranslate]="'customize.required'"></span>
      </div>
      <div class="field-type-txchk__option-box field-type-txchk__option-box--legal-references"
          [ngShow]="fieldTypeTxchk.field.references.length">
          <span class="float-start"
            [afTranslate]="'common.legal-references'"></span>
          <tooltip-law [references]="fieldTypeTxchk.field.references"
              [field]="fieldTypeTxchk.field"
              [ngClass]="isLanding?'field-type-txchk__tooltip-law-custom':'field-type-txchk__tooltip-law'">
          </tooltip-law>
      </div>
      <div class="field-type-txchk__option-box"
          (click)="openModalRelatedInfo(fieldTypeTxchk.field.relatedInfo, $event)"
          [ngShow]="fieldTypeTxchk.field.relatedInfo.length">
          <i class="lf-icon-search-text field-item__icon-related-info field-type-txchk__related-info"
              (click)="openModalRelatedInfo(fieldTypeTxchk.field.relatedInfo, $event)">
          </i>
          <span class="field-type-txchk__info-related"
            [afTranslate]="'common.related-info'"></span>
      </div>
  </div>
</div>
