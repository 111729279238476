<div class="concept-form__landing-page concept-form__landing-page--custom"
    *ngIf="conceptForm.configuredVisibility">
    <states-process-custom [ngClass]="{'concept-form__states-monitoring' : conceptForm.locationName === 'root.public.monitoring'}"
        *ngIf="conceptForm.concept?.conceptObjectId && conceptForm.concept?.processStates && skin !== 'icam-red'"
        [type]="'processConcept'"
        [typeView]="'normal'"
        [concept]="conceptForm.concept"
        [item]="conceptForm.concept"
        [userPublic]="conceptForm.userPublic"
        (changeResponsible)="changeResponsible($event)">
    </states-process-custom>
    <div class="landing-page__block-body">
        <div class="row">
            <div class="col-md-4"
                *ngIf="conceptForm.concept?.conceptObjectId !== 'view' && !conceptForm.concept?.poll && !conceptForm.concept?.publicLite && conceptForm.designViewLanding !== 2">
                <process-record-custom *ngIf="conceptForm.productTypeId !== 4 && (conceptForm.concept?.conceptObjectId && !conceptForm.hideHistoric && skin !== 'icam-red')"
                    [conceptObjectId]="conceptForm.concept?.conceptObjectId"
                    [public]="public"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    (catchFullScreen)="hasFullScreen($event)">
                </process-record-custom>
                <box-query-state *ngIf="conceptForm.concept?.conceptObjectId && conceptForm.icamConceptObject && conceptForm.concept?.processStates"
                    [concept]="conceptForm.concept">
                </box-query-state>
                <concept-legal-documentation-custom *ngIf="conceptForm.concept?.references.length"
                    [referencesList]="conceptForm.concept?.references"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    [concept]="conceptForm.concept"
                    (catchFullScreen)="hasFullScreen($event)"
                    (generateDocument)="generateDocument($event)">
                </concept-legal-documentation-custom>
                <icam-queries-custom *ngIf="public && published"
                    [concept]="conceptForm.concept"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    (catchFullScreen)="hasFullScreen($event)"
                    (generateDocument)="generateDocument($event)">
                </icam-queries-custom>
                <attachments-template-custom *ngIf="conceptForm.productTypeId !== 4"
                    [typeFrom]="'concept'"
                    [itemSelected]="conceptForm.concept"
                    [concept]="conceptForm.concept"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    (catchFullScreen)="hasFullScreen($event)"
                    (launchAction)="launchActionGeneralConcept($event)">
                </attachments-template-custom>
                <box-comments-custom *ngIf="!public"
                    [conceptObjectId]="conceptForm.concept?.conceptObjectId"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    (catchFullScreen)="hasFullScreen($event)">
                </box-comments-custom>
                <box-protection *ngIf="public && conceptForm.custom?.extraInfo"
                    [protectionData]="conceptForm.custom?.extraInfo"
                    [type]="'additional'"></box-protection>
                <box-communication-channel-custom *ngIf="public && skin !== 'icam-red' && isMonitoring"
                    [concept]="conceptForm.concept"
                    [public]="public"
                    [fullScreenBox]="conceptForm.fullScreenBox"
                    (catchFullScreen)="hasFullScreen($event)">
                </box-communication-channel-custom>
                <box-protection *ngIf="public && conceptForm.custom?.dataTreatment"
                    [protectionData]="conceptForm.custom?.dataTreatment">
                </box-protection>
                <video-slider [ngShow]="conceptForm.productTypeId !== 4"
                    [videos]="conceptForm.concept?.videos">
                </video-slider>
            </div>
            <div class="col-md-8"
                [ngClass]="{'concept-form__fields-block--view': conceptForm.concept?.conceptObjectId === 'view',
                    'concept-form__custom-applicant-profile': conceptForm.concept?.publicLite || conceptForm.designViewLanding === 2,
                    'concept-form__custom-poll-block':conceptForm.concept?.poll && conceptForm.custom && conceptForm.custom !== null}">
                <!-- Apartado de pasos -->
                <concept-steps-custom *ngIf="conceptForm.viewConcept === 'steps' && conceptForm.filterContainers?.length > 1"
                    [concept]="conceptForm.concept"
                    [filterContainers]="conceptForm.filterContainers"
                    [changeStepsConcept]="conceptForm.changeStepsConcept">
                </concept-steps-custom>
                <div [id]="'grupo-id-'+fieldContainer.fieldContainerId"
                    *ngFor="let fieldContainer of conceptForm.concept?.fieldContainers; let index = index">
                    <div *ngIf="fieldContainer.show && fieldContainer.showWithProperty && !fieldContainer.hidden && !(fieldContainer.private && conceptForm.userPublic) && !fieldContainer.hiddenInLandings"
                        [ngHide]="!fieldContainer.active && conceptForm.viewConcept === 'steps'"
                        class="concept-form__container-fields concept-form__container-fields--custom">
                        <div *ngIf="!fieldContainer.showOnlySubcontainer
                            "class="concept-form__group-title-block-custom">
                            <div class="concept-form__block-icons-custom"
                                [ngShow]="fieldContainer.references.length || fieldContainer.relatedInfo.length">
                                <tooltip-law
                                    [references]="fieldContainer.references"
                                    [type]="'container'"
                                    class="concept-form__icon-group"
                                    [concept]="conceptForm.concept"></tooltip-law>
                                <i class="lf-icon-search-text concept-form__icons"
                                    (click)="openRelatedInfo(fieldContainer.relatedInfo)"
                                    [ngShow]="fieldContainer.relatedInfo.length"
                                    matTooltip="{{'common.related-info' | afTranslate }}"
                                    matTooltipPosition="above"
                                    matTooltipClass="above">
                                </i>
                            </div>
                            <div class="concept-form__tooltip-info"
                                [ngShow]="fieldContainer.showInfo && conceptForm.openLaw === false">
                                <p class="concept-form__tooltip-inner-text"
                                    *ngFor="let reference of fieldContainer.references">
                                    {{reference.text}}
                                </p>
                            </div>
                            <div class="concept-form__cell-block-custom">
                                <p [id]="'concept-form-container-'+index+'-'+fieldContainer.fieldContainerId"
                                    class="concept-form__container-text-custom"
                                    [ngClass]="{'concept-form__container-text-custom--no-highlight': !fieldContainer.isHighlight}">
                                    <span class="concept-form__block-icon-highlight-custom"
                                        [ngShow]="fieldContainer.isHighlight"
                                        [ngStyle]="{'background-color': conceptForm.custom?.color, 'color': conceptForm.custom?.secondColor}">
                                        <i class="lf-icon-important concept-form__icon-arrow-custom"></i>
                                    </span>
                                    <span class="concept-form__text-title-group concept-form__text-title-group--custom"
                                        [ngClass]="{'concept-form__text-title-group--custom-highlight': fieldContainer.isHighlight}"
                                        [id]="'span-concept-form-container-'+index+'-'+fieldContainer.fieldContainerId">{{fieldContainer.name}}</span>
                                </p>
                            </div>
                        </div>
                        <form class="concept-form__form-concept concept-form__form-concept--custom">
                            <div *ngIf="!fieldContainer.showOnlySubcontainer"
                                class="concept-form__container-group-fields-custom">
                                <div *ngFor="let field of fieldContainer.fields">
                                    <field-item *ngIf="field.show && field.showWithProperty && !(field.private && conceptForm.userPublic && !(field.fieldTypeId === 'l-co' || field.fieldTypeId === 'l-sg'))"
                                        [ngHide]="field.hidden || (field.private && conceptForm.userPublic && (field.fieldTypeId === 'l-co' || field.fieldTypeId === 'l-sg')) || fieldContainer.hiddenInLandings || field.hiddenInLandings"
                                        [fieldInfo]="field"
                                        [isPrivate]="fieldContainer.private"
                                        [evaluatedConcepts] ="conceptForm.concept?.evaluatedFields"
                                        (evaluatedConceptChange)="changesInEvaluatedConcept($event)"
                                        (evaluatedConceptChangeAdditional)="changesInEvaluatedConceptAdditional($event)"
                                        (changeConceptFromField)="changeConceptFromField($event.template)"
                                        [public]="public"
                                        [concept]="conceptForm.concept"
                                        [evaluationId]="evaluationId"
                                        [conceptLocked]="conceptForm.conceptLocked || conceptForm.concept?.conceptNamedKey==='Usuario'">
                                    </field-item>
                                </div>
                            </div>
                            <div *ngFor="let subContainers of fieldContainer.subContainers">
                                <div *ngIf="subContainers.show && subContainers.showWithProperty && !subContainers.hidden && !(subContainers.private && conceptForm.userPublic)"
                                    [ngHide]="subContainers.hiddenInLandings || fieldContainer.hiddenInLandings"
                                    class="concept-form__block-subcontainer concept-form__block-subcontainer--custom"
                                    [id]="'subgrupo-id-'+subContainers.fieldContainerId"
                                    [ngClass]="{'concept-form__block-subcontainer--no-border-custom':subContainers.hideContainer}">
                                    <div *ngIf="!subContainers.poll && !subContainers.multiple"
                                        [ngClass]="subContainers.hideContainer?'concept-form__container-border-custom-hide':'concept-form__container-border-custom'">
                                        <div class="concept-form__subgroup-title-block-custom"
                                            [ngClass]="{'concept-form__hide-container': subContainers.hideContainer}">
                                            <div class="concept-form__block-icons-custom concept-form__block-icons-custom--subcontainer"
                                                [ngShow]="subContainers.references.length || subContainers.relatedInfo.length">
                                                <tooltip-law
                                                    class="concept-form__icon-subgroup-custom"
                                                    [type]="'container'"
                                                    [concept]="conceptForm.concept"
                                                    [references]="subContainers.references">
                                                </tooltip-law>
                                                <i class="lf-icon-search-text concept-form__icons concept-form__icons--info-subcontainer"
                                                    (click)="openRelatedInfo(subContainers.relatedInfo)"
                                                    [ngShow]="subContainers.relatedInfo.length"
                                                    matTooltip="{{'common.related-info' | afTranslate }}"
                                                    matTooltipPosition="above"
                                                    matTooltipClass="above">
                                                </i>
                                            </div>
                                            <div class="concept-form__tooltip-info concept-form__tooltip-info--subgroup"
                                                [ngShow]="subContainers.showInfo && conceptForm.openLaw === false">
                                                <p class="concept-form__tooltip-inner-text"
                                                    *ngFor="let subReference of subContainers.references">
                                                    {{subReference.text}}
                                                </p>
                                            </div>
                                            <div class="concept-form__cell-block-custom">
                                                <p [id]="'concept-form-sub-container-'+ index +'-'+subContainers.fieldContainerId"
                                                    class="concept-form__container-text-custom"
                                                    [ngClass]="{'concept-form__container-text-custom--no-highlight': !subContainers.isHighlight}">
                                                    <span class="concept-form__block-icon-highlight-custom"
                                                        [ngShow]="subContainers.isHighlight"
                                                        [ngStyle]="{'background-color': conceptForm.custom?.color, 'color': conceptForm.custom?.secondColor}">
                                                        <i class="lf-icon-important concept-form__icon-arrow-custom"></i>
                                                    </span>
                                                    <span class="concept-form__text-title-group concept-form__text-title-group--custom"
                                                        [ngClass]="{'concept-form__text-title-group--custom-highlight': subContainers.isHighlight}"
                                                        [id]="'span-concept-form-sub-container-'+ index +'-'+subContainers.fieldContainerId">
                                                        {{subContainers.name}}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="concept-form__container-subgroup-fields-custom"
                                            [ngClass]="{'concept-form__container-subgroup-fields-custom--hide': subContainers.hideContainer}">
                                            <div class="concept-form__form-subcontainer-concept-custom"
                                                [ngClass]="subContainers.hideContainer?'concept-form__form-subcontainer-concept-custom--subgroup-hide':'concept-form__form-subcontainer-concept-custom--subgroup'"
                                                *ngFor="let subField of subContainers.fields">
                                                <field-item *ngIf="subField.show && subField.showWithProperty && !(subField.private && conceptForm.userPublic && !(subField.fieldTypeId === 'l-co' ||subField.fieldTypeId === 'l-sg'))"
                                                    [ngHide]="subField.hidden || (subField.private && conceptForm.userPublic && (subField.fieldTypeId === 'l-co'|| subField.fieldTypeId === 'l-sg')) || fieldContainer.hiddenInLandings || subField.hiddenInLandings || subContainers.hiddenInLandings"
                                                    [fieldInfo]="subField"
                                                    [isPrivate]="fieldContainer.private || subContainers.private"
                                                    [evaluatedConcepts]="conceptForm.concept?.evaluatedFields"
                                                    (evaluatedConceptChange)="changesInEvaluatedConcept($event)"
                                                    (evaluatedConceptChangeAdditional)="changesInEvaluatedConceptAdditional($event)"
                                                    (changeConceptFromField)="changeConceptFromField($event.template)"
                                                    [public]="public"
                                                    [concept]="conceptForm.concept"
                                                    [evaluationId]="evaluationId"
                                                    [conceptLocked]="conceptForm.conceptLocked">
                                                </field-item>
                                            </div>
                                        </div>
                                    </div>
                                    <subcontainer-multiple-custom
                                        *ngIf="subContainers.multiple"
                                        [concept]="conceptForm.concept"
                                        [subContainer]="subContainers"
                                        [evaluatedMap]="conceptForm.evaluatedMap"
                                        [subContainerMultiple]="conceptForm.concept?.subContainerMultiple"
                                        [subcontainerDuplicated]="subContainers.subcontainerDuplicated"
                                        [isPrivate]="(fieldContainer.private || subContainers.private)"
                                        [evaluationProperties]="conceptForm.evaluationProperties"
                                        concept-locked="conceptForm.conceptLocked"
                                        [itsSaveConcept]="conceptForm.itsSaveConcept"
                                        (evaluatedConceptChange)="changesInMultipleConcept($event)"
                                        (saveBeforeCreate)="saveCreateConcept($event)"
                                        (clearSaveConcept)="clearSaveConcept()">
                                    </subcontainer-multiple-custom>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="common__buttons-block concept-form__buttons-block">
                    <!-- BOTONES DE PASOS -->
                    <div class="concept-form__buttons-block-option"
                        *ngIf="conceptForm.showSaveButtonsCustom">
                        <button type="reset"
                                class="common__button-landing-custom common__button-landing-custom--cancel"
                                [ngStyle]="{'border-color': conceptForm.custom?.color, 'color': conceptForm.custom?.color }"
                                [ngHide]="conceptForm.concept?.publicLite"
                                (click)="clear()">{{conceptForm.clearButton}}</button>
                    </div>
                    <div class="concept-form__buttons-block-option"
                        *ngIf="conceptForm.viewConcept === 'steps' && conceptForm.filterContainers?.length > 1">
                        <button class="common__button-landing-custom common__button-landing-custom--cancel"
                            [ngHide]="conceptForm.concept?.actualStep === 1"
                            [ngStyle]="{'border-color': conceptForm.custom?.color, 'color': conceptForm.custom?.color }"
                            (click)="sendChangeStep(conceptForm.concept?.actualStep-1)"
                            [afTranslate]="'common.previous'">Anterior</button>
                        <button class="common__button-landing-custom"
                            [ngHide]="conceptForm.concept?.actualStep === conceptForm.filterContainers?.length || conceptForm.concept?.actualStepIsLast"
                            [ngStyle]="{'background-color': conceptForm.custom?.color, 'color': conceptForm.custom?.textColor }"
                            [ngClass]="{'common__button-landing-custom--last': conceptForm.concept?.actualStep !== conceptForm.filterContainers?.length }"
                            (click)="sendChangeStep(conceptForm.concept?.actualStep+1)"
                            [afTranslate]="'common.next'">Siguiente</button>
                    </div>
                    <div class="concept-form__buttons-block-option"
                        *ngIf="conceptForm.showExitButtonCustom">
                        <button type="reset"
                                class="common__button-landing-custom common__button-landing-custom--cancel"
                                [ngStyle]="{'border-color': conceptForm.custom?.color, 'color': conceptForm.custom?.color}"
                                (click)="goBack()"
                                [afTranslate]="'common.exit'">Salir</button>
                    </div>
                    <div class="concept-form__buttons-block-option"
                        *ngIf="conceptForm.showSaveButtonsCustom">
                        <button type="reset"
                                class="common__button-landing-custom common__button-landing-custom--cancel"
                                [ngStyle]="{'border-color': conceptForm.custom?.color, 'color': conceptForm.custom?.color}"
                                (click)="closeConceptUserProfile()"
                                [ngShow]="conceptForm.concept?.publicLite && !conceptForm.concept?.hiddeButtonCancel"
                                [afTranslate]="'common.cancel'">_Cancelar</button>
                        <button type="submit"
                                class="common__button-landing-custom common__button-landing-custom--send common__button-landing-custom--last"
                                [ngShow]="(conceptForm.viewConcept === 'general' || conceptForm.concept?.actualStep === conceptForm.filterContainers?.length || conceptForm.filterContainers?.length === 1 || conceptForm.concept?.actualStepIsLast) && conceptForm.concept?.conceptNamedKey!=='Usuario'"
                                [ngStyle]="{'background-color': conceptForm.custom?.color, 'color': conceptForm.custom?.textColor }"
                                [disabled]="conceptForm.viewEditConcept || conceptForm.disabledSave"
                                (click)="saveConcept()">{{conceptForm.sendConceptButton}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
