<div class="result-search common__panel">
  <header-nav></header-nav>
  <!-- cerrar resultados (BUSCADOR Y Juridica) -->
  <div class="common__block-button-close-window">
      <button (click)="changeView()"
          class="common__back-link">
          <span [afTranslate]="'result-search.close-result'">CERRAR RESULTADOS</span>
          <i class="lf-icon-close common__back-link-icon"></i>
      </button>
  </div>
  <div class="global-customization__block-container">
  <!-- FIN cerrar resultados (BUSCADOR Y Juridica) -->
      <!-- texto resultados (BUSCADOR Y Juridica) -->
      <div class="common__block-list-new-version common__block-list-new-version--height-auto">
          <div class="common__header-new-version result-search__header-v2">
              <i class="lf-icon-hamburger-menu common__header-icon-new-version"
                  [ngShow]="skin !== 'uk-steel-blue'"></i>
              <span class="common__header-title-new-version"
                  [afTranslate]="'result-search.search-result'">Resultados de la búsqueda</span>
          </div>
          <!-- FIN texto resultados (BUSCADOR Y Juridica) -->
          <!-- SOLO BUSCADOR -->
          <div class="result-search__block-results">
              <div class="result-search__settings-block"
                  *ngIf="!resultSearch.withoutResults || (resultSearch.withoutResults && (resultSearch.searching?.Documents || resultSearch.searching?.Tasks || resultSearch.searching?.Reports || resultSearch.searching?.Concepts || resultSearch.searching?.Juridica))"
                  [ngShow]="resultSearch.searchType==='buscador'">
                  <div class="result-search__body-setting-header">
                      <!-- eliminados, archivados (BUSCADOR) -->
                      <div class="result-search__enable-deleted-container"
                          [ngShow]="resultSearch.selectedTab === 'Documents'">
                          <field-type-switch [label]="'result-search.include-deleted'| afTranslate"
                                               [checked]="resultSearch.includeDeletedDocuments"
                                               (callback)="changeDeleted($event.value)">
                          </field-type-switch>
                      </div>
                      <div class="result-search__enable-deleted-container"
                          [ngShow]="resultSearch.selectedTab === 'Concepts'">
                          <field-type-switch [label]="'result-search.include-archived'| afTranslate"
                                               [checked]="resultSearch.includeArchivedConcepts"
                                               (callback)="changeArchived($event.value)">
                          </field-type-switch>
                      </div>
                      <!-- eliminados, archivados (BUSCADOR) -->
                      <!-- icono de filtro (BUSCADOR) -->
                      <div class="result-search__block-icon-filter"
                          [ngClass]="resultSearch.activeDeleteFilters?'result-search__block-icon-filter--delete':'result-search__block-icon-filter--clear'">
                          <button class="result-search__button-filter"
                              [ngClass]="resultSearch.activeDeleteFilters?'result-search__button-filter--delete lf-icon-filter-delete':'result-search__button-filter--clear lf-icon-filter-1'"
                              matTooltip="{{'result-search.remove-filters' | afTranslate}}"
                              matTooltipPosition="left"
                              matTooltipClass="left"
                              [matTooltipDisabled]="!resultSearch.activeDeleteFilters"
                              (click)="deleteFilters()"
                              [disabled]="!resultSearch.activeDeleteFilters">
                          </button>
                      </div>
                      <!-- FIN icono de filtro (BUSCADOR) -->
                  </div>

                  <div class="result-search__body-setting">

                      <!-- ajustes (BUSCADOR) -->
                      <div class="result-search__block-title-search">
                          <i class="lf-icon-settings result-search__icon-search"></i>
                          <span class="result-search__title-text"
                              [afTranslate]="'result-search.search-settings'">Ajustes de la búsqueda</span>
                      </div>
                      <!-- FIN ajustes (BUSCADOR) -->
                      <!-- filtro (BUSCADOR) -->
                      <div class="result-search__block-subtitle-search">
                          <span [afTranslate]="'result-search.filter-results-text'">Filtra los resultados por uno o más criterios:</span>
                      </div>
                      <div class="result-search__block-lists">
                          <div *ngIf="skin !== 'icam-red'">
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Products"
                                              [field]="resultSearch.selectProduct"
                                              [listValues]="resultSearch.filterResults?.Products"
                                              [preSelectItem]="resultSearch.searchFilter.Products"
                                              [fieldToFind]="'text'"
                                              (itemSelected)="itemSelected('Products', $event.newValue, $event.showlist)"
                                              [orderBy]="'text'">
                              </field-type-list-multiple>
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Evaluations"
                                              [field]="resultSearch.selectEvaluation"
                                              [listValues]="resultSearch.filterResults?.Evaluations"
                                              [preSelectItem]="resultSearch.searchFilter.Evaluations"
                                              [fieldToFind]="'text'"
                                              (itemSelected)="itemSelected('Evaluations', $event.newValue, $event.showlist)"
                                              [orderBy]="'text'">
                              </field-type-list-multiple>
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Clients"
                                              [field]="resultSearch.selectClient"
                                              [listValues]="resultSearch.filterResults?.Clients"
                                              [preSelectItem]="resultSearch.searchFilter.Clients"
                                              [fieldToFind]="'text'"
                                              (itemSelected)="itemSelected('Clients', $event.newValue, $event.showlist)"
                                              [orderBy]="'text'">
                              </field-type-list-multiple>
                          </div>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.TaskTypes"
                                          [field]="resultSearch.selectTaskType"
                                          [listValues]="resultSearch.filterResults?.TaskTypes"
                                          [preSelectItem]="resultSearch.searchFilter.TaskTypes"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('TaskTypes', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.ReportTypes"
                                          [field]="resultSearch.selectReportType"
                                          [listValues]="resultSearch.filterResults?.ReportTypes"
                                          [preSelectItem]="resultSearch.searchFilter.ReportTypes"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('ReportTypes', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Authors && !(skin === 'icam-red' && resultSearch.selectedTab === 'Concepts')"
                                          [field]="resultSearch.selectAuthor"
                                          [listValues]="resultSearch.filterResults?.Authors"
                                          [preSelectItem]="resultSearch.searchFilter.Authors"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('Authors', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Participants"
                                          [field]="resultSearch.selectParticipant"
                                          [listValues]="resultSearch.filterResults?.Participants"
                                          [preSelectItem]="resultSearch.searchFilter.Participants"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('Participants', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Formats"
                                          [field]="resultSearch.selectFormat"
                                          [listValues]="resultSearch.filterResults?.Formats"
                                          [preSelectItem]="resultSearch.searchFilter.Formats"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('Formats', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Status"
                                          [field]="resultSearch.selectState"
                                          [listValues]="resultSearch.filterResults?.Status"
                                          [preSelectItem]="resultSearch.searchFilter.Status"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('Status', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                          <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Sources"
                                          [field]="resultSearch.selectOrigin"
                                          [listValues]="resultSearch.filterResults?.Sources"
                                          [preSelectItem]="resultSearch.searchFilter.Sources"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="itemSelected('Sources', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-multiple>
                           <field-type-list-simple [ngShow]="resultSearch.filterResults?.FFolders"
                                           [field]="resultSearch.selectFFolder"
                                           [listValues]="resultSearch.filterResults?.FFolders"
                                           [preSelectItem]="resultSearch.searchFilter.FFolders"
                                           [fieldToFind]="'text'"
                                           (itemSelected)="itemSelected('FFolders', $event.newValue, $event.showlist)"
                                           [orderBy]="'text'">
                          </field-type-list-simple>
                          <field-type-list-simple [ngShow]="resultSearch.filterResults?.FSubfolders && resultSearch.showFSubfolders"
                                              [field]="resultSearch.selectFSubfolder"
                                              [listValues]="resultSearch.filterResults?.FSubfolders"
                                              [preSelectItem]="resultSearch.searchFilter.FSubfolders"
                                              [fieldToFind]="'text'"
                                              (itemSelected)="itemSelected('FSubfolders', $event.newValue, $event.showlist)"
                                              [orderBy]="'text'">
                          </field-type-list-simple>
                          <field-type-list-simple [ngShow]="resultSearch.filterResults?.Centros"
                                           [field]="resultSearch.selectCentros"
                                           [listValues]="resultSearch.filterResults?.Centros"
                                           [preSelectItem]="resultSearch.searchFilter.Centros"
                                           [fieldToFind]="'text'"
                                           (itemSelected)="itemSelected('Centros', $event.newValue, $event.showlist)"
                                           [orderBy]="'text'">
                          </field-type-list-simple>
                          <field-type-list-simple [ngShow]="resultSearch.filterResults?.Tipologias"
                                           [field]="resultSearch.selectTipologias"
                                           [listValues]="resultSearch.filterResults?.Tipologias"
                                           [preSelectItem]="resultSearch.searchFilter.Tipologias"
                                           [fieldToFind]="'text'"
                                           (itemSelected)="itemSelected('Tipologias', $event.newValue, $event.showlist)"
                                           [orderBy]="'text'">
                          </field-type-list-simple>
                          <field-type-list-simple *ngIf="resultSearch.filterResults?.TipoConcepto && skin === 'icam-red' && resultSearch.selectedTab === 'Concepts'"
                                          [field]="resultSearch.tipoConceptoField"
                                          [listValues]="resultSearch.filterResults?.TipoConcepto"
                                          [preSelectItem]="resultSearch.searchFilter.TipoConcepto"
                                          [fieldToFind]="'text'"
                                          (itemSelected)="selectTipoConcepto('TipoConcepto', $event.newValue, $event.showlist)"
                                          [orderBy]="'text'">
                          </field-type-list-simple>
                          <div *ngIf="skin === 'icam-red' && (resultSearch.selectedTab === 'Documents' || resultSearch.selectedTab === 'Tasks' || (resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto))">
                              <div class="result-search__other-rules">
                                  <span [afTranslate]="'all-documentation.other-rules'">Otros criterios asociados a la consulta:</span>
                              </div>
                              <div [ngShow]="!(resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto !== 'Consulta')">
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.TipoConsulta"
                                      [field]="resultSearch.tipoConsultaField"
                                      [listValues]="resultSearch.filterResults?.TipoConsulta"
                                      [preSelectItem]="resultSearch.searchFilter.TipoConsulta"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('TipoConsulta', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Usuario"
                                      [field]="resultSearch.usuarioField"
                                      [listValues]="resultSearch.filterResults?.Usuario"
                                      [preSelectItem]="resultSearch.searchFilter.Usuario"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Usuario', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Gestor"
                                      [field]="resultSearch.gestorField"
                                      [listValues]="resultSearch.filterResults?.Gestor"
                                      [preSelectItem]="resultSearch.searchFilter.Gestor"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Gestor', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Relevancia"
                                      [field]="resultSearch.relevanciaField"
                                      [listValues]="resultSearch.filterResults?.Relevancia"
                                      [preSelectItem]="resultSearch.searchFilter.Relevancia"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Relevancia', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Etiquetas"
                                      [field]="resultSearch.etiquetasField"
                                      [listValues]="resultSearch.filterResults?.Etiquetas"
                                      [preSelectItem]="resultSearch.searchFilter.Etiquetas"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Etiquetas', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Materias"
                                      [field]="resultSearch.materiasField"
                                      [listValues]="resultSearch.filterResults?.Materias"
                                      [preSelectItem]="resultSearch.searchFilter.Materias"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Materias', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Referencias"
                                      [field]="resultSearch.referenciasField"
                                      [listValues]="resultSearch.filterResults?.Referencias"
                                      [preSelectItem]="resultSearch.searchFilter.Referencias"
                                      [fieldToFind]="'text'"
                                      (itemSelected)="itemSelected('Referencias', $event.newValue, $event.showlist)"
                                      [orderBy]="'text'">
                                  </field-type-list-multiple>
                                  <field-type-date [field]="resultSearch.fechaConsultaField"
                                      [preSelectItem]="resultSearch.searchFilter.FechaConsulta"
                                      (itemSelected)="itemSelected('FechaConsulta', $event.newValue, $event.showlist)">
                                  </field-type-date>
                              </div>
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.TipoPublicacion && resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto === 'Publicacion'"
                                  [field]="resultSearch.tipoPublicacionField"
                                  [listValues]="resultSearch.filterResults?.TipoPublicacion"
                                  [preSelectItem]="resultSearch.searchFilter.TipoPublicacion"
                                  [fieldToFind]="'text'"
                                  (itemSelected)="itemSelected('TipoPublicacion', $event.newValue, $event.showlist)"
                                  [orderBy]="'text'">
                              </field-type-list-multiple>
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.Estado && resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto === 'Usuario'"
                                  [field]="resultSearch.estadoField"
                                  [listValues]="resultSearch.filterResults?.Estado"
                                  [preSelectItem]="resultSearch.searchFilter.Estado"
                                  [fieldToFind]="'text'"
                                  (itemSelected)="itemSelected('Estado', $event.newValue, $event.showlist)"
                                  [orderBy]="'text'">
                              </field-type-list-multiple>
                              <field-type-list-multiple [ngShow]="resultSearch.filterResults?.PerfilUsuario && resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto === 'Usuario'"
                                  [field]="resultSearch.perfilUsuarioField"
                                  [listValues]="resultSearch.filterResults?.PerfilUsuario"
                                  [preSelectItem]="resultSearch.searchFilter.PerfilUsuario"
                                  [fieldToFind]="'text'"
                                  (itemSelected)="itemSelected('PerfilUsuario', $event.newValue, $event.showlist)"
                                  [orderBy]="'text'">
                              </field-type-list-multiple>
                              <field-type-date [ngShow]="resultSearch.selectedTab === 'Concepts' && resultSearch.searchFilter.TipoConcepto !== 'Consulta'"
                                  [field]="resultSearch.creationDateField"
                                  [preSelectItem]="resultSearch.searchFilter.CreationDate"
                                  (itemSelected)="itemSelected('CreationDate', $event.newValue, $event.showlist)">
                              </field-type-date>
                          </div>
                      </div>
                      <!-- FIN filtro (BUSCADOR) -->
                  </div>
              </div>
              <!-- FIN SOLO BUSCADOR -->
              <div>
                  <!-- SOLO Juridica -->
                  <div class="result-search__settings-block"
                      *ngIf="resultSearch.hasJuridical && resultSearch.hayJuridicaDocuments"
                      [ngShow]="resultSearch.searchType==='juridica'">
                      <div class="result-search__body-setting">
                          <!-- Categorías de información (Juridica) -->
                          <div class="result-search__block-title-search result-search__block-title-search--juridica">
                              <i class="lf-icon-qmemento result-search__icon-search"></i>
                              <span class="result-search__title-text"
                                  [afTranslate]="'result-search.information-categories'">Categorías de información</span>
                          </div>
                          <!-- FIN Categorías de información (Juridica) -->
                          <!-- filtro (Juridica) -->
                          <div class="result-search__block-lists result-search__block-lists--juridica">
                              <div *ngFor="let document of resultSearch.juridicaNumDocuments | afOrderBy:'numOrder':false"
                                  class="result-search__block-lists-element"
                                  [ngClass]="{ 'result-search__block-lists-element--selected' : resultSearch.juridicaDocumentSelected.valueId === document.valueId}"
                                  (click)="selectJuridicaDocument(document, 1)">
                                  <div class="result-search__juridica-filter-selected-block"
                                      [ngShow]="resultSearch.juridicaDocumentSelected.valueId === document.valueId"></div>
                                  <div class="result-search__block-lists-text">{{document.name}}</div>
                                  <div class="result-search__block-lists-number">({{document.count}})</div>
                              </div>
                          </div>
                          <!-- FIN filtro (Juridica) -->
                      </div>
                  </div>
                  <!-- FIN SOLO Juridica -->

                  <div class="result-search__results-content-block"
                      *ngIf="!resultSearch.withoutResults || (resultSearch.withoutResults && (resultSearch.searching?.Documents || resultSearch.searching?.Tasks || resultSearch.searching?.Reports || resultSearch.searching?.Concepts || resultSearch.searching?.Juridica))">
                      <div class="result-search__block-header-search">
                          <!-- pestañas (BUSCADOR Y Juridica) -->
                          <div class="result-search__block-header-categories">
                              <div class="result-search__block-select-categories">
                                  <div class="result-search__block-categorie"
                                      [ngClass]="{ 'result-search__block-categorie--selected' : resultSearch.selectedTab === 'Documents'}"
                                      (click)="changeTab('Documents')">
                                      <p class="result-search__text-cat-name"
                                        [afTranslate]="'common.documents'">DOCUMENTOS</p>
                                      <img [src]="resultSearch.loaderImg"
                                          alt="spinner"
                                          class="result-search__spinner"
                                          [ngShow]="resultSearch.searching?.Documents"/>
                                      <p [ngShow]="!resultSearch.searching?.Documents && resultSearch.selectedTab !== 'Documents' "><span>(</span>{{getNumElements('Documents')}}<span>)</span></p>
                                      <p [ngShow]="!resultSearch.searching?.Documents && resultSearch.selectedTab === 'Documents' "><span>(</span>{{resultSearch.filterResults?.Results?.length}}<span>)</span></p>
                                  </div>
                                  <div class="result-search__block-categorie"
                                      [ngClass]="{ 'result-search__block-categorie--selected' : resultSearch.selectedTab === 'Concepts'}"
                                      (click)="changeTab('Concepts')">
                                      <p class="result-search__text-cat-name"
                                      [afTranslate]="'common.concepts'">CONCEPTOS</p>
                                      <img [src]="resultSearch.loaderImg"
                                          alt="spinner"
                                          class="result-search__spinner"
                                          [ngShow]="resultSearch.searching?.Concepts">
                                      <p [ngShow]="!resultSearch.searching?.Concepts && resultSearch.selectedTab !== 'Concepts' "><span>(</span>{{getNumElements('Concepts')}}<span>)</span></p>
                                      <p [ngShow]="!resultSearch.searching?.Concepts && resultSearch.selectedTab === 'Concepts' "><span>(</span>{{resultSearch.filterResults?.Results?.length}}<span>)</span></p>
                                  </div>
                                  <div class="result-search__block-categorie"
                                      [ngClass]="{'result-search__block-categorie--selected' : resultSearch.selectedTab === 'Tasks',
                                              'result-search__block-categorie--last' : !resultSearch.hasReports && !resultSearch.hasJuridical}"
                                      (click)="changeTab('Tasks')">
                                      <p class="result-search__text-cat-name"
                                      [afTranslate]="'common.tasks'">TAREAS</p>
                                      <img [src]="resultSearch.loaderImg"
                                          alt="spinner"
                                          class="result-search__spinner"
                                          [ngShow]="resultSearch.searching?.Tasks">
                                      <p [ngShow]="!resultSearch.searching?.Tasks && resultSearch.selectedTab !== 'Tasks' "><span>(</span>{{getNumElements('Tasks')}}<span>)</span></p>
                                      <p [ngShow]="!resultSearch.searching?.Tasks && resultSearch.selectedTab === 'Tasks' "><span>(</span>{{resultSearch.filterResults?.Results?.length}}<span>)</span></p>
                                  </div>
                                  <div class="result-search__block-categorie"
                                      [ngClass]="{'result-search__block-categorie--selected' : resultSearch.selectedTab === 'Reports',
                                                  'result-search__block-categorie--last' : !(resultSearch.hasJuridical && this.resultSearch.hayJuridicaDocuments)}"
                                      (click)="changeTab('Reports')"
                                      *ngIf="resultSearch.hasReports">
                                      <p class="result-search__text-cat-name"
                                      [afTranslate]="'common.reports'">INFORMES</p>
                                      <img [src]="resultSearch.loaderImg"
                                          alt="spinner"
                                          class="result-search__spinner"
                                          [ngShow]="resultSearch.searching?.Reports">
                                      <p [ngShow]="!resultSearch.searching?.Reports && resultSearch.selectedTab !== 'Reports' "><span>(</span>{{getNumElements('Reports')}}<span>)</span></p>
                                      <p [ngShow]="!resultSearch.searching?.Reports && resultSearch.selectedTab === 'Reports' "><span>(</span>{{resultSearch.filterResults?.Results?.length}}<span>)</span></p>
                                  </div>
                                  <div class="result-search__block-categorie result-search__block-categorie--last"
                                      *ngIf="resultSearch.hasJuridical && this.resultSearch.hayJuridicaDocuments"
                                      [ngClass]="{'result-search__block-categorie--selected' : resultSearch.selectedTab === 'Juridica'}"
                                      (click)="changeTab('Juridica')">
                                      <p class="result-search__text-cat-name"
                                      [afTranslate]="'result-search.b-d-juridica'">B. D. JURÍDICA</p>
                                      <img [src]="resultSearch.loaderImg"
                                          alt="spinner"
                                          class="result-search__spinner"
                                          [ngShow]="resultSearch.searching?.Juridica">
                                      <i [ngShow]="!resultSearch.searching?.Juridica"
                                          class="lf-icon-qmemento result-search__icon-juridica"></i>
                                  </div>
                              </div>
                          </div>
                          <!-- FIN pestañas (BUSCADOR Y Juridica) -->
                          <!-- orden (BUSCADOR) -->
                          <div class="result-search__block-select-order"
                              [ngShow]="resultSearch.searchType==='buscador'">
                              <div class="result-search__block-orderby">
                                  <div class="result-search__block-text-orderby">
                                      <i class="lf-icon-arrow-down result-search__icon-arrow-order"
                                          [ngClass]="resultSearch.orderDirection?'lf-icon-arrow-up':'lf-icon-arrow-down'"
                                          (click)="resultSearch.orderDirection = !resultSearch.orderDirection"></i>
                                      <span class="result-search__text-orderby"
                                          [afTranslate]="'result-search.order-by'">Ordenado por:</span>
                                      <button class="result-search__button-orderby"
                                              [ngClass]="{ 'result-search__button-orderby--selected' : resultSearch.orderType === 'CreationDate'}"
                                              (click)="orderBy('CreationDate')"
                                              [afTranslate]="'result-search.most-recent'">
                                          MÁS RECIENTES
                                      </button>
                                      <button class="result-search__button-orderby"
                                              [ngClass]="{ 'result-search__button-orderby--selected' : resultSearch.orderType === 'Name'}"
                                              (click)="orderBy('Name')"
                                              [afTranslate]="skin === 'icam-red'?'all-documentation.alphabetical':'result-search.names'">
                                          NOMBRES
                                      </button>
                                  </div>
                              </div>
                              <div class="result-search__block-collapse">
                                  <button class="result-search__button-collapse"
                                      (click)="collapseResult()">
                                      <i class="result-search__icon-arrow-order"
                                      [ngClass]="resultSearch.showContent?'lf-icon-collapse':'lf-icon-expand-vertical'"></i>
                                      <span class="result-search__text-order-collapse">{{resultSearch.textCollapse}}</span>
                                  </button>
                              </div>
                          </div>
                          <!-- FIN orden (BUSCADOR) -->
                          <!-- orden (Jurídica) -->
                          <div class="result-search__block-select-order"
                              *ngIf="resultSearch.hasJuridical"
                              [ngShow]="resultSearch.searchType==='juridica'">
                              <div class="result-search__block-orderby">
                                  <div class="result-search__block-text-orderby result-search__block-text-orderby--relevance">
                                      <span class="result-search__text-orderby"
                                          [afTranslate]="'result-search.order-by'">Ordenado por:</span>
                                      <button class="result-search__button-orderby result-search__button-orderby--relevance result-search__button-orderby--selected"
                                      [afTranslate]="'result-search.relevance'">
                                      RELEVANCIA
                                      </button>
                                  </div>
                              </div>
                              <div class="result-search__block-collapse">
                                  <div class="customize-concept__button-box-alert">
                                      <button class="customize-concept__alert-button customize-concept__alert-button--other"
                                              (click)="searchInJuridica()">
                                          <i class="common__icon-button-underlined lf-icon-search"></i>
                                          <span class="common__text-button-underlined"
                                              [afTranslate]="'result-search.launch-search'">LANZAR BÚSQUEDA EN B. D. JURÍDICA</span>
                                      </button>
                                      <div class="report-table__graph-button-line"></div>
                                  </div>
                              </div>
                          </div>
                          <!-- FIN orden (Jurídica) -->
                      </div>

                      <!-- resultados (BUSCADOR) -->
                      <div class="result-search__block-search-results"
                          [ngShow]="resultSearch.searchType==='buscador' && !resultSearch.searching[resultSearch.selectedTab] && resultSearch.selectedTab != ''">
                          <div [ngShow]="!resultSearch.filterResults?.Results?.length"
                              class="result-search__none-results-element">
                              <i class="lf-icon-step-final result-search__icon-circle"></i>
                              <span class="result-search__none-results-element-span"
                                  [afTranslate]="'result-search.subtitle-not-found-category'">No se han encontrado resultados en esta categoría.</span>
                          </div>
                          <div class="result-search__results-repeat"
                              [ngShow]="resultSearch.filterResults?.Results?.length > 0"
                              [ngClass]="{'result-search__results-repeat--collapsed': !resultSearch.showContent,
                                  'result-search__results-repeat--first': index === 0}"
                              *ngFor="let result of resultSearch.filterResults?.Results | afOrderBy:resultSearch.orderType:(resultSearch.orderType === 'CreationDate' ? !resultSearch.orderDirection : resultSearch.orderDirection) | afLimitTo:resultSearch.configPagination.pageSize:resultSearch.configPagination.pageSize*(resultSearch.currentPage - 1); let index = index">
                              <div class="result-search__line-circle"
                                  [ngClass]="{'result-search__line-circle--collapsed': !resultSearch.showContent}"></div>
                              <i class="lf-icon-step-final result-search__icon-circle"></i>
                              <div class="result-search__result-title">
                                  <span class="result-search__name-document"
                                      [innerHTML]="result.Name | setTextColors:resultSearch.textSearch:'result-search__name-document--red'"></span>
                                  <span *ngIf="result.IsDeleted" class="result-search__is-deleted" [afTranslate]="'result-search.deleted'"></span>
                                  <span *ngIf="result.IsArchived" class="result-search__is-deleted" [afTranslate]="'result-search.archived'"></span>
                              </div>
                              <dl class="result-search__highlight-block"
                                *ngIf="result.Highlights && result.Highlights.length">
                                  <dt class="result-search__highlight-qoute-block">
                                      <i class="lf-icon-quote-open result-search__highlights-quote"></i>
                                  </dt>
                                  <dd class="result-search__highlights-text"
                                      [innerHTML]="replaceToColor(result.Highlights[0])"></dd>
                              </dl>
                              <div class="result-search__result-content box-comments__vertical-block--last"
                                  [ngShow]="resultSearch.showContent && result.ProductName">
                                  <div class="result-search__result-content-element"
                                      [ngShow]="skin !== 'icam-red'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'common.product'">PRODUCTO</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.ProductName}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.EvaluationName && skin !== 'icam-red'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'common.implantation'">IMPLANTACIÓN</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.EvaluationName}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.ClientName && skin !== 'icam-red'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.client'">CLIENTE</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.ClientName}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.TipoConcepto && skin === 'icam-red' && resultSearch.selectedTab === 'Concepts'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.tipo-concepto'">TIPO DE CONCEPTO</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.TipoConcepto}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.TipoPublicacion && skin === 'icam-red' && resultSearch.selectedTab === 'Concepts' && result.TipoConcepto === 'Publicacion'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.tipo-publicacion'">TIPO DE PUBLICACIÓN</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.TipoPublicacion}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Estado && skin === 'icam-red' && resultSearch.selectedTab === 'Concepts' && result.TipoConcepto === 'Usuario'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'all-documentation.estado'">Estado</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.Estado}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.PerfilUsuario && skin === 'icam-red' && resultSearch.selectedTab === 'Concepts' && result.TipoConcepto === 'Usuario'">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.user-profile'">Perfil de Usuario</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.PerfilUsuario}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.TaskType">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.task-type'">TIPO DE TAREA</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.TaskType}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.ReportType">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.report-type'">TIPO DE INFORME</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.ReportType}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Author && !(skin === 'icam-red' && resultSearch.selectedTab === 'Concepts')">
                                      <span class="result-search__title-header"
                                          [afTranslate]="skin === 'icam-red'?'common.creator':'result-search.author'">AUTOR</span><span>:</span>
                                      <span class="result-search__title-text-search"
                                          [ngClass]="{'common__line-through':result.AuthorIsDeleted}"
                                          matTooltip="{{result.Author +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above"
                                          [matTooltipDisabled]="!result.AuthorIsDeleted">{{result.Author}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Participants">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.participant'">DESTINATARIO</span><span>:</span>
                                      <span *ngFor="let participant of result.formatedParticipant; let last = last"
                                          class="result-search__title-text-search">
                                          <span [ngClass]="{'common__line-through':participant.isDeleted}"
                                          matTooltip="{{participant.participant +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above"
                                          [matTooltipDisabled]="!participant.isDeleted">{{participant.participant}}</span>
                                          <span [ngHide]="last">, </span>
                                      </span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.CreationDate && !(skin === 'icam-red' && resultSearch.selectedTab === 'Concepts' && result.TipoConcepto === 'Consulta')">
                                      <span class="result-search__title-header"
                                          [afTranslate]="skin === 'icam-red' && resultSearch.selectedTab === 'Concepts'?'concept-list.registration-date':'common.creation'">CREACIÓN</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.CreationDate | convertDate: 'without-time' }}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Status">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.state'">ESTADO</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.Status}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Source">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.origin'">ORIGEN</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.Source}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.FFolderName">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.folder'">CARPETA</span><span>:</span>
                                      <span class="result-search__title-text-search">{{result.FFolderName}}</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Centros">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.centro'">CENTRO</span><span>:</span>
                                      <span *ngIf="result.Centros && result.Centros.length === 1" class="result-search__title-text-search">{{result.Centros}}</span>
                                      <span *ngIf="result.Centros && result.Centros.length > 1"
                                          matTooltip="{{result.Centros.join(', ')}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above"
                                          class="result-search__title-text-search">{{result.Centros[0]}} (+{{result.Tipologias.length-1}})</span>
                                  </div>
                                  <div class="result-search__result-content-element"
                                      [ngShow]="result.Tipologias">
                                      <span class="result-search__title-header"
                                          [afTranslate]="'result-search.tipologia'">TIPOLOGIA</span><span>:</span>
                                      <span *ngIf="result.Tipologias &&result.Tipologias.length === 1" class="result-search__title-text-search">{{result.Tipologias[0]}}</span>
                                      <span *ngIf="result.Tipologias && result.Tipologias.length > 1"
                                          matTooltip="{{result.Tipologias.join(', ')}}"
                                          matTooltipPosition="above"
                                          matTooltipClass="above"
                                          class="result-search__title-text-search">{{result.Tipologias[0]}} (+{{result.Tipologias.length-1}})</span>
                                  </div>
                                  <div [ngShow]="skin === 'icam-red' && result.TipoConcepto === 'Consulta'">
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.TipoConsulta">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.tipoConsulta'">Tipo de consulta</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.TipoConsulta}}</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Usuario">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.usuario'">Usuario</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.Usuario}}</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Gestor">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.gestor'">Gestor</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.Gestor}}</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Relevancia && result.TipoConsulta !== 'FO'">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.relevancia'">Relevancia</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.Relevancia}}</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Etiquetas && result.TipoConsulta !== 'FO'">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.etiquetas'">Etiquetas</span><span>:</span>
                                          <span *ngIf="result.Etiquetas && result.Etiquetas.length === 1" class="result-search__title-text-search">{{result.Etiquetas[0]}}</span>
                                          <span *ngIf="result.Etiquetas && result.Etiquetas.length > 1"
                                              matTooltip="{{result.Etiquetas.join(', ')}}"
                                              matTooltipPosition="above"
                                              matTooltipClass="above"
                                              class="result-search__title-text-search">{{result.Etiquetas[0]}} (+{{result.Etiquetas.length-1}})</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Materias && result.TipoConsulta !== 'FO'">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.materia'">Materia</span><span>:</span>
                                          <span *ngIf="result.Materias && result.Materias.length === 1" class="result-search__title-text-search">{{result.Materias[0]}}</span>
                                          <span *ngIf="result.Materias && result.Materias.length > 1"
                                              matTooltip="{{result.Materias.join(', ')}}"
                                              matTooltipPosition="above"
                                              matTooltipClass="above"
                                              class="result-search__title-text-search">{{result.Materias[0]}} (+{{result.Materias.length-1}})</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.FechaConsulta">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'all-documentation.consult-date'">Fecha de consulta</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.FechaConsulta | convertDate:'without-time'}}</span>
                                      </div>
                                      <div class="result-search__result-content-element"
                                          [ngShow]="result.Referencia">
                                          <span class="result-search__title-header"
                                              [afTranslate]="'common.reference'">Nº DE REFERENCIA</span><span>:</span>
                                          <span class="result-search__title-text-search">{{result.Referencia}}</span>
                                      </div>
                                  </div>

                              </div>
                              <div class="result-search__block-icons-result">
                                  <div class="result-search__table"
                                      ngbDropdown
                                      [autoClose]="'outside'"
                                      (openChange)="changeOpen(result)">
                                      <button class="lf-icon-kebab-menu documentation-list__options-button"
                                        ngbDropdownToggle></button>
                                      <ul class="result-search__list-menu-block dropdown-menu"
                                          ngbDropdownMenu>
                                          <li class="common__list-dropdown-option"
                                            *ngFor="let optionMenu of result.menuDocuments; let last = last">
                                              <button class="common__button-option-list-dropdown"
                                                  [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                                  (click)="buttonMenu(optionMenu.action, result)">
                                                  <i [class]="optionMenu.icon + ' common__icon-option-list-dropdown'"></i>
                                                  <span class="common__text-menu-option-dropdown"
                                                      [afTranslate]="optionMenu.name"></span>
                                              </button>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- FIN resultados (BUSCADOR) -->
                      <!-- resultados (Juridica) -->
                      <div class="result-search__block-search-results"
                          *ngIf="resultSearch.hasJuridical"
                          [ngShow]="resultSearch.searchType==='juridica'">
                          <div class="result-search__results-repeat result-search__results-repeat--juridica"
                              [ngClass]="{'result-search__results-repeat--first': first}"
                              *ngFor="let result of resultSearch.currentJuridicaResults; let first = first">
                              <div class="result-search__line-circle"
                                  [ngClass]="{'result-search__line-circle--collapsed': !resultSearch.showContent}"></div>
                              <i class="lf-icon-step-final result-search__icon-circle"></i>
                              <div class="result-search__result-juridica-entradilla">
                                  <span [ngShow]="result.entradilla"
                                      class="result-search__name-entradilla">{{result.entradilla}}</span>
                              </div>
                              <div class="result-search__result-juridica-title">
                                  <span class="result-search__name-document result-search__name-document--juridica"
                                      [innerHTML]="result.titulo | setTextColors:resultSearch.textSearch:'result-search__name-document--red'"
                                      (click)="navigateToJuridica(result)"></span>
                              </div>
                              <div class="result-search__result-juridica-resumen">
                                  <i class="result-search__result-quote-open lf-icon-quote-open"
                                  [ngShow]="result.resumen"></i>
                                  <span class="result-search__name-resumen"
                                      [ngShow]="result.resumen"
                                      [innerHTML]="result.resumen | setTextColors:resultSearch.textSearch:'result-search__name-document--red'"></span>
                              </div>
                              <div class="result-search__block-icons-result">
                                  <div class="result-search__table"
                                      ngbDropdown
                                      [autoClose]="'outside'"
                                      [open]="resultSearch.openMenu">
                                      <button class="lf-icon-kebab-menu documentation-list__options-button"
                                            ngbDropdownToggle></button>
                                      <ul class="result-search__list-menu-block dropdown-menu"
                                          ngbDropdownMenu>
                                          <li class="common__list-dropdown-option"
                                              *ngFor="let optionMenuJuri of resultSearch.menu['juridicaMenu']; let last = last"
                                              [ngHide]="optionMenuJuri.hide">
                                              <button class="common__button-option-list-dropdown"
                                                  [ngClass]="{'common__button-option-list-dropdown--last': last}"
                                                  (click)="buttonMenu(optionMenuJuri.action, result)">
                                                  <i class="{{optionMenuJuri.icon}} common__icon-option-list-dropdown"></i>
                                                  <span class="common__text-menu-option-dropdown"
                                                      [afTranslate]="'{{optionMenuJuri.name}}'"></span>
                                              </button>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- FIN resultados (Juridica) -->
                  </div>

                  <!-- FIN resultados (BUSCADOR) -->
              </div>
              <!-- paginación (BUSCADOR) -->
              <pagination-control class="result-search__pagination"
                  [ngShow]="resultSearch.searchType==='buscador' && !resultSearch.withoutResults"
                  [config]="resultSearch.configPagination"
                  [collectionSize]="resultSearch.filterResults?.Results?.length"
                  [(currentPage)]="resultSearch.currentPage">
              </pagination-control>
              <!-- FIN paginación (BUSCADOR) -->
              <!-- paginación (Juridica) -->
              <pagination-control class="result-search__pagination"
                  *ngIf="resultSearch.hasJuridical"
                  [ngShow]="resultSearch.searchType==='juridica' && !resultSearch.withoutResults"
                  [config]="resultSearch.configPagination"
                  [collectionSize]="resultSearch.juridicaDocumentSelected.count"
                  [(currentPage)]="resultSearch.juridicaCurrentPage"
                  (currentPageChange)="juridicaPageChanged()">
              </pagination-control>
              <!-- FIN paginación (Juridica) -->
          </div>
          <!-- no results (BUSCADOR Y Juridica) -->
          <div class="result-search__none-results-content-block"
              *ngIf="resultSearch.withoutResults && !resultSearch.searching?.Documents && !resultSearch.searching?.Tasks && !resultSearch.searching?.Reports && !resultSearch.searching?.Concepts && !resultSearch.searching?.Juridica">
              <i class="lf-icon-search result-search__icon-none-result"></i>
              <p class="result-search__none-primary-text"
                  [afTranslate]="'result-search.title-not-found'">¡Lo sentimos!</p>
              <p class="result-search__none-secondary-text"><span [afTranslate]="'result-search.subtitle-not-found'">No se han encontrado resultados para </span>{{resultSearch.textSearch}} .</p>
              <p *ngIf="false">¿Quzás quiso decir?</p>
          </div>
          <!-- FIN no results (BUSCADOR Y Juridica) -->
      </div>
  </div>
  <compliance-footer></compliance-footer>
</div>
