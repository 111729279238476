import { Component, OnInit, Input, Output, OnChanges, EventEmitter, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AttachmentsTemplateParentService } from '../utils/attachments-template-parent.service';

@Component({
  selector: 'attachments-template-custom',
  templateUrl: './attachments-template-custom.component.html'
})
export class AttachmentsTemplateCustomComponent  implements OnInit, OnChanges, OnDestroy {
  skin: string = this.globalCfg.skin
  isLanding: boolean = true;
  currentComponent = this.route.snapshot.data['componentName'];
  icamConceptObject = (this.skin === 'icam-red' && this.isLanding && this.context.view && this.context.view.extraData && this.context.view.extraData.masterConceptId && this.context.view.extraData.masterConceptObjectId);
  userPermissions = this.permisions.getPermissions();
  attachmentsTemplate:any = {
    hiddenTemplate: false,
    fullScreen: false,
    comments: '',
    attachmentList: [],
    newDocuments: [],
    titleHeader: this.icamConceptObject? 'attachments.related-documentation' : 'attachments.documents',
    infoNoDocuments:  this.icamConceptObject? 'attachments.without-documents-related' : 'attachments.without-documents'
  }
  constructor(private route:ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private permisions: UserPermisionsFactory,
    private attachmentsTemplateParent: AttachmentsTemplateParentService) {}

    @Input() itemSelected: any = null;
    @Input() concept: any = null;
    @Input() attachmentList: any = null;
    @Input() fullScreenBox: any = null;
    @Input() typeFrom: string = '';

    @Output() catchFullScreen = new EventEmitter();
    @Output() launchAction = new EventEmitter();

  ngOnInit(): void {
    this.attachmentsTemplateParent.watchChangeListDocuments(this);
  }

  ngOnChanges(): void {
    this.attachmentsTemplateParent.onChanges(this);
  }

  launchActionDocuments(action:any, document:any= null){
    this.attachmentsTemplateParent.launchActionDocuments(action, document, this)
  }

  maximizeScreen(keepClassBox: boolean = false){
    this.attachmentsTemplateParent.maximizeScreen(keepClassBox, this)
  }

  ngOnDestroy(): void {
    this.attachmentsTemplateParent.ngOnDestroy();
  }
}
