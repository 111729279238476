<div class="common__field-type field-type-date">
    <label class="common__input-block common__input-block--field"
        [ngClass]="{'common__input-block--show-references': fieldTypeDate.references && field.evaluatedValue?.conceptDataId !== null,
            'common__show-alert': (field.error && !field.sequentialView) || field.errorInDateLock,
            'common__input-no-editable': field.noEditable,
            'common__input-block--poll-view': isLanding && field.poll && !field.noEditable && field.sequentialView,
            'common__input-block--custom-field': isLanding && !field.sequentialView,
            'common__input-block-open': fieldTypeDate.openDatePicker || fieldTypeDate.openTimePicker || fieldTypeDate.openFromToPicker}"
        [for]="'field-item-date-' + field.id"
        [attr.disabled]="field.noEditable?true:null">
        <div class="common__input-text-poll"
            *ngIf="field.sequentialView && !field.noEditable"
            [ngClass]="{'common__input-text-poll--error': field.error || field.errorInDateLock} ">
            <p class="field-type-text__custom-sequential-label">
                {{fieldTypeDate.titleField}}
                <span class="common__text-required common__text-required--sequential-view"
                        [ngShow]="field.required">*</span>
                <tooltip-info [description]="field.description"
                    [field]="field"
                    [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView"></tooltip-info>
                <tooltip-law [references]="field.references"
                    [field]="field"
                    [ngShow]="field.references?.length"
                    [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
                </tooltip-law>
            </p>
        </div>
        <div class="common__label-block {{fieldTypeDate.classWidthField}}"
            *ngIf="!(field.sequentialView && !field.noEditable)">
            <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error || field.errorInDateLock,
                    'common__input-text--no-editable': field.noEditable}">{{fieldTypeDate.titleField}}</span>
            <span class="common__text-required"
                    [ngShow]="field.required">*</span>
            <div class="common__info-icon-block"
                    [ngHide]="field.noEditable || !field.description">
                <tooltip-info [description]="field.description"
                    [field]="field"></tooltip-info>
            </div>
            <div class="common__tooltip-law-block"
                [ngClass]="{'common__tooltip-law-block--landing-custom': isLanding}"
                [ngShow]="field.references?.length && !field.noEditable">
                <tooltip-law [references]="field.references"
                    [field]="field"></tooltip-law>
            </div>
            <div class="common__tooltip-law-block"
                [ngShow]="field.relatedInfo?.length && !field.poll && !field.noEditable">
                <i class="lf-icon-search-text field-item__icon-related-info"
                    (click)="openModalRelatedInfo($event)"
                    matTooltip="{{'common.related-info' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                </i>
            </div>
        </div>
        <div [ngClass]="{'common__input-poll': field.poll && !field.noEditable && field.sequentialView,
                'common__input-poll--open': (fieldTypeDate.openDatePicker || fieldTypeDate.openTimePicker) && field.poll && !field.noEditable && field.sequentialView,
                'common__show-alert': field.error && field.sequentialView}">
            <i class="lf-icon-connected-data field-type-text__icon-references"
                [ngClass]="{'field-type-text__icon-references--2-icons': (isLanding && field.showTime) || (fieldTypeDate.showAlertButton && (preSelectItem || field.showTime)),
                    'field-type-text__icon-references--3-icons': fieldTypeDate.showAlertButton && field.showTime && preSelectItem}"
                *ngIf="field.evaluatedValue?.hasReferences && !field.poll"
                (click)="showReference($event)"
                matTooltip="{{'common.see-dependencies' | afTranslate}}"
                matTooltipPosition="left"
                matTooltipClass="left"></i>
            <i *ngIf="!isLanding && !public && field.showAlertButton && !field.noEditable && preSelectItem && !field.poll"
                class="lf-icon-notification-new common__input-icon field-type-date__input-icon-alert common__input-icon-active"
                [ngClass]="{'common__input-icon-active--disabled': field.noEditable,
                    'field-type-date__input-icon-alert-with-clock': field.showTime}"
                (click)="openAddAlert($event)"></i>
            <div ngbDropdown
                [autoClose]="'outside'"
                (openChange)="changeOpenDataPicker()"
                class="field-type-date__block-time-picker">
                <button ngbDropdownToggle
                    class="lf-icon-calendar common__input-icon common__input-icon-active"
                    [ngClass]="{'common__input-icon-active--disabled': field.noEditable,
                        'field-type-date__input-icon-calendar': field.showTime}"
                    [disabled]="field.noEditable">
                </button>
                <calendar-datepicker ngbDropdownMenu
                    *ngIf="!field.fromTo"
                    class="field-type-date__datepicker-box"
                    [selectedDate]="preSelectItem"
                    [openDatePicker]="fieldTypeDate.openDatePicker"
                    [dateLockType]="field.dateLockType"
                    [type]="'picker-data'"
                    (selectDatepicker)="changeDatePicker($event.newValue)">
                </calendar-datepicker>
                <div ngbDropdownMenu
                    *ngIf="field.fromTo"
                    class="field-type-date__calendar-dropdown field-type-date__calendar-dropdown--from-to common__block-option-list">
                    <from-to-calendar [fromSave]="fieldTypeDate.creationDateFromDate"
                        [toSave]="fieldTypeDate.creationDateToDate"
                        (changeCalendar)="changeCalendar($event)"
                        [type]="'field-type-date'">
                    </from-to-calendar>
                </div>
            </div>
            <div *ngIf="field.showTime"
                ngbDropdown
                class="field-type-date__block-time-picker"
                [autoClose]="'outside'"
                (openChange)="fieldTypeDate.openTimePicker = !fieldTypeDate.openTimePicker">
                <button ngbDropdownToggle
                    class="lf-icon-clock common__input-icon common__input-icon-active"
                    [ngClass]="{'common__input-icon-active--disabled': field.noEditable}"
                    [disabled]="field.noEditable">
                </button>
                <time-picker ngbDropdownMenu class="field-type-date__no-dropdown-box"
                    [hours]="fieldTypeDate.hours"
                    [minutes]="fieldTypeDate.minutes"
                    [open]="fieldTypeDate.openTimePicker"
                    [field]="field"
                    (changeTime)="changeInTimePicker($event)">
                </time-picker>
            </div>
            <input-date [ngHide]="field.fromTo"
                class="common__input-content"
                [ngClass]="{'common__input-content--poll': field.poll && !field.noEditable && field.sequentialView,
                    'common__input-content--custom': isLanding}"
                [disabled]="field.noEditable"
                [eraseButton]="'no-show'"
                [input]="preSelectItem"
                [field]="field"
                (output)="outputDate($event)">
            </input-date>
            <p class="common__input-content common__input-content--from-to"
                [ngShow]="field.fromTo">{{preSelectItem}}</p>
            <i class="common__delete-content-icon field-type-date__delete-content-icon"
                [ngClass]="{'lf-icon-close-round common__delete-content-icon--custom': isLanding,
                    'lf-icon-close': !isLanding}"
                [ngShow]="preSelectItem || fieldTypeDate.selectInput === 'textUndefined'"
                (click)="deleteValueField($event)">
            </i>
        </div>
        <div class="field-item__blocker-box"
                *ngIf="field.error && field.blocker && !concept.errorInForm.length">
            <div class="field-item__blocker-triangle"></div>
            <i class="lf-icon-warning field-item__blocker-icon"></i>
            <p class="field-item__blocker-text"
                [afTranslate]="'field-items.blocker-field'"></p>
        </div>
    </label>
    <p class="field-type-date__error-in-field"
        [ngShow]="field.errorInDateLock">{{fieldTypeDate.errorText}}</p>
</div>
