import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'customize-field-date',
  templateUrl: './customize-field-date.component.html'
})
export class CustomizeFieldDateComponent implements OnChanges {
  skin: string = this.globalCfg.skin
  customizeFieldDate:any = {
    tinyMceOptions: {},
    defaultValueInfo:{
      label: this.translate.instant('customize.default-value'),
    },
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    },
    dateLimitsConfig: {
      multiple: false,
      label: this.translate.instant('customize.date-limits-title'),
      values: [
          {
              text: this.translate.instant('customize.date-limits-options.option-0'),
              listValueId: 0
          },
          {
              text: this.translate.instant('customize.date-limits-options.option-1'),
              listValueId: 1
          },
          {
              text: this.translate.instant('customize.date-limits-options.option-2'),
              listValueId: 2
          },
          {
              text: this.translate.instant('customize.date-limits-options.option-3'),
              listValueId: 3
          },
          {
              text: this.translate.instant('customize.date-limits-options.option-4'),
              listValueId: 4
          }
      ]
    }
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;
  @Output() changeInField = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFieldDate;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      that.fieldCopy = cloneDeep(that.field);
      that.defaultValueInfo.noEditable = that.field.noEditable;
      that.contentReportInfo.noEditable =  that.field.noEditable;
      if(that.field.defaultValue){
        that.fielddefaultToShow = new Date(that.field.defaultValue);
      }
      that.dateLimitsConfig.selectedLimitType= that.field.dateLockType ? that.field.dateLockType : 0;
    }
    if(this.concept?.poll && this.concept?.disabled){
      that.contentReportInfo.noEditable = this.concept.disabled;
      that.defaultValueInfo.noEditable = this.concept.disabled;
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  itemSelected(e:any, item: any){
      this.customizeFieldDate.field[item] = e.newValue;
      if(item === 'defaultValue'){
        this.customizeFieldDate.fielddefaultToShow = new Date(this.customizeFieldDate.field[item]);
      }
      this.modifyField();
  }

  getEditorValue(event:any){
    this.customizeFieldDate.field.description = event.model;
  }

  cancelFieldText(){
    this.customizeFieldDate.field = cloneDeep(this.customizeFieldDate.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldDate.field
        }
    })
  }

  saveFieldText(){
    this.customizeFieldDate.fieldCopy = cloneDeep(this.customizeFieldDate.field);
    this.changeInField.emit({
        change:{
            type: 'save',
            field: this.customizeFieldDate.field
        }
    })
  }

  openVisibility(e:any){
    this.customizeFieldDate.field.visibilityOr = e.visibilityOr;
    this.customizeFieldDate.field.openBlock = e.openBlock;
  }

  modifyField(){
      this.conceptState.add(this.customizeFieldDate.field);
  }
  changeOptionOn(e:any, type:string){
    this.customizeFieldDate.field[type] = e.model
    this.modifyField();
  }
}
