import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { cloneDeep } from 'lodash';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'customize-field-list',
  templateUrl: './customize-field-list.component.html'
})
export class CustomizeFieldListComponent implements OnChanges {
  skin: string = this.globalCgf.skin;
  requireText: string = this.translate.instant('customize-field.message-required-list');
  valueText: string = this.translate.instant('customize-field.message-value-text')
  customizeFieldList: any = {
    tinyMceOptions: {},
    colorSelected: {},
    newListValue: {
      text:'',
      isDefaultValue: false,
      newItem: true
    },
    maxlength: 50,
    listColors: [
      {
          color: '#5EC6DD',
          colorId: 1
      },
      {
          color: '#7683B5',
          colorId: 2
      },
      {
          color: '#77D166',
          colorId: 3
      },
      {
          color: '#7A58AC',
          colorId: 4
      },
      {
          color: '#929B9C',
          colorId: 5
      },
      {
          color: '#9A7E74',
          colorId: 6
      },
      {
          color: '#C43741',
          colorId: 7
      },
      {
          color: '#E5869D',
          colorId: 8
      },
      {
          color: '#F1923E',
          colorId: 9
      },
      {
          color: '#F1BC3E',
          colorId: 10
      }
    ],
    contentReportInfo: {
      label: this.translate.instant('common.report-field')
    }
  }
  constructor(private globalCgf: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private commonUtilsService: CommonUtilsService,
    private conceptState: ConceptStateFactory,
    private dragulaService: DragulaService) {
      this.dragulaServiceFunction();
    }
  @Input() field: any = null;
  @Input() visibilityItems: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() concept: any = null;
  @Output() changeInField = new EventEmitter();
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  ngOnChanges(changes:SimpleChanges): void{
    let that: any = this.customizeFieldList;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      if(!that.field.fieldAttributes){
        that.field.fieldAttributes = {
          extendedList: that.field.extendedList,
          numericalScale: that.field.numericalScale,
          isManagerClassificator: that.field.isManagerClassificator
        };
      }
      if(that.field.extendedList){
        that.maxlength = 2000;
      }
      this.isHighlight();
      that.contentReportInfo.noEditable = that.field.noEditable;
      if(that.field.fieldTypeId === 'listp'){
        that.field.isOpen = false;
      }
      that.fieldCopy = cloneDeep(that.field);
    }
    if(this.concept?.poll && this.concept?.disabled){
        that.contentReportInfo.noEditable = this.concept.disabled;
    }
    that.tinyMceOptions.noEditable = this.concept.poll && this.concept.disabled?this.concept.disabled:false;
  }

  dragulaServiceFunction(){
    if(!this.dragulaService['groups']?.customizeFieldListValue){
      this.dragulaService.createGroup('customizeFieldListValue', {
        revertOnSpill: false,
        copy: false,
        moves: (element, container, handle) => {
          return handle?.className.indexOf('handleFieldList') !== -1;
        }
      });
    }
  }

  itemSelected(e:any, item:any){
    this.customizeFieldList.field[item] = e.newValue;
    this.modifyField();
  }

  cancelFieldText(){
    this.customizeFieldList.field = cloneDeep(this.customizeFieldList.fieldCopy);
    this.changeInField.emit({
        change:{
            type: 'cancel',
            field: this.customizeFieldList.field
        }
    })
  }

  resetText(){
    if((this.requireText === this.customizeFieldList.newListValue.text  || this.valueText === this.customizeFieldList.newListValue.text) && this.customizeFieldList.listError){
      this.customizeFieldList.listError = false;
      this.customizeFieldList.newListValue.text = '';
    }
  }

  saveFieldText(){
    let that: any = this.customizeFieldList;
    if(that.field.listValues && that.field.listValues.length && this.checkListValues()){
        that.fieldCopy = cloneDeep(that.field);
        that.listError = false;
        that.newListValue.text = '';
        that.newListValue.weight = null;
        that.newListValue.weightError = false;
        this.changeInField.emit({
            change:{
                type: 'save',
                field: that.field
            }
        })
    }else if(!that.errorListValues && !that.numericalScaleError){
        that.listError = true;
        that.newListValue.text = this.requireText;
    }
  }

  checkListValues(){
    let that: any = this.customizeFieldList;
    that.errorListValues = false;
    that.numericalScaleError = false;
    that.showError = false;
    let count = 0;
    for(let listValue of that.field.listValues){
        count++;
        listValue.error = (listValue.text === '' || listValue.text === this.requireText || listValue.text === this.valueText);
        that.errorListValues = ((that.field.numericalScale && listValue.error) ||  (listValue.text === '' || listValue.text === this.requireText || listValue.text === this.valueText) || (that.field.fieldTypeId === 'listp' && this.errorWeight(listValue)));
        that.numericalScaleError = (that.field.numericalScale && listValue.error);
        if(count === that.field.listValues.length){
            if(that.errorListValues){
                this.configureTextError();
                return false;
            }else{
                return true;
            }
        }
    }
    return false;
  }

  openVisibility(e:any){
    this.customizeFieldList.field.visibilityOr = e.visibilityOr;
    this.customizeFieldList.field.openBlock = e.openBlock;
  }

  modifyField(action:any = ''){
    this.customizeFieldList.field.isDisaggregated = !(action === 'required' && !this.customizeFieldList.field.required);
    this.customizeFieldList.field.fieldAttributes.isDisaggregated = !(action === 'required' && !this.customizeFieldList.field.required);
    if(action === 'multiple'){
        this.resetDefaultValue();
        this.customizeFieldList.newListValue.isDefaultValue = false;
    }else{
        this.conceptState.add(this.customizeFieldList.field);
    }
  }

  closeAdvice(){
      this.customizeFieldList.showError = false;
  }



  addNewListvalue(listValue:any){
    let that: any = this.customizeFieldList;
      if(this.verifyNewField(listValue)){
          that.listError = false;
          if(that.newListValue.isDefaultValue){
              this.resetDefaultValue();
          }
          if(that.field.isHighlight){
              if(!that.colorSelected){
                  that.colorSelected = that.listColors[2];
              }
              that.newListValue.color = that.colorSelected.color;
          }
          if(!listValue || listValue.newItem){
              delete that.newListValue.newItem;
              that.field.listValues.push(that.newListValue);
              that.newListValue = {
                  text:'',
                  isDefaultValue: false,
                  newItem: true
              };
              if(that.field.isHighlight){
                  that.colorSelected = that.listColors[2];
              }
          }else if(listValue && !listValue.newItem){
              setTimeout(function(){
                let element: any = $('#customize-field-list-new-list-value-'+that.field.fieldId);
                element.focus().select();
              })
          }
          this.modifyField();
      }else{
          this.configureTextError();
      }
  }

  configureTextError(){
      this.customizeFieldList.textError = '';
      this.customizeFieldList.showError = true;
      if(this.customizeFieldList.textErrorList){
          this.customizeFieldList.textError = this.translate.instant('customize-field-list.error-value-undefined');
      }
      if(this.customizeFieldList.numericalScaleError){
          this.customizeFieldList.textError = this.translate.instant('customize-field-list.numerical-scale-error');
      }
      if(this.customizeFieldList.weightErrorList){
          this.customizeFieldList.textError += ' ' + this.translate.instant('customize-field-bool.info-error-weight');
      }
  }

  verifyNewField(listValue:any){
      let that: any = this.customizeFieldList
      this.resetErrors();
      for(let element of that.field.listValues){

          if((!listValue || listValue.newItem) && (element.text === that.newListValue.text)){
              that.listError = true;
              return false;
          }
          if(listValue && !listValue.newItem && element.listValueId !== listValue.listValueId && (element.text === listValue.text)){
              listValue.error = true;
              return false;
          }
      }
      let textError: boolean = (listValue && !listValue.newItem && (this.requireText === listValue.text  || this.valueText === listValue.text))
      let listValueError: boolean = ((this.requireText === that.newListValue.text || this.valueText === that.newListValue.text) && that.listError)
      let listValueEmpty: boolean = (listValue && listValue.text === '' && !listValue.newItem);
      let numericalScaleError: boolean = (that.field.numericalScale && (listValue && !listValue.newItem && !this.commonUtilsService.checkIsIntegerNumber(listValue.text)))
      let newListValueError: boolean = (that.newListValue.text === '' && (!listValue || listValue.newItem))
      let otherNumericalScaleError: boolean = (that.field.numericalScale && (!listValue || listValue.newItem) && !this.commonUtilsService.checkIsIntegerNumber(that.newListValue.text))
      listValue.error = textError || listValueError || listValueEmpty || numericalScaleError;
      that.textErrorList = textError || listValueError || listValueEmpty || newListValueError;
      that.numericalScaleError = numericalScaleError || otherNumericalScaleError;
      that.listError = newListValueError || otherNumericalScaleError;

      if(listValueEmpty){
        listValue.text = this.valueText;
      }

      if(newListValueError){
        that.newListValue.text = this.valueText;
      }
      if(listValue){
        listValue.error = false;
      }
      return !(textError || listValueError || listValueEmpty || numericalScaleError || newListValueError || otherNumericalScaleError || this.errorWeight(listValue));
  }

  resetErrors(){
      this.customizeFieldList.showError = false;
      this.customizeFieldList.weightErrorList = false;
      this.customizeFieldList.numericalScaleError = false;
      this.customizeFieldList.textErrorList = false;
  }

  errorWeight(listValue:any){
      if(this.customizeFieldList.field.fieldTypeId === 'listp' && (!listValue || (listValue && (listValue.weight === undefined || listValue.weight === null || listValue.weight === '')))){
          listValue.weightError = true;
          this.customizeFieldList.weightErrorList = true;
          return true;
      }
      listValue.weightError = false;
      return false;
  }

  resetDefaultValue(){
    this.customizeFieldList.field.listValues.forEach((listValue:any) => {
      listValue.isDefaultValue = false;
    });
    this.modifyField();
  }

  editListValue(listValue:any, index:any, keyValue:any){
      listValue.edit = !listValue.edit;
      if(this.customizeFieldList.field.fieldTypeId === 'listp'){
          listValue.editWeight = !listValue.editWeight;
      }
      setTimeout(()=>{
        let element: any = $('#customize-field-list-'+this.customizeFieldList.field.fieldId+'-list-'+keyValue+'-'+index)
          element.focus().select();

      })
      this.modifyField();
  }

  deleteListValue(listValue:any){
      this.customizeFieldList.field.listValues = this.commonUtilsService.deleteElement( listValue, this.customizeFieldList.field.listValues, 'text')
      this.modifyField();
  }

  selectDefaultValue(listValue:any){
      if(!listValue.isDefaultValue && !this.customizeFieldList.field.multiple){
          this.resetDefaultValue();
          this.customizeFieldList.newListValue.isDefaultValue = false;
      }
      listValue.isDefaultValue = !listValue.isDefaultValue;
  }

  isHighlight(){
      let field: any = this.customizeFieldList.field;
      if(field.isHighlight === true){
          field.multiple = false;
          field.isOpen = false;
          field.fieldAttributes.numericalScale = false;
          field.numericalScale =  field.fieldAttributes.numericalScale;
          field.listValues.forEach((listValue:any) => {
            if(!listValue.color || listValue.color === ''){
              listValue.color = this.customizeFieldList.listColors[2].color;
            }
          });
      }
  }

  selectColor(color:any, listValue:any=null){
      if(listValue){
          listValue.color = color.color;
      }else{
          this.customizeFieldList.colorSelected = color;
      }
      this.dropdown.close();
      this.modifyField();
  }

  checkExtendList(value:any){
    let that: any = this.customizeFieldList;
      that.field.fieldAttributes.extendedList = value;
      if(that.field.fieldAttributes.extendedList){
          that.field.isOpen = false;
          that.field.isHighlight = false;
          that.field.fieldAttributes.numericalScale = false;
          that.field.numericalScale =  that.field.fieldAttributes.numericalScale;
          that.maxlength = 2000;
      }else{
          that.maxlength = 50;
          that.newListValue.text = that.newListValue.text.substring(0,50)
      }
      this.modifyField();
  }

  checkNumericalScale(value:any){
    let field: any = this.customizeFieldList.field
      field.fieldAttributes.numericalScale = value;
      if(value){
          field.isHighlight = false;
          field.multiple = false;
          field.isOpen = false;
          field.fieldAttributes.extendedList = false;
          field.extendedList = field.fieldAttributes.extendedList;

      }
      this.checklist();
      this.modifyField();
  }

  checklist(){
    let that: any = this.customizeFieldList;
    that.numericalScaleError = false;
    that.showError = false;
    that.field.listValues.forEach((field:any) => {
      let isIntegerNumber : any = this.commonUtilsService.checkIsIntegerNumber(field.text);
      field.error = field.text === ''  || this.valueText === field.text? true : (that.field.numericalScale && !isIntegerNumber);
        if(field.error){
          that.numericalScaleError = that.field.numericalScale;
          this.configureTextError();
        }
    });
  }

  checkValue(listValue:any){
      let that: any = this.customizeFieldList;
      this.resetErrors();
      let isIntegerNumber: boolean = this.commonUtilsService.checkIsIntegerNumber(listValue.text);
      listValue.error = (that.field.numericalScale && !isIntegerNumber);
      listValue.edit = false;
      if(that.field.fieldTypeId === 'listp'){
          listValue.editWeight = false;
      }
      that.field.listValues.forEach((value:any) => {
        if(listValue && !listValue.newItem && value.listValueId !== listValue.listValueId
          && (value.text === listValue.text || listValue.text === '' || this.requireText === listValue.text  || this.valueText === listValue.text)){
          listValue.error = true;
          listValue.text = this.valueText;
          that.textErrorList = true;
        }
      })
      if(listValue.error){
        that.numericalScaleError = that.field.numericalScale;
      }
      if(listValue.error || this.errorWeight(listValue)){
          this.configureTextError();
      }
      this.modifyField();
  }

  addDataKey(e:any, listValue:any){
      if(e.keyCode ===13){
          listValue.edit = false;
          if(this.customizeFieldList.field.fieldTypeId === 'listp'){
              listValue.editWeight = false;
          }
          this.addNewListvalue(listValue);
          e.preventDefault();
      }
  }

  showTooltip( id:any){
      return this.commonUtilsService.showTooltip(id);
  }

  selectIsDissagregated(){
      this.customizeFieldList.field.fieldAttributes.isDisaggregated = this.customizeFieldList.field.isDisaggregated;
      this.modifyField();
  }

  changeOptionOn(e:any, type:string){
    let that: any = this.customizeFieldList;
    that.field[type] = e.model;
    switch(type){
      case 'private':
        break;
      case 'isIdentifier':
        this.modifyField();
        break;
      case 'extendedList':
        this.checkExtendList(e.model);
        break;
      case 'multiple':
        this.modifyField('multiple');
        break;
      case 'numericalScale':
        this.checkNumericalScale(e.model);
        break;
      case 'isOpen':
        this.modifyField('open');
        break;
      case 'isDisaggregated':
        this.selectIsDissagregated();
        break;
      case 'isHighlight':
          this.modifyField('isHighlight');
          break;
      case 'isManagerClassificator':
        that.field.fieldAttributes.isManagerClassificator = that.field.isManagerClassificator;
        that.field.multiple = false;
        that.field.required = true;
        this.modifyField();
        break
      default:
        this.modifyField();
        break;
    }
  }

  getEditorValue(event:any){
    this.customizeFieldList.field.description = event.model;
  }
}
