<div class="listen-record">
  <div class="listen-record__button-close-box">
    <button class="listen-record__button-close"
      (click)="handleAccept()">
      <i class="listen-record__close-icon"
      [ngClass]="isLanding?'lf-icon-close-round':'lf-icon-close' "></i>
    </button>
  </div>
  <div class="listen-record__body-box">
    <button *ngIf="!isLanding; else landingButton"
      class="listen-record__play-button"
      (click)="playRecordVoice()">
      <i class="listen-record__play-button-icon"
        [ngClass]="startAudio?'lf-icon-stop':'lf-icon-play'"></i>
    </button>
    <ng-template #landingButton>
      <button class="listen-record__play-button"
        [ngStyle]="{'background-color':custom.color, 'color': custom.textColor}"
        (click)="playRecordVoice()">
        <i class="listen-record__play-button-icon"
          [ngClass]="startAudio?'lf-icon-stop':'lf-icon-play'"></i>
      </button>
    </ng-template>
    <div class="listen-record__player-time-block">
      <div class="record-audio-modal__time-record-box">
        <p class="record-audio-modal__record-count"
          [ngClass]="{'record-audio-modal__record-count--active':startAudio,
          'record-audio-modal__record-count--landing': isLanding,
          'record-audio-modal__record-count--landing-active':isLanding && startAudio}">{{recordedTime}}</p>
        <p class="record-audio-modal__record-max-time"
        [ngClass]="{'record-audio-modal__record-max-time--landing': isLanding}">{{voiceRecord.maxTime}}</p>
      </div>
      <div class="record-audio-modal__record-background-line"
        [ngClass]="{'record-audio-modal__record-background-line--landing': isLanding}">
        <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--active"
          [ngClass]="{'record-audio-modal__record-background-line--landing-active': isLanding}"
          [progressBar]="percentTime">
        </div>
      </div>
    </div>
    <button class="listen-record__modal-button"
      [ngClass]="{'listen-record__modal-button--landing': isLanding}"
      (click)="audioDownLoad()">
      <i class="lf-icon-download"></i>
    </button>
    <button class="listen-record__modal-button listen-record__modal-button--right"
      [ngClass]="{'listen-record__modal-button--landing': isLanding}"
      *ngIf="!voiceRecord.noDelete"
      (click)="handleAccept('delete')">
      <i class="lf-icon-trash"></i>
    </button>
  </div>
</div>
