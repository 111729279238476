import "tinymce";
import "tinymce/themes/silver";
import "tinymce/models/dom";
import "tinymce/icons/default"
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";

declare const tinymce: any;

import { Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy} from '@angular/core';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { GlobalCfgFactory } from "@shared/factories/global-cfg/global-cfg.factory";
import { ModalServiceService } from "@shared/services/modal-service/modal-service.service";
import { CustomLandingFactory } from "@shared/factories/custom-landing/custom-landing.factory";
import { BroadcastService } from "@shared/services/broadcast/broadcast.service";
import { AfTranslateFactory } from "@shared/modules/translate/factory/translate.factory";
import { ContextFactory } from "@shared/factories/context/context.factory";
import { LoaderFactory } from "@shared/factories/loader/loader.factory";
import { ApiServiceService } from "@shared/services/api-service/api-service.service";

@Component({
  selector: 'rich-editor',
  templateUrl: './rich-editor.component.html'
})
export class RichEditorComponent implements OnInit, OnChanges, OnDestroy {
  subscribers: any = {};
  isLanding: boolean = this.customLanding.isLanding();
  skin: any = this.isLanding?'landing-custom':this.globalCfg.skin;
  elementId:string = this.commonUtilsService.createId();
  classType: any = '';
  events: any = null;
  placeholder: any = '';
  blurFunction: any = null;
  customButtons: any = null;
  noEditable: boolean = false;
  toolbar: any = 'undo redo | blocks removeformat | bold italic underline | alignment bullist numlist | link';
  height: any = 220;
  editor:any;
  configuredEditor: boolean = false;
  initEditor: boolean = false;
  snippetsTagList: any = [];
  richEditor: Object;

  constructor(private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private customLanding: CustomLandingFactory,
    private loader: LoaderFactory,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private broadcastService: BroadcastService,
    private apiService: ApiServiceService) { }

  @Input() tinyMceOptions: any = null;
  @Input() modelText: any = '';
  @Output() emitValue = new EventEmitter();

  ngOnInit(){
    this.watchChangeEditableFieldRich();
  }

  ngOnChanges(): void {
    if(this.modelText ==='' || this.modelText === undefined || this.modelText === null){
      this.placeholder = this.tinyMceOptions.placeholder?this.tinyMceOptions.placeholder:'';
      if(this.initEditor){
        this.editor.setContent('');
      }
    }else if(this.initEditor){
      tinymce.get(this.elementId).setContent(this.modelText);
    }
    this.configureTinyOptions();
  }

  configureTinyOptions(){
    if(this.tinyMceOptions && this.tinyMceOptions !== null){
      this.events = this.tinyMceOptions.events;
      this.placeholder = this.tinyMceOptions.placeholder?this.tinyMceOptions.placeholder:'';
      this.blurFunction = this.tinyMceOptions.blurFunction;
      this.customButtons = this.tinyMceOptions.customButtons;
      this.noEditable = this.tinyMceOptions.noEditable;
      this.height = this.tinyMceOptions.height?this.tinyMceOptions.height:220;
      this.classType = this.tinyMceOptions.typeView?'rich-editor--'+this.tinyMceOptions.typeView:'';
      this.toolbar = this.tinyMceOptions.toolbar?this.tinyMceOptions.toolbar:this.toolbar;
      if(this.customButtons?.length){
        this.customButtons.forEach((button: any)=> {
          this.toolbar += ' ' + button.type;
        })
      }
      if(!this.configuredEditor){
        this.configuredEditor = true;
        this.initTinyEditor();
      }
    }
  }

  initTinyEditor(){
    this.richEditor = {
      placeholder: this.placeholder,
      readonly: this.noEditable,
      base_url: '/tinymce',
      suffix: '.min',
      content_css: [location.origin + '/assets/css/rich-editor.css'],
      body_class: this.noEditable ? 'rich-editor__disabled-tinymce-'+ this.skin : 'rich-editor__body-tinymce rich-editor__body-tinymce--'+ this.skin,
      selector: 'textarea#'+this.elementId,
      menubar: false,
      statusbar: false,
      formats: {
          underline: { inline: 'u', styles: { 'text-decoration': 'underline' }, exact: true }
      },
      tabfocus: ':prev,:next',
      plugins: 'lists advlist',
      paste_as_text: true,
      height: this.height,
      toolbar: this.toolbar,
      setup: (editor:any) => {
        this.editor = editor;
        if(this.modelText){
          editor.on('init', ()=>{
            editor.setContent(this.modelText);
          })
        }
        editor.on('blur', ()=>{
          this.emitValue.emit({model: editor.getContent()})
          if (this.blurFunction) {
            this.blurFunction();
          }
        })

        for (let k in this.events) {
            if (this.events.hasOwnProperty(k)) {
                typeof this.events[k] === 'function'? editor.on(k, this.events[k]) :  console.error('Invalid tinyEditor events initialization. Value for ' + k + ' must be a function');
            }
        }

        //Buttons
        this.editor.ui.registry.addGroupToolbarButton('alignment', {
            icon: 'align-left',
            tooltip: 'Alignment',
            items: 'alignleft aligncenter alignright alignjustify'
        });
        this.editor.ui.registry.addButton('link', {
            icon: 'link',
            onAction: (e:any)=> {
                let addLink: any = $('#addLink-'+this.elementId);
                addLink.click();
            }
        });
        this.configureCustomButtons(editor);

      }
    }
    this.initEditor = true;
  }

  configureCustomButtons(editor: any){
    if(this.customButtons?.length){
      this.customButtons.forEach((button: any)=> {
        switch(button.type){
          case 'bookmarksTag':
            this.addTinyMceBookmarksCombo(button.list, editor);
            break;
          case 'snippetsTag':
            this.addTinyMceSnippetsCombo(button.list, editor);
            break;
          case 'addNewFragment':
            this.addNewFragmentIcon(editor);
            break;
          default:
            break;
        }
      })
    }
  }

  addLink(e: any){
      e.stopPropagation();
      let selectedNode: any;
      let oldNodeLink = tinymce.activeEditor.selection.getNode().tagName === 'A'?true:false;
      if(oldNodeLink){
        selectedNode = tinymce.activeEditor.selection.getNode();
        tinymce.activeEditor.selection.select(selectedNode);
      }
      let bookmark: any = tinymce.activeEditor.selection.getBookmark(2);
      let selectedContent: any = tinymce.activeEditor.selection.getContent();
      this.modalService.linkModal(selectedNode, selectedContent)
        .subscribe((result:any)=>{
            tinymce.activeEditor.selection.moveToBookmark(bookmark);
            if(result.result === 'ok'){
                tinymce.activeEditor.insertContent(result.link);
            }
        })
  }

  addTinyMceBookmarksCombo(bookmarks:any[], editor:any) {
    bookmarks.forEach((bookmark: any)=> {
        bookmark.type = 'menuitem';
        bookmark.text = bookmark.fieldLabel;
        bookmark.value = '<strong>' + bookmark.tag + '</strong>';
        bookmark.onAction = function () {
          editor.insertContent(bookmark.value);
        };
    })
    editor.ui.registry.addIcon('bookmarksIcon', '<i class="lf-icon-bookmarks"></i>' );
    this.editor.ui.registry.addMenuButton('bookmarksTag', {
        icon: 'bookmarksIcon',
        tooltip: this.translate.instant('common.insert-bookmarks'),
        fetch: function (callback: any) {
          callback(bookmarks);
        }
    });
  }

  addTinyMceSnippetsCombo(snippets:any[], editor:any) {
    let that = this;
    snippets.forEach((snippet: any)=> {
        snippet.type = 'menuitem';
        snippet.textValue = snippet.text;
        snippet.value = snippet.textValue;
        snippet.text = snippet.title;
        snippet.onAction = function () {
          editor.insertContent(snippet.value);
        };
    })
    that.snippetsTagList = snippets;
    editor.ui.registry.addIcon('snippetsIcon', '<i class="lf-icon-model"></i>' );
    this.editor.ui.registry.addMenuButton('snippetsTag', {
        icon: 'snippetsIcon',
        tooltip: this.translate.instant('common.insert-snippets'),
        fetch: function (callback: any) {
          callback(that.snippetsTagList);
        }
    });
  }

  addNewFragmentIcon(editor:any){
      editor.ui.registry.addIcon('newFragmentIcon', '<i class="lf-icon-add-round"></i>' );
      this.editor.ui.registry.addButton('addNewFragment', {
          icon: 'newFragmentIcon',
          tooltip: this.translate.instant('field-items.new-fragment'),
          onAction: (e:any) => {
              let addNewFragment: any = $('#addNewFragment-'+this.elementId);
              addNewFragment.click();
          },
          onSetup: (button:any) => {
              button.setEnabled(false)
              editor.on('SelectionChange', function () {
                  button.setEnabled(editor.selection.getContent().trim() !== '');
              });
          }
      });
  }

  openSnippetModal(e:any){
    e.stopPropagation();
    let configuration = {
          product: this.context.settings.defaultProduct,
          snippet: {
              shortId: '',
              title: '',
              text: tinymce.activeEditor.selection.getContent()
          },
          type: 'new-fragment'
    }
    this.modalService.newSnippetModal(this.snippetsTagList, configuration)
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.addSnippet(result.snippet);
        }
      })
  }

  addSnippet(snippet:any) {
      this.loader.openLoader('open-create-snippet');
      snippet.productId = this.context.settings.defaultProduct;
      this.apiService.add('snippets', snippet)
        .then((data:any)=>{
          this.modalService.adviceModal(this.translate.instant('field-items.save-snippet-ok'), 'info:accept:check-round');
          let editor = tinymce.activeEditor;
          data.type = 'menuitem';
          data.value = data.text;
          data.textValue = data.text;
          data.text = data.title;
          data.onAction = function () {
            editor.insertContent(data.value);
          }
          this.snippetsTagList.push(data);
          this.loader.closeLoader(['open-create-snippet']);
      }, (errorData:any)=>{
        this.loader.closeError();
        this.modalService.adviceModal(this.translate.instant('field-items.error-create-snippet'), 'error:accept:warning');
      })
  }

  watchChangeEditableFieldRich(){
    this.subscribers.changeEditableFieldRichBroadcast = this.broadcastService.subscribe("changeEditableFieldRich", (data:any) => {
      if(this.tinyMceOptions.id === data.richEditor.richId){
        this.tinyMceOptions.noEditable = data.richEditor.noEditable;
        let editor: any = tinymce.get(this.elementId);
        let readonly = data.richEditor.noEditable?'readonly':'design';
        editor.mode.set(readonly);
        editor.mode.set(readonly);
        if(data.richEditor.noEditable){
            editor.dom.addClass(editor.getBody(), 'rich-editor__disabled-tinymce-'+ this.skin);
            editor.dom.removeClass(editor.getBody(), 'rich-editor__body-tinymce rich-editor__body-tinymce--'+ this.skin);
        }else{
            editor.dom.addClass(editor.getBody(), 'rich-editor__body-tinymce rich-editor__body-tinymce--'+ this.skin);
            editor.dom.removeClass(editor.getBody(), 'rich-editor__disabled-tinymce-'+ this.skin);
        }
      }
    })
  }

  ngOnDestroy(){
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
