<div class="task-list">
  <div class="common__header-new-version">
      <i class="lf-icon-label-task common__header-icon-new-version"></i>
      <span class="common__header-title-new-version"
          [afTranslate]="'common.tasks'">Tareas</span>
      <div class="common__options-block"
          ngbDropdown
          [open]="taskList.showList">
          <button class="common__selected-option"
              ngbDropdownToggle>
              <span class="evaluations-list__select-language">{{taskList.selectedView.name}}</span>
              <i class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
          </button>
          <div class="common__menu-block-dropdown dropdown-menu"
              ngbDropdownMenu
              role="menu">
              <div class="common__block-option-list">
                  <div class="common__language-item"
                      ngbDropdownItem
                      *ngFor="let view of taskList.taskView"
                      (click)="changeView(view.viewOption)">
                      <span>{{view.name}}</span>
                      <i class="lf-icon-check-round-full common__language-icon"></i>
                  </div>
              </div>
          </div>
      </div>
      <button class="lf-icon-add-round common__header-button-add-new-version"
          [ngShow]="userPermissions !== 'none' && skin !== 'steel-blue' && product.productId !== 'blanqueo'"
          (click)="newTask()"
          [disabled]="userPermissions === 'none'"
          matTooltip="{{'task-edit.new-task' | afTranslate}}"
          matTooltipPosition="left"
          matTooltipClass="left"
          [matTooltipDisabled]="userPermissions === 'none'">
      </button>
  </div>
  <div class="common__table-block">
      <div class="common__table-new-version">
          <div class="common__table-header">
              <div class="task-list__col-new-version task-list__col-new-version--type task-list__col-new-version--header-top">
                  <filter-by-list [type]="'list'"
                      [item]="taskList.typeFilter"
                      [list]="taskList.typeList"
                      (filterElementEmit)="filterElementOn( $event, 'type')">
                  </filter-by-list>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--title"
                  [ngClass]="{'task-list__col-new-version--title-7-column': skin === 'icam-red'}">
                  <p class="common__table-header-text"
                      [afTranslate]="'task-list.title'">Título de la tarea</p>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--evaluation task-list__col-new-version--header-top"
                  *ngIf="skin !== 'icam-red'">
                  <filter-by-list [type]="'listFind'"
                      [item]="taskList.evaluationFilter"
                      [list]="taskList.evaluationList"
                      (filterElementEmit)="filterElementOn( $event, 'evaluation')">
                  </filter-by-list>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--organization"
                  [ngShow]="skin !== 'icam-red'">
                  <p class="common__table-header-text"
                      [afTranslate]="taskList.titleOrganization">_Organización</p>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--recipient"
                  [ngClass]="{'task-list__col-new-version--recipient-7-column': skin === 'icam-red'}">
                  <p class="common__table-header-text"
                      [afTranslate]="'task-list.addressee'">Destinatarios</p>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--creation task-list__col-new-version--header-top"
                  [ngClass]="{'task-list__col-new-version--creation-7-column': skin === 'icam-red'}">
                  <div class="common__cell-block">
                      <p class="common__table-header-text common__table-header-text--ellipsis"
                          (click)="searchBy('creationDate')">
                          <span-tooltip [text]="'common.creation' | afTranslate"></span-tooltip>
                      </p>
                      <i class="common__order-by"
                          [ngClass]="{'lf-icon-arrow-down':(taskList.dateFilter==='creationDate' && taskList.orderOperationDates) || taskList.dateFilter!=='creationDate',
                              'lf-icon-arrow-up': taskList.dateFilter==='creationDate' && !taskList.orderOperationDates,
                              'common__order-by--active': taskList.dateFilter==='creationDate'}"
                          (click)="searchBy('creationDate')"></i>
                  </div>

                  <!-- <p class="common__table-header-text common__cursor-pointer"
                      data-translate="common.creation"
                      ng-click="taskList.searchBy('creationDate')">Creación</p>
                  <i class="common__order-by"
                      ng-class="{'lf-icon-arrow-down':(taskList.dateFilter==='creationDate' && taskList.orderOperationDates) || taskList.dateFilter!=='creationDate',
                          'lf-icon-arrow-up': taskList.dateFilter==='creationDate' && !taskList.orderOperationDates,
                          'common__order-by--active': taskList.dateFilter==='creationDate'}"
                      ng-click="taskList.searchBy('creationDate')"></i> -->
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--expiration task-list__col-new-version--header-top"
                  [ngClass]="{'task-list__col-new-version--expiration-7-column': skin === 'icam-red'}">
                  <div class="common__cell-block">
                      <p class="common__table-header-text common__table-header-text--ellipsis"
                          (click)="searchBy('expirationDate')">
                          <span-tooltip [text]="'task-list.expiration' | afTranslate"></span-tooltip>
                      </p>
                      <i class="common__order-by"
                          [ngClass]="{'lf-icon-arrow-down':(taskList.dateFilter==='expirationDate' && taskList.orderOperationDates) || taskList.dateFilter!=='expirationDate',
                              'lf-icon-arrow-up': taskList.dateFilter==='expirationDate' && !taskList.orderOperationDates,
                              'common__order-by--active': taskList.dateFilter==='expirationDate'}"
                          (click)="searchBy('expirationDate')"></i>
                  </div>
                  <!-- <p class="common__table-header-text common__cursor-pointer"
                      data-translate="task-list.expiration"
                      ng-click="taskList.searchBy('expirationDate')">Vencimiento</p>
                  <i class="common__order-by"
                      ng-class="{'lf-icon-arrow-down':(taskList.dateFilter==='expirationDate' && taskList.orderOperationDates) || taskList.dateFilter!=='expirationDate',
                          'lf-icon-arrow-up': taskList.dateFilter==='expirationDate' && !taskList.orderOperationDates,
                          'common__order-by--active': taskList.dateFilter==='expirationDate'}"
                      ng-click="taskList.searchBy('expirationDate')"></i> -->
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--state task-list__col-new-version--header-top">
                  <filter-by-list [type]="'listFind'"
                      [item]="taskList.stateFilter"
                      [widthClass]="'200px'"
                      [list]="taskList.stateList"
                      (filterElementEmit)="filterElementOn( $event, 'state')">
                  </filter-by-list>
              </div>
              <div class="task-list__col-new-version task-list__col-new-version--actions">
                  <p class="common__table-header-text common__table-header-text--right"
                      [afTranslate]="'common.actions'">Acciones</p>
              </div>
          </div>

          <div class="common__table-body"
              [ngClass]="{'common__table-body--more-elements': taskList.amountElements > 5}">
              <div *ngFor="let task of taskList.filterTaskList | byWord:taskList.filterTitle:['title', 'state', 'client', 'evaluation', 'product'] | afOrderBy:taskList.dateFilter:taskList.orderOperationDates | afLimitTo:taskList.configPagination.pageSize:taskList.configPagination.pageSize*(taskList.currentPage - 1); let i = index"
                  class="common__table-row">
                  <div class="task-list__col-new-version task-list__col-new-version--type task-list__col-new-version--tooltip">
                      <i [class]="(task.videoconference ? 'lf-icon-videoconference': (task.taskTypeId | taskTypeIcons:'task'))+' task-list__icon-type'"
                        matTooltip="{{task.type}}"
                        matTooltipPosition="right"
                        matTooltipClass="right">
                      </i>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--title task-list__col-new-version--tooltip"
                      [ngClass]="{'task-list__col-new-version--title-7-column': skin === 'icam-red'}">
                      <parragraph-tooltip [text]="task.title"
                        (click)="editTask(task)"></parragraph-tooltip>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--evaluation task-list__col-new-version--tooltip"
                      [ngShow]="skin !== 'icam-red'">
                      <parragraph-tooltip [text]="task.evaluation"></parragraph-tooltip>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--organization task-list__col-new-version--tooltip"
                      [ngShow]="skin !== 'icam-red'">
                      <parragraph-tooltip [text]="task.client"></parragraph-tooltip>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--recipient task-list__col-new-version--tooltip"
                      [ngClass]="{'task-list__col-new-version--recipient-7-column': skin === 'icam-red'}">
                      <participants-list *ngIf="task.participants.length"
                          [participants]="task.participants"
                          [type]="'task'">
                      </participants-list>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--creation task-list__col-new-version--tooltip"
                      [ngClass]="{'task-list__col-new-version--creation-7-column': skin === 'icam-red'}">
                      <div class="common__cell-block">
                          <p class="common__col-text">
                              <span-tooltip [text]="task.creationDate | convertDate:'without-time'"></span-tooltip>
                          </p>
                      </div>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--expiration task-list__col-new-version--tooltip"
                      [ngClass]="{'task-list__col-new-version--expiration-7-column': skin === 'icam-red'}">
                      <div class="common__cell-block">
                          <p [id]="'task-dashboard-list-expirationdate-'+i+'-'+task.taskId"
                              class="common__col-text"
                              ngbTooltip=" {{task.expirationDate | convertDate:'without-time'}}"
                              placement="bottom"
                              container="body"
                              tooltipClass="compliance__tooltip-{{skin}}"
                              triggers="manual"
                              #tooltip="ngbTooltip"
                              (mouseenter)="showTooltip('task-dashboard-list-expirationdate-'+i+'-'+task.taskId) && tooltip.open()"
                              (mouseleave)="tooltip.close()">
                              <span [id]="'span-task-dashboard-list-expirationdate-'+i+'-'+task.taskId"
                                  class="task-list__expiration-date">
                                  <i class="task-list__warning-dot-icon lf-icon-step-final"
                                      *ngIf="(task.expirationDate | expired) && !(task.taskStateId === 5 || task.taskStateId === 4 || task.taskStateId === 6)"></i>
                                  {{task.expirationDate | convertDate:'without-time'}}</span>
                          </p>
                      </div>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--state-body task-list__col-new-version--tooltip">
                      <span-tooltip [text]="task.state"
                          [ngShow]="task.taskTypeId !== 7"
                          [class]="'task-concept__task-state task-concept__task-state--' + (task.taskStateId | colors)"></span-tooltip>

                      <div class="task-concept__process-block"
                          *ngIf="task.taskTypeId === 7">
                          <p class="task-concept__task-state task-concept__task-state--process">
                              <span-tooltip [text]="taskList.view!=='process' ? task.state : task.processState.name"></span-tooltip>
                          </p>
                      </div>
                  </div>
                  <div class="task-list__col-new-version task-list__col-new-version--actions-body">
                      <ul class="common__options-row">
                          <li class="common__menu-options-row"
                              [ngClass]="{'common__menu-options-row--view': !taskList.canCreate || task.taskStateId ===5 || task.taskStateId ===6}"
                              (click)="editTask(task)"
                              matTooltip="{{(userPermissions === 'none' || task.taskStateId ===5 || task.taskStateId ===6?'common.view':'common.edit') | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i [ngClass]="userPermissions === 'none' || task.taskStateId ===5 || task.taskStateId ===6?'lf-icon-visible':'lf-icon-editable-form'"></i>
                          </li>
                          <li class="common__menu-options-row "
                              [ngShow]="userPermissions !== 'none' && ((task.taskStateId !== 5 && task.taskStateId !== 6) || task.taskTypeId === 7 )"
                              matTooltip="{{'common.remove' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-trash"
                                  (click)="deleteOption(task)"></i>
                          </li>
                      </ul>
                      <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="common__block-footer-table">
      <div class="common__block-search">
          <input type="text"
              [ngShow]="taskList.search"
              class="common__search-input"
              [(ngModel)]="taskList.filterTitle"
              [placeholder]="'common.search' | afTranslate">
          <span class="common__text-search"
              [ngShow]="!taskList.search"
              (click)="toggleSearch()"
              [afTranslate]="'common.search'">_Buscar</span>
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !taskList.search,
                  'lf-icon-close-search': taskList.search,
                  'common__icon-button-search--open': taskList.search}"
              (click)="toggleSearch()">
          </button>
      </div>
      <pagination-control class="common__pagination-block"
        [ngShow]="taskList.amountElements > taskList.configPagination.pageSize"
        [config]="taskList.configPagination"
        [collectionSize]="taskList.amountElements"
        [(currentPage)]="taskList.currentPage"
        (configuredTotalChange)="taskList.configuredTotal = $event.configuredTotal"></pagination-control>
      <div class="common__block-footer-total">
          <span [ngShow]="taskList.amountElements > taskList.configPagination.pageSize">
            <span class="notranslate">{{taskList.configuredTotal.first}}-{{taskList.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{taskList.amountElements}}</span>
          </span>
          <span [ngShow]="!(taskList.amountElements > taskList.configPagination.pageSize)">
            <span [afTranslate]="'common.total'">_Total</span>
            <span class="common__total-number-element notranslate">{{taskList.amountElements}}</span>
          </span>
      </div>
  </div>
</div>
