<div class="customize-concept common__block-concept common__block-concept--border">
  <div class="customize-concept__block-alert-instanciable-concept"
      [ngShow]="customizeConcept.showHeaderInfo">
      <div class="customize-concept__block-text-alert">
          <i class="lf-icon-warning customize-concept__icon-alert"></i>
          <span class="customize-concept__text-alert">{{customizeConcept.textHeaderInfo}}</span>

      </div>
      <div class="customize-concept__block-button-alert"
          [ngShow]="!concept.poll">
          <div class="customize-concept__button-box-alert">
              <button class="customize-concept__alert-button"
                  (click)="resetConcept()">
                  <i class="common__icon-button-underlined lf-icon-redesign"></i>
                  <span class="common__text-button-underlined"
                      [afTranslate]="'customize-concept.redesign-concept'">_Desbloquear y rediseñar concepto</span>
              </button>

          </div>
      </div>
      <i class="lf-icon-close customize-concept__icon-close-header"
          [ngShow]="concept.poll"
          (click)="customizeConcept.showHeaderInfo = !customizeConcept.showHeaderInfo"></i>
  </div>
  <div class="customize-concept__block-body-form"
      [ngClass]="{'customize-concept__block-body-form--header': customizeConcept.showHeaderInfo}">
      <div class="concept-form__fields-block concept-form__fields-block--edit-concept">
          <div class="concept-form__container-fields concept-form__container-fields--edit-concept">
              <i class="customize-concept__info-block-icon"
                  [ngClass]="customizeConcept.showInfo?'lf-icon-information':'lf-icon-close-round'"
                  (click)="customizeConcept.showInfo = !customizeConcept.showInfo"></i>
              <div class="customize-concept__triangle-box"
                  [ngShow]="!customizeConcept.showInfo"></div>
              <div class="customize-concept__info-block"
                  [ngClass]="{'customize-concept__info-block--open':customizeConcept.showMoreInfo}"
                  [ngShow]="!customizeConcept.showInfo">
                  <p class="customize-concept__info-block-title"
                      [afTranslate]="'customize.select-template-form'">_¿Cómo crear una plantilla de formularios?</p>
                  <ul class="customize-concept__info-steps-block">
                      <li class="customize-concept__info-step">
                          <i class="lf-icon-number-one customize-concept__icon-step"></i>
                          <span class="span__auto-ajust"
                            [afTranslate]="'customize.group'">_Agrupar</span>
                      </li>
                      <li class="customize-concept__info-step">
                          <i class="lf-icon-number-two customize-concept__icon-step"></i>
                          <span class="span__auto-ajust"
                            [afTranslate]="'customize.place-fields'">Colocar campos</span>
                      </li>
                      <li class="customize-concept__info-step">
                          <i class="lf-icon-number-three customize-concept__icon-step"></i>
                          <span class="span__auto-ajust"
                            [afTranslate]="'customize.parameterize'">Parametrizar</span>
                      </li>
                      <li class="customize-concept__info-step">
                          <i class="lf-icon-number-four customize-concept__icon-step"></i>
                          <span class="span__auto-ajust"
                            [afTranslate]="'customize.order'">Ordenar</span>
                      </li>
                      <li class="customize-concept__info-step">
                          <i class="lf-icon-number-five customize-concept__icon-step"></i>
                          <span class="span__auto-ajust"
                            [afTranslate]="'common.save'">Guardar</span>
                      </li>
                  </ul>
                  <i class="customize-concept__icon-open-info"
                      [ngClass]="customizeConcept.showMoreInfo?'lf-icon-angle-up':'lf-icon-angle-down'"
                      (click)="customizeConcept.showMoreInfo = !customizeConcept.showMoreInfo"></i>
                  <ul class="customize-concept__info-list">
                      <li>
                          <span class="customize-concept__list-info-title"
                              [afTranslate]="'customize.group'">Agrupar</span>
                          <p class="customize-concept__list-info-text"
                              [afTranslate]="'customize.info-group'">Todos los campos deben estar agrupados. Añade un grupo inicial para poder empezar a diseñar el formulario. Después podrás seguir añadiendo grupos y subgrupos.</p>
                      </li>
                      <li>
                          <span class="customize-concept__list-info-title"
                              [afTranslate]="'customize.place-fields'">Colocar campos</span>
                          <p class="customize-concept__list-info-text"
                              [afTranslate]="'customize.info-place-fields'">Elige los campos más adecuados para solicitar la información. Arrástralos desde la caja de la derecha hacia la zona de puntos, uno a uno.</p>
                      </li>
                      <li>
                          <span class="customize-concept__list-info-title"
                              [afTranslate]="'customize.parameterize'">Parametrizar</span>
                          <p class="customize-concept__list-info-text"
                              [afTranslate]="'customize.info-parameterize'">Identifica y configura las opciones de los campos. Añade información descriptiva y de ayuda al usuario.</p>
                      </li>
                      <li>
                          <span class="customize-concept__list-info-title"
                              [afTranslate]="'customize.order'">Ordenar</span>
                          <p class="customize-concept__list-info-text"
                              [afTranslate]="'customize.info-order'">Podrás alterar el orden de los campos arrastrándolos desde el borde derecho de la caja.</p>
                      </li>
                      <li>
                          <span class="customize-concept__list-info-title"
                              [afTranslate]="'common.save'">Guardar</span>
                          <p class="customize-concept__list-info-text"
                              [afTranslate]="'customize.info-save'">Asegúrate de que toda la información se ha guardado correctamente antes de pasar a la pestaña de Proceso. Para finalizar, pulsa la opción de “Guardar plantilla”.</p>
                      </li>
                  </ul>
              </div>
              <div class="common__create common__create--edit-concept">
                  <button type="submit"
                      class="common__button common__button--active"
                      (click)="addGroup(concept.fieldContainers)"
                      [afTranslate]="'customize.add-group'"
                      [disabled]="concept.disabled">Añadir grupo</button>
              </div>
          </div>
          <div class="customize-concept__desactive-box"
              [ngHide]="concept.fieldContainers.length">
              <i class="lf-icon-drag-drop customize-concept__desactive-icon"></i>
              <p class="customize-concept__desactive-title"
                  [afTranslate]="'customize.deactivated-zone'">Zona desactivada</p>
              <p class="customize-concept__desactive-text"
                  [afTranslate]="'customize.deactivated-zone-text-primary'">Crea un grupo para poder empezar a colocar campos de formulario dentro</p>
              <p class="customize-concept__desactive-text"
                  [afTranslate]="'customize.deactivated-zone-text-secondary'">Los grupos son necesarios para estructurar y contextualizar la información</p>
          </div>
          <ul class="customize-concept__group-block"
              [ngShow]="concept.fieldContainers.length"
              [dragula]="'container'"
              [dragulaModel]="concept.fieldContainers"
              (dragulaModelChange)="reorderContainer($event, concept.fieldContainers)">
              <li class="customize-concept__group"
                  *ngFor="let fieldContainer of concept.fieldContainers; let index = index"
                  [id]="fieldContainer.fieldContainerId">
                  <div class="customize-concept__group-header"
                      [ngClass]="{'customize-concept__group-header--big': fieldContainer.open,
                        'customize-concept__group-header--great-big': fieldContainer.open && fieldContainer.visibility}">
                      <div class="customize-concept__group-header-name"
                          [ngClass]="{'customize-concept__group-header-name--open': fieldContainer.open}">
                          <i class="customize-concept__show-inner-icon"
                              [ngClass]="fieldContainer.open ?'lf-icon-angle-up':'lf-icon-angle-down'"
                              (click)="showGroup(fieldContainer, concept.fieldContainers)"></i>
                          <i class="lf-icon-step-done customize-concept__private-icon"
                              [ngShow]="fieldContainer.private && concept.allowPublicShare"></i>
                          <i class="lf-icon-important customize-concept__group-highlight"
                              [ngShow]="fieldContainer.isHighlight"></i>
                          <input type="text"
                              class="customize-concept__group-name customize-concept__group-name--input"
                              [id]="'customize-concept-group-'+fieldContainer.fieldContainerId"
                              [ngClass]="{'common__ellipsis':!fieldContainer.open,
                                  'customize-concept__group-name--input-highlight':fieldContainer.isHighlight,
                                  'customize-concept__group-name--input-private':fieldContainer.private,
                                  'customize-concept__group-name--input-both':fieldContainer.private && fieldContainer.isHighlight}"
                              [(ngModel)]="fieldContainer.name"
                              [disabled]="!fieldContainer.open || concept.disabled"
                              (blur)="fieldContainerNameChange(fieldContainer)"
                              maxlength="300">
                      </div>
                      <div class="customize-concept__group-header-options"
                          [ngClass]="{'customize-concept__group-header-options--open': fieldContainer.open}">
                          <i class="lf-icon-kebab-menu customize-concept__group-icon customize-concept__group-icon--more"
                              [ngShow]="!fieldContainer.open"></i>
                          <i class="lf-icon-arrows customize-concept__group-icon customize-concept__group-icon--arrow handleContainer"
                              id="handleContainer"
                              [ngClass]="{'customize-concept__group-icon--no-delete':fieldContainer.instantiated}"
                              [ngShow]="!fieldContainer.open && !concept.disabled"></i>
                          <div [ngShow]="fieldContainer.open"
                              class="customize-concept__block-highlight">
                              <field-type-switch
                                  [label]="'customize.highlight'|afTranslate"
                                  [checked]="fieldContainer.isHighlight"
                                  [disabled]="concept.disabled"
                                  [negativeColor]="true"
                                  (callback)="toggleSwitchHighlight(fieldContainer, $event.value)">
                              </field-type-switch>
                          </div>
                          <div [ngShow]="fieldContainer.open && concept.allowPublicShare"
                              class="customize-concept__block-highlight">
                              <field-type-switch
                                  [label]="'customize.private'|afTranslate"
                                  [checked]="fieldContainer.private"
                                  [disabled]="concept.disabled"
                                  [negativeColor]="true"
                                  (callback)="changePrivate(fieldContainer, $event.value)">
                              </field-type-switch>
                          </div>
                          <div [ngShow]="fieldContainer.open && !concept.allowPublicShare"
                              class="customize-concept__block-highlight">
                              <field-type-switch
                                  [label]="'customize.hidden-landing'|afTranslate"
                                  [checked]="fieldContainer.hiddenInLandings"
                                  [negativeColor]="true"
                                  (callback)="modifyGroup(fieldContainer, $event.value)">
                              </field-type-switch>
                          </div>
                          <div [ngShow]="fieldContainer.open && concept.poll"
                              class="customize-concept__block-highlight">
                              <field-type-switch
                                  [label]="'customize.visibility-conditions'|afTranslate"
                                  [checked]="fieldContainer.visibility"
                                  [disabled]="concept.disabled"
                                  [negativeColor]="true"
                                  (callback)="changeVisibilities(fieldContainer, $event.value)">
                              </field-type-switch>
                          </div>
                          <button [ngShow]="fieldContainer.open"
                              [disabled]="concept.disabled"
                              (click)="addGroup(fieldContainer, fieldContainer)"
                              class="customize-concept__block-add-subgroup">
                              <i class="lf-icon-add-round customize-concept__add-subgroup-icon"
                                  [ngClass]="{'customize-concept__add-subgroup-icon--disabled': concept.disabled}"></i>
                              <p class="customize-concept__add-subgroup"
                                  [ngClass]="{'customize-concept__add-subgroup--disabled': concept.disabled}"
                                  [afTranslate]="'customize.subgroup'">Subgrupo</p>
                          </button>
                      </div>
                      <visibility *ngIf="fieldContainer.visibility && concept.poll && fieldContainer.open"
                          [items]="customizeConcept.items"
                          [type]="'container'"
                          [concept]="concept"
                          [selectedProperties]="fieldContainer.propertyVisibleValues"
                          [selectedListValues]="fieldContainer.listVisibleValues"
                          [selectedFields]="fieldContainer.fieldVisibleValues"
                          [orCondition]="fieldContainer.visibilityOr"
                          [readonly]="fieldContainer.published"
                          [itemId]="fieldContainer.fieldContainerId"
                          (visibilityOpen)="openVisibilityOn($event, fieldContainer)">
                      </visibility>
                      <div class="customize-concept__fieldcontainer-menu"
                          [ngShow]="!fieldContainer.open">
                          <i class="customize-concept__menu-icon customize-concept__menu-icon--writing"
                              [ngClass]="concept.disabled?'lf-icon-visible':'lf-icon-editable-form'"
                              (click)="showGroup(fieldContainer, concept.fieldContainers)"></i>
                          <i class="lf-icon-trash customize-concept__menu-icon customize-concept__menu-icon--delete"
                              [ngShow]="!fieldContainer.instantiated && !concept.disabled"
                              (click)="deleteGroup(fieldContainer, concept.fieldContainers, 'grupo')"
                              [ngClass]="{'customize-concept__icon-delete-opened':fieldContainer.open}"></i>
                      </div>
                  </div>
                  <div class="customize-concept__group-body"
                      [ngShow]="fieldContainer.open">
                      <ul class="customize-concept__group-block customize-concept__group-block--subcontainer "
                          [ngShow]="fieldContainer.subContainers.length"
                          [dragula]="'subContainer'"
                          [dragulaModel]="fieldContainer.subContainers"
                          (dragulaModelChange)="reorderContainer($event, fieldContainer.subContainers)">
                          <li class="customize-concept__group"
                              *ngFor="let subContainers of fieldContainer.subContainers"
                              [id]="subContainers.fieldContainerId">
                              <div class="customize-concept__group-header customize-concept__group-header--subcontainer"
                                  [ngClass]="{'customize-concept__group-header--big': subContainers.open && !subContainers.openBlock,
                                      'customize-concept__group-header--great-big': subContainers.open && subContainers.visibility}">
                                  <div class="customize-concept__group-header-name"
                                      [ngClass]="{'customize-concept__group-header-name--open': subContainers.open}">
                                      <i class="customize-concept__show-inner-icon"
                                          [ngClass]="subContainers.open?'lf-icon-angle-up':'lf-icon-angle-down'"
                                          (click)="showGroup(subContainers, fieldContainer.subContainers)"></i>
                                      <i class="lf-icon-step-done customize-concept__private-icon"
                                          [ngShow]="(fieldContainer.private || subContainers.private) && concept.allowPublicShare"></i>
                                      <i class="lf-icon-important customize-concept__group-highlight"
                                          [ngShow]="subContainers.isHighlight"></i>
                                      <input type="text"
                                          class="customize-concept__group-name customize-concept__group-name--input"
                                          [id]="'customize-concept-group-'+subContainers.fieldContainerId"
                                          [ngClass]="{'common__ellipsis':!subContainers.open,
                                          'customize-concept__group-name--input-highlight':subContainers.isHighlight,
                                          'customize-concept__group-name--input-private':fieldContainer.private || subContainers.private,
                                          'customize-concept__group-name--input-both':(fieldContainer.private || subContainers.private) && fieldContainer.isHighlight}"
                                          [(ngModel)]="subContainers.name"
                                          [disabled]="!subContainers.open || concept.disabled"
                                          (blur)="fieldContainerNameChange(subContainers)"
                                          maxlength="250">
                                  </div>
                                  <div class="customize-concept__group-header-options"
                                      [ngClass]="{'customize-concept__group-header-options--open': subContainers.open}">
                                      <i class="lf-icon-kebab-menu customize-concept__group-icon customize-concept__group-icon--more"
                                          [ngShow]="!subContainers.open"></i>
                                      <i class="lf-icon-arrows customize-concept__group-icon customize-concept__group-icon--arrow handleSubContainer"
                                          id="handleSubContainer"
                                          [ngClass]="{'customize-concept__group-icon--no-delete':subContainers.instantiated}"
                                          [ngShow]="!subContainers.open && !concept.disabled"></i>
                                      <div [ngShow]="subContainers.open"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.highlight'|afTranslate"
                                              [checked]="subContainers.isHighlight"
                                              [disabled]="concept.disabled"
                                              [negativeColor]="true"
                                              (callback)="changeOption(subContainers, 'isHighlight', $event.value)">
                                          </field-type-switch>
                                      </div>
                                      <div [ngShow]="subContainers.open && concept.allowPublicShare"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.private'|afTranslate"
                                              [checked]="fieldContainer.private ? fieldContainer.private : subContainers.private"
                                              [disabled]="concept.disabled || fieldContainer.private || (concept.communicationChannel && subContainers.multiple)"
                                              [negativeColor]="true"
                                              (callback)="changePrivate(subContainers, $event.value)">
                                          </field-type-switch>
                                      </div>
                                      <div [ngShow]="fieldContainer.open && !concept.allowPublicShare"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.hidden-landing'|afTranslate"
                                              [checked]="fieldContainer.hiddenInLandings ? fieldContainer.hiddenInLandings : subContainers.hiddenInLandings"
                                              [disabled]="fieldContainer.hiddenInLandings"
                                              [negativeColor]="true"
                                              (callback)="changeOption(subContainers, 'hiddenInLandings', $event.value)">
                                          </field-type-switch>
                                      </div>
                                      <div [ngShow]="subContainers.open"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.hide-container'|afTranslate"
                                              [checked]="subContainers.hideContainer"
                                              [disabled]="concept.disabled"
                                              [negativeColor]="true"
                                              (callback)="changeOption(subContainers, 'hideContainer', $event.value)">
                                          </field-type-switch>
                                      </div>
                                      <div [ngShow]="subContainers.open && !concept.poll"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.multiple'|afTranslate"
                                              [checked]="subContainers.multiple"
                                              [disabled]="subContainers.instantiated"
                                              [negativeColor]="true"
                                              (callback)="changeOption(subContainers, 'multiple', $event.value)">
                                          </field-type-switch>
                                      </div>
                                      <div [ngShow]="subContainers.open && concept.poll"
                                          class="customize-concept__block-highlight">
                                          <field-type-switch
                                              [label]="'customize.visibility-conditions'|afTranslate"
                                              [checked]="subContainers.visibility"
                                              [disabled]="subContainers.instantiated || concept.disabled"
                                              [negativeColor]="true"
                                              (callback)="changeVisibilities(subContainers, $event.value)">
                                          </field-type-switch>
                                      </div>
                                  </div>
                                  <visibility class="field-item__input"
                                      *ngIf="subContainers.visibility && concept.poll && subContainers.open"
                                      [items]="customizeConcept.items"
                                      [type]="'subcontainer'"
                                      [concept]="concept"
                                      [selectedProperties]="subContainers.propertyVisibleValues"
                                      [selectedListValues]="subContainers.listVisibleValues"
                                      [selectedFields]="subContainers.fieldVisibleValues"
                                      [orCondition]="subContainers.visibilityOr"
                                      [readonly]="subContainers.published"
                                      [itemId]="subContainers.fieldContainerId"
                                      (visibilityOpen)="openVisibilityOn($event, subContainers)">
                                  </visibility>
                                  <div class="customize-concept__fieldcontainer-menu"
                                      [ngShow]="!subContainers.open">
                                      <i class="customize-concept__menu-icon customize-concept__menu-icon--writing"
                                          [ngClass]="concept.disabled?'lf-icon-visible':'lf-icon-editable-form'"
                                          (click)="showGroup(subContainers, fieldContainer.subContainers)"></i>
                                      <i class="lf-icon-trash customize-concept__menu-icon customize-concept__menu-icon--delete"
                                          [ngShow]="!subContainers.instantiated && !concept.disabled"
                                          [ngClass]="{'customize-concept__icon-delete-opened':subContainers.open}"
                                          (click)="deleteGroup(subContainers, fieldContainer.subContainers, 'subgrupo')"></i>
                                  </div>
                              </div>
                              <div class="customize-concept__group-body"
                                  [ngShow]="subContainers.open">
                                  <ul class="customize-concept__field-list-block-customize"
                                      [dragula]="'fieldList'"
                                      [dragulaModel]="subContainers.fields"
                                      (dragulaModelChange)="reorderFields($event, subContainers)"
                                      id="fieldSubContainer">
                                      <li class="customize-concept__field-item"
                                          *ngFor="let subContainerFields of subContainers.fields"
                                          [id]="subContainerFields.fieldId">
                                          <customize-fields [field]="subContainerFields"
                                              [typeField]="'fieldSubContainer'"
                                              [pollObject]="pollObject"
                                              [fieldOriginList]="customizeConcept.fieldOriginList"
                                              [contentTypeList]="customizeConcept.contentTypeList"
                                              [visibilityItems]="customizeConcept.items"
                                              [fieldContainers]="concept.fieldContainers"
                                              [isPrivate]="subContainers.private || fieldContainer.private"
                                              [hiddenInLandings]="subContainers.hiddenInLandings || fieldContainer.hiddenInLandings"
                                              [isMultiple]="subContainers.multiple"
                                              [allowPublicShare]="concept.allowPublicShare"
                                              [disabled]="subContainers.instantiated"
                                              [reportList]="customizeConcept.reportList"
                                              [templatesList]="customizeConcept.templatesList"
                                              [concept]="concept"
                                              (openField)="showFieldOn($event, subContainers)"
                                              (launchAction)="launchActionOn($event, subContainers)">
                                          </customize-fields>
                                      </li>
                                  </ul>
                                  <div class="customize-concept__inner-info"
                                    [dragula]="'fieldList'"
                                    [dragulaModel]="subContainers.fields"
                                    (dragulaModelChange)="addFieldsInnerInfo($event, subContainers)"
                                    id="fieldSubContainerInnerInfo">
                                    <div class="customize-concept__desactive-box customize-concept__desactive-box--inner-group"
                                        [ngClass]="{'customize-concept__desactive-box--with-fields': subContainers.fields.length}"
                                        [ngShow]="!concept.disabled">
                                        <i class="lf-icon-drag-drop customize-concept__desactive-icon"></i>
                                        <div [ngHide]="subContainers.fields.length">
                                            <p class="customize-concept__desactive-text"
                                                [afTranslate]="'customize.drag-form-fields-primary'">Arrastra aquí los campos de formulario adecuados</p>
                                            <p class="customize-concept__desactive-text"
                                                [afTranslate]="'customize.drag-form-fields-secondary'">para solicitar los datos relacionados con este grupo informativo</p>
                                        </div>
                                        <p class="customize-concept__desactive-text"
                                            [ngShow]="subContainers.fields.length"
                                            [afTranslate]="'customize.add-new-field'">Añade un nuevo campo al grupo</p>
                                    </div>
                                    <div class="customize-concept__desactive-box customize-concept__desactive-box--with-fields"
                                        [ngShow]="concept.disabled">
                                        <i class="lf-icon-drag-drop customize-concept__desactive-icon"></i>
                                        <p class="customize-concept__desactive-title"
                                            [afTranslate]="'customize.deactivated-zone'">Zona desactivada</p>
                                    </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                      <div>
                        <ul class="customize-concept__field-list-block-customize"
                            [dragula]="'fieldList'"
                            id="fieldContainer"
                            [dragulaModel]="fieldContainer.fields"
                            (dragulaModelChange)="reorderFields($event, fieldContainer)">
                          <li class="customize-concept__field-item"
                              *ngFor="let containerField of fieldContainer.fields"
                              [id]="containerField.fieldId">
                              <customize-fields [field]="containerField"
                                  [typeField]="'fieldContainer'"
                                  [pollObject]="pollObject"
                                  [fieldOriginList]="customizeConcept.fieldOriginList"
                                  [contentTypeList]="customizeConcept.contentTypeList"
                                  [visibilityItems]="customizeConcept.items"
                                  [fieldContainers]="concept.fieldContainers"
                                  [isPrivate]="fieldContainer.private"
                                  [hiddenInLandings]="fieldContainer.hiddenInLandings"
                                  [allowPublicShare]="concept.allowPublicShare"
                                  [disabled]="fieldContainer.instantiated"
                                  [reportList]="customizeConcept.reportList"
                                  [templatesList]="customizeConcept.templatesList"
                                  [concept]="concept"
                                  (openField)="showFieldOn($event, fieldContainer)"
                                  (launchAction)="launchActionOn($event, fieldContainer)">
                              </customize-fields>
                          </li>
                        </ul>
                        <div class="customize-concept__inner-info"
                            [dragula]="'fieldList'"
                            [dragulaModel]="fieldContainer.fields"
                            (dragulaModelChange)="addFieldsInnerInfo($event, fieldContainer)"
                            id="fieldContainerInnerInfo">
                          <div class="customize-concept__desactive-box customize-concept__desactive-box--inner-group"
                              [ngClass]="{'customize-concept__desactive-box--with-fields': fieldContainer.fields.length}"
                              [ngShow]="!concept.disabled">
                              <i class="lf-icon-drag-drop customize-concept__desactive-icon"></i>
                              <div [ngHide]="fieldContainer.fields.length">
                                  <p class="customize-concept__desactive-text"
                                      [afTranslate]="'customize.drag-form-fields-primary'">Arrastra aquí los campos de formulario adecuados</p>
                                  <p class="customize-concept__desactive-text"
                                      [afTranslate]="'customize.drag-form-fields-secondary'">para solicitar los datos relacionados con este grupo informativo</p>
                              </div>
                              <p class="customize-concept__desactive-text"
                                  [ngShow]="fieldContainer.fields.length"
                                  [afTranslate]="'customize.add-new-field'">Añade un nuevo campo al grupo</p>
                          </div>
                          <div class="customize-concept__desactive-box customize-concept__desactive-box--with-fields"
                              [ngShow]="concept.disabled">
                              <i class="lf-icon-drag-drop customize-concept__desactive-icon"></i>
                              <p class="customize-concept__desactive-title"
                                  [afTranslate]="'customize.deactivated-zone'">Zona desactivada</p>
                          </div>
                        </div>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
      <div class="concept-form__concept-properties">
          <field-list
              [public]="concept.allowPublicShare"
              [concept]="concept"
              [poll]="concept.poll">
          </field-list>
      </div>
  </div>
</div>
