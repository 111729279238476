import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import $ from 'jquery';

@Component({
  selector: 'edit-concept',
  templateUrl: './edit-concept.component.html'
})
export class EditConceptComponent implements OnInit {
  skin: string = this.globalCfg.skin
  to: string = this.route.snapshot.params['to'];
  evaluationId = this.route.snapshot.params['evaluationId'];
  conceptInfo:any = this.route.snapshot.data['conceptInfo'];
  processInfo:any = this.route.snapshot.data['processInfo'];
  fieldContainerInfo:any = this.route.snapshot.data['fieldContainerInfo'];
  fullMenu:any = this.route.snapshot.data['fullMenu'];
  editConcept:any = {
    action: '',
    pollObject: {
        pollstate: 'init'
    }
  }
  constructor(private conceptState: ConceptStateFactory,
    private broadcastService: BroadcastService,
    private loader: LoaderFactory,
    private route: ActivatedRoute,
    private globalCfg: GlobalCfgFactory,
    private apiService: ApiServiceService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory,
    private router: Router
    ) { }


  ngOnInit(): void {
    this.editConcept.conceptInfo = this.conceptInfo;
    this.conceptState.reset();
    this.broadcastService.broadcast('refreshEvaluatedConceptInfo', {evaluatedConcept: this.conceptInfo});
    if(this.to !== 'template' && this.to !== 'template-front'){
        this.loader.openLoader('edit-concept-init-edit')
    }
    this.loader.closeLoader(['heat-map-graph', 'evaluation-menu-menu-action']);
    $('#compliance-view-body').scrollTop(0);
    if(this.to === 'form-process' ||  this.to === 'template' ||  this.to === 'process-back'){
      this.editConcept.conceptInfo.fieldContainers = this.fieldContainerInfo;
      this.editConcept.conceptInfo.conceptObjectId = 'view';
      this.getProcess();
      this.editConcept.fromForm = 'form-process';
    }else{
      this.editConcept.fromForm = 'form';
    }
    this.getFolderInfo();
    this.emitFullMenu();
    this.configureConcept();

  }

  getProcess(){
    this.apiService.get('processes/'+ this.conceptInfo.conceptId + '/summary')
      .then((data:any)=>{
          this.editConcept.conceptInfo.processStates = data;
          if(data){
              this.processStates();
          }
      }, ()=>{});
  }

  processStates(){
      this.apiService.get('processes/'+ this.conceptInfo.conceptId, 'states')
      .then((data)=>{
          this.editConcept.activeConcept.listStates = data;
          this.editConcept.activeConcept.listStates = this.commonUtilsService.editList(data, 'stateId', 'name');
      }, (errorData:any)=>{
          this.loader.closeError();
      });
  }


  launchAction(action:any){
      switch(action.type){
          case 'changeEvaluatedConcept':
              this.editConcept.pollObject = cloneDeep(action.change);
              break;
          case 'closeConcept':
              this.closeConcept(action.item)
              break;
          case 'selectTab':
              if(this.conceptState.getProcess() || this.conceptState.getAmount()){
                  this.modalService.adviceModal(this.translate.instant('process.edit.process-modified-warning'), 'info:question:question').subscribe((result:any)=>{
                    if(result.result ==='ok'){
                      this.conceptState.removeAll();
                      this.conceptState.setProcess(false);
                      this.changeTab(action.item);
                      if (action.item ==='process'){
                          this.loader.openLoader('charge-process-concept-list');
                      }
                    }
                  })

              }else{
                  if (action.item ==='process'){
                      this.loader.openLoader('charge-process-concept-list');
                  }
                  this.changeTab(action.item)
              }
              break;
          case 'savedPropertiesConcept':
              this.broadcastService.broadcast('savedPropertiesConcept', action);
              break;
          default:
                this.editConcept.activeConcept = action.item;
              break;
      }
  }

  changeTab(subTab:any){
      this.editConcept.activeConcept.subTab = subTab;
      this.editConcept.dirty = false;
      this.editConcept.dirtyFields = [];
  }

  closeConcept(concept:any){
    let that: any = this.editConcept;
      let index:number = 0;
      for(let i = 0; i < that.conceptList.length; i++){
          if(concept && concept.conceptObjectId === that.conceptList[i].conceptObjectId){
              index = i;
              break;
          }
      }
      if(index === 0 && (this.conceptState.getProcess() || this.conceptState.getAmount())){
          this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question').subscribe((result:any)=>{
            if(result.result ==='ok'){
              this.conceptState.removeAll();
              this.conceptState.setProcess(false)
              this.closeConceptSave();
            }
          })

      }else if(that.conceptList.length && concept){
          that.conceptList.splice(index, 1)
          that.activeConcept = that.conceptList[that.conceptList.length-1];
          that.conceptInfo.active = true;
      }

      if(concept && !this.editConcept.conceptList.length){
        this.router.navigateByUrl('evaluation/'+this.route.snapshot.params['evaluationId']+'/folder/'+this.route.snapshot.params['folderId'])
      }
  }

  getFolderInfo(){
      this.apiService.get('folders/'+this.evaluationId, this.editConcept.conceptInfo.folderId)
          .then((data:any)=>{
              this.editConcept.parentFolderName = data.name;
              this.loader.closeLoader(['folder-edit-concept', 'edit-concept-init-edit', 'folder-edit-process', 'evaluation-menu-menu-action']);
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  configureConcept(){
    let that: any = this.editConcept
      that.conceptInfo.fieldContainers = this.fieldContainerInfo;
      that.conceptInfo.active = true;
      that.conceptInfo.subTab = this.to;
      that.conceptInfo.new = true;
      if(this.to === 'process-back'){
          that.conceptInfo.subTab = 'process';
      }else if(this.to === 'template-front'){
          that.conceptInfo.subTab = 'template';
      }
      that.conceptList = [that.conceptInfo];
      that.conceptInfo.fieldContainers =  this.commonUtilsService.searchFormu(that.conceptInfo.fieldContainers, that.conceptInfo);
      that.activeConcept = that.conceptInfo;
      that.activeConcept.evaluatedFields = [];
      that.activeConcept.withProcess = !((this.processInfo.processId === 0 || this.processInfo.processId === null || this.processInfo.processId === undefined) || this.processInfo.processBaseId === null)
      this.configureEditable();
  }

  configureEditable(){
      this.editConcept.conceptInfo.fieldContainers.forEach((fieldContainers:any) => {
        fieldContainers.editable = false;
        fieldContainers.subContainers.forEach((subContainers:any) => {
          subContainers.editable = false;
        });
      });
  }

  emitFullMenu(){
      this.broadcastService.broadcast('changeFullMenu', {
          fullMenu: this.fullMenu
      });
  }

  closeConceptSave(){
      if(this.editConcept.conceptInfo.productTypeId === 2){
          if(this.editConcept.conceptInfo.instantiated){
            this.router.navigate(['evaluation', this.evaluationId, 'folder', this.editConcept.conceptInfo.folderId]);
          }else{
            this.loader.openLoader('folder-open-concept');
            this.editConcept.conceptInfo.poll ?this.goBack() : this.newInstance();
          }
      }else{
          this.goBack();
      }
  }

  newInstance(){
      this.apiService.add('conceptobjects/'+ this.editConcept.conceptInfo.conceptId, {title:this.editConcept.conceptInfo.title})
        .then((data:any)=> {
            this.goBack();
        }, (errorData:any)=>{
            this.loader.closeError();
        });
  }

  goBack(){
    if(this.conceptState.getAmount() || this.conceptState.getProcess()){
        this.loader.closeLoader(['folder-open-concept']);
        this.modalService.adviceModal(this.translate.instant('concept.advertence-message'), 'info:question:question').subscribe((result:any)=>{
          if(result.result==='ok'){
            this.conceptState.removeAll();
            this.conceptState.setProcess(false)
            this.router.navigate(['evaluation', this.evaluationId, 'folder', this.editConcept.conceptInfo.folderId]);
          }
        })
    }else{
        this.router.navigate(['evaluation', this.evaluationId, 'folder', this.editConcept.conceptInfo.folderId]);
    }
  }

  goPreview() {
      if (this.editConcept.conceptInfo.poll) {
          let url: string = this.editConcept.conceptInfo.anonymousAccess? '/public/concept-preview/' : '/shared/concept-preview/';
          url += this.editConcept.conceptInfo.conceptId
          window.open(url, '_blank');
      }
  }
}
