import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'filials',
  templateUrl: './filials.component.html'
})
export class FilialsComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  filials: any = {
    child: null
  }
  constructor(private globalCfg: GlobalCfgFactory){ }

  @Input() child : any [];
  @Output() openChildEmit = new EventEmitter();
  @Output() selectChildEmit = new EventEmitter();

  ngOnInit(): void {
    this.filials.child = this.child;
  }

  openChild(e: any, child:any){
    let item:any = {
      child: child,
      e: e
    }
    this.openChildEmit.emit(item);
  }

  selectChild(e: any, child:any){
    let item:any = {
      child: child,
      e: e
    }
    this.selectChildEmit.emit(item);
  }

  openChildOn(e:any){
    this.openChildEmit.emit(e);
  }

  selectChildOn(event:any){
    event.e.stopPropagation();
    this.selectChildEmit.emit(event);
  }
}
