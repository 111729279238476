import moment from 'moment';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { SharepointLogoutService } from '@shared/services/sharepoint-logout/sharepoint-logout.service';
import { SnapShotsService } from '@shared/services/snap-shots/snap-shots.service';
import { TargetService } from '@shared/services/target/target.service';
import { cloneDeep, each, find } from 'lodash';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html'
})
export class LandingPageComponent implements OnInit, OnDestroy {
  subscribers : any = {};
  targetState: any = this.targetService.parseString(this.route.snapshot.params['target']);
  skin: string = this.globalCfg.skin;
  sharedConcept = this.route.snapshot.data['name'] === 'root.shared.concept';
  icamConceptObject = (this.skin === 'icam-red' && this.route.snapshot.data['componentName'] === 'landingPage' && this.context?.view?.extraData?.masterConceptObjectId);
  viewEditConcept = this.route.snapshot.data['name'] === 'root.shared.concept-preview' || this.route.snapshot.data['name'] === 'root.public.concept-preview';
  meetingId: any = this.route.snapshot.params['meetingId'];
  assist: any = this.route.snapshot.params['assist'];
  fieldContainerId: any = this.route.snapshot.params['fieldContainerId'];
  concept: any = this.route.snapshot.data['concept'];
  task: any = this.route.snapshot.data['task'];
  public: any = this.route.snapshot.data['public'];
  isMonitoring: any = this.route.snapshot.data['isMonitoring'];

  landingPage: any = {
    comingSoon: true,
    visibilityLanding: true,
    showProcedure: false,
    showTask: false,
    form: '',
    sharedConcept: false,
    viewEditConcept: false,
    showButtonsHeader: true,
    fieldIdentified: null,
    searchText: '',
    company: {},
    showButtonProcedures: false,
    conceptTask: [],
    languages: this.commonUtilsService.configureAvailableLanguages(this.apiPropeties.availableLanguages),
    translatesFooter: {
      reservedRights: this.translate.instant('compliance-footer.reserved-rights'),
      legalWarning: this.translate.instant('compliance-footer.legal-warning'),
      privacyPolicy: this.translate.instant('compliance-footer.privacy-policy'),
      cookiesPolicy: this.translate.instant('compliance-footer.cookies-policy'),
      generalTerms: this.translate.instant('compliance-footer.general-terms'),
      privacy: this.translate.instant('compliance-footer.privacy'),
      cookies: this.translate.instant('compliance-footer.cookies'),
      processingPersonalData: this.translate.instant('compliance-footer.processing-personal-data'),
      userPermissions: this.userPermissions.getPermissions()
    }
  }
  constructor(private userPermissions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private context: ContextFactory,
    private conceptState: ConceptStateFactory,
    private broadcastService: BroadcastService,
    private targetService: TargetService,
    private commonUtilsService: CommonUtilsService,
    private apiPropeties: ApiPropertiesFactory,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private apiService: ApiServiceService,
    private router: Router,
    private customLanding: CustomLandingFactory,
    private modalService: ModalServiceService,
    private sharepointLogoutService: SharepointLogoutService,
    private snapshotsService: SnapShotsService) { }

    // @Input() concept: null;
    // @Input() task: null;
    // @Input() public: null;

  ngOnInit(): void {
    let that: any = this.landingPage;
    that.concept = this.concept
    that.task = this.task;
    let type:any = this.skin === 'icam-red'?'queries' : 'procedures';

    that.proceduresText = this.translate.instant('landing-page.see-all-'+type);
    that.proceduresTextTittle = this.translate.instant('landing-page.all-'+type);

    let language:any = sessionStorage.getItem('selectLanguage');
    if(language){
        that.selectedLanguage = this.catchDefaultLanguage(language);
    }else if(this.context.user.culture){
        that.selectedLanguage = this.catchDefaultLanguage(this.context.user.culture);
    }else{
        that.selectedLanguage = this.catchDefaultLanguage();
    }

    that.showButtonNewQuery = that.concept?.conceptObjectId != null;
    this.conceptState.reset();
    this.watchSaveConcept();
    this.watchDeleteDocument();
    this.watchChangeConcept();
    if(this.meetingId){
        this.sendConfirmMeeting();
    }else{
        that.showTask = true;

        if (this.public) {
            this.getParamsConceptPublic();
        }else {
            let url : string = 'company/globalcustomization';
            let itemId : any = this.context.user.companyId;
            if (that.task?.taskId) {
                url = 'company/globalcustomization/bytask';
                itemId = that.task.taskId;
            } else if(that.concept?.conceptObjectId){
                url = 'company/globalcustomization/byconceptobject';
                itemId = that.concept.conceptObjectId;
            }
            this.getCustomConfiguration(url, itemId);
        }
        this.configureShared();
        that.inIframe = this.public && this.inIframe();
    }

    if(that.concept){
        if (!that.concept?.conceptObjectId) {
            that.concept.serverLocketEdit = cloneDeep(that.concept.lockedEdit);
            that.concept.lockedEdit = false;
        }
        if(!that.concept?.sequentialView){
            that.concept.progress = 0;
        }
        that.concept.templateList = [];
    }

    this.loader.closeLoader(['front-login-login', 'landing-page-on-init']);
    if(this.context?.user?.login && that.concept?.conceptObjectId){
        this.getConcepts(that.concept, this.context.user.login);

        if(this.commonUtilsService.validateMail(this.context.user.login) && this.context.user.isLogged){
            that.showButtonProcedures = true;
        }
    }
    that.icamConceptConsult = this.icamConceptObject && that.concept && (that.concept.namedKey === 'Consulta' || that.concept.conceptNamedKey === 'Consulta');
    that.disabledButtonsHeader = that.icamConceptConsult;
    if(that.icamConceptConsult &&  !that.concept?.publicLite && !this.context?.view?.extraData?.masterConceptTermsOkFieldValue && this.context?.view?.extraData?.masterConceptTermsOkFieldId){
        this.showUserProfile();
    }

    if(this.skin==="icam-red"){
      this.commonUtilsService.registerEvent('Login Landing ICAM','icam')
    }
  }

  watchSaveConcept(){
    this.subscribers.conceptPublicIdentifyBroadcast = this.broadcastService.subscribe('concept-public-identify', (data:any)=> {
        this.showProcedures();
    });
  }

  watchDeleteDocument(){
      this.subscribers.deleteAttachmentsDocumentLandingBroadcast = this.broadcastService.subscribe('deleteAttachmentsDocumentLanding', (data:any)=>{
        this.broadcastService.broadcast('deleteAttachmentsDocument', {document: data.document})
      })
  }

  watchChangeConcept(){
    this.subscribers.createNewQueryLandingBroadcast = this.broadcastService.subscribe('createNewQueryLanding', (data:any)=> {
        this.newConcept();
    });
  }

  getProcess(conceptObjectId:any, type:any){
    this.apiService.getWithTranslate('processesdata/'+ conceptObjectId, 'summary')
      .then((data:any)=> {
          if(type === 'task'){
              this.landingPage.conceptTask.processStates = data;
          }else if(type === 'concept'){
              this.landingPage.conceptSelected.processStates = data;
          }
          this.loader.closeLoader(['landing-page-on-init']);
      }, ()=>{});
  }

  checkExpired(){
      if(this.landingPage.concept.poll && !this.landingPage.viewEditConcept){
          if(this.landingPage.concept.pollExpirationDate){
              if(!this.checkExpirationDate(this.landingPage.concept)){
                  this.public? this.exitLanding("poll-expired-public") : this.exitLanding("poll-expired-shared");
              }
          }
      }
  }

  sendConfirmMeeting(){
      this.apiService.patch('tasks', this.meetingId + '/assist/' + this.assist)
          .then((data:any)=>{
              this.loader.closeLoader(['landing-page-on-init']);
              this.exitMeeting();
          }, (errorData:any)=>{
              this.loader.closeError()
              this.exitMeeting();
          })
  }

  exitMeeting(){
      let assit = this.assist ==='false'?'dismiss' : 'confirm';
      let finishData = {
        exitType: assit+'-meeting',
        taskId: this.meetingId
      }
      sessionStorage.setItem('exit-landing', JSON.stringify(finishData));
      this.router.navigate(['shared/exit'])
  }

  showProcedures(){
      this.landingPage.showButtonsHeader = false;
      if(this.landingPage.icamConceptConsult){
          this.landingPage.showButtonsHeader = true;
          if(this.landingPage.concept.conceptTitle){
              this.landingPage.titleShared = this.landingPage.concept.conceptTitle;
          }
      }
      this.landingPage.showProcedure = true;
      this.landingPage.showButtonNewQuery = false;
  }

  showUserProfile(){
      this.landingPage.fromView = 'concept';
      if(this.landingPage.showProcedure){
          this.landingPage.showProcedure = false;
          this.landingPage.fromView = 'procedure';
      }else{
          if(this.landingPage.icamConceptConsult && this.landingPage.concept.conceptTitle){
              this.landingPage.titleShared = this.landingPage.concept.conceptTitle;
          }
      }
      this.landingPage.showConceptUserProfile = true;
      this.landingPage.showButtonNewQuery = false;
  }

  getUserProfileConceptObject(){
      this.apiService.get('conceptobjects',  this.context.view.extraData.masterConceptObjectId)
          .then((data:any)=>{
              this.getUserProfileFieldcontainers(data);
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  getUserProfileFieldcontainers(concept:any){
      this.apiService.getWithTranslate('fieldcontainers/'+concept.evaluationId+'/'+concept.conceptId, 'front')
          .then((data:any)=>{
              this.setLockedEdit(data);
              concept.fieldContainers = data;
              this.getUserProfileEvaluatedFields(concept, concept.conceptObjectId);
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  getUserProfileEvaluatedFields(concept:any, conceptObjectId:any){
      this.apiService.get('conceptdata', conceptObjectId)
          .then((data:any)=>{
              concept.evaluatedFields = data;
              concept.publicLite = true;
              if(!this.context?.view?.extraData?.masterConceptTermsOkFieldValue && this.context?.view?.extraData?.masterConceptTermsOkFieldId){
                  concept.hiddeButtonCancel = true;
              }
              this.landingPage.conceptUserProfile = concept;
              this.getAdditionalUser();
          }, (errorData:any)=>{
              this.loader.closeError();
          });
  }

  getAdditionalUser(){
      let identifierField:any = this.findAdditionalField(this.landingPage.concept.fieldContainers, { isIdentifier: true });
      if(identifierField && this.commonUtilsService.validateMail(identifierField.evaluatedValue)){
          if(!this.context?.user?.login){
              this.context.user.login = identifierField.evaluatedValue;
          }
          this.getConcepts(this.landingPage.concept, identifierField.evaluatedValue);
      }
  }

  findAdditionalField(containers:any, condition:any) {
      let foundField:any = null
      for(let i:number = 0; i < containers.length; i++){
        let container: any = containers[i];
        for(let j:number = 0; j < container.fields.length; j++){
          let field:any = container.fields[j]
          if (field.additionalRelatedFields?.length) {
            foundField = find(field.additionalRelatedFields, condition);
            return foundField;
          }
        }
        if (!foundField && container.subContainers && container.subContainers.length) {
          foundField = this.findAdditionalField(container.subContainers, condition);
        }
        return foundField;
      }
      return foundField;
  }

  getParamsConceptPublic(){
    let that: any = this.landingPage
    this.apiService.get('concepts/publicconfiguration/concept', that.concept.conceptId+'?defLanguage=' + this.apiPropeties.defaultLanguage)
      .then((data:any)=>{
          that.dataHeader = {
              productName: that.concept.productName,
              client: that.concept.clientName,
              evaluationName: that.concept.evaluationName,
              breadcrumbs: that.concept.breadCrumb,
              title: that.concept.title,
              evaluationId : that.concept.evaluationId
          }
          that.dataHeader.logo = data.logo;
          that.dataHeader.color = data.color;
          if(that.concept.conceptObjectId){
              that.titleShared = that.concept.title;
          }else{
            that.titleShared = data.landingTitle?data.landingTitle:that.concept.title;
          }
          if(data.defaultCustomization){
              let footerDescription : any = this.commonUtilsService.getFooterDescription(that.translatesFooter);
              data.footer = footerDescription;
              if(!data.logo){
                  that.dataHeader.logo = that.concept.logo;
                  data.logo = that.concept.logo;
              }
          }else{
              this.checkExpired();
          }
          this.configureCustomLanding(data);
      }, (errorData:any)=>{
          this.loader.closeError();
          that.footerConceptPublic = {};
      })
  }

  configureCustomLanding(custom:any){
      let that: any = this.landingPage
      that.custom = custom;
      that.custom.colorClass = that.custom.color.substring(1, that.custom.color.length)
      that.custom.mainColor = that.custom.color;
      that.custom.footerDescription = that.custom.footer;

      if(!that.custom.footerDesignType){
          that.custom.footerDesignType = 3;
      }
      if(!that.custom.textColor){
          that.custom.textColor = '#ffffff';
          if(that.custom.color.toLowerCase() === '#fff201' || that.custom.color.toLowerCase() === '#ffb001'){
              that.custom.textColor = '#000000';
          }
      }

      that.custom.secondColor = '#000000';
      if(that.custom.color === '#000' || that.custom.color === '#000000' || that.custom.color === '#001978'){
          that.custom.secondColor = '#ffffff';
      }
      that.custom = this.commonUtilsService.configureLogos(that.custom);
      this.customLanding.setCustom(that.custom);
      this.checkShowModalHolidays();
      that.showLanding = true;
  }

  checkShowModalHolidays(){
      let today = new Date();
      let monthToday = today.getMonth() + 1;
      let dayToday = today.getDate();
      if(this.skin === 'icam-red' && ((monthToday === 7 && dayToday >= 24) || monthToday === 8)){
        let textHolidays = 'AVISO IMPORTANTE - Recuerde que el servicio de Consultas Documentales e Información permanecerá cerrado del 1 al 31 de agosto. Aquellas consultas que pudieran quedar pendientes así como las nuevas que quiera plantear durante este periodo, se tramitarán por orden de entrada y prioridad a partir del día 1 de septiembre.';
        this.modalService.adviceModal(textHolidays, 'info:only-continue:exclamation-full');
      }
  }

  catchDefaultLanguage(culture:any = false){
      let language = culture? culture : this.apiPropeties.defaultLanguage;
      for(let i = 0; i < this.landingPage.languages.length; i++){
          if(language === this.landingPage.languages[i].listValueId){
              return this.landingPage.languages[i];
          }
      }
  }

  inIframe () {
      try {
          return window.self !== window.top;
      } catch (e) {
          return true;
      }
  }

  getCustomConfiguration(url:any, id:any) {
      this.apiService.get(url, id)
          .then((data:any)=>{
            if(data){
              if(data.defaultCustomization){
                data.footer = this.commonUtilsService.getFooterDescription(this.landingPage.translatesFooter);
              }
              this.configureCustomLanding(data);
            }else{
              this.landingPage.custom = {};
              this.customLanding.setCustom(this.landingPage.custom);
              this.landingPage.showLanding = true;
            }
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  configureShared(){
      let that: any = this.landingPage
      if (that.concept) {
          that.titleShared = that.concept.title;
          if(that.concept?.publicLite){
              that.titleShared = this.translate.instant('landing-page.documentary-consultations-channel');//'Canal de consultas';
          }else if(that.concept.productId === 'blanqueo' && that.concept.conceptNamedKey === 'Cliente'){
              that.titleShared = that.concept.clientName;
          }else if(that.concept.productId === 'blanqueo' && that.concept.conceptNamedKey === 'SujetoObligado'){
              that.titleShared = this.translate.instant('common.obligated-subject');
          }
          if (!this.public && that.concept.conceptObjectId) {
              this.getConceptFieldcontainers(that.concept);
          } else if (this.public) {
              that.concept.active = true;
              this.getConceptFieldcontainers(that.concept);
          }else if(that.viewEditConcept){
            this.getConceptFieldcontainers(that.concept);
          }

          if(!this.public){
              this.configureHeader();
              that.showLanding = true;
          }
      }

      if(that.task){
          if(that.task.conceptObjectId){
              that.task.titleTask = that.task.conceptObjectTitle;
              if(!that.concept || (!that.concept?.documentation)){
                  this.getConceptDocumentation(that.conceptTask, that.task.conceptObjectId);
              }
              this.getProcess(that.task.conceptObjectId, 'task');
          }
          this.configureHeader('task');

          if(that.task.taskTypeId === 3){
              that.showEdit = true;
          }
      }
  }

  configureHeader(type:any = 'concept'){
    let item: any = this.landingPage[type];
    let breadCrumb: any = type==='task'?item.breadcrumb:item.breadCrumb;
    this.landingPage.dataHeader = {
        productName: item.productName,
        client: item.clientName,
        evaluationName: item.evaluationName,
        breadcrumbs: item.breadCrumb,
        customBreadcrumbs: this.extractBreadcrumbs(breadCrumb),
        title: type==='concept'? item.conceptTitle : item.titleTask,
        logo: item.logo,
        evaluationId : item.evaluationId,
        color: item.color
    }
    type==='concept'?this.checkExpired() : this.configureTitle();
  }

  extractBreadcrumbs(breadcrumb:any){
      let concept: any = this.landingPage.concept;
      if(breadcrumb && !(concept?.productId === 'blanqueo' && (concept?.conceptNamedKey === 'Cliente' || concept?.conceptNamedKey === 'SujetoObligado'))){
          return breadcrumb.split(' > ');
      }
  }

  configureTitle(){
      let task: any = this.landingPage.task;
      this.landingPage.titleShared = this.translate.instant('task-edit.task-of') + task.type;
      if(!task.conceptObjectId && task.breadcrumb !==''){
          task.titleSharedConcept = this.translate.instant('task-edit.task-of') + task.type;
      }else if(task && task.conceptObjectId && task.breadcrumb !== ''){
          task.titleSharedConcept = this.translate.instant('landing-page.task-associated') + task.titleTask;
      }
      else if (task && !task.conceptObjectId){
          task.titleSharedConcept = this.translate.instant('open-task.general-task');
      }
      this.landingPage.userLogin = task.createdBy;
      this.landingPage.creationDate = task.creationDate;
      this.landingPage.showLanding = true;
      this.loader.closeLoader(['landing-page-on-init']);
  }

  getConceptDocumentation(concept:any, conceptObjectId:any){
      this.apiService.get('documentobjects/'+conceptObjectId)
          .then((data:any)=>{
              concept.documentation = data;
          }, ()=>{})
  }

  getConceptFieldcontainers(concept:any){
      let that: any = this.landingPage
      let url:any = this.fieldContainerId? this.fieldContainerId+'/front' : 'front'
      let modalInfo : any = sessionStorage.getItem('modalInfo');
      sessionStorage.removeItem('modalInfo');
      this.apiService.getWithTranslate('fieldcontainers/'+concept.evaluationId+'/'+concept.conceptId, url)
          .then((data:any)=>{
              if(that.viewEditConcept){
                  that.concept.fieldContainers = data;
                  that.concept.evaluatedFields = [];
                  that.concept.evaluatedFields = this.getViewEvaluatedFieds(that.concept);
                  that.conceptSelected = that.concept;
              }else{
                  this.setLockedEdit(data);
                  let fieldContainers = data;
                  if(this.fieldContainerId && data?.[0].parentId){
                    fieldContainers = [
                      {
                        showOnlySubcontainer: true,
                        fields: [],
                        propertyVisibleValues: [],
                        references: [],
                        relatedInfo: [],
                        subContainers: data
                      }
                    ]
                  }
                  concept.fieldContainers = fieldContainers;
                  this.addObjectDocumentation(concept);
                  if (!this.public) {
                      this.getVideosFromEvaluatedConcept(concept);
                  } else {
                      if (concept.conceptObjectId) {
                          if(that.icamConceptConsult){
                              this.getConceptClean(concept, data);
                              that.showButtonProcedures = true;
                              that.showButtonUserProfile = true;
                          }
                          this.getEvaluatedConceptData(concept, concept.conceptObjectId);
                      } else {
                          this.getDefalutEvaluatedFields(concept, data);
                          if(that.concept?.publicLite){
                            let extraData: any = this.context.view.extraData;
                            concept.evaluatedFields.forEach((evaluatedFields:any) => {
                              if(evaluatedFields.fieldId === extraData.tokenFieldId){
                                evaluatedFields.nvarcharValue = extraData.tokenFieldValue;
                              }
                              if(evaluatedFields.fieldId === extraData.perfilFieldId){
                                  evaluatedFields.floatvalue = extraData.perfilFieldValue;
                              }
                              if(evaluatedFields.fieldId === extraData.estadoFieldId){
                                  evaluatedFields.floatvalue = extraData.estadoFieldValue ;
                              }
                              if(evaluatedFields.fieldId === extraData.situacionFieldId){
                                  evaluatedFields.floatvalue = extraData.situacionFieldValue;
                              }
                              if(evaluatedFields.fieldId === extraData.idUsuarioFieldId){
                                  evaluatedFields.nvarcharValue = extraData.idUsuarioFieldValue;
                              }
                            });
                          }else if(that.icamConceptConsult){
                              that.showButtonProcedures = true;
                              that.showButtonUserProfile = true;
                              if(modalInfo === 'createdPublicLite'){
                                  this.modalService.adviceModal(this.translate.instant('landing-page.saved-user-profile'), 'info:accept:check-round-full');
                              }
                          }
                          that.conceptSelected = concept;
                          if(that.conceptSelected.sequentialView){
                              that.showTitleSequentialView = false;
                          }
                          that.conceptSelectedClean = cloneDeep(that.conceptSelected);
                      }

                      this.loader.closeLoader(['landing-page-on-init']);

                  }
              }
          }, ()=>{
              this.loader.closeError()
          })
  }

  getViewEvaluatedFieds(concept:any) {
      this.landingPage.evaluatedFields = [];
      this.addContainersValue(concept);
      return this.landingPage.evaluatedFields;
  }

  addContainersValue(parent:any) {
    if(parent.fieldContainer){
      parent.fieldContainers.forEach((container:any) => {
        this.addContainerValue(container);
        container.subContainers.forEach((subContainer:any) => {
          this.addContainerValue(subContainer);
        });

      });
    }
}

addContainerValue(container:any) {
    container.fields.forEach((field:any) => {
      let conceptData : any = this.createConceptDataMockup(container, field);
      this.landingPage.evaluatedFields.push(conceptData);
      this.addContainersValue(container);
    });

}

createConceptDataMockup(container:any, field:any) {
    let conceptData:any = {
        bitValue: null,
        conceptDataId: -(this.landingPage.evaluatedFields.length + 1),
        conceptObjectId: 'viewEdit',
        datetimeValue: null,
        documents: [],
        fieldId: field.fieldId,
        fieldTypeId: field.fieldTypeId,
        fileValue: null,
        floatvalue: null,
        hasReferences: false,
        label: field.label,
        listValues: field.listValues,
        numSubContainer: null,
        nvarcharValue: null,
        subContainerId: null
    }
    if (container.multiple) {
        conceptData.subContainerId = container.fieldContainerId;
        conceptData.numSubContainer = 1;
    }

    return conceptData;
}

  setLockedEdit(fieldContainers:any){
      let that:any = this.landingPage;
      that.concept.lockedEdit = that.concept.conceptObjectId && that.concept.stateLockedEdit?that.concept.stateLockedEdit:that.concept.lockedEdit;
      fieldContainers.forEach((fieldContainer:any) => {
          fieldContainer.lockedEdit = that.concept.lockedEdit;
          fieldContainer.fields.forEach((field:any) => {
            field.lockedEdit = that.concept.lockedEdit;
          });
          fieldContainer.subContainers.forEach((subContainer:any) => {
            subContainer.lockedEdit = that.concept.lockedEdit;
            subContainer.fields.forEach((subField:any) => {
              subField.lockedEdit = that.concept.lockedEdit;
            });
          });
      });
  }

  addObjectDocumentation(concept:any){
      if(concept && !concept.documentation){
          concept.documentation = {
              documents: [],
              subFolders: [],
              totalDocuments: 0
          }
      }
  }

  getVideosFromEvaluatedConcept(concept:any){
      this.apiService.get('concepts', concept.evaluationId+'/'+concept.conceptId )
          .then( (data:any)=> {
          concept.videos = data.videos;
          this.getEvaluatedConceptData(concept, concept.conceptObjectId);
      }, (errorData:any)=>{
          this.loader.closeError();
      })
  }

  getEvaluatedConceptData(concept:any, conceptObjectId:any){
      let url : any = 'conceptdata';
      let id : any = conceptObjectId;
      if(this.fieldContainerId){
          url = 'conceptdata/'+conceptObjectId;
          id = this.fieldContainerId;
      }
      this.apiService.get(url, id)
          .then((data:any)=>{
              concept.evaluatedFields = data;
              this.landingPage.conceptSelected = concept;
              if(this.landingPage.conceptSelected.sequentialView){
                  this.landingPage.showTitleSequentialView = false;
              }
              this.getProcess(this.landingPage.conceptSelected.conceptObjectId, 'concept');
          }, (errorData:any)=>{
              this.loader.closeError();
          });
  }

  getDefalutEvaluatedFields(concept:any, data:any){
      let newevaluatedFields : any = this.createDefaultEvaluatedFields(data);
      concept.evaluatedFields = newevaluatedFields;
  }

  createDefaultEvaluatedFields(root:any) {
      let arrayFields : any = new Array();
      root.forEach((group:any)=> {
          this.createDefaultNodes(group, arrayFields, false);
      })
      return arrayFields;
  }

  createDefaultNodes(node:any, array:any, multiple:any) {
      if (!isNaN(node.fieldId)) {
          let newField : any = this.createField(node, multiple)
          array.push(newField);
      }
      else {
          if (node.fields) {
              node.fields.forEach((field:any)=> {
                  this.createDefaultNodes(field, array, node.multiple);
              });
          }
          if (node.subContainers) {
              node.subContainers.forEach((subcontainer:any)=> {
                  this.createDefaultNodes(subcontainer, array, false);
              });
          }
      }
  }

  createField(element:any, multiple:any) {
      let newField:any = {
          bitValue: null,
          datetimeValue: null,
          conceptDataId: null,
          conceptObjectId: null,
          fieldId: element.fieldId,
          fieldTypeId: element.fieldTypeId,
          fileValue: null,
          floatvalue: null,
          hasReferences: false,
          label: element.label,
          listValues: element.listValues,
          numSubContainer: (multiple) ? 1 : null,
          nvarcharValue: null,
          subContainerId: (multiple) ? element.fieldContainerId : null,
          isIdentifier: element.isIdentifier
      }
      if(element.isIdentifier){
          this.landingPage.fieldIdentified = newField;
      }
      return newField;
  }

  getConceptClean(concept:any, dataFieldContainer:any){
      this.apiService.getWithTranslate('concepts/byid', concept.conceptId)
          .then((data:any)=>{
              this.landingPage.conceptSelectedClean = data;
              this.landingPage.conceptSelectedClean.fieldContainers = cloneDeep(concept.fieldContainers);
              this.addObjectDocumentation(this.landingPage.conceptSelectedClean);
              this.getDefalutEvaluatedFields(this.landingPage.conceptSelectedClean, dataFieldContainer);
          }, (errorData:any)=>{
              this.loader.closeError();
          })
  }

  showConceptInfo(){
      this.modalService.conceptInfo(this.landingPage.concept, this.landingPage.custom)
  }

  editConcept() {
      window.open('/shared/task-concept-evaluated/' + this.landingPage.task.conceptObjectId, '_blank');
  }

  getConcepts(concept:any, email:any, savedConcept:any = false) {
      let url = 'conceptobjects/anonymous/myinstances/' + concept.conceptId;
      let body:any = { email: email };
      if (this.icamConceptObject) {
          url = 'icam/anonymous/myinstances/' + this.context.view.extraData.masterConceptObjectId
          body = undefined;
      }
      this.apiService.add(url, body)
          .then((data:any)=> {
              this.configureConceptList(concept, data, savedConcept);
          }, ()=>{});
  }

  configureConceptList(concept:any, data:any, savedConcept:any){
      concept.amountEvaluatedConcept = data.length;
      concept.allEvaluated = data;
      concept.list = data;
      concept.archived = [];

      if(this.landingPage.fieldIdentified){
          if(data[data.length-1]){
              this.context.user.password = decodeURIComponent(data[data.length-1].publicUrl.substring(data[data.length-1].publicUrl.indexOf('=')+1));
              this.apiService.login(this.context.user, 'public')
                .then((data:any)=>{
                  this.loginOk(data)
                }, (errorData:any)=>{
                  this.loginError(errorData);
                });
          }
      }

      if(this.landingPage.icamConceptConsult && !(!this.context?.view?.extraData?.masterConceptTermsOkFieldValue && this.context?.view?.extraData?.masterConceptTermsOkFieldId)){
          this.landingPage.disabledButtonsHeader = false;
      }
      if(savedConcept){
          this.landingPage.showProcedure = true;
          this.landingPage.hiddenButtonBackProcedure = true;
          let text:any = savedConcept === 'createdConcept'? 'landing-page.saved-new-concept-icam-ok' : 'landing-page.saved-concept-icam-ok';
          this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round-full');
      }
  }

  launchAction(action:any){
    let that: any = this.landingPage;
      switch(action.type){
          case 'changeProgressSequential':
              that.showTitleSequentialView = true;
              this.changeProgress(action.item);
              break;
          case 'closeConceptUserProfile':
              that.showConceptUserProfile = false;
              if(that.fromView === 'procedure'){
                  that.showProcedure = true;
              }else if(that.icamConceptConsult && that.concept.conceptTitle){
                  that.titleShared = that.concept.title;
              }
              break;
          case 'closeSaveConceptUserProfile':
              that.showConceptUserProfile = false;
              if(that.icamConceptConsult && that.concept.conceptTitle){
                  that.titleShared = that.concept.title;
              }
              if(!this.context?.view?.extraData?.masterConceptTermsOkFieldValue && this.context?.view?.extraData?.masterConceptTermsOkFieldId){
                  this.context.view.extraData.masterConceptTermsOkFieldValue = true;
                  this.context.storeView();
                  that.conceptUserProfile.hiddeButtonCancel = false;
                  that.disabledButtonsHeader = false;
              }
              if (action.actionFrom !== 'exitNew') {
                  this.modalService.adviceModal(this.translate.instant('landing-page.saved-user-profile'), 'info:accept:check-round-full');
              }
              break;
          case 'closeSaveExitConceptICAM':
              that.titleShared = that.concept.conceptTitle;
              this.getConcepts(that.concept, this.context.user.login/*, savedConcept*/);
              that.showProcedure = true;
              that.hiddenButtonBackProcedure = true;
              break;
          case 'closeSaveConceptICAM':
              this.newConcept();
              break;
          case 'changeConceptAdditional':
              if(!that.conceptUserProfile && !that.calledUserProfile){
                  that.calledUserProfile = true;
                  this.getUserProfileConceptObject();
              }
              break
          default:
              break;
      }
  }

  changeProgress(progress:any){
      this.landingPage.conceptSelected.progress = progress;
      this.landingPage.conceptSelected.status = 2;
      if(progress <= 30){
          this.landingPage.conceptSelected.status = 0;
      }else if(progress < 60){
          this.landingPage.conceptSelected.status = 1;
      }
  }

  loginOk(data:any) {
    if (this.landingPage.isLandingLogin || data.user.hasPermissions || this.targetState && this.targetState.name === 'import-evaluation') {
        this.continueLogin(data);
    } else {
        this.landingPage.jwtToken = data.jwtToken;
        this.landingPage.showNoPermissionsWarning = true;
        this.landingPage.continueLogin = this.continueLogin(data);
        this.loader.closeLoader('front-login-login');
    }
  }

  continueLogin(data:any) {
    this.sharepointLogoutService.logout();
    data.user = this.context.user;
    data.user.isLogged = this.commonUtilsService.validateMail(this.landingPage.credentials.login);
    this.context.setLogin(data);
  }



  logOut() {
      window.location.href = this.globalCfg.externalPublicLogoutUrl == null
          ? window.location.origin + '/gestionUsuarios/Desconexion.jsp'
          : this.globalCfg.externalPublicLogoutUrl;
  }

  loginError(err:any) {
      this.loader.closeError();
      this.landingPage.errorConect = true;
      this.landingPage.errorText = this.translate.instant('login-block.sorry');
      this.landingPage.showNoPermissionsWarning = false;
      if(!this.landingPage.isLandingLogin){
          let text: any = '';
          switch(err.status){
              case 401:
                  text = 'front-login.error-user';
                  break;
              case 403:
                  text = 'front-login.error-product';
                  break;
              default:
                  text = 'front-login.error-connect';
                  break;
          }
          this.landingPage.errorLoginMessage = this.translate.instant(text);
      }else{
          if(err){
              switch(err.status){
                  case 401:
                      this.landingPage.error = this.translate.instant('login-block.invalid');
                      if(this.landingPage.askingNref){
                          this.landingPage.error = this.translate.instant('login-block.invalid-ref');
                      }else if(this.landingPage.isLandingLogin && this.landingPage.errorConect){
                          this.landingPage.error = this.translate.instant('field-type-email.invalid-email');
                      }
                      this.landingPage.icon = 'lf-icon-key';
                      break;
                  case 403:
                      this.landingPage.error = this.translate.instant('login-block.file-not-found');
                      this.landingPage.icon = 'lf-icon-face-sad';
                      break;
                  case 410:
                      this.landingPage.errorText = this.translate.instant('login-block.close-task')
                      this.landingPage.error = this.translate.instant('login-block.no-content')
                      this.landingPage.icon = 'lf-icon-lock';
                      break;
                  case 400:
                      this.landingPage.error =this.translate.instant('login-block.file-not-found')
                      this.landingPage.icon = 'lf-icon-key';
                      break;
                  default:
                      this.landingPage.icon = 'lf-icon-face-sad';
                      this.landingPage.error = this.translate.instant('login-block.error-generic');
                      break;
              }
          }
      }

  }

  printConcept(){
      this.landingPage.concept.custom = this.landingPage.custom;
      this.landingPage.concept.landingPrint = true;
      let historicJson : any = this.snapshotsService.getConceptJson(this.landingPage.concept);
      delete this.landingPage.concept.custom;
      delete this.landingPage.concept.landingPrint;
      sessionStorage.removeItem('historyViewerData');
      sessionStorage.setItem('historyViewerDataJson', historicJson);
      window.open('/history-viewer', '_blank');
  }

  createNewQuery() {
      if (this.conceptState.getAmount() || this.conceptState.getProcess()) {
          this.modalService.adviceModal(this.translate.instant('landing-page.no-saved-changes'), 'info:exit:question').subscribe((result:any)=>{
            if(result.result !== 'cancel'){
              if(result.result === 'save-exit'){
                this.broadcastService.broadcast('saveConcept');
              }else if(result.result === 'discard-exit'){
                this.newConcept();
              }
            }
          })

      } else {
          this.newConcept();
      }
  }

  newConcept(){
      if(this.landingPage.conceptSelected.documentation.totalDocuments > this.landingPage.conceptSelectedClean.documentation.totalDocuments){
        this.broadcastService.broadcast('deleteAllAttachmentsDocument');
      }
      this.landingPage.conceptSelected = cloneDeep(this.landingPage.conceptSelectedClean);
      this.landingPage.showProcedure = false;
      this.landingPage.showConceptUserProfile = false;
      this.landingPage.hiddenButtonBackProcedure = false;
      this.landingPage.showButtonNewQuery = false;
  }

  checkExpirationDate(concept:any){
      if(concept.pollExpirationDate){
          this.landingPage.dataHeader.pollExpirationDate = concept.pollExpirationDate;
          this.landingPage.dataHeader.pollBeginDate = concept.pollBeginDate;
          if(this.public){
              this.landingPage.dataHeader.title = concept.title;
          }
          return !(moment(new Date).valueOf() > moment(concept.pollExpirationDate).valueOf())
      }
      return false;
  }

  exitLanding(exitType:any){
    let that: any = this.landingPage;
      let userEmail:any = null;
      if(this.landingPage.concept.fieldContainers && this.landingPage.concept.fieldContainers.length){
        let identifierField:any = this.findField(this.landingPage.concept.fieldContainers, { isIdentifier: true });
        if (identifierField) {
            let identifierData:any = find(this.landingPage.concept.evaluatedFields, { fieldId: identifierField.fieldId });
            if (identifierData) {
                userEmail = identifierData.nvarcharValue;
            }
        }
      }

      let finishData = {
          exitType: exitType,
          conceptObjectId: that.concept.conceptObjectId,
          footerConceptPublic: that.footerConceptPublic,
          userEmail: userEmail,
          conceptId: that.concept.conceptId,
          dataHeader: that.dataHeader

      }
      sessionStorage.setItem('exit-landing', JSON.stringify(finishData));
      let urlLocation = this.route.snapshot.data['name'].indexOf('root.public') !== -1?'public/exit':'shared/exit';
      this.router.navigate([urlLocation]);
  }

  findField(containers:any, condition:any):any {
      let field : any = null;
      for(let i:number = 0; i < containers.length; i++){
        let container: any = containers[i]
        field = find(container.fields, condition);
        if (!field && container.subContainers && container.subContainers.length) {
            field = this.findField(container.subContainers, condition);
        }
        if (field) {
            return false;
        }
      }
      return field;
  }

    changeAppLanguage(language:any){
        sessionStorage.setItem('selectLanguage', language.appLanguage);
        window.location.reload();
    }

    goBack(){
        if(this.landingPage.icamConceptConsult && this.landingPage.concept.conceptTitle && !this.landingPage.showConceptUserProfile){
            this.landingPage.titleShared = this.landingPage.concept.title;
        }
        this.landingPage.showButtonsHeader = true;
        this.landingPage.showProcedure = false;
    }

    toggleSearch(){
        this.landingPage.search = !this.landingPage.search;
        if (!this.landingPage.search) {
            this.landingPage.searchText = '';
        }
    }

    ngOnDestroy(): void {
      this.commonUtilsService.OnDestroy(this.subscribers);
    }
}
