<div class="concept-instances-list">
  <div class="common__header-new-version">
      <i class="lf-icon-label-news common__header-icon-new-version"></i>
      <span class="common__header-title-new-version">{{conceptInstancesList.headerTitle}}</span>
      <div class="common__options-block"
          ngbDropdown>
          <button class="common__selected-option"
            ngbDropdownToggle>
              <span class="evaluations-list__select-language">{{conceptInstancesList.selectedView.name}}</span>
              <i class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
          </button>
          <div class="common__menu-block-dropdown dropdown-menu"
            ngbDropdownMenu>
              <div class="common__block-option-list">
                  <div class="common__language-item"
                      ngbDropdownItem
                      *ngFor="let view of conceptInstancesList.conceptInstancesView"
                      (click)="changeView(view.viewOption)">
                      <span>{{view.name}}</span>
                      <i class="lf-icon-check-round-full common__language-icon"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__table-block">
      <div class="common__table-new-version">
          <div class="common__table-header">
              <div class="concept-instances-list__col concept-instances-list__col--reference-icam concept-instances-list__col--top-1">
                  <filter-by-search
                      [item]="conceptInstancesList.referenciaQueryFilter"
                      (launchActionSearch)="orderBySearch($event, 'referencia')">
                  </filter-by-search>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--type-icam concept-instances-list__col--top-1">
                  <filter-by-list [type]="'list'"
                      [item]="conceptInstancesList.tipoFilter"
                      [widthClass]="'200px'"
                      [list]="conceptInstancesList.tipoList"
                      (filterElementEmit)="filterElementOn($event, 'tipo')">
                  </filter-by-list>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--creation-icam concept-instances-list__col--top-2">
                  <p class="common__table-header-text common__cursor-pointer"
                      [afTranslate]="'concept-instances-list.entry'"
                      (click)="searchBy('creationDate')">_Entrada</p>
                  <i class="lf-icon-arrow-down common__order-by"
                      [ngClass]="{'lf-icon-arrow-up': !conceptInstancesList.order && conceptInstancesList.filterOrder === 'creationDate',
                          'common__order-by--active': conceptInstancesList.filterOrder === 'creationDate'}"
                      (click)="searchBy('creationDate')"></i>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--state-icam concept-instances-list__col--top-1">
                  <filter-by-list [type]="'listFind'"
                      [item]="conceptInstancesList.stepFilter"
                      [widthClass]="'200px'"
                      [list]="conceptInstancesList.stepList"
                      (filterElementEmit)="filterElementOn($event, 'step')">
                  </filter-by-list>
              </div>
              <div class="concept-list__table-col concept-list__table-col--confidential"></div>
              <div class="concept-instances-list__col concept-instances-list__col--title-icam concept-instances-list__col--top-2">
                  <p class="common__table-header-text common__cursor-pointer"
                      [afTranslate]="'common.affair'"
                      (click)="searchBy('title')">_Asunto</p>
                  <i class="lf-icon-arrow-down common__order-by"
                      [ngClass]="{'lf-icon-arrow-up': !conceptInstancesList.order && conceptInstancesList.filterOrder === 'title',
                          'common__order-by--active': conceptInstancesList.filterOrder === 'title'}"
                      (click)="searchBy('title')"></i>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--alerts-icam concept-instances-list__col--top-1">
                  <filter-by-list [type]="'list'"
                      [item]="conceptInstancesList.alertStateFilter"
                      [widthClass]="'150px'"
                      [list]="conceptInstancesList.alertStateList"
                      (filterElementEmit)="filterElementOn($event, 'alertState')">
                  </filter-by-list>
              </div>
              <div class="concept-instances-list__col concept-instances-list__col--actions-icam">
                  <p class="common__table-header-text common__table-header-text--right"
                      [afTranslate]="'common.actions'">Acciones</p>
              </div>
          </div>
          <div class="common__table-body common__table-body--14-elements"
              [ngClass]="{'common__table-body--more-elements': conceptInstancesList.instancesListFilter?.length > 14}">
              <div class="common__table-row"
                  *ngFor="let instance of conceptInstancesList.instancesListFilter | byWord:conceptInstancesList.searchInstances:['referencia', 'tipo', 'step', 'title'] | afOrderBy:conceptInstancesList.filterOrder:conceptInstancesList.order | afLimitTo:conceptInstancesList.configPagination.pageSize:conceptInstancesList.configPagination.pageSize*(conceptInstancesList.currentPage - 1) | unique: 'conceptObjectId'; let i = index"
                  [ngHide]="instance.isArchived">
                  <div class="concept-instances-list__col concept-instances-list__col--reference-icam concept-instances-list__col--tooltip common__cursor-pointer"
                      (click)="launchActionInstance(instance, 'open')">
                      <parragraph-tooltip [text]="instance.referencia"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--type-icam concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.tipo"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--creation-icam concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.creationDate | convertDate"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--state-icam concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.step"></parragraph-tooltip>
                  </div>
                  <div class="concept-list__table-col concept-list__table-col--confidential">
                      <div *ngIf="instance.confidential"
                          ngbDropdown
                          [id]="'concept-instances-list-confidential-'+instance.conceptObjectId"
                          autoClose="'outside'">
                          <i class="lf-icon-lock concept-list__icon-confidential"
                              ngbDropdownToggle
                              matTooltip="{{'expanded-menu.confidential' | afTranslate}}"
                              matTooltipPosition="above"
                              matTooltipClass="above">
                          </i>
                          <list-in-dropdown ngbDropdownMenu
                              class="dropdown-menu concept-list__box-select-list"
                              (closeDropdownEmit)="closeDropdown('concept-instances-list-confidential-'+instance.conceptObjectId)"
                              [list]="instance.confidentialList"
                              [type]="'confidential'">
                          </list-in-dropdown>
                      </div>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--title-icam concept-instances-list__col--tooltip">
                      <parragraph-tooltip [text]="instance.title"></parragraph-tooltip>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--alerts-icam concept-instances-list__col--tooltip">
                      <i [class]="'lf-icon-radio-button-active concept-instances-list__icon-alert concept-instances-list__icon-alert--' +instance.colorAlert"></i>
                      <span class="common__col-text concept-instances-list__col-text-alert">{{instance.processingHours}}</span>
                  </div>
                  <div class="concept-instances-list__col concept-instances-list__col--actions-icam">
                      <ul class="common__options-row">
                          <li class="common__menu-options-row"
                              matTooltip="{{(instance.canModify || instance.canManage?'concept-list.description-edit':'concept-list.description-view') | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i [ngClass]="{'lf-icon-editable-form': instance.canModify || instance.canManage, 'lf-icon-visible': !instance.canModify && !instance.canManage}"
                                  (click)="launchActionInstance(instance, 'open')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              matTooltip="{{'concept-list.description-open' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-open-window"
                                  (click)="launchActionInstance(instance, 'openNewTab')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="userPermissions !== 'none'"
                              matTooltip="{{'documentation-list.manage-confident' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-lock"
                                  (click)="openModalConfidential(instance)"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="instance.canModify && instance.canManage && instance.conceptId && !instance.poll && !instance.hasAutoArchive"
                              matTooltip="{{'concept-list.archive-concept' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-archive"
                                  (click)="launchActionInstance(instance, 'archive')"></i>
                          </li>
                          <li class="common__menu-options-row"
                              *ngIf="instance.canModify && instance.canManage && !instance.poll && !instance.restrictedDelete"
                              matTooltip="{{'concept-list.description-delete' | afTranslate}}"
                              matTooltipPosition="below"
                              matTooltipClass="below">
                              <i class="lf-icon-trash"
                                  (click)="launchActionInstance(instance, 'delete')"></i>
                          </li>
                      </ul>
                      <i class="lf-icon-kebab-menu common__icon-ellipsis"></i>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="common__block-footer-table">
      <div class="common__block-search">
          <input type="text"
              [ngShow]="conceptInstancesList.search"
              class="common__search-input"
              [(ngModel)]="conceptInstancesList.searchInstances"
              placeholder="{{'common.search' |translate}}"
              [focusWhen]="conceptInstancesList.search">
          <span class="common__text-search"
              [ngShow]="!conceptInstancesList.search"
              (click)="toggleSearch()"
              [afTranslate]="'common.search'">_Buscar</span>
          <button class="lf-icon-search common__icon-button common__icon-button-search"
              [ngClass]="{'lf-icon-search': !conceptInstancesList.search,
                  'lf-icon-close-search': conceptInstancesList.search,
                  'common__icon-button-search--open': conceptInstancesList.search}"
              (click)="toggleSearch()">
          </button>
      </div>
      <pagination-control class="common__pagination-block"
        [ngShow]="conceptInstancesList.instancesListFilter?.length > conceptInstancesList.configPagination.pageSize"
        [config]="conceptInstancesList.configPagination"
        [collectionSize]="conceptInstancesList.instancesListFilter?.length"
        [(currentPage)]="conceptInstancesList.currentPage"
        (configuredTotalChange)="conceptInstancesList.configuredTotal = $event.configuredTotal"></pagination-control>
      <div class="common__block-footer-total">
          <span [ngShow]="conceptInstancesList.instancesListFilter?.length > conceptInstancesList.configPagination.pageSize">
            <span class="notranslate">{{conceptInstancesList.configuredTotal.first}}-{{conceptInstancesList.configuredTotal.last}}</span>
            <span class="common__range-number-element" [afTranslate]="'common.of'">_de</span>
            <span class="notranslate">{{conceptInstancesList.instancesListFilter?.length}}</span>
          </span>
          <span [ngShow]="!(conceptInstancesList.instancesListFilter?.length > conceptInstancesList.configPagination.pageSize)">
            <span [afTranslate]="'common.total'">_Total</span>
            <span class="common__total-number-element notranslate">{{conceptInstancesList.instancesListFilter?.length}}</span>
          </span>
      </div>
  </div>
</div>
