import * as d3 from 'd3'
import html2canvas from 'html2canvas';

import { Component, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'horizontal-poll-graph',
  templateUrl: './horizontal-poll-graph.component.html'
})

export class HorizontalPollGraphComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  horizontalPollGraph:any = {};
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private container: ElementRef) { }
  @Input() graphData: null;
  @Input() isModal: boolean=false;
  @Input() fromComponent: string;
  @ViewChild(MatTooltip)
  tooltip: MatTooltip

  ngOnChanges(): void {
    let that: any = this.horizontalPollGraph;
    if(this.graphData){
      that.graphData = this.graphData;
      that.graphData.graphicId = this.commonUtilsService.createId();

      for(var i = 0; i < that.graphData.original.length; i++){
          that.graphData.original[i].ind = i;
      }
      that.textHeight = 11;
      if(that.graphData.original.length >= 20 && !this.isModal) {
          that.textHeight = 8;
      }
      that.graphWidth = 356;
      that.graphHeight = 326;
      if(this.isModal){
          that.graphWidth = 634;
          that.graphHeight = 1049;
          that.graphLeft = 112;
          that.graphRight = 50;
      }else if(that.graphData.multiple){
          that.graphWidth = 706;
      }
      if(that.svg){
          that.svg.remove();
      }
      setTimeout(()=>{
          this.drawGraph();
      });
    }
  }


  drawGraph(){
    let that: any = this.horizontalPollGraph;
    that.margin = {
        top: 12,
        right: 12,
        bottom: 12,
        left: 12
    };
    if(this.isModal){
        that.margin.left = that.graphLeft;
        that.margin.right = that.graphRight;
    }

    that.width = that.graphWidth - that.margin.right + that.margin.left,
    that.height =  that.graphHeight - that.margin.top + that.margin.bottom;
    this.drawSvg();
    this.drawAxxis();
    this.generateTooltip()
    this.drawCanvas();
  }

  drawSvg(){
    let that: any = this.horizontalPollGraph;
      that.svg = d3.select(this.container.nativeElement.firstChild.firstChild)
      // that.svg = d3.select("#horizontal-poll-graph-"+that.graphData.graphicId)
          .append("svg")
          .attr("width", that.width + that.margin.left + that.margin.right)
          .attr("height", that.height + that.margin.top + that.margin.bottom)
          // .attr('id', 'horizontal-poll-graph__graph-'+that.graphData.graphicId)
          .attr("class", !this.isModal? "horizontal-poll-graph__graph horizontal-poll-graph__graph--" + this.skin : "");
      if(this.isModal){
          that.svg.append("foreignObject")
              .attr("width", that.width + 24)
              .attr("height", that.height + that.margin.top + that.margin.bottom)
              .attr("transform", "translate("+(that.margin.left - 12) +",0 )")
              // .append("xhtml:div")
              .attr("class", "horizontal-poll-graph__graph horizontal-poll-graph__graph--div horizontal-poll-graph__graph--" + this.skin);
      }

      that.canvas = that.svg.append("g")
          .attr("class", 'horizontal-poll-graph__canvas')
          .attr("transform",
              "translate(" + that.margin.left + "," + that.margin.top + ")");

  }

  drawAxxis(){
    let that: any = this.horizontalPollGraph;
    that.y = d3.scaleBand()
        .range([that.height, 0])
        .padding(0);
    that.x = d3.scaleLinear()
        .range([0, that.width]);
    that.x.domain([0, 100])
    that.y.domain(that.graphData.original.map((d:any) => {
        return d.label;
    }));
    that.canvas.append("g")
      .attr("transform", "translate(0," + that.height + ")")
      .attr('class', 'horizontal-poll-graph__x-ticks')
      .call(d3.axisBottom(that.x).tickSize(-that.height ).tickFormat(null));

    // add the y Axis
    that.canvas.append("g")
      .attr('class', 'horizontal-poll-graph__y-ticks')
      .call(that.y);
  }

  generateTooltip(){
    let that: any = this.horizontalPollGraph;
      d3.select("#horizontal-poll-graph-"+that.graphData.graphicId)
          .append("div")
          .attr('id', 'horizonta-poll-graph-tooltip-'+that.graphData.graphicId)
          .attr('class', 'horizontal-poll-graph__tooltip-block')
          .append('div')
          .attr('id', 'horizonta-poll-graph-tooltip-content-'+that.graphData.graphicId)
          .attr('class', 'horizontal-poll-graph__tooltip-content horizontal-poll-graph__tooltip-content--'+this.skin)
          .text('');

      d3.select('#horizonta-poll-graph-tooltip-'+that.graphData.graphicId)
          .append("div")
          .attr('class', 'horizontal-poll-graph__tooltip-triangle');
  }

  drawCanvas(){
    let that: any = this.horizontalPollGraph;
      let bar:any = that.canvas.selectAll(".bar")
          .data(that.graphData.original)
          .enter()
          .append("g")
          .attr("class", "horizontal-poll-graph__bar-block")
          .attr("transform", (d:any, i:any)=> {
              return "translate(0," + i * that.y.bandwidth() + ")";
          });

      bar.append("line")
          .attr("x1", "0")
          .attr("y1", that.y.bandwidth())
          .attr("x2", that.width)
          .attr("y2", that.y.bandwidth())
          .attr("class", "horizontal-poll-graph__separate-line");

      bar.append("rect")
          .attr("width", (d:any)=>{
              return !d.percentage? 2 : (d.percentage / 100) * that.width;
          })
          .attr("height", that.y.bandwidth())
          .attr("class", "horizontal-poll-graph__percent-bar")
          .attr("fill", (d:any)=>{
              return d.color;
          })
      if(this.isModal){
          bar.append("foreignObject")
              .attr("width", "100")
              .attr("height", that.y.bandwidth())
              .attr("transform", "translate(-"+that.margin.left+","+((that.y.bandwidth() / 2) - 8) +" )")
              .append("xhtml:div")
              .attr("class", "horizontal-poll-graph__y-label horizontal-poll-graph__y-label--"+this.skin)
              .text((d:any) =>{
                  return d.label;
              });
      }

          let foreignDiv:any = bar.append("foreignObject")
              .attr("width", (d:any)=>{
                  var width = that.width - ((d.percentage / 100) * that.width );
                  return width < 85? 85 : width;
              })
              .attr("height", (d:any)=>{
                  return that.y.bandwidth() < 25? 25 : that.y.bandwidth()
              })
              .attr("transform", (d:any) => {
                  var translateX = (d.percentage / 100) * that.width ;
                  if( (that.width - translateX) < 85){
                      return "translate("+ (that.width - 85)  +","+((that.y.bandwidth() / 2) - that.textHeight) +" )";
                  }else{
                      return "translate("+ translateX +","+((that.y.bandwidth() / 2) - that.textHeight) +")";
                  }

              })
              .append("xhtml:div")
              .attr("class",()=>{
                  let text:string = "horizontal-poll-graph__legend-block";
                  if(that.graphData.original.length >= 20 && !this.isModal){
                      text +=" horizontal-poll-graph__legend-block--more-elements"
                  }else if(that.graphData.original.length >= 20 && this.isModal){
                      text +=" horizontal-poll-graph__legend-block--more-elements-modal"
                  }
                  return text;
              });

      foreignDiv.append('div')
          .attr("class",()=>{
              let text:string = "horizontal-poll-graph__percent-block horizontal-poll-graph__percent-block--"+this.skin;

              if(that.graphData.original.length >= 20){
                  text +=" horizontal-poll-graph__percent-block--small"
              }
              return text;
          })
          .text((d:any)=>{
              return d.percentage+'%'
          });

      foreignDiv.append('div')
          .attr("class",()=>{
              var text = "horizontal-poll-graph__answers-block horizontal-poll-graph__answers-block--"+this.skin;

              if(that.graphData.original.length >= 20){
                  text +=" horizontal-poll-graph__answers-block--small"
              }
              return text;
          })
          .text((d:any)=>{
              return d.answers;
          });

      bar.append("rect")
          .attr("width", that.width)
          .attr("height", that.y.bandwidth())
          .attr("class", "horizontal-poll-graph__full-bar horizontal-poll-graph__full-bar--"+this.skin)
          .on("mouseover", ()=>{
              if(!this.isModal){
                  d3.select('#horizonta-poll-graph-tooltip-'+that.graphData.graphicId).style("visibility", "visible");
              }
          })
          .on("mousemove", (d:any)=>{
              if(!this.isModal){
                  d3.select('#horizonta-poll-graph-tooltip-'+that.graphData.graphicId)
                      .style("top", (d.ind)*that.y.bandwidth() + (that.y.bandwidth() / 2) +"px")
                      .style("left", "-"+ $('#horizonta-poll-graph-tooltip-'+that.graphData.graphicId).outerWidth() +"px");
                  d3.select('#horizonta-poll-graph-tooltip-content-'+that.graphData.graphicId)
                      .text(d.label);
              }
          })
          .on("mouseout", ()=>{
              if(!this.isModal){
                  d3.select('#horizonta-poll-graph-tooltip-'+that.graphData.graphicId).style("visibility", "hidden");
              }
          });
  }

  saveAsPng(){
    // let documentObject:any = document;
    let that = this;
    that.horizontalPollGraph.disabledSaveAsPng = true;
    that.horizontalPollGraph.disableTooltip = true;
    that.tooltip.hide();
    // let graphSelected = documentObject.querySelector('#horizontal-poll-graph-'+this.horizontalPollGraph.graphData.graphicId);
    // html2canvas(graphSelected)
    html2canvas(this.container.nativeElement.firstChild.firstChild)
      .then(function(canvas) {
          canvas.toBlob((blob:any)=>{
              let data = [new ClipboardItem({ [blob.type]: blob })];
              navigator.clipboard.write(data)
                  .then(()=>{}, (err)=>{})
          });
          that.horizontalPollGraph.copiedTooltip = true;
          that.horizontalPollGraph.disableTooltip = false;
          // if(!that.tooltip.isOpen()){
            that.tooltip.show();
          // }
          setTimeout(()=>{
              that.tooltip.hide();
              that.horizontalPollGraph.copiedTooltip = false;
              that.horizontalPollGraph.disabledSaveAsPng = false;
          }, 3000)
      })
  }
}
