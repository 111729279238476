<div class="common__task-header report-table__reports-header report-table__reports-header--dynamic">
  <div class="report-table__residual-option">
    <!-- <switch-option *ngIf="reportTable.optionSelect ==='graph' && reportTable.showResidualOption"
        [model]="reportTable.residualOption"
        [fieldText]="reportTable.residualOption?'report-table.hide-residual':'report-table.show-residual'"
        (changeOptionEmit)="reportTable.residualOption = $event.model">
    </switch-option> -->
  </div>
  <div>
    <button class="common__button-border-none"
            [ngClass]="{'report-table__disabled-button-graph': archived}"
            [ngShow]="skin!=='gm-law' && configurateReport.excelCharts > 0"
            (click)="changeView()"
            [disabled]="archived">
        <i class="common__icon-button-underlined"
          [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
        <span class="common__text-button-underlined">{{reportTable.optionText}}</span>
    </button>
    <button class="common__button-generic"
            [ngShow]="skin==='gm-law' && configurateReport.excelCharts > 0"
            (click)="changeView()">
        <span class="common__text-button-underlined">{{reportTable.optionText}}</span>
        <i class="common__icon-button"
          [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
    </button>
    <button class="lf-icon-refresh common__button-checked"
            [ngClass]="{'report-table__disabled-button': archived}"
            [ngShow]="hasPermision && skin!=='gm-law'"
            (click)="refreshTable()"
            matTooltip="{{'report-table.refresh' | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above"
            [disabled]="archived">
    </button>
    <button class=" common__button-generic"
            [ngShow]="hasPermision && skin==='gm-law'"
            (click)="refreshTable()">
        <span class="common__text-button-underlined--mrspan"
          [afTranslate]="'report-table.refresh'"></span>
            <i class="lf-icon-refresh common__icon-button"
                [ngClass]="reportTable.optionSelect === 'list'?'lf-icon-analysis':'lf-icon-products'"></i>
    </button>
    <button class="lf-icon-send common__button-checked"
            [ngShow]="skin!=='gm-law' && hasPermision"
            (click)="exportReport()"
            matTooltip="{{'report-table.export'| afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
    </button>
    <button class="common__button-generic"
            [ngShow]="hasPermision && skin==='gm-law'"
            (click)="exportReport()">
        <span class="common__text-button-underlined--mrspan "
          [afTranslate]="'report-table.export'"></span>
        <i class="lf-icon-send common__icon-button"></i>
    </button>
  </div>

</div>
<div class="report-table__table report-table__table--reports"
    horizontal-scroll
    move="moveScroll(scrollX)"
    [ngClass]="{'report-table__table--responsive':configurateReport.type !== 4}"
    [ngHide]="reportTable.optionSelect === 'graph'">
    <!-- <div class="report-table__filter-block"
        [ngShow]="reportTable.listFilter.length">
        <div class="report-table__filter-title-block">
            <i class="lf-icon-settings report-table__filter-title-icon"></i>
            <p class="report-table__filter-title-text"
                [afTranslate]="'common.filters-apply'"></p>
        </div>
        <ul class="report-table__filter-list-items">
            <li class="report-table__filter-item"
                *ngFor="let filter of reportTable.listFilter">
                {{filter.column.name}}: {{filter.text}}
                <i class="lf-icon-close-round-full report-table__filter-item-icon"
                    (click)="deleteFilter(filter)"></i>
            </li>
        </ul>
        <button class="common__button common__button--grey-white"
            (click)="clearAllFilters($event)"
            [afTranslate]="'common.clean'">
            Limpiar
        </button>
    </div> -->
    <!-- Tabla -->
    <div class="report-table__table-container"
        [ngStyle]="{'width': reportTable.newTableWidth}">
        <!-- Cabecera -->
        <div class="report-table__table-header" >
            <div *ngFor="let column of reportTable.report.columns; let first = first">
                <div *ngIf="!(first || column.fieldTypeId === 'options') || (!first && column.fieldTypeId ==='options')"
                    class="report-table__table-col"
                    [ngClass]="{'report-table__action-fixed report-table__action-fixed--header':!first && column.fieldTypeId ==='options'}"
                    [ngStyle]="!first && column.fieldTypeId ==='options' ? {} : {'width':column.displayWidth, 'margin':column.margin}">
                    <div class="report-table__title-column"
                        [ngSwitch]="column.columnType">
                            <filter-by-search *ngSwitchCase="'1'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                (launchActionSearch)="orderBySearch($event, column)">
                            </filter-by-search>
                            <div class="concept-list__table-col concept-list__table-col--modification-query concept-list__table-col--top-2"
                                *ngSwitchCase="'2'"
                                ngbDropdown
                                [id]="column.id"
                                [autoClose]="'outside'">
                                <p class="common__table-header-text common__cursor-pointer report-record-detail__date-text"
                                    matTooltip="{{column.textColumn}}"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    ngbDropdownToggle>{{column.textColumn}}</p>
                                <i class="common__filter-icon lf-icon-filter-1"
                                    [ngClass]="{'common__filter-icon--active lf-icon-filter-applied': column[column.id+'DateFilter']}"
                                    ngbDropdownToggle></i>
                                <div class="concept-list__calendar-dropdown common__block-option-list"
                                    ngbDropdownMenu role="menu">
                                    <from-to-calendar [fromSave]="column[column.id+'DateFromDate']"
                                        [toSave]="column[column.id+'DateToDate']"
                                        (changeCalendar)="changeCalendar($event, column)"
                                        [type]="'report-filter-as-icam'">
                                    </from-to-calendar>
                                </div>
                            </div>
                            <filter-by-list *ngSwitchCase="'3'"
                                [type]="'list'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                [list]="reportTable[column.id+'List']"
                                (filterElementEmit)="selectTypeOperation($event, column)">
                            </filter-by-list>
                            <filter-by-list *ngSwitchCase="'4'"
                                [type]="'listFind'"
                                [item]="column"
                                [textColumn]="'textColumn'"
                                [list]="reportTable[column.id+'List']"
                                (filterElementEmit)="selectTypeOperation($event, column)">
                            </filter-by-list>
                            <div *ngSwitchDefault>
                                <span-tooltip class="common__related-field-item common__table-header-text"
                                [ngClass]="{
                                    'common__table-header-text--right': column.fieldTypeId === 'options',
                                    'common__table-header-text--has-icon': column.canOrder || column.canFilter
                                }"
                                [text]="column.textColumn"></span-tooltip>
                                <i class="common__filter-icon lf-icon-arrow-down"
                                    [ngShow]="column.canOrder"
                                    [ngClass]="{'common__filter-icon--active': reportTable.orderByColumn.name === column.textColumn,
                                        'lf-icon-arrow-up': reportTable.orderByColumn.name === column.textColumn && !reportTable.orderAsc}"
                                    (click)="orderTable(column)">
                                </i>
                            </div>
                            
                    </div>
                </div>
            </div>
        </div>
        <div class="report-table__table-body">
            <div [id]="'row'+index"
                *ngFor="let row of reportTable.reportsListFilter | afOrderBy:reportTable.orderByColumn.id:reportTable.orderAsc; let index = index">
                <div class="report-table__rows-container"
                    [ngClass]="{'report-table__rows-container--disabled': archived}">
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield1.key !== 'OFAFMVJ-42' && row.OFAFMVJfield1.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield1.displayWidth, 'margin': row.OFAFMVJfield1.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield1.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield1.value >0}">
                                    {{row.OFAFMVJfield1.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield1.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield1.key==='tareas' || row.OFAFMVJfield1.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield1.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield1.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield1.fieldTypeId !== 'date'? row.OFAFMVJfield1.value : row.OFAFMVJfield1.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield1.value === 1 && (row.OFAFMVJfield1.fieldTypeId === 'check' && row.OFAFMVJfield1.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield1.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield2.key !== 'OFAFMVJ-42' && row.OFAFMVJfield2.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield2.displayWidth, 'margin': row.OFAFMVJfield2.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield2.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield2.value >0}">
                                    {{row.OFAFMVJfield2.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield2.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield2.key==='tareas' || row.OFAFMVJfield2.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield2.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield2.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield2.fieldTypeId !== 'date'? row.OFAFMVJfield2.value : row.OFAFMVJfield2.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield2.value === 1 && (row.OFAFMVJfield2.fieldTypeId === 'check' && row.OFAFMVJfield2.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield2.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield3.key !== 'OFAFMVJ-42' && row.OFAFMVJfield3.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield3.displayWidth, 'margin': row.OFAFMVJfield3.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield3.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield3.value >0}">
                                    {{row.OFAFMVJfield3.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield3.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield3.key==='tareas' || row.OFAFMVJfield3.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield3.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield3.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield3.fieldTypeId !== 'date'? row.OFAFMVJfield3.value : row.OFAFMVJfield3.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield3.value === 1 && (row.OFAFMVJfield3.fieldTypeId === 'check' && row.OFAFMVJfield3.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield3.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield4.key !== 'OFAFMVJ-42' && row.OFAFMVJfield4.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield4.displayWidth, 'margin': row.OFAFMVJfield4.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield4.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield4.value >0}">
                                    {{row.OFAFMVJfield4.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield4.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield4.key==='tareas' || row.OFAFMVJfield4.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield4.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield4.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield4.fieldTypeId !== 'date'? row.OFAFMVJfield4.value : row.OFAFMVJfield4.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield4.value === 1 && (row.OFAFMVJfield4.fieldTypeId === 'check' && row.OFAFMVJfield4.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield4.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield5.key !== 'OFAFMVJ-42' && row.OFAFMVJfield5.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield5.displayWidth, 'margin': row.OFAFMVJfield5.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield5.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield5.value >0}">
                                    {{row.OFAFMVJfield5.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield5.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield5.key==='tareas' || row.OFAFMVJfield5.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield5.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield5.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield5.fieldTypeId !== 'date'? row.OFAFMVJfield5.value : row.OFAFMVJfield5.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield5.value === 1 && (row.OFAFMVJfield5.fieldTypeId === 'check' && row.OFAFMVJfield5.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield5.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield6.key !== 'OFAFMVJ-42' && row.OFAFMVJfield6.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield6.displayWidth, 'margin': row.OFAFMVJfield6.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield6.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield6.value >0}">
                                    {{row.OFAFMVJfield6.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield6.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield6.key==='tareas' || row.OFAFMVJfield6.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield6.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield6.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield6.fieldTypeId !== 'date'? row.OFAFMVJfield6.value : row.OFAFMVJfield6.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield6.value === 1 && (row.OFAFMVJfield6.fieldTypeId === 'check' && row.OFAFMVJfield6.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield6.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield7.key !== 'OFAFMVJ-42' && row.OFAFMVJfield7.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield7.displayWidth, 'margin': row.OFAFMVJfield7.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield7.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield7.value >0}">
                                    {{row.OFAFMVJfield7.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield7.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield7.key==='tareas' || row.OFAFMVJfield7.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield7.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield7.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield7.fieldTypeId !== 'date'? row.OFAFMVJfield7.value : row.OFAFMVJfield7.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield7.value === 1 && (row.OFAFMVJfield7.fieldTypeId === 'check' && row.OFAFMVJfield7.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield7.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield8.key !== 'OFAFMVJ-42' && row.OFAFMVJfield8.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield8.displayWidth, 'margin': row.OFAFMVJfield8.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield8.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield8.value >0}">
                                    {{row.OFAFMVJfield8.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield8.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield8.key==='tareas' || row.OFAFMVJfield8.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield8.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield8.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield8.fieldTypeId !== 'date'? row.OFAFMVJfield8.value : row.OFAFMVJfield8.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield8.value === 1 && (row.OFAFMVJfield8.fieldTypeId === 'check' && row.OFAFMVJfield8.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield8.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield9.key !== 'OFAFMVJ-42' && row.OFAFMVJfield9.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield9.displayWidth, 'margin': row.OFAFMVJfield9.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield9.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield9.value >0}">
                                    {{row.OFAFMVJfield9.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield9.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield9.key==='tareas' || row.OFAFMVJfield9.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield9.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield9.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield9.fieldTypeId !== 'date'? row.OFAFMVJfield9.value : row.OFAFMVJfield9.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield9.value === 1 && (row.OFAFMVJfield9.fieldTypeId === 'check' && row.OFAFMVJfield9.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield9.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield10.key !== 'OFAFMVJ-42' && row.OFAFMVJfield10.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield10.displayWidth, 'margin': row.OFAFMVJfield10.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield10.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield10.value >0}">
                                    {{row.OFAFMVJfield10.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield10.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield10.key==='tareas' || row.OFAFMVJfield10.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield10.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield10.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield10.fieldTypeId !== 'date'? row.OFAFMVJfield10.value : row.OFAFMVJfield10.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield10.value === 1 && (row.OFAFMVJfield10.fieldTypeId === 'check' && row.OFAFMVJfield10.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield10.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield11.key !== 'OFAFMVJ-42' && row.OFAFMVJfield11.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield11.displayWidth, 'margin': row.OFAFMVJfield11.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield11.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield11.value >0}">
                                    {{row.OFAFMVJfield11.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield11.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield11.key==='tareas' || row.OFAFMVJfield11.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield11.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield11.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield11.fieldTypeId !== 'date'? row.OFAFMVJfield11.value : row.OFAFMVJfield11.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield11.value === 1 && (row.OFAFMVJfield11.fieldTypeId === 'check' && row.OFAFMVJfield11.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield11.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield12.key !== 'OFAFMVJ-42' && row.OFAFMVJfield12.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield12.displayWidth, 'margin': row.OFAFMVJfield12.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield12.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield12.value >0}">
                                    {{row.OFAFMVJfield12.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield12.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield12.key==='tareas' || row.OFAFMVJfield12.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield12.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield12.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield12.fieldTypeId !== 'date'? row.OFAFMVJfield12.value : row.OFAFMVJfield12.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield12.value === 1 && (row.OFAFMVJfield12.fieldTypeId === 'check' && row.OFAFMVJfield12.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield12.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield13.key !== 'OFAFMVJ-42' && row.OFAFMVJfield13.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield13.displayWidth, 'margin': row.OFAFMVJfield13.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield13.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield13.value >0}">
                                    {{row.OFAFMVJfield13.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield13.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield13.key==='tareas' || row.OFAFMVJfield13.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield13.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield13.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield13.fieldTypeId !== 'date'? row.OFAFMVJfield13.value : row.OFAFMVJfield13.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield13.value === 1 && (row.OFAFMVJfield13.fieldTypeId === 'check' && row.OFAFMVJfield13.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield13.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield14.key !== 'OFAFMVJ-42' && row.OFAFMVJfield14.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield14.displayWidth, 'margin': row.OFAFMVJfield14.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield14.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield14.value >0}">
                                    {{row.OFAFMVJfield14.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield14.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield14.key==='tareas' || row.OFAFMVJfield14.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield14.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield14.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield14.fieldTypeId !== 'date'? row.OFAFMVJfield14.value : row.OFAFMVJfield14.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield14.value === 1 && (row.OFAFMVJfield14.fieldTypeId === 'check' && row.OFAFMVJfield14.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield14.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield15.key !== 'OFAFMVJ-42' && row.OFAFMVJfield15.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield15.displayWidth, 'margin': row.OFAFMVJfield15.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield15.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield15.value >0}">
                                    {{row.OFAFMVJfield15.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield15.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield15.key==='tareas' || row.OFAFMVJfield15.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield15.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield15.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield15.fieldTypeId !== 'date'? row.OFAFMVJfield15.value : row.OFAFMVJfield15.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield15.value === 1 && (row.OFAFMVJfield15.fieldTypeId === 'check' && row.OFAFMVJfield15.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield15.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield16.key !== 'OFAFMVJ-42' && row.OFAFMVJfield16.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield16.displayWidth, 'margin': row.OFAFMVJfield16.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield16.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield16.value >0}">
                                    {{row.OFAFMVJfield16.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield16.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield16.key==='tareas' || row.OFAFMVJfield16.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield16.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield16.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield16.fieldTypeId !== 'date'? row.OFAFMVJfield16.value : row.OFAFMVJfield16.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield16.value === 1 && (row.OFAFMVJfield16.fieldTypeId === 'check' && row.OFAFMVJfield16.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield16.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield17.key !== 'OFAFMVJ-42' && row.OFAFMVJfield17.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield17.displayWidth, 'margin': row.OFAFMVJfield17.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield17.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield17.value >0}">
                                    {{row.OFAFMVJfield17.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield17.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield17.key==='tareas' || row.OFAFMVJfield17.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield17.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield17.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield17.fieldTypeId !== 'date'? row.OFAFMVJfield17.value : row.OFAFMVJfield17.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield17.value === 1 && (row.OFAFMVJfield17.fieldTypeId === 'check' && row.OFAFMVJfield17.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield17.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield18.key !== 'OFAFMVJ-42' && row.OFAFMVJfield18.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield18.displayWidth, 'margin': row.OFAFMVJfield18.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield18.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield18.value >0}">
                                    {{row.OFAFMVJfield18.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield18.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield18.key==='tareas' || row.OFAFMVJfield18.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield18.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield18.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield18.fieldTypeId !== 'date'? row.OFAFMVJfield18.value : row.OFAFMVJfield18.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield18.value === 1 && (row.OFAFMVJfield18.fieldTypeId === 'check' && row.OFAFMVJfield18.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield18.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield19.key !== 'OFAFMVJ-42' && row.OFAFMVJfield19.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield19.displayWidth, 'margin': row.OFAFMVJfield19.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield19.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield19.value >0}">
                                    {{row.OFAFMVJfield19.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield19.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield19.key==='tareas' || row.OFAFMVJfield19.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield19.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield19.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield19.fieldTypeId !== 'date'? row.OFAFMVJfield19.value : row.OFAFMVJfield19.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield19.value === 1 && (row.OFAFMVJfield19.fieldTypeId === 'check' && row.OFAFMVJfield19.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield19.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield20.key !== 'OFAFMVJ-42' && row.OFAFMVJfield20.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield20.displayWidth, 'margin': row.OFAFMVJfield20.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield20.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield20.value >0}">
                                    {{row.OFAFMVJfield20.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield20.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield20.key==='tareas' || row.OFAFMVJfield20.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield20.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield20.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield20.fieldTypeId !== 'date'? row.OFAFMVJfield20.value : row.OFAFMVJfield20.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield20.value === 1 && (row.OFAFMVJfield20.fieldTypeId === 'check' && row.OFAFMVJfield20.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield20.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield21.key !== 'OFAFMVJ-42' && row.OFAFMVJfield21.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield21.displayWidth, 'margin': row.OFAFMVJfield21.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield21.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield21.value >0}">
                                    {{row.OFAFMVJfield21.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield21.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield21.key==='tareas' || row.OFAFMVJfield21.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield21.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield21.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield21.fieldTypeId !== 'date'? row.OFAFMVJfield21.value : row.OFAFMVJfield21.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield21.value === 1 && (row.OFAFMVJfield21.fieldTypeId === 'check' && row.OFAFMVJfield21.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield21.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield22.key !== 'OFAFMVJ-42' && row.OFAFMVJfield22.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield22.displayWidth, 'margin': row.OFAFMVJfield22.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield22.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield22.value >0}">
                                    {{row.OFAFMVJfield22.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield22.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield22.key==='tareas' || row.OFAFMVJfield22.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield22.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield22.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield22.fieldTypeId !== 'date'? row.OFAFMVJfield22.value : row.OFAFMVJfield22.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield22.value === 1 && (row.OFAFMVJfield22.fieldTypeId === 'check' && row.OFAFMVJfield22.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield22.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield23.key !== 'OFAFMVJ-42' && row.OFAFMVJfield23.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield23.displayWidth, 'margin': row.OFAFMVJfield23.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield23.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield23.value >0}">
                                    {{row.OFAFMVJfield23.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield23.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield23.key==='tareas' || row.OFAFMVJfield23.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield23.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield23.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield23.fieldTypeId !== 'date'? row.OFAFMVJfield23.value : row.OFAFMVJfield23.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield23.value === 1 && (row.OFAFMVJfield23.fieldTypeId === 'check' && row.OFAFMVJfield23.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield23.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield24.key !== 'OFAFMVJ-42' && row.OFAFMVJfield24.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield24.displayWidth, 'margin': row.OFAFMVJfield24.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield24.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield24.value >0}">
                                    {{row.OFAFMVJfield24.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield24.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield24.key==='tareas' || row.OFAFMVJfield24.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield24.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield24.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield24.fieldTypeId !== 'date'? row.OFAFMVJfield24.value : row.OFAFMVJfield24.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield24.value === 1 && (row.OFAFMVJfield24.fieldTypeId === 'check' && row.OFAFMVJfield24.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield24.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield25.key !== 'OFAFMVJ-42' && row.OFAFMVJfield25.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield25.displayWidth, 'margin': row.OFAFMVJfield25.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield25.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield25.value >0}">
                                    {{row.OFAFMVJfield25.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield25.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--{{skin}} report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield25.key==='tareas' || row.OFAFMVJfield25.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield25.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield25.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield25.fieldTypeId !== 'date'? row.OFAFMVJfield25.value : row.OFAFMVJfield25.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield25.value === 1 && (row.OFAFMVJfield25.fieldTypeId === 'check' && row.OFAFMVJfield25.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield25.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__table-col"
                        *ngIf="row.OFAFMVJfield26.key !== 'OFAFMVJ-42' && row.OFAFMVJfield26.value !== 'OFAFMVJ-42'"
                        [ngStyle]="{'width': row.OFAFMVJfield26.displayWidth, 'margin': row.OFAFMVJfield26.margin}">
                        <div class="report-table__table-row" >
                            <button class="report-table__button-task"
                                [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                (click)="relatedConcept(row, 'task')"
                                [disabled]="row.OFAFMVJfield0.value === 0">
                                <i class="lf-icon-task report-table__task"
                                    [ngShow]="row.OFAFMVJfield0.key==='tareas'"
                                    [ngClass]="{' report-table__task--with-task': row.OFAFMVJfield26.value >0}">
                                </i>
                                <i class="report-table__icon-numeric report-table__number"
                                    [ngClass]="{' report-table__number--zero': row.OFAFMVJfield26.value >0}">
                                    {{row.OFAFMVJfield26.value}}
                                </i>
                            </button>
                            <div *ngIf="row.OFAFMVJfield26.fieldTypeId !== 'check'"
                                class="report-table__hightlight">
                                <span class="report-table__table-body-header report-table__table-body-header--report"
                                    [ngHide]="row.OFAFMVJfield26.key==='tareas' || row.OFAFMVJfield26.value === 'check'"
                                    [ngStyle]="{'background-color': row.OFAFMVJfield26.backgroundColor}"
                                    [ngClass]="{'report-table__colors': row.OFAFMVJfield26.hasColor}">
                                    <span>
                                        {{row.OFAFMVJfield26.fieldTypeId !== 'date'? row.OFAFMVJfield26.value : row.OFAFMVJfield26.value | reportExtractIso:reportTable.report.columns}}
                                    </span>
                                </span>
                            </div>
                            <i class="lf-icon-check-round-full report-table__icon-check"
                                [ngShow]="row.OFAFMVJfield26.value === 1 && (row.OFAFMVJfield26.fieldTypeId === 'check' && row.OFAFMVJfield26.hasOpacity)"
                                [ngStyle]="{'color':row.OFAFMVJfield26.backgroundIconColor}">
                            </i>
                        </div>
                    </div>
                    <div class="report-table__action-fixed"
                        [ngStyle]="{ 'height':initHeightColumn('row' + index) + 'px'}">
                        <div class="report-table__text-options report-table__menu-action">
                            <div class="report-table__options-block">
                                <ul class="report-table__options report-table__options--reports">
                                    <li class="report-table__menu-icons report-table__menu-icons--edit"
                                        (click)="relatedConcept(row, 'concept')">
                                        <i class="lf-icon-open-window"
                                            matTooltip="{{reportTable.openTooltip}}"
                                            matTooltipPosition="above"
                                            matTooltipClass="above">
                                        </i>
                                    </li>
                                </ul>
                            </div>
                            <span class="lf-icon-kebab-menu report-table__ellipsis-icon"
                                [ngClass]="{'report-table__ellipsis-icon--disabled':archived}"></span>
                        </div>
                    </div>
                  </div>
            </div>

            <div [ngShow]="reportTable.openTasks"
                class="report-table__tasks">
                <div class="report-table__title-task">{{reportTable.titleTask}}
                    <i class="lf-icon-close report-table__close-icon"
                        (click)="reportTable.openTasks = !reportTable.openTasks"></i>
                </div>
                <div class="report-table__subtitle-task"
                    [afTranslate]="'report-table.relationed-tasks'">TAREAS RELACIONADAS CON LA IMPLEMENTACIÓN DE ESTA MEDIDA</div>
                <!-- <task-report></task-report> -->
            </div>
        </div>
    </div>
</div>
<report-graph *ngIf="reportTable.optionSelect === 'graph'"
    [report]="reportTable.report"
    [showResidualOption]="reportTable.showResidualOption"
    [configurateReport]="configurateReport">
</report-graph>
