<div class="customize-field-num">
  <div class="common__header-textarea"
      [ngClass]="{'common__input-no-editable': concept.disabled}">
      <span class="task-edit__text-description-title"
          [afTranslate]="'common.title'">Titulo</span>
  </div>
  <textarea class="common__textarea-simple"
      [ngClass]="{'common__input-no-editable': concept.disabled}"
      [(ngModel)]="customizeFieldNum.field.extendedLabel"
      [disabled]="concept.disabled"
      (blur)="modifyField()">
  </textarea>
  <div class="common__header-textarea"
      [ngClass]="{'common__input-no-editable': concept.disabled}">
      <span class="task-edit__text-description-title"
          [afTranslate]="'common.description'">Descripción</span>
  </div>
  <rich-editor [tinyMceOptions]="customizeFieldNum.tinyMceOptions"
    [modelText]="customizeFieldNum.field.description"
    (emitValue)="getEditorValue($event)">
  </rich-editor>
  <div class="customize-field-text__field-text">
      <field-type-list-simple [field]="customizeFieldNum.contentReportInfo"
          [preSelectItem]="customizeFieldNum.field.reportFieldId"
          [listValues]="reportList"
          (itemSelected)="itemSelected($event, 'reportFieldId')">
      </field-type-list-simple>
  </div>
  <div class="customize-field-text__field-text">
      <visibility class="field-item__input"
          *ngIf="concept.poll"
          [items]="visibilityItems"
          [type]="'field'"
          [concept]="concept"
          [selectedProperties]="customizeFieldNum.field.propertyVisibleValues"
          [selectedListValues]="customizeFieldNum.field.listVisibleValues"
          [selectedFields]="customizeFieldNum.field.fieldVisibleValues"
          [orCondition]="customizeFieldNum.field.visibilityOr"
          [readonly]="customizeFieldNum.field.noEditable || concept.disabled"
          [itemId]="customizeFieldNum.field.fieldId"
          (visibilityOpen)="openVisibility($event)">
      </visibility>
  </div>
  <div class="customize-field-text__field-text">
      <field-type-number [field]="customizeFieldNum.decimalsInfo"
          [preSelectItem]="customizeFieldNum.field.decimals"
          (itemSelected)="itemSelected($event, 'decimals')">
      </field-type-number>
  </div>
  <div class="customize-field-text__field-text">
      <field-type-number [field]="customizeFieldNum.defaultValueInfo"
          [preSelectItem]="customizeFieldNum.field.defaultValue"
          [decimals]="customizeFieldNum.field.decimals"
          (itemSelected)="itemSelected($event, 'defaultValue')">
      </field-type-number>
  </div>
  <switch-option [ngShow]="concept.allowPublicShare"
      [tooltipText]="'customize.private-help'"
      [model]="isPrivate?isPrivate:field.private"
      [disabled]="concept.disabled || isPrivate || customizeFieldNum.field.isIdentifier"
      [fieldText]="'customize.private'"
      (changeOptionEmit)="changeOptionOn($event, 'private')">
  </switch-option>
  <switch-option [ngHide]="concept.allowPublicShare"
      [tooltipText]="'customize.hidden-landing-help'"
      [model]="hiddenInLandings?hiddenInLandings:field.hiddenInLandings"
      [disabled]="hiddenInLandings"
      [fieldText]="'customize.hidden-landing'"
      (changeOptionEmit)="changeOptionOn($event, 'hiddenInLandings')">
  </switch-option>
  <switch-option
      [model]="customizeFieldNum.field.required"
      [disabled]="customizeFieldNum.field.noEditable || concept.disabled || customizeFieldNum.field.isIdentifier"
      [fieldText]="'customize.required'"
      (changeOptionEmit)="changeOptionOn($event, 'required')">
  </switch-option>

  <div class="common__buttons-block common__buttons-block--customize">
      <button type="reset"
          class="common__button common__button--clean"
          (click)="cancelFieldText()"
          [disabled]="concept.disabled"
          [afTranslate]="'common.cancel'">Cancelar </button>
      <button type="submit"
          class="common__button common__button--active"
          (click)="saveFieldText()"
          [disabled]="concept.disabled"
          [afTranslate]="'common.save'">Guardar </button>
  </div>
</div>
