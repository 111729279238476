<div class="predefined-templates__template-box"
    [ngClass]="{'predefined-templates__template-box--edit-comment': boxCommunicationChannel.edit && !boxCommunicationChannel.fullScreen,
    'predefined-templates__template-box--min-height':!boxCommunicationChannel.commentListFilter?.length && !boxCommunicationChannel.showCommentBox,
    'predefined-templates__template-box--max-height':boxCommunicationChannel.commentListFilter?.length && !boxCommunicationChannel.showCommentBox,
    'predefined-templates__template-box--expand': boxCommunicationChannel.fullScreen}">
    <div class="concept-legal-documentation__border-box"></div>
    <div class="box-comments__container-icons">
        <i class="lf-icon-open-window predefined-templates__change-view-icon "
            [ngClass]="boxCommunicationChannel.fullScreen?'lf-icon-collapse-panel':'lf-icon-open-window'"
            (click)="maximizeScreen()"
            matTooltip="{{(boxCommunicationChannel.fullScreen?'common.fold':'common.deploy') | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
        <i class="lf-icon-download box-comments__add-icon"
          [ngShow]="boxCommunicationChannel.commentListFilter?.length"
          (click)="downloadPdf()"
          matTooltip="{{'box-comunication-channel.download-pdf' | afTranslate}}"
          matTooltipPosition="above"
          matTooltipClass="above"
          [matTooltipDisabled]="boxCommunicationChannel.showCommentBox"></i>
        <i class="lf-icon-add-round box-comments__add-icon"
            [ngShow]="(boxCommunicationChannel.hasPermissions && boxCommunicationChannel.conceptObjectId) && !boxCommunicationChannel.concept.anonymized"
            (click)="newComment()"
            matTooltip="{{'box-comunication-channel.new-message' | afTranslate}}"
            matTooltipPosition="above"
            matTooltipClass="above">
        </i>
        <button class="box-comunication-channel__info-block box-comunication-channel__info-block--default"
          *ngIf="boxCommunicationChannel.unreadAmountApplicant > 0">
          <i class="lf-icon-chat box-comunication-channel__info-icon"></i>
          <span
            class="box-comunication-channel__count-number box-comunication-channel__count-number--default">
            {{boxCommunicationChannel.unreadAmountApplicant}}
          </span>
        </button>
    </div>
    <div class="box-comunication-channel__template-header">
        <i class="lf-icon-communication box-comunication-channel__header-icon"></i>
    </div>
    <p class="box-comunication-channel__block-text"
        [afTranslate]="'box-comunication-channel.message-system'">_Sistema de mensajería</p>
    <p class="predefined-templates__block-empty"
        [ngShow]="!boxCommunicationChannel.commentList.length && !boxCommunicationChannel.showCommentBox"
        [afTranslate]="'box-comunication-channel.no-messages-between'">__No hay mensajes entre el informante y el responsable del canal</p>

    <div class="box-comments__add-comment-block "
        [ngShow]="boxCommunicationChannel.showCommentBox &&
            (boxCommunicationChannel.hasPermissions && boxCommunicationChannel.conceptObjectId) && !boxCommunicationChannel.concept.anonymized">
        <div *ngIf="!boxCommunicationChannel.disabledButtons">
          <div class="box-comunication-channel__new-mesaje-header-block">
            <p class="box-comunication-channel__add-comment-title"
            [afTranslate]="'box-comunication-channel.new-message'">__Nuevo mensaje</p>
            <div>
              <button class="box-comunication-channel__text-link"
                *ngIf="boxCommunicationChannel.hasVoiceRecord"
                (click)="listenVoiceRecord()">
                {{boxCommunicationChannel.voiceRecord.name}}
              </button>
              <button class="box-comunication-channel__voice-recorder-button"
              [ngShow]="skin === 'dark-blue' || skin === 'gm-law'"
              (click)="openRecordVoice()">
                <i class="lf-icon-microphone box-comunication-channel__voice-recorder-icon"></i>
              </button>
            </div>
          </div>
          <textarea
              class="box-comments__add-comment-text"
              [(ngModel)]="boxCommunicationChannel.commentText"
              (keyup)="editTextArea()">
          </textarea>
          <div class="box-comments__buttons-block">
            <button class="common__button common__button--clean common__button--litle"
              [ngClass]="{'common__button--disabled-opacity': boxCommunicationChannel.disabledButtons}"
              (click)="closeComment()"
              [disabled]="boxCommunicationChannel.disabledButtons"
              [afTranslate]="'common.cancel'">
              _Cancel
            </button>
            <button class="common__button common__button--active common__button--litle"
              [ngClass]="{'common__button--disabled-opacity': boxCommunicationChannel.disabledButtons}"
              [disabled]="boxCommunicationChannel.disabledButtons"
              (click)="createNewComment(boxCommunicationChannel.commentText)"
              [afTranslate]="'common.send'">
              _enviar
            </button>
          </div>
        </div>
        <div class="box-comunication-channel__spinner-center"
          *ngIf="boxCommunicationChannel.disabledButtons">
          <div class="record-audio-modal__animation">
            <i class="lf-icon-refresh record-audio-modal__apply-changes-icon"></i>
          </div>
          <p class="box-comunication-channel__spinner-center-text"
            [afTranslate]="'box-comunication-channel.send-messages'">_Enviando Mensaje</p>
        </div>
    </div>

    <ul class="box-comunication-channel__comment-list"
        [ngShow]="boxCommunicationChannel.showCommentList && boxCommunicationChannel.commentListFilter?.length"
        [ngClass]="{'box-comunication-channel__comment-list--full-add-comment': boxCommunicationChannel.fullScreen && boxCommunicationChannel.showCommentBox,
            'box-comunication-channel__comment-list--full-no-comment': boxCommunicationChannel.fullScreen && !boxCommunicationChannel.showCommentBox}">
        <li class="box-comunication-channel__list-item"
            [ngClass]="{'box-comunication-channel__list-item--hovered':comments.unread && !comments.managerMessage}"
            *ngFor="let comments of boxCommunicationChannel.commentListFilter | afOrderBy:'creationDate':true | afLimitTo:boxCommunicationChannel.fullScreen?boxCommunicationChannel.commentListFilter?.length:1;">
            <div class="box-comunication-channel__box-icon-left"
                [ngClass]="{'box-comunication-channel__box-icon-left--manage': comments.managerMessage}">
                <i [ngClass]="comments.managerMessage?'lf-icon-defendant':'lf-icon-requester'"></i>
            </div>
             <div class="predefined-templates__template-menu"
                [ngShow]="comments.unread && !comments.managerMessage"
                (click)="markAsRead(comments)">
                <i class="lf-icon-check-round predefined-templates__writing-icon box-comunication-channel__check-icon"
                    matTooltip="{{'box-comunication-channel.check-read' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                </i>
            </div>
            <div >
                <i class="icon-check-round"></i>
            </div>
            <div class="box-comunication-channel__block-header-comment"
                [ngClass]="{'box-comunication-channel__block-header-comment--manage': comments.managerMessage}">
                <button class="box-comunication-channel__audio-block"
                  *ngIf=" comments.showAudioRecord && !boxCommunicationChannel.concept.anonymized"
                  (click)="listenVoiceRecord(comments)">
                  <i class="lf-icon-sound box-comunication-channel__audio-icon"></i>
                </button>
                <p class="box-comunication-channel__paragraph">
                    <span class="box-comunication-channel__space-between">{{comments.creationDate | convertDate: 'without-time' }}</span>
                    <span>{{comments.creationDate | convertDate: 'only-time' }}</span>
                </p>
                <p class="box-comunication-channel__author box-comunication-channel__author--default"
                    [afTranslate]="comments.managerMessage?'box-comunication-channel.manager':'box-comunication-channel.applicant'"></p>
                <p class="box-comunication-channel__read-by"
                  *ngIf="skinsAccepted && comments.readTime !== null && comments.managerMessage">
                  <span [afTranslate]="'box-comunication-channel.message-read'"></span>
                  <span>{{ comments.readTime | convertDate:'without-time-and-hour'}} h</span>
                  <i class="lf-icon-visible box-comunication-channel__read-by-icon"></i>
                </p>
                <div *ngIf="comments.unread && !comments.managerMessage"
                  class="box-comunication-channel__box-new">
                  <i class="lf-icon-step-final box-comunication-channel__new-advise-dot"></i>
                  <span class="box-comunication-channel__new-advise-text"
                    [afTranslate]="'box-comunication-channel.new'">
                  </span>
                </div>
            </div>
            <p class="box-comments__comment-text box-comments__comment-text--communication-channel"
                [ngClass]="{'box-comments__comment-text--only-last': !boxCommunicationChannel.fullScreen,
                    'box-comments__comment-text--full-screen': boxCommunicationChannel.fullScreen}"
                [ngShow]="!comments.edit"
                [innerHTML]="comments.commentText">
            </p>
            <button class="box-comunication-channel__text-link"
              *ngIf="comments.showAudioRecord && !boxCommunicationChannel.concept.anonymized"
              (click)="listenVoiceRecord(comments)">
              {{comments.name}}
            </button>
            <div
              *ngIf="comments.commentAudio == null && comments.audioFileName != null"
              class="field-type-text__transcription-block field-type-text__transcription-block--box-communication">
              <p class="field-type-text__transcription-text field-type-text__transcription-text--box-communication"
                [afTranslate]="'record-audio-modal.audio-transcription'"></p>
            </div>
            <div class="box-comments__edit-comment"
                [ngShow]="comments.edit">
                <textarea [id]="'edit-comment-'+comments.commentId"
                    class="box-comments__add-comment-text"
                    [(ngModel)]="comments.commentTextarea">
                </textarea>
                <div class="box-comments__buttons-block">
                    <button class="common__button common__button--clean common__button--litle"
                        (click)="cancelComment(comments)"
                        [afTranslate]="'common.cancel'">_Cancelar</button>
                    <button class="common__button common__button--active common__button--litle"
                        (click)="changeComment(comments)"
                        [afTranslate]="'common.save'">_Guardar</button>
                </div>
            </div>
            <button class="box-comments__read-more-block"
                (click)="maximizeScreen()"
                [ngShow]="boxCommunicationChannel.commentList.length > 1 && !boxCommunicationChannel.fullScreen">
                <i class="lf-icon-visible box-comments__read-more-icon"></i>
                <span class="box-comments__read-more-text"
                    [afTranslate]="'comments.read-more'">Leer más</span>
            </button>
        </li>
    </ul>
</div>

<div class="common__pdf-hidde-element">
  <div [id]="'pdf-historic-communication-channel'">
    <pdf-header
      [newDesign]="true"
      [pdfInfo]="boxCommunicationChannel.pdfInfo"
      [datePdf]="boxCommunicationChannel.configPdf?.exportDate"></pdf-header>
    <div class="common__pdf-body-figma">
      <div class="common__pdf-title-block-figma">
          <span>{{concept.evaluationName}}</span>
      </div>
      <div class="box-comunication-channel__container-list-pdf">
        <div class="box-comunication-channel__element-list-pdf"
          *ngFor="let commentsPdf of boxCommunicationChannel.commentListFilter | afOrderBy:'creationDate':true">
          <div class="box-comunication-channel__element-header-pdf">
            <span class="box-comunication-channel__element-manager-pdf"
              [afTranslate]="commentsPdf.managerMessage?'box-comunication-channel.manager':'box-comunication-channel.applicant'"></span>
            <span>{{commentsPdf.creationDate | convertDate: 'without-time-and-hour' }}</span>
          </div>
          <div class="box-comunication-channel__element-message-pdf">
            " <span [innerHTML]="commentsPdf.commentText"></span> "
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
