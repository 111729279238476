//Modules
import { QRCodeModule } from 'angularx-qrcode';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app.routing.module';
import { NgPipesModule } from 'ngx-pipes';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatTooltipModule} from '@angular/material/tooltip';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { DragulaModule } from 'ng2-dragula';
import { AngularCropperjsModule } from 'angular-cropperjs';

//Loaders
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Pipes
import { ArrayPipe } from './shared/filters/array/array.pipe';
import { ByWordPipe } from './shared/filters/by-word/by-word.pipe';
import { ByWordChildrenPipe } from '@shared/filters/by-word-children/by-word-children.pipe';
import { ConvertBytesPipe } from './shared/filters/convert-bytes/convert-bytes.pipe';
import { ConvertDatePipe } from './shared/filters/convert-date/convert-date.pipe';
import { CreatorLoggedPipe } from './shared/filters/creator-logged/creator-logged.pipe';
import { DistinctPropertyPipe } from './shared/filters/distinct-property/distinct-property.pipe';
import { ExpiredPipe } from './shared/filters/expired/expired.pipe';
import { ExtensionDocumentPipe } from './shared/filters/extension-document/extension-document.pipe';
import { FieldTypeIconsPipe } from './shared/filters/field-type-icons/field-type-icons.pipe';
import { FilteredByPipe } from './shared/filters/filtered-by/filtered-by.pipe';
import { HiddenPipe } from './shared/filters/hidden/hidden.pipe';
import { OneMorePipe } from './shared/filters/one-more/one-more.pipe';
import { RelativeTimePipe } from './shared/filters/relative-time/relative-time.pipe';
import { ReportExtractIsoPipe } from './shared/filters/report-extract-iso/report-extract-iso.pipe';
import { SetTextColorsPipe } from './shared/filters/set-text-color/set-text-colors.pipe';
import { TaskTypeIconsPipe } from './shared/filters/task-type-icons/task-type-icons.pipe';
import { TrustUrlPipe } from './shared/filters/trust-url/trust-url.pipe';
import { UniquePipe } from './shared/filters/unique/unique.pipe';
import { SliceArrayPipe } from '@shared/filters/slice-array/slice-array.pipe';
import { ColorsPipe } from '@shared/filters/colors/colors.pipe';
import { AfOrderByPipe } from '@shared/filters/af-order-by/af-order-by.pipe';
import { AfTranslatePipe } from '@shared/modules/translate/pipe/af-translate.pipe';
import { AddZeroPipe } from '@shared/filters/add-zero/add-zero.pipe';
import { OnlyToShowPipe } from '@shared/filters/only-to-show/only-to-show.pipe';
import { AfLimitToPipe } from '@shared/filters/af-limit-to/af-limit-to.pipe';
import { TrustHtmlPipe } from '@shared/filters/trust-html/trust-html.pipe';
import { AfSiteTermsPipe } from '@shared/modules/translate/pipe/af-siteTerms.pipe';

//Directives
import { AfTranslateDirective } from '@shared/modules/translate/directive/af-translate.directive';
import { NgShowDirective } from '@shared/directives/ng-show/ng-show.directive';
import { NgHideDirective } from '@shared/directives/ng-hide/ng-hide.directive';
import { ResizeWindowDirective } from '@shared/directives/resize-window/resize-window.directive';
import { ScrollConceptDirective } from '@shared/directives/scroll-concept/scroll-concept.directive';
import { DropZoneDirective } from '@shared/directives/drop-zone/drop-zone.directive';
import { FocusWhenDirective } from '@shared/directives/focus-when/focus-when.directive';
import { ValidateNumberDirective } from '@shared/directives/validate-number/validate-number.directive';
import { NgMouseWheelDirective } from '@shared/directives/ng-mouse-wheel/ng-mouse-wheel.directive';
import { AfSiteTermsDirective } from '@shared/modules/translate/directive/af-SiteTerms.directive';

//Components
import { AppComponent } from './app.component';
import { FrontLoginComponent } from '@core/components/front-login/front-login/front-login.component';
import { FrontLoginCustomComponent } from '@core/components/front-login/front-login-custom/front-login-custom.component';
import { DashboardViewComponent } from '@core/components/dashboard-view/dashboard-view.component';
import { DashboardPanelComponent } from '@core/components/dashboard-panel/dashboard-panel-default/dashboard-panel.component';
import { ComplianceFooterComponent } from '@shared/components/compliance-footer/compliance-footer.component';
import { HeaderPollComponent } from '@shared/components/header-poll/header-poll.component';
import { HeaderNavComponent } from '@shared/components/header-nav/header-nav.component';
import { AdviceModalComponent } from '@shared/components/advice-modal/advice-modal-default/advice-modal.component';
import { AdviceModalCustomComponent } from '@shared/components/advice-modal/advice-modal-custom/advice-modal-custom.component';
import { ExportModalComponent } from '@shared/components/export-modal/export-modal.component';
import { ExportFormatModalComponent } from '@shared/components/export-format-modal/export-format-modal.component';
import { ImportModalComponent } from '@shared/components/import-modal/import-modal.component';
import { ClientsListComponent } from '@core/components/clients-list/clients-list-default/clients-list.component';
import { ClientsListUkComponent } from '@core/components/clients-list/clients-list-uk/clients-list-uk.component';
import { ClientDetailsComponent } from '@core/components/client-details/client-details.component';
import { EvaluationsListComponent } from '@core/components/evaluations-list/evaluations-list.component';
import { InformativeLoadingModalComponent } from '@shared/components/informative-loading-modal/informative-loading-modal.component';
import { NewEvaluationComponent } from '@core/components/new-evaluation/new-evaluation.component';
import { FilialsComponent } from '@shared/components/filials/filials.component';
import { WizardStepsComponent } from '@shared/components/wizard-steps/wizard-steps.component';
import { ExpandedTextareaComponent } from '@shared/components/expanded-textarea/expanded-textarea.component';
import { FieldTypeBoolComponent } from '@shared/components/field-type-bool/field-type-bool/field-type-bool.component';
import { FieldTypeBoolCustomComponent } from '@shared/components/field-type-bool/field-type-bool-custom/field-type-bool-custom.component';
import { FieldTypePasswordComponent } from '@shared/components/field-type-password/field-type-password.component';
import { FieldTypeNumberComponent } from '@shared/components/field-type-number/field-type-number.component';
import { FieldTypeLogoComponent } from '@shared/components/field-type-logo/field-type-logo.component';
import { FieldTypeTextSimpleComponent } from '@shared/components/field-type-text/field-type-text-simple/field-type-text-simple.component';
import { FieldTypeTextMultilineComponent } from '@shared/components/field-type-text/field-type-text-multiline/field-type-text-multiline.component';
import { FieldTypeListSimpleComponent } from '@shared/components/field-type-list/field-type-list-simple/field-type-list-simple.component';
import { FieldTypeListMultipleComponent } from '@shared/components/field-type-list/field-type-list-multiple/field-type-list-multiple.component';
import { FieldTypeListNumericalComponent } from '@shared/components/field-type-list/field-type-list-numerical/field-type-list-numerical.component';
import { FieldTypeListButtonComponent } from '@shared/components/field-type-list/field-type-list-button/field-type-list-button.component';
import { FieldTypeListHighlightComponent } from '@shared/components/field-type-list/field-type-list-highlight/field-type-list-highlight.component';
import { FieldTypeListTreeComponent } from '@shared/components/field-type-list/field-type-list-tree/field-type-list-tree.component';
import { FieldTypeClientsSelectorComponent } from '@shared/components/field-type-clients-selector/field-type-clients-selector.component';
import { FieldTypeContactsSimpleComponent } from '@shared/components/field-type-contacts/field-type-contacts-simple/field-type-contacts-simple.component';
import { FieldTypeContactsMultipleComponent } from '@shared/components/field-type-contacts/field-type-contacts-multiple/field-type-contacts-multiple.component';
import { FieldTypeContactsSimpleCustomComponent } from '@shared/components/field-type-contacts/field-type-contacts-simple-custom/field-type-contacts-simple-custom.component';
import { FieldTypeContactsMultipleCustomComponent } from '@shared/components/field-type-contacts/field-type-contacts-multiple-custom/field-type-contacts-multiple-custom.component';
import { NewContactComponent } from '@core/components/new-contact/new-contact.component';
import { SequentialListSimpleComponent } from '@shared/components/field-type-list/sequential-list-simple/sequential-list-simple.component';
import { SequentialListMultipleComponent } from '@shared/components/field-type-list/sequential-list-multiple/sequential-list-multiple.component';
import { SequentialListNumericalComponent } from '@shared/components/field-type-list/sequential-list-numerical/sequential-list-numerical.component';
import { SequentialListHighlightComponent } from '@shared/components/field-type-list/sequential-list-highlight/sequential-list-highlight.component';
import { TreeListMultipleComponent } from '@shared/components/field-type-list/tree-list-multiple/tree-list-multiple.component';
import { TreeSelectedMultipleComponent } from '@shared/components/field-type-list/tree-selected-multiple/tree-selected-multiple.component';
import { TreeListFilialsComponent } from '@shared/components/field-type-list/tree-list-filials/tree-list-filials.component';
import { MenuHeaderCommentsComponent} from '@core/components/menu-header-comments/menu-header-comments.component';
import { FilterHeaderComponent } from '@shared/components/filter-header/filter-header.component';
import { MenuHeaderNotificationsComponent } from '@core/components/menu-header-notifications/menu-header-notifications.component';
import { DashboardReportsComponent } from '@core/components/dashboard-reports/dashboard-reports.component';
import { FromToCalendarComponent } from '@shared/components/from-to-calendar/from-to-calendar.component';
import { MenuHeaderUserComponent } from '@core/components/menu-header-user/menu-header-user.component';
import { PaginationControlComponent } from '@shared/components/pagination-control/pagination-control.component';
import { OrganizationModalComponent } from '@shared/components/organization-modal/organization-modal.component';
import { LineGraphComponent } from '@core/components/line-graph/line-graph.component';
import { ResetPasswordComponent } from '@core/components/reset-password/reset-password.component';
import { MenuHeaderProductsComponent } from '@core/components/menu-header-products/menu-header-products.component';
import { DonutGraphComponent } from '@shared/components/donut-graph/donut-graph.component';
import { ConceptInstancesListComponent } from '@core/components/concept-instances-list/concept-instances-list-default/concept-instances-list.component';
import { ConceptInstancesListUkComponent } from '@core/components/concept-instances-list/concept-instances-list-uk/concept-instances-list-uk.component';
import { ConceptInstancesListIcamComponent } from '@core/components/concept-instances-list/concept-instances-list-icam/concept-instances-list-icam.component';
import { FilterByListComponent } from '@shared/components/filter-by-list/filter-by-list.component';
import { ManageConfidentialModalComponent } from '@core/components/manage-confidential-modal/manage-confidential-modal.component';

import { ShareDocumentModalComponent } from '@shared/components/share-document-modal/share-document-modal.component';
import { TaskListComponent } from '@core/components/task-list/task-list.component';
import { EvaluationComponent } from '@core/components/evaluation/evaluation.component';
import { RenameModalComponent } from '@shared/components/rename-modal/rename-modal.component';
import { BasicDataModalComponent } from '@shared/components/basic-data-modal/basic-data-modal.component';
import { TooltipInfoComponent } from '@shared/components/tooltip-info/tooltip-info.component';
import { TooltipLawComponent } from '@shared/components/tooltip-law/tooltip-law.component';
import { DuplicateModalComponent } from '@core/components/duplicate-modal/duplicate-modal.component';
import { HelpModalComponent } from '@core/components/help-modal/help-modal.component';
import { BranchTreeComponent } from '@shared/components/branch-tree/branch-tree.component';
import { EvaluationMenuComponent } from '@core/components/evaluation-menu/evaluation-menu.component';
import { HelpTooltipComponent } from '@core/components/help-tooltip/help-tooltip.component';
import { EvaluationTreeComponent } from '@core/components/evaluation-tree/evaluation-tree.component';
import { EvaluationHeaderComponent } from '@core/components/evaluation-header/evaluation-header.component';
import { DraftEvaluationsComponent } from '@core/components/draft-evaluations/draft-evaluations.component';
import { FolderComponent } from '@core/components/folder/folder.component';
import { DuplicateEvaluationComponent } from '@shared/components/duplicate-evaluation/duplicate-evaluation.component';
import { MigrationDataComponent } from '@shared/components/migration-data/migration-data.component';
import { ConfigureImageComponent } from '@core/components/configure-image/configure-image.component';
import { ImageEditorComponent } from '@core/components/image-editor/image-editor.component';
import { ParragraphTooltipComponent } from '@shared/components/parragraph-tooltip/parragraph-tooltip.component';
import { SpanTooltipComponent } from '@shared/components/span-tooltip/span-tooltip.component';
import { PublicLinkPollComponent } from '@core/components/public-link-poll/public-link-poll.component';
import { FieldTypeColorComponent } from '@shared/components/field-type-color/field-type-color.component';
import { FieldTypeListExtendedComponent } from '@shared/components/field-type-list-extended/field-type-list-extended.component';
import { FieldTypeSwitchComponent } from '@shared/components/field-type-switch/field-type-switch.component';
import { PublicLinkComponent } from '@core/components/public-link/public-link.component';
import { CorporativeInformationModalComponent } from '@core/components/corporative-information-modal/corporative-information-modal.component';
import { AddAssignmentModalComponent } from '@core/components/add-assignment-modal/add-assignment-modal.component';
import { ReferenceDocumentComponent } from '@core/components/reference-document/reference-document.component';
import { UploadDocumentsModalComponent } from '@shared/components/upload-document/upload-documents-modal/upload-documents-modal.component';
import { UploadDocumentsCustomModalComponent } from '@shared/components/upload-document/upload-documents-custom-modal/upload-documents-custom-modal.component';
import { ProcessOpsModalComponent } from '@shared/components/process-ops-modal/process-ops-modal.component';
import { ProcessModalComponent } from '@shared/components/process-modal/process-modal.component';
import { AssociatedDocumentationComponent } from '@shared/components/associated-documentation/associated-documentation.component';
import { AddAlertComponent } from '@shared/components/add-alert/add-alert.component';
import { NotificationPreviewComponent } from '@core/components/notification-preview/notification-preview.component';
import { FieldTypeDateComponent } from '@shared/components/field-type-date/field-type-date.component';
import { TimePickerComponent } from '@shared/components/time-picker/time-picker.component';
import { InputDateComponent } from './shared/components/input-date/input-date.component';
import { PersonalizedNotificationModalComponent } from '@shared/components/personalized-notification-modal/personalized-notification-modal.component';
import { TaskModalComponent } from '@shared/components/task-modal/task-modal.component';
import { NewDocumentComponent } from '@shared/components/new-document/new-document.component';
import { SelectSimpleComponent } from '@shared/components/select-simple/select-simple.component';
import { InputNumberComponent } from '@shared/components/input-number/input-number.component';
import { FieldItemComponent } from '@shared/components/field-item/field-item.component';
import { NewSnippetModalComponent } from '@shared/components/new-snippet-modal/new-snippet-modal.component';
import { AdviceMessageComponent } from '@shared/components/advice-message/advice-message.component';
import { RelatedInformationModalComponent } from '@shared/components/related-information-modal/related-information-modal.component';
import { LawInfoComponent } from '@shared/components/law-info/law-info.component';
import { LegalDocumentComponent } from '@shared/components/legal-document/legal-document.component';
import { ShareInstanceComponent } from '@shared/components/share-instance/share-instance.component';
import { FieldReferencesComponent } from '@core/components/field-references/field-references.component';
import { FieldTypeTemplateComponent } from '@shared/components/field-type-template/field-type-template.component';
import { FieldTypeTxchkComponent } from '@shared/components/field-type-txchk/field-type-txchk.component';
import { FieldTypeFormuComponent } from '@shared/components/field-type-formu/field-type-formu/field-type-formu.component';
import { FieldTypeFormRichComponent } from '@shared/components/field-type-formu/field-type-form-rich/field-type-form-rich.component';
import { FieldTypeReportComponent } from '@shared/components/field-type-report/field-type-report.component';
import { FieldTypeTimeComponent } from '@shared/components/field-type-time/field-type-time.component';
import { FieldTypeLinkComponent } from '@shared/components/field-type-link/field-type-link.component';
import { FieldTypeEmailComponent } from '@shared/components/field-type-email/field-type-email.component';
import { FieldTypeOtherComponent } from '@shared/components/field-type-other/field-type-other.component';
import { FieldTypeFileComponent } from '@shared/components/field-type-file/field-type-file.component';
import { RelationshipReasonModalComponent } from '@shared/components/relationship-reason-modal/relationship-reason-modal.component';
import { FieldTypeTaskComponent } from '@shared/components/field-type-task/field-type-task.component';
import { FieldTypeAlertComponent } from '@shared/components/field-type-alert/field-type-alert.component';
import { ExpirationPeriodComponent } from '@shared/components/expiration-period/expiration-period.component';
import { PickerDataSimpleComponent } from '@shared/components/picker-data-simple/picker-data-simple.component';
import { EstablishPatternModalComponent } from '@shared/components/establish-pattern-modal/establish-pattern-modal.component';
import { ConceptListGlobalReportComponent } from '@shared/components/concept-list/concept-list-global-report/concept-list-global-report.component';
import { ConceptListQueryComponent } from '@shared/components/concept-list/concept-list-query/concept-list-query.component';
import { ConceptListPublicationComponent } from '@shared/components/concept-list/concept-list-publication/concept-list-publication.component';
import { ConceptListPollComponent } from '@shared/components/concept-list/concept-list-poll/concept-list-poll.component';
import { ConceptListDigitalizacionComponent } from '@shared/components/concept-list/concept-list-digitalizacion/concept-list-digitalizacion.component';
import { ConceptListCollegiateComponent } from '@shared/components/concept-list/concept-list-collegiate/concept-list-collegiate.component';
import { ListInDropdownComponent } from '@shared/components/list-in-dropdown/list-in-dropdown.component';
import { AssignManagerComponent } from '@shared/components/assign-manager/assign-manager.component';
import { ChangeStateModalComponent } from '@shared/components/change-state-modal/change-state-modal.component';
import { StatesProcessDefaultComponent } from '@shared/components/states-process/states-process-default/states-process-default.component';
import { StatesProcessCustomComponent } from '@shared/components/states-process/states-process-custom/states-process-custom.component';
import { FilterBySearchComponent } from '@shared/components/filter-by-search/filter-by-search.component';
import { EditConceptComponent } from '@core/components/edit-concept/edit-concept.component';
import { ConceptTabComponent} from '@shared/components/concept-tab/concept-tab.component';
import { ConceptInfoDefaultComponent } from '@shared/components/concept-info/concept-info-default/concept-info-default.component';
import { ConceptInfoCustomComponent } from '@shared/components/concept-info/concept-info-custom/concept-info-custom.component';
import { CustomizeConceptComponent } from '@core/components/customize-concept/customize-concept.component';
import { FieldListComponent } from '@core/components/field-list/field-list.component';
import { VisibilityComponent } from '@shared/components/visibility/visibility.component';
import { PickerVisibilityComponent } from '@core/components/picker-visibility/picker-visibility.component';
import { ConceptListDefaultComponent } from '@shared/components/concept-list/concept-list-default/concept-list-default.component';
import { CustomizeFieldsComponent } from '@core/components/customize-fields/customize-fields.component';
import { CustomizeFieldTextComponent } from '@core/components/customize-field-text/customize-field-text.component';
import { SwitchOptionComponent } from '@shared/components/switch-option/switch-option.component';
import { CustomizeFieldNumComponent } from '@core/components/customize-field-num/customize-field-num.component';
import { CustomizeFieldListComponent } from '@core/components/customize-field-list/customize-field-list.component';
import { EvaluationShareComponent } from '@core/components/evaluation-share/evaluation-share.component';
import { DashboardRolesObligatedSubjectComponent } from '@core/components/dashboard-roles-obligated-subject/dashboard-roles-obligated-subject.component';
import { RolesWindowComponent } from '@core/components/roles-window/roles-window.component';
import { FieldTypeRoleComponent } from '@shared/components/field-type-role/field-type-role.component';
import { CustomizeFieldTextRichComponent } from '@core/components/customize-field-text-rich/customize-field-text-rich.component';
import { CustomizeFieldDateComponent } from '@core/components/customize-field-date/customize-field-date.component';
import { CustomizeFieldMoneyComponent } from '@core/components/customize-field-money/customize-field-money.component';
import { CustomizeFieldAlertComponent } from '@core/components/customize-field-alert/customize-field-alert.component';
import { CustomizeFieldBoolComponent } from '@core/components/customize-field-bool/customize-field-bool.component';
import { CustomizeFieldFileComponent } from '@core/components/customize-field-file/customize-field-file.component';
import { CustomizeFieldFormulaComponent } from '@core/components/customize-field-formula/customize-field-formula.component';
import { FormulaSettingModalComponent } from '@core/components/formula-setting-modal/formula-setting-modal.component';
import { CustomizeFieldOtherComponent } from '@core/components/customize-field-other/customize-field-other.component';
import { CustomizeFieldTxchkComponent } from '@core/components/customize-field-txchk/customize-field-txchk.component';
import { CustomizeFieldTemplateComponent } from '@core/components/customize-field-template/customize-field-template.component';
import { CustomizeFieldUserComponent } from '@core/components/customize-field-user/customize-field-user.component';
import { CustomizeFieldTaskComponent } from '@core/components/customize-field-task/customize-field-task.component';
import { ProcessEditComponent } from './shared/components/process-edit/process-edit.component';
import { ProcessEditModalComponent } from './shared/components/process-edit-modal/process-edit-modal.component';
import { UpDocumentComponent } from '@core/components/up-document/up-document.component';
import { TemplatesConceptComponent } from '@core/components/templates-concept/templates-concept.component';
import { ConceptFormCustomComponent } from '@shared/components/concept-form/concept-form-custom/concept-form-custom.component';
import { FinishBlockModalComponent } from '@shared/components/finish-block-modal/finish-block-modal.component';
import { CalendarDatepickerComponent } from '@shared/components/calendar-datepicker/calendar-datepicker.component';
import { GenerateDocumentComponent } from '@shared/components/generate-document/generate-document-default/generate-document.component';
import { ConceptFormSequentialComponent } from '@shared/components/concept-form/concept-form-sequential/concept-form-sequential.component';
import { ConceptStepsDefaultComponent } from '@shared/components/concept-steps/concept-steps-default/concept-steps-default.component';
import { SubcontainerMultipleDefaultComponent } from '@shared/components/subcontainer-multiple/subcontainer-multiple-default/subcontainer-multiple-default.component';
import { SubcontainerMultipleCustomComponent } from '@shared/components/subcontainer-multiple/subcontainer-multiple-custom/subcontainer-multiple-custom.component';
import { ConceptFormComponent } from '@shared/components/concept-form/concept-form-default/concept-form.component';
import { BoxFormComponent } from '@shared/components/box-form/box-form.component';
import { ConceptLegalDocumentationDefaultComponent } from '@shared/components/concept-legal-documentation/concept-legal-documentation-default/concept-legal-documentation-default.component';
import { ConceptLegalDocumentationCustomComponent } from '@shared/components/concept-legal-documentation/concept-legal-documentation-custom/concept-legal-documentation-custom.component';
import { PredefinedTemplatesComponent } from '@shared/components/predefined-templates/predefined-templates.component';
import { BoxCommentsDefaultComponent } from '@shared/components/box-comments/box-comments-default/box-comments-default.component';
import { BoxCommentsCustomComponent } from '@shared/components/box-comments/box-comments-custom/box-comments-custom.component';
import { BoxCommunicationChannelDefaultComponent } from '@shared/components/box-communication-channel/box-communication-channel-default/box-communication-channel-default.component';
import { BoxCommunicationChannelCustomComponent } from '@shared/components/box-communication-channel/box-communication-channel-custom/box-communication-channel-custom.component';
import { BoxQueriesComponent } from '@shared/components/box-queries/box-queries.component';
import { IcamQueriesDefaultComponent } from '@shared/components/icam-queries/icam-queries-default/icam-queries-default.component';
import { IcamQueriesCustomComponent } from '@shared/components/icam-queries/icam-queries-custom/icam-queries-custom.component';
import { VideoSliderComponent } from '@shared/components/video-slider/video-slider.component';
import { VideoPlayerComponent } from '@shared/components/video-player/video-player.component';
import { ProcessRecordDefaultComponent } from '@core/components/process-record/process-record-default/process-record-default.component';
import { ProcessRecordCustomComponent } from '@core/components/process-record/process-record-custom/process-record-custom.component';
import { BoxQueryStateComponent } from '@core/components/box-query-state/box-query-state.component';
import { AttachmentsTemplateCustomComponent } from '@shared/components/attachments-template/attachments-template-custom/attachments-template-custom.component';
import { AttachmentsTemplateDefaultComponent } from '@shared/components/attachments-template/attachments-template-default/attachments-template-default.component';
import { BoxProtectionComponent } from '@core/components/box-protection/box-protection.component';
import { AttachDocumentModalComponent } from '@shared/components/attach-document-modal/attach-document-modal.component';
import { ConceptComponent } from '@core/components/concept/concept.component';
import { HistoricConceptComponent } from '@shared/components/historic-concept/historic-concept.component';
import { AlertConceptComponent } from '@shared/components/alert-concept/alert-concept.component';
import { AlertDetailComponent } from '@shared/components/alert-detail/alert-detail.component';
import { ParticipantsListComponent } from '@shared/components/participants-list/participants-list.component';
import { TaskConceptComponent } from './shared/components/task-concept/task-concept.component';
import { RoleAssignmentModalComponent } from '@core/components/role-assignment-modal/role-assignment-modal.component';
import { TaskConceptListComponent } from '@shared/components/task-concept-list/task-concept-list.component';
import { TaskEditDefaultComponent } from '@shared/components/task-edit/task-edit-default/task-edit-default.component';
import { TaskEditCustomComponent } from '@shared/components/task-edit/task-edit-custom/task-edit-custom.component';
import { TaskHistoricCustomComponent } from '@shared/components/task-historic/task-historic-custom/task-historic-custom.component';
import { TaskHistoricDefaultComponent } from '@shared/components/task-historic/task-historic-default/task-historic-default.component';
import { TaskFormDefaultComponent } from '@shared/components/task-form/task-form-default/task-form-default.component';
import { TaskFormCustomComponent } from '@shared/components/task-form/task-form-custom/task-form-custom.component';
import { GlobalCustomizationComponent } from '@core/components/global-customization/global-customization.component';
import { SendReminderComponent } from '@core/components/send-reminder/send-reminder.component';
import { FieldTypeRadioOptionComponent } from '@shared/components/field-type-radio-option/field-type-radio-option.component';
import { ModalMoreInfoComponent } from '@shared/components/modal-more-info/modal-more-info.component';
import { FieldTypeRichTextComponent } from '@shared/components/field-type-rich-text/field-type-rich-text/field-type-rich-text.component';
import { FieldTypeRichTextEditableComponent } from '@shared/components/field-type-rich-text/field-type-rich-text-editable/field-type-rich-text-editable.component';
import { PreviewCustomizationComponent } from '@core/components/preview-customization/preview-customization.component';
import { CustomizeFooterComponent } from '@shared/components/customize-footer/customize-footer.component';
import { TaskGenericComponent } from '@shared/components/task-generic/task-generic.component';
import { NewReportComponent } from '@core/components/new-report/new-report.component';
import { NewCustomReportComponent } from '@core/components/new-custom-report/new-custom-report.component';
import { NewCustomReportIdentificationComponent } from '@core/components/new-custom-report-identification/new-custom-report-identification.component';
import { NewCustomReportConfigurationComponent } from '@core/components/new-custom-report-configuration/new-custom-report-configuration.component';
import { NewCustomReportPreviewComponent } from '@core/components/new-custom-report-preview/new-custom-report-preview.component';
import { NewCustomReportFieldComponent } from '@core/components/new-custom-report-field/new-custom-report-field.component';
import { NewCustomReportFieldIdentificationComponent } from '@core/components/new-custom-report-field-identification/new-custom-report-field-identification.component';
import { NewCustomReportFieldAssignmentComponent } from '@core/components/new-custom-report-field-assignment/new-custom-report-field-assignment.component';
import { ClientsTrackingDashboardComponent } from '@core/components/clients-tracking-dashboard/clients-tracking-dashboard.component';
import { ClientsTrackingComponent } from '@shared/components/clients-tracking/clients-tracking.component';
import { InstantVideoconferencesComponent } from '@core/components/instant-videoconferences/instant-videoconferences.component';
import { NewInstantVideoconferenceComponent } from '@core/components/new-instant-videoconference/new-instant-videoconference.component';
import { DocumentationListComponent } from '@core/components/documentation-list/documentation-list.component';
import { SnippetsReusableComponent } from '@core/components/snippets-reusable/snippets-reusable.component';
import { NewVersionComponent } from '@shared/components/new-version/new-version.component';
import { DocumentHistoricComponent } from '@core/components/document-historic/document-historic.component';
import { CertifyDocumentComponent } from '@core/components/certify-document/certify-document.component';
import { RequestTrackingComponent } from '@core/components/request-tracking/request-tracking.component';
import { MoveModalComponent } from '@core/components/move-modal/move-modal.component';
import { DuplicateDocumentComponent } from '@core/components/duplicate-document/duplicate-document.component';
import { ResultSearchComponent } from '@core/components/result-search/result-search.component';
import { OnboardingComponent } from '@core/components/onboarding/onboarding.component';
import { DuplicateDocumentationComponent } from '@core/components/duplicate-documentation/duplicate-documentation.component';
import { DuplicateDocumentationCollegiateComponent } from '@core/components/duplicate-documentation-view/duplicate-documentation-collegiate/duplicate-documentation-collegiate.component';
import { DuplicateDocumentationDigitizationComponent } from '@core/components/duplicate-documentation-view/duplicate-documentation-digitization/duplicate-documentation-digitization.component';
import { DuplicateDocumentationPublicationComponent } from '@core/components/duplicate-documentation-view/duplicate-documentation-publication/duplicate-documentation-publication.component';
import { DuplicateDocumentationQueryComponent } from '@core/components/duplicate-documentation-view/duplicate-documentation-query/duplicate-documentation-query.component';
import { DuplicateDocumentationStepComponent } from '@core/components/duplicate-documentation-view/duplicate-documentation-step/duplicate-documentation-step.component';
import { ReportsComponent } from '@core/components/reports/reports.component';
import { UploadReportComponent } from '@core/components/upload-report/upload-report.component';
import { FieldTypeOnefileComponent } from '@shared/components/field-type-onefile/field-type-onefile.component';
import { AllDocumentationComponent } from '@core/components/all-documentation/all-documentation.component';
import { ClientEvaluationComponent } from '@core/components/client-evaluation/client-evaluation.component';
import { ReportInfoComponent } from '@shared/components/report-info/report-info.component';
import { ReportTableDefaultComponent } from '@core/components/report-table/report-table-default/report-table-default.component';
import { UsersAdminComponent } from '@core/components/users-admin/users-admin.component';
import { UsersAdminMainComponent } from '@core/components/users-admin-main/users-admin-main.component';
import { UsersAdminPersonRowComponent } from '@core/components/users-admin-person-row/users-admin-person-row.component';
import { UsersAdminNewWizardComponent } from '@core/components/users-admin-new-wizard/users-admin-new-wizard.component';
import { UsersAdminPersonFormComponent } from '@core/components/users-admin-person-form/users-admin-person-form.component';
import { UsersAdminSecurityFormComponent } from '@core/components/users-admin-security-form/users-admin-security-form.component';
import { UsersAdminEditComponent } from '@core/components/users-admin-edit/users-admin-edit.component';
import { UsersAdminRolesListComponent } from '@core/components/users-admin-roles-list/users-admin-roles-list.component';
import { UsersAdminGroupFormComponent } from '@core/components/users-admin-group-form/users-admin-group-form.component';
import { ReportTableDynamicComponent } from '@core/components/report-table/report-table-dynamic/report-table-dynamic.component';
import { ReportTableCanalEntradaComponent } from '@core/components/report-table/report-table-canal-entrada/report-table-canal-entrada.component';
import { ReportTableCategoriaServicioComponent } from '@core/components/report-table/report-table-categoria-servicio/report-table-categoria-servicio.component';
import { ReportTableConsultasPagoComponent } from '@core/components/report-table/report-table-consultas-pago/report-table-consultas-pago.component';
import { ReportTableFragmentosDigitalizadosComponent } from '@core/components/report-table/report-table-fragmentos-digitalizados/report-table-fragmentos-digitalizados.component';
import { ReportTableMateriasSubmateriaComponent } from '@core/components/report-table/report-table-materias-submateria/report-table-materias-submateria.component';
import { ReportTablePerfilUsuarioComponent } from '@core/components/report-table/report-table-perfil-usuario/report-table-perfil-usuario.component';
import { ReportTablePersonalizadoComponent } from '@core/components/report-table/report-table-personalizado/report-table-personalizado.component';
import { ReportTablePorUsuarioComponent } from '@core/components/report-table/report-table-por-usuario/report-table-por-usuario.component';
import { ReportTablePrecioMedioTransaccionComponent } from '@core/components/report-table/report-table-precio-medio-transaccion/report-table-precio-medio-transaccion.component';
import { ReportTableResueltasPorGestorComponent } from '@core/components/report-table/report-table-resueltas-por-gestor/report-table-resueltas-por-gestor.component';
import { ReportTableTiempoMedioGestionComponent } from '@core/components/report-table/report-table-tiempo-medio-gestion/report-table-tiempo-medio-gestion.component';
import { ReportTableTiempoMedioResolucionComponent } from '@core/components/report-table/report-table-tiempo-medio-resolucion/report-table-tiempo-medio-resolucion.component';
import { ReportTableTipoDocumentoComponent } from '@core/components/report-table/report-table-tipo-documento/report-table-tipo-documento.component';
import { ReportTableTodasPublicacionesComponent } from '@core/components/report-table/report-table-todas-publicaciones/report-table-todas-publicaciones.component';
import { ReportGraphComponent } from '@core/components/report-graph/report-graph.component';
import { HeatMapGraphComponent } from '@core/components/heat-map-graph/heat-map-graph.component';
import { BarHorizontalGraphComponent } from '@core/components/bar-horizontal-graph/bar-horizontal-graph.component';
import { ColumnsGraphComponent } from '@core/components/columns-graph/columns-graph.component';
import { StackedGraphComponent } from '@core/components/stacked-graph/stacked-graph.component';
import { ScatterPlotGraphComponent } from './core/components/scatter-plot-graph/scatter-plot-graph.component';
import { BarGraphComponent } from '@core/components/bar-graph/bar-graph.component';
import { CustomReportsComponent } from '@core/components/custom-reports/custom-reports.component';
import { CustomReportListComponent } from '@core/components/custom-report-list/custom-report-list.component';
import { CustomReportFieldListComponent } from '@core/components/custom-report-field-list/custom-report-field-list.component';
import { WindowListComponent } from '@core/components/window-list/window-list.component';
import { RoleErrorModalComponent } from '@shared/components/role-error-modal/role-error-modal.component';
import { ChannelConfigurationComponent } from '@core/components/channel-configuration/channel-configuration.component';
import { QrModalComponent } from '@shared/components/qr-modal/qr-modal.component';
import { ResultsReportComponent } from '@core/components/results-report/results-report.component';
import { ExportResultReportComponent } from '@core/components/export-result-report/export-result-report.component';
import { ViewExtendedReportComponent } from '@core/components/view-extended-report/view-extended-report.component';
import { HorizontalPollGraphComponent } from '@shared/components/horizontal-poll-graph/horizontal-poll-graph.component';
import { AlertListWindowComponent } from '@core/components/alert-list-window/alert-list-window.component';
import { ConceptWindowComponent } from '@core/components/concept-window/concept-window.component';
import { ConceptWindowTabComponent } from '@core/components/concept-window-tab/concept-window-tab.component';
import { DashboardPanelUkComponent } from '@core/components/dashboard-panel/dashboard-panel-uk/dashboard-panel-uk.component';
import { FieldAssignmentsModalComponent } from '@shared/components/field-assignments-modal/field-assignments-modal.component';
import { StatesTaskHelpModalComponent } from '@core/components/states-task-help-modal/states-task-help-modal.component';
import { ReportExportModalComponent } from '@shared/components/report-export-modal/report-export-modal.component';
import { LinkModalComponent } from '@core/components/link-modal/link-modal.component';
import { AutomaticPostProcessModalComponent } from '@core/components/automatic-post-process-modal/automatic-post-process-modal.component';
import { BlockedEditionModalComponent } from '@core/components/blocked-edition-modal/blocked-edition-modal.component';
import { ReportRecordDefaultComponent } from '@core/components/report-record-detail/report-record-default/report-record-default.component';
import { ReportRecordDetailComponent } from '@core/components/report-record-detail/report-record-detail/report-record-detail.component';
import { ReportRecordDocumentationComponent } from '@core/components/report-record-detail/report-record-documentation/report-record-documentation.component';
import { FilterByDateComponent } from '@shared/components/filter-by-date/filter-by-date.component';
import { ProtectionDataModalComponent } from '@core/components/protection-data-modal/protection-data-modal.component';
import { ConceptStepsCustomComponent } from '@shared/components/concept-steps/concept-steps-custom/concept-steps-custom.component';
import { DocumentationReportListComponent } from '@shared/components/documentation-report-list/documentation-report-list.component';
import { ConcursalFormsModalComponent } from '@core/components/concursal-forms-modal/concursal-forms-modal.component';
import { FavoritesComponent } from '@core/components/favorites/favorites.component';
import { ExitLoginComponent } from '@shared/components/exit-login/exit-login.component';
import { GeneralReportComponent } from '@core/components/general-report/general-report.component';
import { EditionComparatorComponent } from '@core/components/edition-comparator/edition-comparator.component';
import { EditionComparatorFormComponent } from '@core/components/edition-comparator-form/edition-comparator-form.component';
import { FieldTypeAccessComponent } from '@shared/components/field-type-access/field-type-access.component';
import { OrganizationChartComponent } from '@core/components/organization-chart/organization-chart.component';
import { ConceptListLandingComponent } from '@core/components/concept-list-landing/concept-list-landing.component';
import { GenerateDocumentCustomComponent } from '@shared/components/generate-document/generate-document-custom/generate-document-custom.component';
import { ReportIncidentsListComponent } from '@core/components/report-incidents-list/report-incidents-list.component';
import { EvaluationImportComponent } from '@core/components/evaluation-import/evaluation-import.component';
import { LandingPageComponent } from '@core/components/landing-page/landing-page.component';
import { NoPermissionsComponent } from '@shared/components/no-permissions/no-permissions.component';
import { ScheduleComponent } from '@shared/components/schedule/schedule.component';
import { ScheduleEventComponent } from '@core/components/schedule-event/schedule-event.component';
import { HistoryViewerComponent } from '@shared/components/history-viewer/history-viewer.component';
import { HistoryViewerFieldsComponent } from '@shared/components/history-viewer-fields/history-viewer-fields.component';
import { ScheduleBigComponent } from '@core/components/schedule-big/schedule-big.component';
import { UploadDocumentsComponent } from '@shared/components/upload-document/upload-documents/upload-documents.component';
import { RappidComponent } from '@shared/components/rappid/rappid.component';
import { PdfHeaderComponent } from '@shared/components/pdf-header/pdf-header.component';
import { PdfTableComponent } from '@shared/components/pdf-table/pdf-table.component';
import { RichEditorComponent } from '@shared/components/rich-editor/rich-editor.component';
import { InputTimeComponent } from '@shared/components/input-time/input-time.component';
import { FieldTypeConsultListsComponent } from '@shared/components/field-type-consult-lists/field-type-consult-lists.component';
import { ConsultListsModalComponent } from '@shared/components/consult-lists-modal/consult-lists-modal.component';
import { ReportConsultListsPdfComponent } from '@shared/components/report-consult-lists-pdf/report-consult-lists-pdf.component';
import { ListComparatorInDropdownComponent } from '@shared/components/list-comparator-in-dropdown/list-comparator-in-dropdown.component';
import { TreeListSimpleComponent } from '@shared/components/field-type-list/tree-list-simple/tree-list-simple.component';
import { NotificationModalComponent } from '@shared/components/notification-modal/notification-modal.component';
import { CustomSelectComponent } from '@shared/components/custom-select/custom-select.component';
import { CalendarMonthYearComponent } from '@shared/components/calendar-month-year/calendar-month-year.component';
import { EvaluationBlanqueoRowComponent } from '@core/components/evaluation-blanqueo-row/evaluation-blanqueo-row.component';
import { OfficeOnlineComponent } from '@shared/components/office-online/office-online.component';
import { EditConceptModule } from './shared/modules/edit-concept/edit-concept.module';
import { AvailableProcessesComponent } from './core/components/available-processes/available-processes.component';
import { LoginBeAccessComponent } from '@core/components/login-be-access/login-be-access.component';
import { IcamAccessLandingComponent } from '@core/components/icam-access-landing/icam-access-landing.component';
import { ScheduleNextEventComponent } from '@core/components/schedule-next-event/schedule-next-event.component';
import { RenderCanvasDirective } from './shared/directives/render-canvas/render-canvas.directive';
import { ReportsListComponent } from '@core/components/reports-list/report-list/reports-list.component';
import { ReportListBeComponent } from '@core/components/reports-list/report-list-be/report-list-be.component';
import { AdviceListModalComponent } from '@shared/components/advice-modal/advice-list-modal/advice-list-modal.component';
import { CacheInterceptor } from '@shared/interceptors/cache-interceptor/cache-interceptor.interceptor';
import { ComplianceComponent } from '@core/components/compliance/compliance.component';
import { RecordAudioModalComponent } from '@shared/components/record-audio-modal/record-audio-default-modal/record-audio-modal.component';
import { ProgressBarDirective } from '@shared/directives/progress-bar/progress-bar.directive';
import { ListenRecordComponent } from '@shared/components/listen-record/listen-record.component';
import { RecordAudioCustomModalComponent } from '@shared/components/record-audio-modal/record-audio-custom-modal/record-audio-custom-modal.component';

@NgModule({
  declarations: [
    ColorsPipe,
    ArrayPipe,
    ByWordPipe,
    ByWordChildrenPipe,
    ConvertBytesPipe,
    ConvertDatePipe,
    CreatorLoggedPipe,
    DistinctPropertyPipe,
    ExpiredPipe,
    ExtensionDocumentPipe,
    FieldTypeIconsPipe,
    FilteredByPipe,
    HiddenPipe,
    OneMorePipe,
    RelativeTimePipe,
    ReportExtractIsoPipe,
    SetTextColorsPipe,
    TaskTypeIconsPipe,
    TrustUrlPipe,
    UniquePipe,
    AfOrderByPipe,
    AfTranslatePipe,
    AfSiteTermsPipe,
    SliceArrayPipe,
    AfLimitToPipe,
    AddZeroPipe,
    TrustHtmlPipe,
    NgShowDirective,
    NgHideDirective,
    ScrollConceptDirective,
    AfTranslateDirective,
    AfSiteTermsDirective,
    ResizeWindowDirective,
    RenderCanvasDirective,
    ProgressBarDirective,
    AppComponent,
    FrontLoginComponent,
    FrontLoginCustomComponent,
    DashboardViewComponent,
    DashboardPanelComponent,
    ComplianceFooterComponent,
    HeaderPollComponent,
    HeaderNavComponent,
    AdviceModalComponent,
    AdviceModalCustomComponent,
    ExportModalComponent,
    ExportFormatModalComponent,
    ImportModalComponent,
    ClientsListComponent,
    ClientsListUkComponent,
    ClientDetailsComponent,
    EvaluationsListComponent,
    InformativeLoadingModalComponent,
    NewEvaluationComponent,
    FilialsComponent,
    WizardStepsComponent,
    ExpandedTextareaComponent,
    FieldTypeTextMultilineComponent,
    FieldTypeTextSimpleComponent,
    FieldTypeListSimpleComponent,
    SequentialListSimpleComponent,
    FieldTypeListMultipleComponent,
    SequentialListMultipleComponent,
    FieldTypeListNumericalComponent,
    SequentialListNumericalComponent,
    FieldTypeListButtonComponent,
    FieldTypeListHighlightComponent,
    SequentialListHighlightComponent,
    FieldTypeListTreeComponent,
    TreeListSimpleComponent,
    TreeListMultipleComponent,
    TreeSelectedMultipleComponent,
    TreeListFilialsComponent,
    FieldTypeBoolComponent,
    FieldTypeBoolCustomComponent,
    MenuHeaderCommentsComponent,
    MenuHeaderNotificationsComponent,
    FilterHeaderComponent,
    DashboardReportsComponent,
    MenuHeaderUserComponent,
    PaginationControlComponent,
    OrganizationModalComponent,
    FromToCalendarComponent,
    LineGraphComponent,
    FieldTypeClientsSelectorComponent,
    ResetPasswordComponent,
    FieldTypeContactsSimpleComponent,
    FieldTypeContactsMultipleComponent,
    FieldTypeContactsSimpleCustomComponent,
    FieldTypeContactsMultipleCustomComponent,
    NewContactComponent,
    MenuHeaderProductsComponent,
    DonutGraphComponent,
    ConceptInstancesListComponent,
    ConceptInstancesListUkComponent,
    ConceptInstancesListIcamComponent,
    FilterByListComponent,
    ManageConfidentialModalComponent,
    ReportsListComponent,
    ShareDocumentModalComponent,
    FieldTypePasswordComponent,
    TaskListComponent,
    EvaluationComponent,
    RenameModalComponent,
    BasicDataModalComponent,
    FieldTypeNumberComponent,
    FieldTypeLogoComponent,
    TooltipInfoComponent,
    TooltipLawComponent,
    DuplicateModalComponent,
    HelpModalComponent,
    BranchTreeComponent,
    EvaluationMenuComponent,
    HelpTooltipComponent,
    EvaluationTreeComponent,
    EvaluationHeaderComponent,
    DraftEvaluationsComponent,
    FolderComponent,
    DuplicateEvaluationComponent,
    MigrationDataComponent,
    ConfigureImageComponent,
    ImageEditorComponent,
    ParragraphTooltipComponent,
    SpanTooltipComponent,
    PublicLinkPollComponent,
    FieldTypeColorComponent,
    FieldTypeListExtendedComponent,
    PublicLinkComponent,
    FieldTypeSwitchComponent,
    CorporativeInformationModalComponent,
    AddAssignmentModalComponent,
    ReferenceDocumentComponent,
    UploadDocumentsModalComponent,
    UploadDocumentsCustomModalComponent,
    ProcessOpsModalComponent,
    ProcessModalComponent,
    AssociatedDocumentationComponent,
    AddAlertComponent,
    NotificationPreviewComponent,
    FieldTypeDateComponent,
    TimePickerComponent,
    InputDateComponent,
    PersonalizedNotificationModalComponent,
    TaskModalComponent,
    NewDocumentComponent,
    SelectSimpleComponent,
    InputNumberComponent,
    FieldItemComponent,
    NewSnippetModalComponent,
    AdviceMessageComponent,
    RelatedInformationModalComponent,
    LawInfoComponent,
    LegalDocumentComponent,
    ShareInstanceComponent,
    FieldReferencesComponent,
    FieldTypeTemplateComponent,
    FieldTypeTxchkComponent,
    FieldTypeFormuComponent,
    FieldTypeFormRichComponent,
    FieldTypeReportComponent,
    FieldTypeTimeComponent,
    FieldTypeLinkComponent,
    FieldTypeEmailComponent,
    FieldTypeOtherComponent,
    FieldTypeFileComponent,
    RelationshipReasonModalComponent,
    FieldTypeTaskComponent,
    FieldTypeAlertComponent,
    ExpirationPeriodComponent,
    PickerDataSimpleComponent,
    EstablishPatternModalComponent,
    ConceptListQueryComponent,
    ConceptListPublicationComponent,
    ConceptListPollComponent,
    ConceptListDigitalizacionComponent,
    ConceptListCollegiateComponent,
    ListInDropdownComponent,
    AssignManagerComponent,
    ChangeStateModalComponent,
    StatesProcessDefaultComponent,
    StatesProcessCustomComponent,
    FilterBySearchComponent,
    EditConceptComponent,
    ConceptTabComponent,
    ConceptInfoDefaultComponent,
    ConceptInfoCustomComponent,
    CustomizeConceptComponent,
    FieldListComponent,
    VisibilityComponent,
    PickerVisibilityComponent,
    ConceptListGlobalReportComponent,
    ConceptListDefaultComponent,
    CustomizeFieldsComponent,
    CustomizeFieldTextComponent,
    SwitchOptionComponent,
    CustomizeFieldNumComponent,
    CustomizeFieldListComponent,
    CustomizeFieldTextRichComponent,
    CustomizeFieldDateComponent,
    CustomizeFieldMoneyComponent,
    CustomizeFieldAlertComponent,
    CustomizeFieldBoolComponent,
    CustomizeFieldFileComponent,
    CustomizeFieldFormulaComponent,
    FormulaSettingModalComponent,
    CustomizeFieldOtherComponent,
    CustomizeFieldTxchkComponent,
    CustomizeFieldTemplateComponent,
    CustomizeFieldUserComponent,
    CustomizeFieldTaskComponent,
    ProcessEditComponent,
    ProcessEditModalComponent,
    UpDocumentComponent,
    TemplatesConceptComponent,
    EvaluationShareComponent,
    DashboardRolesObligatedSubjectComponent,
    RolesWindowComponent,
    FieldTypeRoleComponent,
    ConceptFormComponent,
    ConceptFormCustomComponent,
    FinishBlockModalComponent,
    CalendarDatepickerComponent,
    GenerateDocumentComponent,
    ConceptFormSequentialComponent,
    ConceptStepsDefaultComponent,
    SubcontainerMultipleDefaultComponent,
    SubcontainerMultipleCustomComponent,
    BoxFormComponent,
    ConceptLegalDocumentationDefaultComponent,
    ConceptLegalDocumentationCustomComponent,
    PredefinedTemplatesComponent,
    BoxCommentsDefaultComponent,
    BoxCommentsCustomComponent,
    BoxCommunicationChannelDefaultComponent,
    BoxCommunicationChannelCustomComponent,
    BoxQueriesComponent,
    IcamQueriesDefaultComponent,
    IcamQueriesCustomComponent,
    VideoSliderComponent,
    VideoPlayerComponent,
    ProcessRecordDefaultComponent,
    ProcessRecordCustomComponent,
    BoxQueryStateComponent,
    ProcessRecordCustomComponent,
    ProcessRecordDefaultComponent,
    AttachmentsTemplateCustomComponent,
    AttachmentsTemplateDefaultComponent,
    BoxProtectionComponent,
    AttachDocumentModalComponent,
    ConceptComponent,
    HistoricConceptComponent,
    AlertConceptComponent,
    AlertDetailComponent,
    ParticipantsListComponent,
    TaskConceptComponent,
    RoleAssignmentModalComponent,
    TaskConceptListComponent,
    TaskEditDefaultComponent,
    TaskEditCustomComponent,
    TaskHistoricCustomComponent,
    TaskHistoricDefaultComponent,
    TaskFormDefaultComponent,
    TaskFormCustomComponent,
    GlobalCustomizationComponent,
    SendReminderComponent,
    FieldTypeRadioOptionComponent,
    ModalMoreInfoComponent,
    FieldTypeRichTextComponent,
    FieldTypeRichTextEditableComponent,
    PreviewCustomizationComponent,
    CustomizeFooterComponent,
    TaskGenericComponent,
    NewReportComponent,
    NewCustomReportComponent,
    NewCustomReportIdentificationComponent,
    NewCustomReportConfigurationComponent,
    NewCustomReportPreviewComponent,
    NewCustomReportFieldComponent,
    NewCustomReportFieldIdentificationComponent,
    NewCustomReportFieldAssignmentComponent,
    ClientsTrackingDashboardComponent,
    ClientsTrackingComponent,
    InstantVideoconferencesComponent,
    NewInstantVideoconferenceComponent,
    DocumentationListComponent,
    SnippetsReusableComponent,
    NewVersionComponent,
    DocumentHistoricComponent,
    CertifyDocumentComponent,
    RequestTrackingComponent,
    MoveModalComponent,
    DuplicateDocumentComponent,
    ResultSearchComponent,
    OnboardingComponent,
    DuplicateDocumentationComponent,
    DuplicateDocumentationCollegiateComponent,
    DuplicateDocumentationDigitizationComponent,
    DuplicateDocumentationPublicationComponent,
    DuplicateDocumentationQueryComponent,
    DuplicateDocumentationStepComponent,
    ReportsComponent,
    UploadReportComponent,
    FieldTypeOnefileComponent,
    AllDocumentationComponent,
    ClientEvaluationComponent,
    ReportInfoComponent,
    ReportTableDefaultComponent,
    UsersAdminComponent,
    UsersAdminMainComponent,
    UsersAdminPersonRowComponent,
    UsersAdminNewWizardComponent,
    UsersAdminPersonFormComponent,
    UsersAdminSecurityFormComponent,
    UsersAdminEditComponent,
    UsersAdminRolesListComponent,
    UsersAdminGroupFormComponent,
    ReportTableDynamicComponent,
    ReportTableCanalEntradaComponent,
    ReportTableCategoriaServicioComponent,
    ReportTableConsultasPagoComponent,
    ReportTableFragmentosDigitalizadosComponent,
    ReportTableMateriasSubmateriaComponent,
    ReportTablePerfilUsuarioComponent,
    ReportTablePersonalizadoComponent,
    ReportTablePorUsuarioComponent,
    ReportTablePrecioMedioTransaccionComponent,
    ReportTableResueltasPorGestorComponent,
    ReportTableTiempoMedioGestionComponent,
    ReportTableTiempoMedioResolucionComponent,
    ReportTableTipoDocumentoComponent,
    ReportTableTodasPublicacionesComponent,
    ReportGraphComponent,
    HeatMapGraphComponent,
    BarHorizontalGraphComponent,
    ColumnsGraphComponent,
    StackedGraphComponent,
    ScatterPlotGraphComponent,
    BarGraphComponent,
    CustomReportsComponent,
    CustomReportListComponent,
    CustomReportFieldListComponent,
    WindowListComponent,
    RoleErrorModalComponent,
    ChannelConfigurationComponent,
    QrModalComponent,
    ResultsReportComponent,
    ExportResultReportComponent,
    ViewExtendedReportComponent,
    HorizontalPollGraphComponent,
    AlertListWindowComponent,
    ConceptWindowComponent,
    ConceptWindowTabComponent,
    DashboardPanelUkComponent,
    FieldAssignmentsModalComponent,
    StatesTaskHelpModalComponent,
    ReportExportModalComponent,
    LinkModalComponent,
    AutomaticPostProcessModalComponent,
    BlockedEditionModalComponent,
    ReportRecordDefaultComponent,
    ReportRecordDetailComponent,
    ReportRecordDocumentationComponent,
    FilterByDateComponent,
    ProtectionDataModalComponent,
    ConceptStepsCustomComponent,
    DocumentationReportListComponent,
    ConcursalFormsModalComponent,
    FavoritesComponent,
    ExitLoginComponent,
    GeneralReportComponent,
    OnlyToShowPipe,
    EditionComparatorComponent,
    EditionComparatorFormComponent,
    FieldTypeAccessComponent,
    OrganizationChartComponent,
    ConceptListLandingComponent,
    GenerateDocumentCustomComponent,
    ReportIncidentsListComponent,
    EvaluationImportComponent,
    LandingPageComponent,
    NoPermissionsComponent,
    ScheduleComponent,
    ScheduleEventComponent,
    HistoryViewerComponent,
    HistoryViewerFieldsComponent,
    ScheduleBigComponent,
    UploadDocumentsComponent,
    DropZoneDirective,
    FocusWhenDirective,
    RappidComponent,
    PdfHeaderComponent,
    PdfTableComponent,
    RichEditorComponent,
    InputTimeComponent,
    FieldTypeConsultListsComponent,
    ConsultListsModalComponent,
    ReportConsultListsPdfComponent,
    ListComparatorInDropdownComponent,
    NotificationModalComponent,
    CustomSelectComponent,
    CalendarMonthYearComponent,
    EvaluationBlanqueoRowComponent,
    ValidateNumberDirective,
    OfficeOnlineComponent,
    AvailableProcessesComponent,
    LoginBeAccessComponent,
    IcamAccessLandingComponent,
    ScheduleNextEventComponent,
    NgMouseWheelDirective,
    ReportListBeComponent,
    AdviceListModalComponent,
    ComplianceComponent,
    RecordAudioModalComponent,
    RecordAudioCustomModalComponent,
    ListenRecordComponent
  ],
  imports: [
    FullCalendarModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    AppRoutingModule,
    NgPipesModule,
    NgbModule,
    EditorModule,
    ReactiveFormsModule,
    QRCodeModule,
    DragulaModule.forRoot(),
    EditConceptModule,
    AngularCropperjsModule,
    MatTooltipModule,
    NoopAnimationsModule
  ],
  providers: [ByWordPipe, { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
