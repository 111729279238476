<div class="common__field-type">
    <label class="common__input-block common__input-block--field"
        [ngClass]="{'common__input-block--show-references': references && field.evaluatedValue.conceptDataId !== null,
        'common__show-alert': (field.error && !field.sequentialView) || field.errorInForm,
        'common__input-no-editable': field.noEditable,
        'common__input-block--poll-view': field.poll && !field.noEditable && field.sequentialView,
        'common__input-block--custom-field': isLanding && !field.sequentialView}"
        [for]="'field-item-numb-' + field.id"
        [attr.disabled]="field.noEditable?true:null">
        <div class="common__input-text-poll"
            *ngIf="field.sequentialView && !field.noEditable"
            [ngClass]="{'common__input-text-poll--error': field.error || field.errorInForm}">
            <p class="field-type-text__custom-sequential-label">{{titleField}}
                <span class="common__text-required common__text-required--sequential-view"
                    [ngShow]="field.required">*</span>
                <tooltip-info [description]="field.description"
                    [field]="field"
                    [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
                </tooltip-info>
                <tooltip-law [references]="field.references"
                    [field]="field"
                    [ngShow]="field.references?.length"
                    [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
                </tooltip-law>
            </p>
        </div>
        <div class="common__label-block"
            [ngClass]="field.evaluatedValue?.hasReferences && !field.poll ? 'common__label-block--two-icon' : 'common__label-block--one-icon'"
            *ngIf="!(field.sequentialView && !field.noEditable)">
            <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error || field.errorInForm,
                        'common__input-text--no-editable': field.noEditable}">{{titleField}}</span>
            <span class="common__text-required"
                [ngShow]="field.required">*</span>
            <div class="common__info-icon-block"
                [ngHide]="field.noEditable || !field.description">
                <tooltip-info [description]="field.description"
                    [field]="field"
                    custom="custom">
                </tooltip-info>
            </div>
            <div class="common__tooltip-law-block"
                [ngShow]="field.references && field.references?.length && !field.noEditable">
                <tooltip-law [references]="field.references"
                    [field]="field"
                    custom="custom">
                </tooltip-law>
            </div>
            <div class="common__tooltip-law-block"
                [ngShow]="field.relatedInfo && field.relatedInfo?.length && !field.poll && !field.noEditable">
                <i class="lf-icon-search-text field-item__icon-related-info"
                    (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                    matTooltip="{{'common.related-info' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                </i>
            </div>
        </div>
        <div [ngClass]="{'common__input-poll': field.poll && !field.noEditable && field.sequentialView,
                          'common__show-alert': (field.error && field.sequentialView) || field.errorInForm}">
            <div class="float-end">
                <i class="lf-icon-connected-data field-type-text__icon-references field-type-text__icon-references--less-top"
                    *ngIf="field.evaluatedValue?.hasReferences && !field.poll"
                    (click)="showReference($event)"
                    matTooltip="{{'common.see-dependencies' | afTranslate }}"
                    matTooltipPosition="left"
                    matTooltipClass="left">
                </i>
                <i *ngIf="type !== 'money'"
                    [class]="icon + ' common__input-icon'"
                    [ngClass]="{'field-item__input-icon-custom-coin': isLanding && type==='money',
                                'field-item__input-icon-custom-number': isLanding && type==='num'}"></i>
                <span *ngIf="fieldTypeNumber.type === 'money'"
                    class="field-type-number__coin-text"
                    [ngClass]="{'field-type-number__coin-text--custom': isLanding && type==='money'}" >{{field.currencySymbol}}</span>
            </div>
            <input [id]="'field-item-numb-' + field.id"
                [name]="'field-item-numb-' + field.id"
                class="common__input-content"
                [ngClass]="{'common__input-content-alert': field.error || field.errorInForm,
                    'common__input-content-no-editable': field.noEditable,
                    'common__input-content--poll': field.poll && !field.noEditable && field.sequentialView}"
                type="text"
                [maxlength]="field.maxLength?field.maxLength:'524288'"
                [step]="decimals"
                validateNumber
                [maxDecimal]="decimals"
                [noDot]="dotSeparation"
                [noNegatives]="field.positive"
                autocomplete="off"
                [(ngModel)]="inputNumber"
                (blur)="changeInNumber('')"
                [readonly]="field.noEditable"
                [value]="inputNumber"
                [disabled]="field.noEditable">
            <i class="common__delete-content-icon"
                [ngClass]="{'lf-icon-close-round': isLanding,
                            'lf-icon-close': !isLanding}"
                [ngShow]="inputNumber && !(field.origen && field.origen === 'formula')"
                (click)="resetNumber()"></i>
            <span class="field-type-number__span-block"
                [ngShow]="field.origen && field.origen === 'formula' && inputNumber && inputNumber !== ''">
                <span [afTranslate]="'common.add'"></span>
                <i class="lf-icon-arrow-right field-type-number__arrow-icon"></i>
            </span>
        </div>
        <div class="field-item__blocker-box"
          *ngIf="field.error && field.blocker">
          <div class="field-item__blocker-triangle"></div>
          <i class="lf-icon-warning field-item__blocker-icon"></i>
          <p class="field-item__blocker-text"
            [afTranslate]="'field-items.blocker-field'">campo bloqueante
          </p>
      </div>
    </label>
    <p class="field-type-date__error-in-field"
      [ngShow]="field.errorInForm"
      [afTranslate]="'field-type-number.error-range-value'">El valor no está dentro del rango permitido</p>
</div>
