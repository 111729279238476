import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';
import $ from 'jquery';
import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';

@Component({
  selector: 'result-search',
  templateUrl: './result-search.component.html'
})
export class ResultSearchComponent implements OnInit {
  skin: any = this.globalCfg.skin;
  filters: any = ['tipoConsulta', 'usuario', 'gestor', 'relevancia', 'etiquetas', 'materias', 'tipoConcepto', 'tipoPublicacion', 'estado', 'perfilUsuario', 'referencias'];
  resultSearch: any = {
    menu: {},
    searchResult: {},
    filterResults: {
      Results: [],
      // Products: [],
      // Evaluations: [],
      // Clients: [],
      // TaskTypes: [],
      // ReportTypes: [],
      // Authors: [],
      // Participants: [],
      // Status: [],
      // Sources: [],
      // FFolders: [],
      // FSubfolders: [],
      // Centros: [],
      // Tipologias: [],
      // TipoConcepto: [],
      // TipoConsulta: [],
      // Usuario: [],
      // Gestor: [],
      // Relevancia: [],
      // Etiquetas: [],
      // Materias: [],
      // Referencias: []
    },
    selectedTab: '',
    juridicaNumPages: this.apiProperties.juridicaNumPages?parseInt(this.apiProperties.juridicaNumPages):1,
    withoutResults: true,
    hasJuridical: this.context.areas.juridical && this.globalCfg.caps.legalReferences && this.skin !== 'icam-red',
    hasReports: this.skin !== 'icam-red',
    includeDeletedDocuments: false,
    includeArchivedConcepts: this.skin === 'icam-red',
    textSearch: this.route.snapshot.params['text'],
    textCollapse: this.translate.instant('result-search.collapse-results'),
    orderType: 'CreationDate',
    orderDirection: false,
    currentPage: 1,
    juridicaCurrentPage: 1,
    // pageSize: 10,
    configPagination: {
      pageSize: 10
    },
    showContent: true,
    showFSubfolders: false,
    loaderImg: this.commonUtilsService.configureLoaderImg(),
    searchType: 'buscador',
    currentResults: {},
    searching: {
      Documents: false,
      Tasks:     false,
      Reports:   false,
      Concepts:  false,
      Juridica:  false
    },
    JsessionId: '',
    juridicaTypeDocumentsEnum: {},
    juridicaNumDocuments: [],
    juridicaDocumentSelected: {},
    juridicaDocuments: {},
    currentJuridicaResults: [],
    hayJuridicaDocuments: false,
    searchFilter: {
        Products: [],
        Concepts: [],
        Clients: [],
        Evaluations: [],
        TaskTypes: [],
        ReportTypes: [],
        Authors: [],
        Participants: [],
        Formats: [],
        Status: [],
        Sources: [],
        Centros: [],
        Tipologias: [],
        FFolders: [],
        FSubfolders: []
    },
    selectProduct: {
      id: 'select-products',
      label: this.translate.instant('common.products'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectClient: {
      id: 'select-clients',
      label: this.translate.instant('result-search.clients'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectEvaluation: {
      id: 'select-implantation',
      label: this.translate.instant('common.implantations'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectTaskType: {
      id: 'select-task-type',
      label: this.translate.instant('result-search.task-types'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectReportType: {
      id: 'select-report-type',
      label: this.translate.instant('result-search.report-types'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectAuthor: {
      id:'select-author',
      label: this.translate.instant(this.skin === 'icam-red'?'all-documentation.creador':'result-search.authors'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectParticipant: {
      id: 'select-participant',
      label: this.translate.instant('result-search.participants'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectFormat: {
      id: 'select-format',
      label: this.translate.instant('result-search.formats'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectState: {
      id: 'select-state',
      label: this.translate.instant('result-search.states'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectOrigin: {
      id: 'select-origin',
      label: this.translate.instant('result-search.origins'),
      required: false,
      multiple: true,
      hiddenPill: true
    },
    selectCentros: {
      id: 'select-centros',
      label: this.translate.instant('result-search.centro'),
      required: false,
      hiddenPill: true
    },
    selectTipologias: {
      id: 'select-tipologias',
      label: this.translate.instant('result-search.tipologia'),
      required: false,
      hiddenPill: true
    },
    selectFFolder: {
      id: 'select-ffolder',
      label: this.translate.instant('result-search.folder'),
      required: false,
      hiddenPill: true
    },
    selectFSubfolder: {
      id: 'select-fsubfolder',
      label: this.translate.instant('result-search.subfolder'),
      required: false,
      hiddenPill: true
    },
  }
  constructor(private router: Router,
    private route: ActivatedRoute,
    private apiProperties: ApiPropertiesFactory,
    private context: ContextFactory,
    private globalCfg: GlobalCfgFactory,
    private loader: LoaderFactory,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private broadcast: BroadcastService,
    private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private filterColumnService: FilterColumnService,
    private documentActions: DocumentActionsService) { }

  ngOnInit(): void {
    this.configureInit();
  }

  configureInit(){
    if(this.skin === 'icam-red'){
        this.resultSearch.searchFilter.TipoConsulta = [];
        this.resultSearch.searchFilter.Usuario = [];
        this.resultSearch.searchFilter.Gestor = [];
        this.resultSearch.searchFilter.Relevancia = [];
        this.resultSearch.searchFilter.Etiquetas = [];
        this.resultSearch.searchFilter.Materias = [];
        this.resultSearch.searchFilter.FechaConsulta = '';
        this.resultSearch.searchFilter.TipoConcepto = '';
        this.resultSearch.searchFilter.TipoPublicacion = [];
        this.resultSearch.searchFilter.Estado = [];
        this.resultSearch.searchFilter.PerfilUsuario = [];
        this.resultSearch.searchFilter.CreationDate = '';
        this.getFieldsFilters();
    }
    this.loader.openLoader('search-result-from-header');
    this.getExpandedMenu();
    this.findSearch();
  }

  getFieldsFilters(){
    this.filters.forEach((filter: any) => {
      let text = '';
      let multiple = true;
      switch(filter){
          case 'materias':
              text = this.translate.instant('all-documentation.materia');
              break;
          case 'tipoConcepto':
              text = this.translate.instant('result-search.tipo-concepto');
              multiple = false;
              break;
          case 'tipoPublicacion':
              text = this.translate.instant('result-search.tipo-publicacion');
              break;
          case 'perfilUsuario':
              text = this.translate.instant('result-search.user-profile');
              break;
          default:
              text = this.translate.instant('all-documentation.'+filter);
              break;
      }
      this.resultSearch[filter+'Field']={
          id: filter+'-field',
          label: text,
          required: false,
          multiple: multiple,
          hiddenPill: true
      }
    });
    this.resultSearch.fechaConsultaField = {
        label:  this.translate.instant('all-documentation.consult-date'),
        id: 'consult-date-field',
        fromTo: true
    }
    this.resultSearch.creationDateField = {
        label:  this.translate.instant('concept-list.registration-date'),
        id: 'registro-date-field',
        fromTo: true
    }
  }

  findSearch(){
    this.getResultSearch();
    this.getConceptsSearch();
    if(this.resultSearch.hasJuridical){
        this.getBDJuridica();
    }
  }

  getResultSearch() {
    this.resultSearch.searching.Documents = true;
    this.resultSearch.searching.Tasks = true;
    this.resultSearch.searching.Reports = true;

    let encodedText = encodeURIComponent(this.resultSearch.textSearch);
    this.apiService.get('search?text=' + encodedText)
      .then((data: any)=> {
        if (data.Tasks && data.Tasks.Results && data.Tasks.Results.length){
            this.extractParticipants(data.Tasks.Results);
        }
        // Si no se está buscando conceptos y hay conceptos, primero se asigna data que es el resto y después se guardan los conceptos
        if(this.resultSearch.searchResult.Concepts && this.resultSearch.searchResult.Concepts.Results && !this.resultSearch.searching.Concepts) {
          let auxConcepts = cloneDeep(this.resultSearch.searchResult.Concepts);
          this.resultSearch.searchResult = data;
          this.resultSearch.searchResult.Concepts = auxConcepts;
        }else{
          this.resultSearch.searchResult = data;
        }

        this.resultSearch.searching.Documents = false;
        this.resultSearch.searching.Tasks     = false;
        this.resultSearch.searching.Reports   = false;
        this.searchFinished('general');
        this.deleteFilters();

        // Si hay resultados y ya se han buscado los conceptos -> se filtra
        if (!this.resultSearch.withoutResults && !this.resultSearch.searching.Concepts && this.resultSearch.currentResults && this.resultSearch.currentResults.Results && this.resultSearch.currentResults.Results.length > 0) {
            this.filterArray();
        }
      }, (errordata: any)=>{
          this.resultSearch.searching.Documents = false;
          this.resultSearch.searching.Tasks     = false;
          this.resultSearch.searching.Reports   = false;
          this.loader.closeError();
      });
  }

  extractParticipants(result: any){
    result.forEach((participants: any) => {
      participants.formatedParticipant = [];
      let participantsList = participants.Participants.split(',');
      participantsList.forEach((participant: any) => {
        let extractParticipant = participant.split('·');
        participants.formatedParticipant.push({
            participant: extractParticipant[0],
            isDeleted: !!parseInt(extractParticipant[1])
        });
      });
    });
  }

  getExpandedMenu() {
    this.apiService.getExpandedMenu()
      .then((data: any)=> {
        this.resultSearch.menu['Documents'] = data['menuSearchDocument'].menu;
        this.resultSearch.menu['Tasks'    ] = data['menuSearchSimple'  ].menu;
        this.resultSearch.menu['Reports'  ] = data['menuSearchSimple'  ].menu;
        this.resultSearch.menu['Concepts' ] = data['menuSearchSimple'  ].menu;
        this.resultSearch.menu['Deleted'  ] = data['menuSearchDeleted' ].menu;
        this.resultSearch.menu['Archived' ] = data['menuSearchArchived'].menu;
        this.resultSearch.menu['juridicaMenu'] = data['menuSearchJuridicaDocument'].menu;
      });
  }

  searchFinished(type: any) {
    if(this.resultSearch.withoutResults){
      switch (type) {
        case 'general':
            if (this.resultSearch.searchResult.Documents && this.resultSearch.searchResult.Documents.Results && this.resultSearch.searchResult.Documents.Results.length > 0) {
                this.resultSearch.selectedTab = "Documents";
                this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Documents);
                this.resultSearch.withoutResults = false;
            }
            else if (this.resultSearch.searchResult.Tasks && this.resultSearch.searchResult.Tasks.Results && this.resultSearch.searchResult.Tasks.Results.length > 0) {
                this.resultSearch.selectedTab = "Tasks";
                this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Tasks);
                this.resultSearch.withoutResults = false;
            }
            else if (this.resultSearch.searchResult.Reports && this.resultSearch.searchResult.Reports.Results && this.resultSearch.searchResult.Reports.Results.length > 0) {
                this.resultSearch.selectedTab = "Reports";
                this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Reports);
                this.resultSearch.withoutResults = false;
            } else {
                this.resultSearch.currentResults = [];
            }
            this.resultSearch.filterResults = cloneDeep(this.resultSearch.currentResults);
            this.closeLoader();
            break;
        case 'concepts':
            if (this.resultSearch.searchResult.Concepts && this.resultSearch.searchResult.Concepts.Results && this.resultSearch.searchResult.Concepts.Results.length > 0) {
                this.resultSearch.selectedTab = "Concepts";
                this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Concepts);
                this.resultSearch.withoutResults = false;
            }
            this.resultSearch.filterResults = cloneDeep(this.resultSearch.currentResults);
            this.closeLoader();
            break;
        case 'juridica':
            if (this.resultSearch.hayJuridicaDocuments) {
                this.changeTab('Juridica');
                this.resultSearch.withoutResults = false;
            }
            this.closeLoader();
            break;
        default:
            this.closeLoader();
            break;
      }
    }
    this.checkWithoutResultsLoader();
  }

  checkWithoutResultsLoader() {
    if (this.resultSearch.withoutResults && !this.resultSearch.searching.Documents && !this.resultSearch.searching.Tasks && !this.resultSearch.searching.Reports && !this.resultSearch.searching.Concepts && !this.resultSearch.searching.Juridica) {
        this.closeLoader();
    }
  }

  closeLoader() {
    this.loader.closeLoader(['search-result-from-header','front-login-login']);
  }

  changeTab(tab: any) {
    switch(tab){
        case 'Documents':
            if (this.resultSearch.searching.Documents || !this.resultSearch.searchResult.Documents) {
                return;
            }
            this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Documents);
            this.resultSearch.searchType = 'buscador';
            break;
        case 'Tasks':
            if (this.resultSearch.searching.Tasks || !this.resultSearch.searchResult.Tasks) {
                return;
            }
            this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Tasks);
            this.resultSearch.searchType = 'buscador';
            break;
        case 'Reports':
            if (this.resultSearch.searching.Reports || !this.resultSearch.searchResult.Reports) {
                return;
            }
            this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Reports);
            this.resultSearch.searchType = 'buscador';
            break;
        case 'Concepts':
            if (this.resultSearch.searching.Concepts || !this.resultSearch.searchResult.Concepts) {
                return;
            }
            this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Concepts);
            this.resultSearch.searchType = 'buscador';
            break;
        case 'Juridica':
            if (this.resultSearch.searching.Juridica || !this.resultSearch.hayJuridicaDocuments) {
                return;
            }
            this.resultSearch.searchType = 'juridica';
            break;
    }
    this.resultSearch.selectedTab = tab;
    this.deleteFilters();
    if(this.resultSearch.searchType === 'buscador'){
      this.resultSearch.filterResults = cloneDeep(this.resultSearch.currentResults);
      this.filterArray();
    }
  }

  deleteFilters(){
    this.broadcast.broadcast('resetFieldTypeList');
    if(this.skin === 'icam-red'){
      this.broadcast.broadcast('resetFieldTypeDate');
    }
  }

  getConceptsSearch() {
    this.resultSearch.searching.Concepts = true;
    let encodedText = encodeURIComponent(this.resultSearch.textSearch);
    this.apiService.get('search/type/Concepts?text=' + encodedText + '&onlyResults=false')
      .then((data: any)=> {
        this.resultSearch.searchResult.Concepts = data.Concepts;
        this.resultSearch.searching.Concepts    = false;
        this.searchFinished('concepts');
        if(data.Concepts.Results && data.Concepts.Results.length > 0 && this.searchFilterIsEmpty()){
            // Este caso se da cuando no hay resultados generales y si hay de juridica y ha obtenido antes los de juridica
            if (!this.resultSearch.currentResults) {
                this.resultSearch.currentResults = cloneDeep(this.resultSearch.searchResult.Concepts);
                this.resultSearch.filterResults = cloneDeep(this.resultSearch.currentResults);
            }
            this.filterArray();
        }else if((!data.Concepts.Results || data.Concepts.Results.length == 0) && !this.resultSearch.withoutResults && this.searchFilterIsEmpty()){
            // Este caso se da cuando si hay resultados generales, no hay de conceptos
            this.filterArray();
        }
      }, (errordata: any)=>{
          this.loader.closeError();
      });
  }

  searchFilterIsEmpty() {
    if (this.resultSearch.searchFilter["Products"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Concepts"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Clients"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Evaluations"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["TaskTypes"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["ReportTypes"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Authors"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Participants"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Formats"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Status"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Sources"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Centros"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["Tipologias"].length > 0) {
        return false;
    }
    if (this.resultSearch.searchFilter["FFolders"].length > 0) { // no se pone subfolders porque depende de folders. No deberia darse el caso de tener subfolder y no folder, pero por si acaso
        return false;
    }
    return true;
  }

  getBDJuridica() {
    this.resultSearch.searching.Juridica = true;
    this.apiService.get('search/patton/createsession/' + this.context.user.externalAccessId)
      .then((data: any)=> {
        if(data && data.JSESSIONID){
          this.resultSearch.JsessionId = data.JSESSIONID;
          this.getCountBDJuridica();
        }
      }, (errordata: any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('result-search.error-connecting-juridica'));
      });
  }

  getCountBDJuridica(){
    this.apiService.get('search/patton/getjuridicadocumentscount?text=' + this.resultSearch.textSearch +'&jSessionId=' + this.resultSearch.JsessionId)
      .then((data: any)=> {
        let resultObject: any = {};
        let i = 0;
        if(data){
            Object.entries(data).forEach((entry: any, index: number) => {
              let juridicaDocument = {
                name: this.getTitleJuridicaDocumentation(entry[0]),
                count: entry[1],
                numOrder: index,
                valueId: entry[0]
              }
              this.resultSearch.juridicaNumDocuments.push(juridicaDocument);
              resultObject[juridicaDocument.valueId] = i;
              i++;
            });
        }
        this.resultSearch.juridicaTypeDocumentsEnum = Object.freeze(resultObject);
        if(i > 0){
            this.setInitialDocuments();
        }else{
          this.resultSearch.searching.Juridica = false;
          this.searchFinished('juridica');
        }
      }, (errordata: any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('result-search.error-connecting-juridica'));
      });
  }

  setInitialDocuments() {
    for (let juridicaDocument of this.resultSearch.juridicaNumDocuments) {
      if (!isNaN(juridicaDocument.count) && juridicaDocument.count > 0) {
          this.selectJuridicaDocument(juridicaDocument, this.resultSearch.juridicaCurrentPage, true);
          break;
      }
    }
  }

  changeOpen(result: any) {
    result.openMenu = !result.openMenu;
    if(result.openMenu){
      if(!result.IsArchived && !result.IsDeleted){
        result.menuDocuments = [];
        this.resultSearch.menu[this.resultSearch.selectedTab].forEach((menuDocument: any) => {
          if(this.showOptionMenu(result, menuDocument)){
            result.menuDocuments.push(menuDocument);
          }
        });
      }else if(result.IsArchived){
        result.menuDocuments = this.resultSearch.menu.Archived;
      }else if(result.IsDeleted){
        result.menuDocuments = this.resultSearch.menu.Deleted;
      }
    }
  }

  showOptionMenu(result: any, optionMenu: any){
    // if(optionMenu.action === 'edit-local' || optionMenu.action === 'open' || (result.DocumenObjectId && result.TaskId && (!result.ConceptObjectId || result.ConceptObjectId === -1))){
    //   return false;
    // }else
    if(optionMenu.action === 'edit-local'){
      let index = result.Name.lastIndexOf('.');
      let extension = result.Name.substring(index + 1);
      optionMenu.icon = this.commonUtilsService.getFileExtension(extension);
      let showOption = !result.isDeleted && optionMenu.icon !== 'hide-icon' && this.skin === 'dark-blue';
      return showOption;
    }else{
      return !result.isDeleted && !result.IsArchived;
    }
  }

  changeDeleted(newValue: any) {
    this.resultSearch.includeDeletedDocuments = newValue;
    this.filterArray();
  }

  changeArchived(newValue: any) {
    this.resultSearch.includeArchivedConcepts = newValue;
    this.filterArray();
  }

  changeView(){
    this.commonUtilsService.goToStateUrl();
  }

  orderBy(type: any) {
      if (this.resultSearch.orderType === type) {
          this.resultSearch.orderDirection = !this.resultSearch.orderDirection;
      } else {
          this.resultSearch.orderType = type;
          this.resultSearch.orderDirection = false;
      }
  }

  collapseResult(){
      this.resultSearch.showContent = !this.resultSearch.showContent;
      this.resultSearch.textCollapse = this.translate.instant('result-search.expand-results');
      this.resultSearch.configPagination.pageSize = 25;
      if(this.resultSearch.showContent){
          this.resultSearch.textCollapse = this.translate.instant('result-search.collapse-results');
          this.resultSearch.configPagination.pageSize = 10;
      }
  }

  buttonMenu(action: any, result: any) {
      let editPathNeeded = true;
      if (action === 'origin' || action === 'juridica-view' || action === 'restore' || action === 'unarchive') {
          editPathNeeded = false;
      }
      if (editPathNeeded && !result.editPath) {
          this.getEditPath(action, result);
      }
      else {
          this.launchAction(action, result);
      }
  }

  getEditPath(action: any, result: any) {
    if(!result.ConceptObjectId || result.ConceptObjectId === -1){
        if(result.DocumentObjectId && result.TaskId){
          result.editPath = result.EvaluationId && result.EvaluationId !== -1? result.ClientId + '/' + result.EvaluationId + '/Tasks/' + result.TaskId + '/' + result.Name:  result.ClientId + '/Tasks/' + result.TaskId + '/' + result.Name;
        }else{
          result.editPath =  result.ClientId + '/' + result.EvaluationId + '/Reports/' + result.ReportObjectId + '/' + result.Name;
        }
        let index = result.Name.lastIndexOf('.');
        if (index > 0 && (index + 1 < result.Name.length)) {
            result.extension = result.Name.substring(index + 1);
        }
        this.launchAction(action, result);
    }else{
        this.loader.openLoader('front-login-login');
        this.apiService.get('documentobjects', result.ConceptObjectId)
        .then((data: any)=> {
          this.searchDocument(data, result);
          if (result.editPath) {
              this.launchAction(action, result);
          }
        });
    }
  }

  searchDocument(data: any, result: any){
    for (let documentSearch of data.documents) {
        if (documentSearch.documentObjectId === result.DocumentObjectId) {
            result.editPath = documentSearch.editPath;
            result.extension = documentSearch.extension;
            this.loader.closeLoader('front-login-login');
            break;
        }
    }
    if(data.subFolders && data.subFolders.length){
        this.searchInFolder(data.subFolders, result);
    }
  }

  searchInFolder(data: any, result: any){
    data.forEach((folder: any) => {
      this.searchDocument(folder, result);
    });
  }

  launchAction(action: any, result: any) {
    switch (action) {
        case 'download':
        case 'open':
            let openParams: any = {};
            for(let key in result){
              let newKey = key.charAt(0).toLowerCase() + key.slice(1);
              openParams[newKey] = result[key];
            }
            this.documentActions.doAction(action, openParams);
            break;
        case 'edit-local':
            this.editLocal(result);
            break;
        case 'origin':
            this.navigateToOrigin(result);
            break;
        case 'juridica-view':
            this.navigateToJuridica(result);
            break;
        case 'restore':
            this.restore(result);
            break;
        case 'unarchive':
            this.unarchive(result);
            break;
        default:
            break;
    }
  }

  editLocal(result: any) {
      let protocol = null;
      switch (result.extension.toUpperCase()) {
          case 'DOC':
          case 'DOCX':
              protocol = 'ms-word';
              break;
          case 'XLS':
          case 'XLSX':
              protocol = 'ms-excel';
              break;
          case 'PPT':
          case 'PPTX':
              protocol = 'ms-powerpoint';
              break;
      }
      if (protocol) {
          let spSite = (result.SharepointSiteUrl && result.SharepointSiteUrl !== '-')?result.SharepointSiteUrl:this.apiProperties.documentDepotUri;
          let libraryName = (result.DocumentLibraryName && result.DocumentLibraryName !== '-')?result.DocumentLibraryName:''//this.apiProperties.documentLibraryName;
          let appUri = protocol + ':ofe|u|' + spSite + libraryName + "/" + result.editPath;
          window.open(appUri);
      } else {
          console.log("Local edit: Unknow extension: " + result.extension);
      }
  }

  restore(result: any) {
    this.loader.openLoader('search-result-from-header');
    this.apiService.patch('documentobjects/fromrecyclebin', result.DocumentObjectId)
      .then((data: any)=> {
        result.IsDeleted = 0;
        this.refreshCurrentResultList(result, 'DocumentObjectId', 'IsDeleted');
        this.closeLoader();
      }, (errordata: any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('result-search.error-restoring'));
      });
  }

  unarchive(result: any) {
    this.loader.openLoader('search-result-from-header');
    this.apiService.patch('conceptobjects/archived', result.ConceptObjectId + '/' + 0)
      .then((data: any)=> {
        result.IsArchived = 0;
        this.refreshCurrentResultList(result, 'ConceptObjectId', 'IsArchived');
        this.navigateToOrigin(result);
        this.closeLoader();
      }, (errordata: any)=>{
          this.loader.closeError();
          this.errorModal(this.translate.instant('result-search.error-unarchiving'));
      });
  }

  refreshCurrentResultList(result: any, typeId: any, type: any){
      for (let originalResult of this.resultSearch.currentResults.Results.length) {
          if(result[typeId] === originalResult[typeId]){
              originalResult[type] = result[type];
              break;
          }
      }
  }

  navigateToOrigin(result: any) {
    if(this.resultSearch.selectedTab === 'Tasks'){
        this.openTask(result);
    }else if(this.resultSearch.selectedTab === 'Reports'){
        this.goToReport(result);
    }else if(result.ConceptObjectId && result.ConceptObjectId !== -1){
        if(result.ProductId === 'blanqueo' && result.EvaluationId === -1){
            this.goToConceptWindow(result);
        }else{
            this.goToConcept(result);
        }
    }else if(result.DocumentObjectId && result.TaskId){
        this.goToTask(result);
    }else{ // Si estamos en documents y es un report
        this.goToReport(result);
    }
  }

  openTask(task: any) {
    if (task.ConceptObjectId && task.ConceptObjectId !== -1) {
        this.goToConceptTask(task);
    } else {
        this.goToTask(task);
    }
  }

  goToConceptTask(task: any) {
      this.loader.openLoader('dashboard-panel-concept-task');
      this.router.navigateByUrl('evaluation/'+task.EvaluationId+'/folder/'+task.FolderId+'/concept/'+task.ConceptId+'/concept-evaluated/'+task.ConceptObjectId+'/tasks/'+task.TaskId);
  }

  goToTask(task: any) {
    this.loader.openLoader('dashboard-panel-generic-task');
    let taskId = task.TaskId?task.TaskId:'';
    this.router.navigate(['task', taskId]);
  }

  goToReport(report: any) {
    let url =  report.ReportTypeId <= 0?'/evaluation/' + report.EvaluationId+'/reports' : '/evaluation/' + report.EvaluationId + '/reports/' + report.ReportId + '/report-related/' + report.ReportObjectId;
    window.open(url, '_blank');
  }

  goToConcept(concept: any) {
      window.open('/evaluation/' + concept.EvaluationId +
          '/folder/' + concept.FolderId +
          '/concept/' + concept.ConceptId +
          '/concept-evaluated/' + concept.ConceptObjectId, '_blank');
  }

  goToConceptWindow(concept: any){
      if(concept.ClientId !== -1){
          window.open('/client/'+ concept.ClientId, '_blank');
      }else{
          window.open('/obligated-subject', '_blank');
      }
  }


  selectJuridicaDocument(document: any, page: any, hideLoader: any=false) {
    let valueId = document.valueId;
    if (this.resultSearch.juridicaDocuments[valueId] && this.resultSearch.juridicaDocuments[valueId][page]) {
        this.setActualJuridicaDocuments(document, page);
    }else {
        if(!hideLoader) {
          this.loader.openLoader('search-result-from-header');
        }
        let startAt = (page - 1) * this.resultSearch.configPagination.pageSize;
        let maxResults = this.resultSearch.juridicaNumPages * this.resultSearch.configPagination.pageSize;
        this.apiService.get('search/patton/getjuridicadocuments?text=' + this.resultSearch.textSearch + '&indice=' + valueId + '&jSessionId=' + this.resultSearch.JsessionId + '&startAt=' + startAt + '&maxResults=' + maxResults)
          .then((data: any)=> {
            if (!this.resultSearch.hayJuridicaDocuments) {
                this.resultSearch.searching.Juridica = false;
                this.resultSearch.hayJuridicaDocuments = true;
                this.searchFinished('juridica');
            }
            if (!this.resultSearch.juridicaDocuments[valueId]) {
                this.resultSearch.juridicaDocuments[valueId] = {};
            }
            let lengthDocs = (data && data.DOCUMENTOS) ? data.DOCUMENTOS.length : 0;
            if (lengthDocs > 0) {
                let numPaginas = Math.ceil(lengthDocs / this.resultSearch.configPagination.pageSize);
                for (let i = 0; i < numPaginas; i++) {
                    let elements = data.DOCUMENTOS.slice(i * this.resultSearch.configPagination.pageSize, (i + 1) * this.resultSearch.configPagination.pageSize);
                    this.convertJuridicaDocument(valueId, elements, page + i);
                }
                this.setActualJuridicaDocuments(document, page);
            }

            this.closeLoader();
          }, (errordata: any)=>{
              this.loader.closeError();
              this.errorModal(this.translate.instant('result-search.error-connecting-juridica'));
          });
    }
  }

  setActualJuridicaDocuments(document: any, page: any) {
    this.resultSearch.currentJuridicaResults = cloneDeep((this.resultSearch.juridicaDocuments[document.valueId][page]));
    this.resultSearch.juridicaDocumentSelected = document;
  }

  convertJuridicaDocument(document: any, elements: any, page:any) {
      let lengthDocs = elements.length;
      let docs = [];
      for (let i = 0; i < lengthDocs; i++) {
          let docL = elements[i];
          let doc  = this.createJuridicaElement(docL, document);
          docs[i] = doc;
      }
      this.resultSearch.juridicaDocuments[document][page] = docs;
  }

  createJuridicaElement(docL: any, document: any) {
      let tituloElement     = '';
      let entradillaElement = '';
      let resumenElement    = '';
      let marginal          = docL.MARGINAL;
      let nrefElement       = docL.NREF;
      let documentType      = document;

      let entradillaStr   = (docL.ENTRADILLA  ) ? docL.ENTRADILLA   : '';
      let tituloStr       = (docL.TITULO      ) ? docL.TITULO       : '';
      let descripcionStr  = (docL.DESCRIPCION ) ? docL.DESCRIPCION  : '';

      switch (this.resultSearch.juridicaTypeDocumentsEnum[document]) {
          case this.resultSearch.juridicaTypeDocumentsEnum.mementos:
          case this.resultSearch.juridicaTypeDocumentsEnum.doctrina_articulos:
          case this.resultSearch.juridicaTypeDocumentsEnum.notas_practicas:
          case this.resultSearch.juridicaTypeDocumentsEnum.tendencias_guidance:
          case this.resultSearch.juridicaTypeDocumentsEnum.novedades_guidance:
              tituloElement  = tituloStr;
              resumenElement = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.jurisprudencia:
              tituloElement  = descripcionStr + ' (EDJ ' + nrefElement + ')';
              resumenElement = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.doctrina:
              tituloElement  = tituloStr + ' (EDD ' + nrefElement + ')';
              resumenElement = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.legislacion:
              tituloElement     = tituloStr + ' (EDL ' + nrefElement + ')';
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.convenios:
              let boletin       = (docL.BOLETIN) ? ' ('         + docL.BOLETIN + ')' : '';
              let codigo        = (docL.CODIGO ) ? ' (código: ' + docL.CODIGO  + ')' : '';
              tituloElement     = tituloStr + boletin + codigo;
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.subvenciones:
              tituloElement  = tituloStr + ' (EDS ' + nrefElement + ')';
              resumenElement = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.comentarios:
              tituloElement     = tituloStr + ' (EDC ' + nrefElement + ')';
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.apuntesConsejos:
              tituloElement  = tituloStr + ' (EDC ' + nrefElement + ')';
              resumenElement = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.esquemas:
              tituloElement = tituloStr;
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.formularios:
              tituloElement     = tituloStr + ' (EDF ' + nrefElement + ')';
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.formulariosEfl:
          case this.resultSearch.juridicaTypeDocumentsEnum.casos_practicos:
              tituloElement     = tituloStr;
              entradillaElement = entradillaStr;
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.consultas:
              tituloElement     = tituloStr + ' (EDE ' + nrefElement + ')';
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.bibliografia:
              let autores    = (docL.AUTORES) ? ' Autores: ' + docL.AUTORES : '';
              let fecha      = (docL.FECHA  ) ? ' Fecha: ' + docL.FECHA   : '';
              tituloElement  = tituloStr;
              resumenElement = this.getFragmento(docL) + autores + fecha;
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.actum:
              tituloElement     = tituloStr;
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.ejemplos:
              tituloElement     = this.getTituloFromFragmento(docL); // FRAMENTOS/X/TITULO El apartado contenido de X
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.doctrina_libros:
          case this.resultSearch.juridicaTypeDocumentsEnum.empleo_publico:
              tituloElement = tituloStr;
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.otros:
              tituloElement     = tituloStr + ' (EDO ' + nrefElement + ')';
              entradillaElement = entradillaStr;
              resumenElement    = this.getFragmento(docL);
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.eventos:
              let frag       = this.getFragmento(docL);
              tituloElement  = frag + ' (EDH ' + nrefElement + ')';
              resumenElement = frag;
              break;
          default:
              break;
      }

      let doc = {
          entradilla:   entradillaElement,
          titulo:       tituloElement,
          resumen:      resumenElement,
          nref:         nrefElement,
          marginal:     marginal,
          documentType: documentType
      }

      return doc;
  }

  getFragmento(docL: any) {
      let fragmento = '';
      if (docL.FRAGMENTOS.length > 0) {
          // el fragmento puede venir con puntos suspensivos al inicio y final o no hacerlo
          fragmento = docL.FRAGMENTOS[0].CONTENIDO;
          let puntosSuspensivos = false;
          if (fragmento.startsWith('...')) {
              fragmento = fragmento.substring(3);
              puntosSuspensivos = true;
          }
          if (fragmento.substring(fragmento.length - 3) === '...') {
              fragmento = fragmento.substring(0, fragmento.length - 3);
              puntosSuspensivos = true;
          }
          fragmento = $('<div/>').append(fragmento.replace(/\"/g, '\'')).text();
          if (puntosSuspensivos) {
              fragmento = '...' + fragmento + '...';
          }
      }
      return fragmento;
  }

  getTituloFromFragmento(docL: any) {
      let titulo = '';
      let numFrags = docL.FRAGMENTOS.length;
      for (let i = 0; i < numFrags; i++) {
          let frag = docL.FRAGMENTOS[i];
          if (frag["TITULO"] && frag["CONTENIDO"]) {
              titulo = frag["CONTENIDO"];
              break;
          }
      }
      return titulo;
  }

  navigateToJuridica(result: any) {
      let ei = this.context.user.externalAccessId;
      let url = this.apiProperties.juridicaURL + 'login.do?ei=' + ei;
      switch (this.resultSearch.juridicaTypeDocumentsEnum[result.documentType]) {
          case this.resultSearch.juridicaTypeDocumentsEnum.formulariosEfl:
              url += "&producto_inicial=*&ji=presentarMarginalFormularioEfl('*', '" + result.nref + "', '" + result.marginal + "')";
              break;
          case this.resultSearch.juridicaTypeDocumentsEnum.mementos:
              url += "&producto_inicial=*&ji=presentarMarginalMemento('*', 'ES', '" + result.nref + "', '" + result.marginal + "')";
              break;
          default:
              url += '&producto_inicial=UNIVERSAL&nref=' + result.nref;
      }
      window.open(url);
  }

  searchInJuridica() {
    window.open(this.apiProperties.juridicaURL + 'login.do?producto=UNIVERSAL&orden=relevancia&universal=' + this.resultSearch.textSearch + '&subindices=' + this.resultSearch.juridicaDocumentSelected.valueId + '&ei=' + this.context.user.externalAccessId);
  }

  errorModal(text: any) {
      this.modalService.adviceModal(text, 'error:accept:warning');
  }

  itemSelected(type: any, newValue: any, showlist: any) {
    if (Array.isArray(this.resultSearch.searchFilter[type])) {
        this.resultSearch.searchFilter[type].pop();
        if ((newValue && newValue.length > 0) || (!isNaN(newValue) && newValue > 0)) {
            this.resultSearch.searchFilter[type].push(newValue);
        }
    } else {
        this.resultSearch.searchFilter[type] = newValue;
    }

    if (type == 'FFolders') {
        this.filterFSubfolder(newValue);
    }

    if (this.resultSearch.currentResults && this.resultSearch.filterResults) {
        this.filterArray(type, newValue, showlist);
    }
    this.resultSearch.activeDeleteFilters = this.findSomeSelected();
  }

  filterFSubfolder(folders: any) {
    if(folders){
        this.resultSearch.showFSubfolders = true;
        let subfoldersFiltered: any = []; // copia de los filtros por subcarpeta seleccionados
        let subfoldersNew: any = [];
        this.resultSearch.currentResults.FSubfolders.forEach((subfolder: any) => {// se recorren todas las subcarpetas del filtro (sin filtrar)
          if(this.resultSearch.searchFilter.FFolders.length > 0 && this.resultSearch.searchFilter.FFolders[0] === subfolder.parentFolderId){
            let subfolderClone = cloneDeep(subfolder);
            subfoldersNew.push(subfolderClone);
            if(this.resultSearch.searchFilter.FSubfolders.length > 0 && this.resultSearch.searchFilter.FSubfolders[0] === subfolder.listValueId){
                subfoldersFiltered.push(subfolder.listValueId);
            }
        }
        });
        if(subfoldersNew.length === 0){
            this.resultSearch.filterResults.FSubfolders = [];
        }else{
            this.resultSearch.filterResults.FSubfolders = subfoldersNew;
        }

        if(subfoldersFiltered.length === 0){
            this.resultSearch.searchFilter.FSubfolders = [];
        }else{
            this.resultSearch.searchFilter.FSubfolders.push(subfoldersFiltered);
        }
    }else{
        this.resultSearch.showFSubfolders = false;
        this.broadcast.broadcast('resetFieldTypeList', { fieldId: 'select-fsubfolder'});
    }
  }

  findSomeSelected(){
    let valuesList: any = Object.values(this.resultSearch.searchFilter);
    let filtered = false;
    for (let valueElement of valuesList) {
        if(valueElement?.length){
            filtered = true;
            break;
        }
    }
    return filtered;
  }

  filterArray(keyType: any=undefined, newValue: any=undefined, showlist: any=undefined) {
    this.resultSearch.filterResults.Results = cloneDeep(this.resultSearch.currentResults.Results);
    if(this.resultSearch.selectedTab === 'Documents' && !this.resultSearch.includeDeletedDocuments){
      this.resultSearch.filterResults.Results = this.resultSearch.filterResults.Results.filter((value:any)=>{
          return value.IsDeleted == 0;
        });
    }

    if(this.resultSearch.selectedTab === 'Concepts' && !this.resultSearch.includeArchivedConcepts){
      this.resultSearch.filterResults.Results = this.resultSearch.filterResults.Results.filter((value:any)=>{
        return value.IsArchived == 0;
      });
    }

    let keys = Object.keys(this.resultSearch.searchFilter);
    keys.forEach((key: any) => {// se recorren todas las subcarpetas del filtro (sin filtrar)
      let currentFilter = this.resultSearch.searchFilter[key];
      if (currentFilter && (!Array.isArray(this.resultSearch.searchFilter[key]) || Array.isArray(this.resultSearch.searchFilter[key]) && currentFilter.length > 0)) {
          if(key === 'FechaConsulta' || key === 'CreationDate'){
              this.getFilterRangeDate(currentFilter, key);
          }else
          if (Array.isArray(this.resultSearch.searchFilter[key])) {
              if(this.isMultipleCommaSeparator(key)){
                  this.filterMultipleArraySeparatorResult(key, currentFilter, true);
              }else if(this.isMultipleArraySeparator(key)){
                  this.filterMultipleArraySeparatorResult(key, currentFilter);
              }else{
                  this.filterArrayResult(key, currentFilter);
              }
          }else{
              this.filterResult(key, currentFilter);
          }
      }
    })

    let auxKeys = cloneDeep(keys); // van a ser los nombres de los filtros no aplicados
    keys.forEach((key: any) => {
        if (this.resultSearch.searchFilter[key] !== null && this.resultSearch.searchFilter[key].length > 0) {
            auxKeys.splice(auxKeys.indexOf(key), 1);
        }
    });

    auxKeys.forEach((key: any) => {
        let auxResults = cloneDeep(this.resultSearch.currentResults);
        this.resultSearch.filterResults[key] = cloneDeep(this.resultSearch.currentResults[key]);

        let distinctFilter = this.filterItems(key);
        if(auxResults[key]){
          auxResults[key].forEach((element: any) => {
              // si no hay en el menu un resultado con ese valor, se elimina del menu
              let exist = false;
              if(this.isMultipleCommaSeparator(key)){
                  distinctFilter.forEach((value: any) => {
                    let res = value.split(',');
                    res.forEach((elementArr: any) => {
                        if (element.listValueId === elementArr) {
                            exist = true;
                        }
                    })
                  })
              }else if(this.isMultipleArraySeparator(key)){
                distinctFilter.forEach((value: any) => {
                  value.forEach((elementArr: any) => {
                      if (element.listValueId === elementArr) {
                          exist = true;
                      }
                  })
                })
              }else{
                  exist = distinctFilter.indexOf(element.listValueId) > -1;
              }
              if(!exist){
                let indexE = this.findIndex(this.resultSearch.filterResults[key], element.listValueId);
                if (indexE >= 0) {
                  this.resultSearch.filterResults[key].splice(indexE, 1);
                }
              }
          })
        }
    })

    auxKeys = Object.keys(this.resultSearch.searchFilter);
    auxKeys.forEach((key: any) => {
        if (key !== keyType || (newValue !== undefined && newValue !== null && newValue.length === 0) || showlist === false) {
            let distinctFilter = this.filterItems(key);
            if (this.isMultipleCommaSeparator(key)) {
                let distinctFilterComma: any = [];
                distinctFilter.forEach((value: any) => {
                let res = value.split(',');
                    res.forEach((elementArr: any) => {
                        if (distinctFilterComma.indexOf(elementArr) == -1) {
                            distinctFilterComma.push(elementArr);
                        }
                    })
                })
                distinctFilter = distinctFilterComma;
            }
            else if (this.isMultipleArraySeparator(key)) {
                let distinctFilterArray: any = [];
                distinctFilter.forEach((value: any) => {
                    value.forEach((elementArr: any) => {
                        if (distinctFilterArray.indexOf(elementArr) == -1) {
                            distinctFilterArray.push(elementArr);
                        }
                    })
                })
                distinctFilter = distinctFilterArray;
            }
            if (this.resultSearch.filterResults[key]) {
                this.resultSearch.filterResults[key].forEach((element: any) => {
                    element.numElements = 0;
                });
                distinctFilter.forEach((element: any) => {
                    let keyId = this.getKeyElement(key);
                    let numApariciones = 0;
                    if (this.isMultipleCommaSeparator(key)) {
                        numApariciones = this.resultSearch.filterResults.Results.filter((value:any)=>{
                          return value[keyId].split(',').indexOf(element) >= 0;
                        }).length;
                    }
                    else if (this.isMultipleArraySeparator(key)) {
                        numApariciones = this.resultSearch.filterResults.Results.filter((value:any)=>{
                          return value[keyId] != null && value[keyId].indexOf(element) >= 0;
                        }).length;
                    }
                    else {
                        numApariciones = this.resultSearch.filterResults.Results.filter((value:any)=>{
                          return value[keyId] === element;
                        }).length;
                    }
                    let indexE = this.findIndex(this.resultSearch.filterResults[key], element);
                    if (indexE >= 0) {
                        this.resultSearch.filterResults[key][indexE].numElements = numApariciones;
                    }
                })
            }
        }
    })
    this.calculateTotalCount();
  }

  calculateTotalCount() {
    let numDocuments = (this.resultSearch.searchResult.Documents && this.resultSearch.searchResult.Documents.Results) ? this.resultSearch.searchResult.Documents.Results.length : 0;
    let numTasks     = (this.resultSearch.searchResult.Tasks     && this.resultSearch.searchResult.Tasks.Results    ) ? this.resultSearch.searchResult.Tasks.Results.length     : 0;
    let numReports   = (this.resultSearch.searchResult.Reports   && this.resultSearch.searchResult.Reports.Results  ) ? this.resultSearch.searchResult.Reports.Results.length   : 0;
    let numConcepts  = (this.resultSearch.searchResult.Concepts  && this.resultSearch.searchResult.Concepts.Results ) ? this.resultSearch.searchResult.Concepts.Results.length  : 0;

    if(this.resultSearch.filterResults.Results && this.resultSearch.filterResults.Results.length){
        this.resultSearch.totalCount = this.resultSearch.filterResults.Results.length;
    }else{
        this.resultSearch.totalCount = 0;
    }

    switch (this.resultSearch.selectedTab) {
        case "Documents":
            this.resultSearch.totalCount += numTasks + numReports + numConcepts;
            break;
        case "Tasks":
            this.resultSearch.totalCount += numDocuments + numReports + numConcepts;
            break;
        case "Reports":
            this.resultSearch.totalCount += numTasks + numDocuments + numConcepts;
            break;
        case "Concepts":
            this.resultSearch.totalCount += numTasks + numDocuments + numReports;
            break;
    }
  }

  getFilterRangeDate(currentFilter: any, key: any){
      if(currentFilter.indexOf(' - ')!== -1){
          let dates = {
              from: this.commonUtilsService.convertShortDate(currentFilter.split(' - ')[0]),
              to: this.commonUtilsService.convertShortDate(currentFilter.split(' - ')[1])
          };
          this.resultSearch.filterResults.Results = this.filterColumnService.filterByRangeDates(this.resultSearch.filterResults.Results, key, dates);
      }
  }

  isMultipleCommaSeparator(key: any) {
      if (key === 'Participants') {
          return true;
      }
      return false;
  }

  isMultipleArraySeparator(key: any) {
      if (key === 'Centros' || key === 'Tipologias' || key === 'Etiquetas' || key === 'Materias') {
          return true;
      }
      return false;
  }

  filterMultipleArraySeparatorResult(key: any, currentFilter: any, commaSeparator: any = false) {
    this.resultSearch.filterResults.Results = this.resultSearch.filterResults.Results.filter((result:any)=>{
      let exist = false;
      let array = Array.isArray(currentFilter[0]) ? currentFilter[0] : currentFilter;
      array.forEach((filterElement: any) => {
        let res = commaSeparator?result[this.getKeyElement(key)].split(','):result[this.getKeyElement(key)];
        res.forEach((filterElementRes: any) => {
          if (filterElement === filterElementRes) {
              exist = true;
          }
        })
      });
      return exist;
    });
  }

  filterArrayResult(key: any, currentFilter: any) {
    this.resultSearch.filterResults.Results = this.resultSearch.filterResults.Results.filter((result:any)=>{
      let exist = false;
      let array = Array.isArray(currentFilter[0]) ? currentFilter[0] : currentFilter;
      array.forEach((filterElement: any) => {
        if (result[this.getKeyElement(key)] === filterElement) {
            exist = true;
        }
      });
      return exist;
    });
  }

  filterResult(key: any, currentFilter: any) {
    this.resultSearch.filterResults.Results = this.resultSearch.filterResults.Results.filter((result:any)=>{
      return result[this.getKeyElement(key)] === currentFilter;
    });
  }

  filterItems(key: any) {
    let distinctValues: any = [];
    if(this.resultSearch.filterResults.Results?.length){
      this.resultSearch.filterResults.Results.filter((result:any)=>{
        let valOfValue = result[this.getKeyElement(key)];
        if (valOfValue && !distinctValues.includes(valOfValue)) {
            distinctValues.push(valOfValue);
        }
      });
    }
    return distinctValues;
  }

  findIndex(array: any, value: any) {
    let indexE = -1;
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        if (element.listValueId === value) {
            indexE = index;
            break;
        }
    }
    return indexE;
  }

  getKeyElement(value: any) {
    switch (value) {
        case 'Products':
            return 'ProductId'
        case 'Clients':
            return 'ClientId'
        case 'Evaluations':
            return 'EvaluationId'
        case 'Authors':
            return 'Author'
        case 'Formats':
            return 'Format'
        case 'Status':
            return 'Status'
        case 'Sources':
            return 'Source'
        case 'Participants':
            return 'Participants';
        case 'TaskTypes':
            return 'TaskType';
        case 'ReportTypes':
            return 'ReportTypes';
        case 'Centros':
            return 'Centros';
        case 'Tipologias':
            return 'Tipologias';
        case 'FFolders':
            return 'FFolderId';
        case 'FSubfolders':
            return 'FSubfolderId';
        case 'TipoConsulta':
            return 'TipoConsulta';
        case 'Usuario':
            return 'Usuario';
        case 'Gestor':
            return 'Gestor';
        case 'Relevancia':
            return 'Relevancia';
        case 'Etiquetas':
            return 'Etiquetas';
        case 'Materias':
            return 'Materias';
        case 'FechaConsulta':
            return 'FechaConsulta';
        case 'TipoConcepto':
            return 'TipoConcepto';
        case 'TipoPublicacion':
            return 'TipoPublicacion';
        case 'Estado':
            return 'Estado';
        case 'PerfilUsuario':
            return 'PerfilUsuario';
        case 'CreationDate':
            return 'CreationDate';
        default:
            return '';
    }
  }

  selectTipoConcepto(type: any, newValue: any, showlist: any){
    if(type === 'TipoConcepto' && !newValue && this.resultSearch.searchFilter[type] && !this.resultSearch.deleteAllFilters){
        this.resultSearch.deleteAllFilters = true;
        this.deleteFilters();
        this.itemSelected(type, newValue, showlist);
    }else if(type === 'TipoConcepto' && newValue){
        this.resultSearch.deleteAllFilters = false;
        this.itemSelected(type, newValue, showlist);
    }
  }

  replaceToColor(text: any){
      if(text){
          return '...'+ text.replaceAll('<em>', '<span class="result-search__highlights-text--red">').replaceAll('</em>', '</span>') +'...';
      }
      return '';
  }

  juridicaPageChanged() {
      this.selectJuridicaDocument(this.resultSearch.juridicaDocumentSelected, this.resultSearch.juridicaCurrentPage);
  }

  getNumElements(type: any) {
    switch (type) {
      case 'Documents':
          if(!this.resultSearch.searchResult.Documents || !this.resultSearch.searchResult.Documents.Results){
              return;
          }
          if(this.resultSearch.includeDeletedDocuments){
              return this.resultSearch.searchResult.Documents.Results.length;
          }else{
            return this.resultSearch.searchResult.Documents.Results.filter((value:any)=>{
              return value.IsDeleted == 0;
            }).length;
          }
      case 'Concepts':
          if(!this.resultSearch.searchResult.Concepts || !this.resultSearch.searchResult.Concepts.Results){
              return;
          }
          if(this.resultSearch.includeArchivedConcepts){
              return this.resultSearch.searchResult.Concepts.Results.length;
          }else{
            return this.resultSearch.searchResult.Concepts.Results.filter((value:any)=>{
              return value.IsArchived == 0;
            }).length;
          }
      case 'Tasks':
          if(!this.resultSearch.searchResult.Tasks || !this.resultSearch.searchResult.Tasks.Results){
              return;
          }
          return this.resultSearch.searchResult.Tasks.Results.length;
      case 'Reports':
          if(!this.resultSearch.searchResult.Reports || !this.resultSearch.searchResult.Reports.Results){
              return;
          }
          return this.resultSearch.searchResult.Reports.Results.length;
      default:
          return 0;
    }
  }

  getTitleJuridicaDocumentation(type: any){
    switch (type) {
      case 'mementos':
          return 'Mementos';
      case 'jurisprudencia':
          return 'Jurisprudencia';
      case 'doctrina':
          return 'Doctr. adtva.';
      case 'legislacion':
          return 'Legislación';
      case 'convenios':
          return 'Convenios';
      case 'subvenciones':
          return 'Subvenciones';
      case 'comentarios':
          return 'Comentarios';
      case 'apuntesConsejos':
          return 'Apuntes&Consejos';
      case 'esquemas':
          return 'Esq. Procesales';
      case 'formulariosEfl':
          return 'Formularios';
      case 'esquemasQuantor':
          return 'Esquemas';
      case 'consultas':
          return 'Consultas';
      case 'bibliografia':
          return 'Bibliografía';
      case 'actum':
          return 'Actualidad';
      case 'ejemplos':
          return 'Ejemplos';
      case 'casos_practicos':
          return 'Casos prácticos';
      case 'doctrina_libros':
          return 'Libros Doctrina';
      case 'doctrina_articulos':
          return 'Artículos Doctrina';
      case 'otros':
          return 'Otra documentación';
      case 'notas_practicas':
          return 'Notas Practicas';
      case 'tendencias_guidance':
          return 'Tendencias';
      case 'novedades_guidance':
          return 'Novedades';
      case 'eventos':
          return 'Eventos';
      case 'empleo_publico':
          return 'Empleo Público';
      default:
          return type;
    }
  }

}
