import { Injectable } from '@angular/core';
import { cloneDeep } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class StateManagementFactory {
  state:any = {
    // name: '',
    // params: null,
    // prevState: null,
    url: ''
  }
  // setState(name: string, params:any, prevState: any = ''){
  //   this.state.name = cloneDeep(name);
  //   this.state.params = cloneDeep(params);
  //   this.state.prevState = cloneDeep(prevState);
  // }
  setStateUrl(url: any){
    this.state.url = url;
  }
  getState(){
    return this.state;
  }
}
