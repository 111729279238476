<div class="front-login"
  *ngIf="frontLogin.showTemplate">
    <header class="front-login__header"
            [ngClass]="{'front-login__header--public': frontLogin.public && skin === 'dark-blue'}"
            *ngIf="!((frontLogin.showNoPermissionsWarning || (frontLogin.errorConect && frontLogin.isLandingLogin)) && skin === 'steel-blue')">
        <div class="front-login__header-login"
             *ngIf="!(frontLogin.public && skin === 'dark-blue')">
            <div class="front-login__go-home">
                <img [src]="frontLogin.logoHeader"
                    class="front-login__logo-header"
                    alt="Logo"/>
            </div>
            <a class="front-login__go-shop" href="https://www.efl.es/" target="_blank"
                *ngIf="skin === 'dark-blue'">
                <i class="lf-icon-shop front-login__go-shop-icon"></i>
                <span [afTranslate]="'front-login.shop'">
                    tienda
                </span>
            </a>
        </div>
    </header>
    <!-- Cabecera para Usuario sin permisos BE -->
    <div class="front-login__header"
        *ngIf="(frontLogin.showNoPermissionsWarning || (frontLogin.errorConect && frontLogin.isLandingLogin)) && skin === 'steel-blue'">
        <img class="preview-customization__logo-img"
            alt="Logo"
            [src]="frontLogin.logoHeader" />
        <div *ngIf="frontLogin.languages.length > 1">
            <div class="header-nav__custom-translates-list-block header-nav__custom-translates-list-block--front-login"
                ngbDropdown
                [open]="showList">
                <button id="dropdownBasic2"
                    class="header-nav__select-language-button"
                    ngbDropdownToggle>
                    <span class="header-nav__select-language">{{frontLogin.selectedLanguage.text}}</span>
                    <span class="header-nav__select-language-short">{{frontLogin.selectedLanguage.appLanguage}}</span>
                    <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                </button>
                <div class="header-nav__menus-block dropdown-menu"
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic2">
                    <div *ngFor="let language of frontLogin.languages">
                        <div ngbDropdownItem
                          class="common__language-item common__language-item"
                          (click)="changeAppLanguage(language)">
                          <span>{{language.text}}</span>
                          <span>({{language.specific}})</span>
                          <i class="lf-icon-check-round-full common__language-icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="front-login__container">
          <div class="front-login__login-block"
              [ngClass]="{'front-login__login-block--landing': frontLogin.isLandingLogin,
                  'front-login__login-block--info': frontLogin.unexpectedError}">
              <div *ngIf="!frontLogin.showNoPermissionsWarning && !frontLogin.showViewGenerate">
                  <div class="front-login__login-block-img"
                      [hidden]="!frontLogin.logo"
                      [ngClass]="{'front-login__login-block-img--with-error':frontLogin.error,
                          'front-login__login-block-img--reset-pass':frontLogin.resetPass,
                          'front-login__login-block-img--contact-login': frontLogin.isLandingLogin,
                          'front-login__login-block-img--unexpected-error': frontLogin.unexpectedError}">
                      <img [src]="frontLogin.logo"
                          alt="Logo"
                          class="front-login__logo-img"/>
                  </div>
                  <div class="front-login__title-restricted"
                      *ngIf="frontLogin.isLandingLogin && !frontLogin.errorConect"
                      [afTranslate]="'login-block.acces-content'">Acceso a contenido restringido</div>
                  <div class="front-login__input-error-text front-login__input-error-text--error-all"
                          [hidden]="!(!frontLogin.isLandingLogin && frontLogin.errorConect)">
                      {{frontLogin.errorLoginMessage}}
                  </div>
                  <div class="front-login__input-error-text front-login__input-error-text--error-all"
                          [hidden]="!(frontLogin.error && frontLogin.isLandingLogin && !frontLogin.errorConect)"
                          [afTranslate]="'front-login.error-email'">El email introducido no es correcto.</div>

                  <form class="front-login__contact-login"
                          *ngIf="frontLogin.isLandingLogin"
                          [hidden]="frontLogin.errorConect">
                      <div class="front-login__text">{{frontLogin.inputLabel}}</div>
                      <div class="front-login__input-block front-login__input-block--contact-login">
                          <div class="front-login__input-block-icon">
                              <i [class]="'lf-icon-mail front-login__icon '+frontLogin.inputIcon"></i>
                          </div>
                          <input type="text"
                              class="front-login__input-block-text"
                              autocomplete="off"
                              required
                              [(ngModel)]="frontLogin.landingLoginForm.login"
                              [disabled]="frontLogin.working"
                              [placeholder]="frontLogin.inputPlaceholder">
                          <i class="lf-icon-close-round-full front-login__input-error-icon"
                              [hidden]="!frontLogin.errorLogin"></i>
                      </div>
                      <button class="front-login__login-button front-login__login-button--landing-login"
                          [disabled]="frontLogin.working"
                          (click)="doLogin(frontLogin.landingLoginForm)">{{frontLogin.buttonText}}</button>
                      <div class="front-login__input-error-text"
                          [hidden]="!frontLogin.errorLogin"
                          [afTranslate]="'front-login.email-required'">Debe introducir un email</div>
                  </form>
                  <div [hidden]="!(!frontLogin.isLandingLogin && !frontLogin.unexpectedError)">
                      <form class="front-login__login-form"
                          [hidden]="frontLogin.resetPass"
                          novalidate>
                          <div class="front-login__block-input-with-error">
                              <div class="front-login__input-block">
                                  <div class="front-login__input-block-icon">
                                      <i class="lf-icon-user front-login__icon"></i>
                                  </div>
                                  <input type="text"
                                      name="login"
                                      class="front-login__input-block-text"
                                      [ngModelOptions]="{standalone: true}"
                                      autocomplete="off"
                                      required
                                      [(ngModel)]="frontLogin.credentials.login"
                                      (input)="frontLogin.emptyLogin = frontLogin.credentials.login === ''"
                                      [disabled]="frontLogin.working"
                                      [placeholder]="'common.user'| afTranslate">
                                    <i
                                        *ngIf="frontLogin.emptyLogin"
                                        class="lf-icon-close-round-full front-login__input-error-icon">
                                    </i>
                              </div>
                                <div
                                    *ngIf="frontLogin.emptyLogin"
                                    [afTranslate]="'front-login.field-required'"
                                    class="front-login__input-error-text">
                                    _Este campo es obligatorio
                                </div>
                          </div>
                          <div class="front-login__block-input-with-error">
                              <div class="front-login__input-block">
                                  <div class="front-login__input-block-icon">
                                      <i class="lf-icon-lock front-login__icon"></i>
                                  </div>
                                  <input type="password"
                                      name="password"
                                      class="front-login__input-block-text"
                                      [ngModelOptions]="{standalone: true}"
                                      autocomplete="off"
                                      required
                                      (input)="frontLogin.emptyPassword = frontLogin.credentials.password === ''"
                                      [(ngModel)]="frontLogin.credentials.password"
                                      [disabled]="frontLogin.working"
                                      [placeholder]="'login-block.password'| afTranslate">
                                    <i
                                        *ngIf="frontLogin.emptyPassword"
                                        class="lf-icon-close-round-full front-login__input-error-icon">
                                    </i>
                              </div>
                                <div
                                    *ngIf="frontLogin.emptyPassword"
                                    [afTranslate]="'front-login.field-required'"
                                    class="front-login__input-error-text"
                                    >
                                    _Este campo es obligatorio
                                </div>
                          </div>

                          <div class="front-login__remember-block">
                              <div class="float-start">
                                  <label for="front-login-remember"
                                          class="front-login__remember-check-box">
                                      <input id="front-login-remember"
                                          type="checkbox"
                                          class="front-login__checkbox-hidden"
                                          [ngModelOptions]="{standalone: true}"
                                          [(ngModel)]="frontLogin.rememberLogin">
                                      <i class="lf-icon-check front-login__checkbox-icon"
                                          [hidden]="!frontLogin.rememberLogin"></i>
                                  </label>
                                  <p class="front-login__remember-text"
                                      [afTranslate]="'front-login.remember'">Recordar</p>
                              </div>
                              <a class="front-login__remember-pass"
                                  (click)="resetPassword()"
                                  [afTranslate]="'front-login.forgot-password'">¿Olvidaste tu contraseña?</a>
                          </div>
                          <button class="front-login__login-button"
                                  [disabled]="frontLogin.working"
                                  (click)="doLogin(frontLogin.credentials)"
                                  [afTranslate]="'front-login.init-session'">Iniciar sesión</button>
                      </form>
                      <form [ngShow]="frontLogin.resetPass"
                          class="front-login__login-form"
                          name="changePass"
                          novalidate>
                          <p class="front-login__reset-pass-title"
                              [afTranslate]="'front-login.remember-password'">_Recordar contraseña</p>
                          <div class="front-login__input-block">
                              <div class="front-login__input-block-icon">
                                  <i class="lf-icon-user front-login__icon"></i>
                              </div>
                              <input type="text"
                                  name="user"
                                  class="front-login__input-block-text"
                                  autocomplete="off"
                                  required
                                  [(ngModel)]="frontLogin.forgotUser"
                                  placeholder="{{frontLogin.userText}}">
                          </div>
                          <p class="front-login__reset-pass-info"
                              [afTranslate]="'front-login.info-access-keys'">_Te enviaremos un correo electrónico para poder resetear tus claves de acceso.</p>
                          <button class="front-login__login-button front-login__login-button--continue-reset"
                              (click)="sendReset()"
                              [afTranslate]="'common.continue'">_Continuar</button>
                          <div [ngShow]="frontLogin.errorType"
                              class="front-login__user-error">{{frontLogin.errorType}}</div>
                      </form>
                  </div>
                  <div [ngShow]="frontLogin.unexpectedError"
                      class="front-login__block-info-reset-pass">
                      <i [class]="frontLogin.icon +' front-login__input-icon'"></i>
                      <p class="front-login__unexpected-text">{{frontLogin.resetPassTitle}}</p>
                      <p class="front-login__unexpected-message">{{frontLogin.resetPassText}}</p>
                  </div>
              </div>
              <div *ngIf="!frontLogin.showNoPermissionsWarning && frontLogin.showViewGenerate && !frontLogin.unexpectedError">
                  <div [ngHide]="frontLogin.generatedPassOk || frontLogin.generateNewPasswordExpired">
                      <div class="front-login__login-block-img"
                              [ngShow]="frontLogin.logo">
                          <img [src]="frontLogin.logo"
                              alt="Logo"
                              class="front-login__logo-img"/>
                      </div>
                      <form class="front-login__login-form"
                          name="generatePassword"
                          novalidate>
                          <p class="front-login__reset-pass-title front-login__reset-pass-title--generate-password"
                              [afTranslate]="'front-login.generate-password'">_Generar contraseña</p>
                          <p class="front-login__enter-new-password"
                              [ngClass]="{'front-login__enter-new-password--hidden': frontLogin.notSecurePassword}"
                              [afTranslate]="'front-login.enter-new-password'">_Por favor introduce una nueva contraseña</p>
                          <div class="front-login__input-block">
                              <div class="front-login__input-block-icon">
                                  <i class="lf-icon-lock front-login__icon"></i>
                              </div>
                              <input type="password"
                                  name="newpassword"
                                  class="front-login__input-block-text"
                                  [ngClass]="{'front-login__input-block-text--error': frontLogin.newPassword.passwordError}"
                                  autocomplete="off"
                                  required
                                  [(ngModel)]="frontLogin.newPassword.password"
                                  [disabled]="frontLogin.working"
                                  placeholder="{{'reset-password.new-password' | afTranslate}}">
                          </div>
                          <div class="front-login__input-block">
                              <div class="front-login__input-block-icon">
                                  <i class="lf-icon-lock front-login__icon"></i>
                              </div>
                              <input type="password"
                                  name="repeatpassword"
                                  class="front-login__input-block-text"
                                  [ngClass]="{'front-login__input-block-text--error': frontLogin.newPassword.repeatPasswordError}"
                                  autocomplete="off"
                                  required
                                  [(ngModel)]="frontLogin.newPassword.repeatPassword"
                                  [disabled]="frontLogin.working"
                                  placeholder="{{'reset-password.repeat-password' | afTranslate}}">
                          </div>
                          <button class="front-login__login-button front-login__login-button--continue-generate"
                              [disabled]="frontLogin.working"
                              (click)="generateNewPassword()"
                              [afTranslate]="'common.continue'">Continuar</button>
                          <p class="front-login__text-no-security"
                              [ngShow]="frontLogin.notSecurePassword || frontLogin.noMatchPassword"
                              [afTranslate]="frontLogin.messageInfoError"></p>
                      </form>
                  </div>
                  <div [ngShow]="frontLogin.generatedPassOk || frontLogin.generateNewPasswordExpired">
                      <div class="front-login__login-block-img"
                              [ngShow]="frontLogin.logo">
                          <img [src]="frontLogin.logo"
                              alt="Logo"
                              class="front-login__logo-img"/>
                      </div>
                      <div class="front-login__block-generate-pass-ok">
                          <i [class]="frontLogin.iconGeneratedPass + ' front-login__error-message-icon'"></i>
                          <p class="front-login__info-text">{{frontLogin.textInfoGeneratedPass}}</p>
                          <span class="front-login__link-external-web"
                              (click)="goToLoginView()"
                              [afTranslate]="'front-login.go-login'">_Go to login page</span>
                      </div>
                  </div>
              </div>
              <no-permissions *ngIf="frontLogin.showNoPermissionsWarning"
                  [jwtToken]="frontLogin.jwtToken"
                  [continueText]="frontLogin.noPermissionsContinueText"
                  (launchAction)="clickAction($event)"></no-permissions>
              <div class="front-login__error-message"
                  [ngClass]="{'front-login__error-message--error-login':frontLogin.showbackButton,
                      'front-login__error-message--external-login': frontLogin.showButtonExternalWeb}"
                  [ngShow]="frontLogin.errorConect && frontLogin.isLandingLogin">
                  <i [class]="frontLogin.icon+' front-login__error-message-icon'"></i>
                  <div class="front-login__message"
                      [ngClass]="{'front-login__message--with-button':frontLogin.showbackButton,
                          'front-login__message--external-login': frontLogin.showButtonExternalWeb}">
                      <p class="front-login__first-text">{{frontLogin.errorText}}</p>
                      <span>{{frontLogin.error}}</span>
                  </div>
                  <button class="front-login__try-again-button"
                      [hidden]="!frontLogin.showbackButton"
                      (click)="tryAgain()">
                      <span [afTranslate]="'front-login.try-again'">_Volver a intentar</span>
                  </button>
                  <div [hidden]="!frontLogin.showButtonExternalWeb">
                      <a class="front-login__link-external-web"
                          [href]="frontLogin.externalWebUrl"
                          [afTranslate]="'front-login.go-login'">_Go to login page</a>
                  </div>
              </div>
              <div class="front-login__information-block"
                  [ngClass]="{'front-login__information-block--reset-pass':frontLogin.resetPass && !frontLogin.isLandingLogin,
                      'front-login__information-block--reset-error front-login__information-block--reset-info': frontLogin.unexpectedError && !frontLogin.isLandingLogin,
                      'front-login__information-block--login-uk': skin === 'uk-steel-blue',
                      'front-login__information-block--generate-pass': frontLogin.showViewGenerate}">
                  <p [class]="'front-login__information-question front-login__information-question--'+skin"
                      [afTranslate]="'front-login.information-question'"
                      [hidden]="!(!frontLogin.isLandingLogin || (frontLogin.isLandingLogin && (!frontLogin.public || ((skin === 'steel-blue' || skin === 'icam-red') && frontLogin.errorConect))))">¿Necesitas ayuda?</p>
                  <div class="front-login__information-contact"
                      [hidden]="!(!frontLogin.isLandingLogin || (frontLogin.isLandingLogin && frontLogin.errorConect && (skin === 'steel-blue' || skin === 'icam-red')))">
                      <span [afSiteTerms]="'tel'">
                        <!-- {{"tel" | afSiteTerms}} -->
                    </span>
                      <span class="front-login__separator-info">|</span>
                      <a class="front-login__information-contact-email"
                          [href]="'mailto://'+frontLogin.mailContact">{{frontLogin.mailContact}}</a>
                  </div>
                  <div class="front-login__information-contact"
                      [hidden]="!(frontLogin.isLandingLogin && !frontLogin.public)">
                      <span [afTranslate]="'front-login.contact-admin'">Contacta con el administrador de la tarea </span>
                  </div>
              </div>
          </div>
    </div>
    <div class="front-login__footer">
        <compliance-footer configure-options="frontLogin.footerConceptPublic"
            [public]="frontLogin.public">
        </compliance-footer>
    </div>
</div>
