import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeToNameFactory{
  mapped: any = new Map();

  constructor() {
    this.mapped.set('application/octet-stream', 'content-type.other-archives')
      .set('application/msword', 'content-type.word-document-doc')
      .set('application/pdf', 'content-type.acrobat-pdf')
      .set('application/vnd.ms-excel', 'content-type.excel-spreadsheet-xls')
      .set('application/vnd.ms-powerpoint', 'content-type.powerpoint-presentation-ppt')
      .set('application/vnd.openxmlformats-officedocument.presentationml.presentation', 'content-type.powerpoint-presentation-pptx')
      .set('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'content-type.excel-spreadsheet-xlsx')
      .set('application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'content-type.word-document-docx')
      .set('image/jpeg', 'content-type.image-archive-jpeg')
      .set('image/png', 'content-type.image-archive-png')
      .set('message/rfc822', 'content-type.mail-archive')
      .set('video/quicktime', 'content-type.video-text')
      .set('text/csv', 'content-type.excel-spreadsheet-csv')
      .set('text/plain', 'content-type.text-archive')
      .set('video/mp4', 'content-type.mp4-archive')
      .set('application/x-zip-compressed', 'content-type.zip-archive');
  }

}
