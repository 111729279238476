import { Component, Input } from '@angular/core';

@Component({
  selector: 'box-protection',
  templateUrl: './box-protection.component.html'
})
export class BoxProtectionComponent{

  @Input() protectionData: any = null;
  @Input() type: any = null;

}
