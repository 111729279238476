import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ContextFactory } from "@shared/factories/context/context.factory";
import { GlobalCfgFactory } from "@shared/factories/global-cfg/global-cfg.factory";
import { AfTranslateFactory } from "@shared/modules/translate/factory/translate.factory";
import { ApiServiceService } from "@shared/services/api-service/api-service.service";
import { BroadcastService } from "@shared/services/broadcast/broadcast.service";
import { CommonUtilsService } from "@shared/services/common-utils/common-utils.service";
import { ModalServiceService } from "@shared/services/modal-service/modal-service.service";
import { cloneDeep } from "lodash";


@Component({
  selector: 'add-alert',
  templateUrl: './add-alert.component.html'
})
export class AddAlertComponent implements OnInit{

  subscribers: any = {};
  constructor(
    private context:ContextFactory,
    private globalCfg:GlobalCfgFactory,
    private translate:AfTranslateFactory,
    private apiService:ApiServiceService,
    private commonUtilsService:CommonUtilsService,
    private activeModal:NgbActiveModal,
    private modalService:ModalServiceService,
    private broadcastService:BroadcastService
  ) { }

  @Input() configuration:any;

  skin:string = this.globalCfg.skin;
  addAlert:any = {
    showText: true,
    productId: this.context.settings.defaultProduct,
    stepState: 1,
    daysBefore: 0,
    daysAfter: 0,
    addAlert: {
      numDays: 0,
      hour: 0,
      delayUnits: 0
    },
    tinyMceOptions: {
      noEditable: false,
      height: 187,
      placeholder: this.translate.instant('common.add-comment')
    },
    whenField: {
        id: 'add-alert-when',
        required: true
    },
    numberDaysInfo: {
        id: 'add-alert-days',
        required: true,
        fieldTypeId: 'num',
        defaultValue: 1
    },
    repeatInterval: {
        period: 'interval',
        during: '1',
        every: '1',
        everyTime: {listValueId: 'd', text: this.translate.instant('repeat-pattern.days')},
        ending:  {listValueId: 0, text: this.translate.instant('add-alert.repeat-time-0')}
    },
    fieldReferenceDate: {
        required: true
    },
    titleAlert: {
        required: true
    },
    notificationField: {
        fieldId: 'add-alert-notification-step',
        fieldTypeId: 'rich',
        isEditable: true,
        height: 185,
        richId: this.commonUtilsService.createId()
    },
    customNotificationField: {
        fieldId: 'custom-notification-field',
        fieldTypeId: 'rich',
        required: true,
        isEditable: true,
        height: 185,
        richId: this.commonUtilsService.createId()
    },
    statesField: {
        id: 'add-alert-states',
        required: true
    }
  };

  ngOnInit() {
    let that = this.addAlert;
    let config = this.configuration;
    that.repeatIntervalInit = cloneDeep(that.repeatInterval);
    that.type = config.type ? config.type.toLowerCase() : config.type;
    that.alert = config.alert;
    that.concept = config.concept;
    that.defaultHour = config.defaultHour;
    that.referenceDate = (config.from === 'fieldTypeAlert')?true:false;
    that.notifyStep = Boolean(config.from === 'processOpsModal' && that.concept?.allowPublicShare && (that.concept?.identifierFieldId || that.concept?.namedKey === 'Consulta'));
    that.designType = (that.type === 'processgeneral' ? 'process' : that.type);
    that.designType = (that.type === 'task' ? 'date' : that.designType);
    that.contactsField = {
        typeConfiguration: 'alert',
        fieldType: 'multiple',
        typeCall: 'contacts',
        required: true,
        selectContacts: []
    }

    if (config.from === 'processOpsModal') {
        this.getProcessTags();
    }

    that.titleAlert.label = this.translate.instant('common.title-alert');
    that.numberDaysInfo.label = this.translate.instant('add-alert.number-days');
    that.fieldReferenceDate.label= this.translate.instant('add-alert.reference-date');
    that.whenField.label = (that.type === 'date' ? this.translate.instant('add-alert.date-tracking') : this.translate.instant('add-alert.notification-moment'));
    that.statesField.label = this.translate.instant('add-alert.process-state');
    that.title = (that.alert ? this.translate.instant('add-alert.edit-alert') : this.translate.instant('add-alert.add-alert'));
    that.warningText = this.translate.instant('add-alert.warning-notification');

    this.configureSteps();
    this.configurePatterns();
    this.getAlertTypes();
    this.configureAlert();

    if (that.designType === 'process') {
        this.getStates(that.concept.conceptId);
    }

    if(that.alert && that.alert.referenceDate){
        this.itemSelected('referenceDate',that.alert.referenceDate);
    }
  }

  getProcessTags(){
    let that = this.addAlert;
    this.apiService.get('processgeneralalerts/process/' + that.concept.conceptId + '/tags').then(
      (data:any) => {
        that.customNotificationField.bookmarksTag = data;
        if (that.alert && that.alert.customTemplate) {
            that.customTemplateSwitch = true;
            that.customNotificationField.description = that.alert.customTemplate;
        }
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
        if(this.configuration.evaluationInfo?.snippets){
          this.getSnippetsList();
        }else{
          that.snippetsLoaded = true;
        }
    });
  }

  getSnippetsList(){
    let that = this.addAlert;
    this.apiService.get('snippets/product/' + that.productId).then(
      (data:any) => {
        that.customNotificationField.snippetsTag = data;
      },
      (error:any) => {
        console.error(error);
      }
    ).finally(() => {
        that.snippetsLoaded = true;
    });
  }

  getAlertTypes() {
    let that = this.addAlert;
    this.apiService.get(that.type + 'alerts/types').then(
      (data:any) => {
          that.whenList = this.commonUtilsService.editList(data, 'alertTypeId', 'alertType');
          if(that.alert && that.alert.dateAlertTypeId){
              that.alert.alertTypeId = that.alert.dateAlertTypeId;
              this.itemSelected('when', that.alert.alertTypeId);
              this.itemSelected('numDays', that.alert.numDays);
          }
          if(that.alert && that.alert.alertTypeId){
              switch(that.alert.alertTypeId){
                  case 2:
                      that.numberDaysInfo.label= this.translate.instant('add-alert.number-days-before');
                      break;
                  case 3:
                      that.numberDaysInfo.label= this.translate.instant('add-alert.number-days');
                      break;
              }
          }
          that.chargedAlertTypes = true;
      },
      (error:any) => {
        console.error(error);
        that.whenList = [];
        that.chargedAlertTypes = true;
      }
    );
  }

  getStates(processId:string) {
    this.apiService.get('processes/' + processId + '/states').then(
      (data:any) => {
        this.addAlert.processStates = this.commonUtilsService.editList(data, 'stateId', 'name');
      },
      (error:any) => {
        console.error(error);
      }
    );
  }

  configureSteps(){
    let that = this.addAlert;
    let steps = [
        {name: this.translate.instant('header-nav.title-tooltip-configuration')},
        {name: this.translate.instant('contacts.recipients')}
    ];
    if (that.notifyStep) {
        steps.push({name: this.translate.instant('add-alert.notify-step')});
        let noEditable = true;
        if(that.alert){
            noEditable = !that.alert.notifyToIdentifying;
        }
        that.notificationField.label = this.translate.instant('add-alert.add-note-notification');
        that.notificationField.noEditable = noEditable;
        that.customNotificationField.label = this.translate.instant('add-alert.template-text');
    }
    that.steps = steps;
  }

  configurePatterns(){
    let that = this.addAlert;
    that.frecuencyList = [
      {listValueId: 'd', text: this.translate.instant('repeat-pattern.days')},
      {listValueId: 'w', text: this.translate.instant('repeat-pattern.week-s')},
      {listValueId: 'm', text: this.translate.instant('repeat-pattern.month')},
      {listValueId: 'y', text: this.translate.instant('repeat-pattern.year-s')}
    ];
    that.frecuencyRepeatList = [
      {listValueId: 0, text: this.translate.instant('add-alert.repeat-time-0')},
      {listValueId: 1, text: this.translate.instant('add-alert.repeat-time-1')}
    ];
    for(let i = 2; i < 11; i++){
        let frequencyRepeat = {listValueId: i, text: this.translate.instant('add-alert.repeat-time-'+i)};
        that.frecuencyRepeatList.push(frequencyRepeat);
    }
    if(that.designType === 'process'){
        let hour = {listValueId: 'h', text: this.translate.instant('repeat-pattern.hour-s')};
        that.frecuencyList.splice(0, 0, hour);
    }
  }

  itemSelected(type:string, newValue:any){
    let that = this.addAlert;
    that.addAlert[type] = newValue;
    if (type === 'when' && that.type === 'date' && newValue === 3) {
      this.updateFieldLabel('numberDaysInfo', this.translate.instant('add-alert.number-days'));
    } else if (type === 'when' && that.type === 'date' && newValue === 2) {
      this.updateFieldLabel('numberDaysInfo', this.translate.instant('add-alert.number-days-before'));
    }else if(type === 'stateId'){
        that.statesField.error = false;
    }
  }

  updateFieldLabel(fieldProp:string, newLabel:string) {
    let that = this.addAlert;
    let fieldCopy = cloneDeep(that[fieldProp]);
    fieldCopy.label = newLabel;
    that[fieldProp] = fieldCopy;
  }

  configureAlert(){
    let that = this.addAlert;
    if (that.alert) {
        this.configureEditAlert();
    } else {
        if(that.type === 'document'){
            that.addAlert.when = 1;
        }
        if (that.designType === 'process') {
            that.addAlert.when = '1';
            that.addAlert.delayTimeUnit = that.alert && that.alert.delayTimeUnit ? this.commonUtilsService.configureSelectedTimeUnit(that.alert.delayTimeUnit, that.frecuencyList, 1) : that.frecuencyList[1];
        }
        if(that.type === 'date'){
            that.addAlert.hour = that.defaultHour?that.defaultHour:'00:00:00';
        }
    }
  }

  configureEditAlert(){
    let that = this.addAlert;
    that.addAlert.name = that.alert.title;
    that.addAlert.when = that.alert.alertTypeId;
    if (that.alert.source === 'Date' || that.alert.source === 'Task') {
        that.addAlert.hour = that.alert.hour?that.alert.hour:'00:00:00';
        that.addAlert.repeatDays = that.alert.repeatDays;
        that.addAlert.numDays = that.alert.numDays;
        that.addAlert.timeUnit = that.alert && that.alert.timeUnit ? this.commonUtilsService.configureSelectedTimeUnit(that.alert.timeUnit, that.frecuencyList, 0) : that.frecuencyList[1];
    }
    if (that.alert.source === 'Process' || that.alert.source === 'ProcessGeneral') {
        if(that.addAlert.when){
            that.addAlert.when = that.addAlert.when.toString();
        }
        that.addAlert.delayUnits = that.alert.delayUnits;
        that.addAlert.delayTimeUnit = that.alert && that.alert.delayTimeUnit ? this.commonUtilsService.configureSelectedTimeUnit(that.alert.delayTimeUnit, that.frecuencyList, 1) : that.frecuencyList[1];
        if (that.alert.destination && that.alert.destination.length) {
            that.message = that.alert.destination[0].comments;
        }
        that.addAlert.stateId = that.alert.source === 'Process' ? that.alert.sourceId : that.alert.stateId;
        that.notifyToIdentifying = that.alert.notifyToIdentifying;
        that.customTemplateEditable = that.alert.customTemplateEditable;
        if(!that.alert.customTemplate){
            that.notificationField.description = that.alert.comments;
        }
    }
    that.contactsField.selectContacts = that.alert.destination;
    that.repeat = that.alert.repeat?that.alert.repeat:false;
    if (that.alert.repeat) {
        if (that.alert.repeatType) {
            let indexAdditional = that.designType === 'process'?1:0;
            that.repeatInterval.period = 'frecuency';
            that.repeatInterval.every = that.alert.repeatDays;
            that.repeatInterval.ending = that.frecuencyRepeatList[that.alert.repeatOccurrences];
            that.repeatInterval.everyTime = that.alert.repeatTimeUnit ? this.commonUtilsService.configureSelectedTimeUnit(that.alert.repeatTimeUnit, that.frecuencyList, indexAdditional) : that.frecuencyList[indexAdditional];
        }else{
            that.repeatInterval.period = 'interval';
            that.repeatInterval.during = that.alert.repeatDays;
        }
        this.addIntervalAlert();
    }
  }

  addIntervalAlert() {
    let that = this.addAlert;
    if (that.repeatInterval.period === 'interval') {
        that.repeatInterval.every = '';
        that.repeatInterval.ending = undefined;
        that.repeatInterval.everyTime = undefined;
        that.repeatText = this.translate.instant('add-alert.during') + ' ' + that.repeatInterval.during + this.translate.instant('add-alert.days');
        if (parseInt(that.repeatInterval.during) === 1) {
            that.repeatText = this.translate.instant('add-alert.during') + ' ' + that.repeatInterval.during + this.translate.instant('add-alert.day');
        }
    } else {
        that.repeatInterval.during = '';
        that.repeatText = this.translate.instant('add-alert.every') + ' ' + that.repeatInterval.every + ' ' + that.repeatInterval.everyTime.text + ' / ' + that.repeatInterval.ending.text;
    }
    that.repeatInterval.every = that.repeatInterval.every ? parseInt(that.repeatInterval.every) : that.repeatInterval.every;
    that.repeatInterval.during = that.repeatInterval.during ? parseInt(that.repeatInterval.during) : that.repeatInterval.during;
  }

  itemName(type:string, newValue:any){
    let that = this.addAlert;
    that.addAlert[type] = newValue;
    if(that.addAlert.name !== '' && that.addAlert.name !== undefined){
        that.titleAlert.error =  false;
    }
  }

  publishAlert(){
    let that = this.addAlert;
    if (this.validateFields()){
        if(that.alert && that.stepState !== 3 && parseInt(that.addAlert.when) === 2 && parseInt(that.alert.alertTypeId) === 1 && that.customTemplateSwitch){
            this.modalService.adviceModal(this.translate.instant('add-alert.alerts-permanence'), 'info:question:question')
              .subscribe((result: any) => {
                if(result.result !== 'cancel'){
                  this.configureSaveAlert();
                }
              });
        }else{
          this.configureSaveAlert();
        }
    }
  }

  validateFields(){
    let that = this.addAlert;
    let errors = false;
    that.titleAlert.error = (that.addAlert.name === '' || that.addAlert.name === undefined);
    that.whenField.error = (that.addAlert.when === undefined);
    that.customNotificationField.error = (that.customTemplateSwitch && that.customNotificationField && !that.customNotificationField.description);
    that.contactsField.error = (!this.addAlert.contactsField.selectContacts.length && that.stepState > 1);

    if(that.referenceDate){
      that.fieldReferenceDate.error = (!that.addAlert.referenceDate)
    }

    if(that.type ==='date' && (that.addAlert.when ===2 || that.addAlert.when ===3)) {
      that.numberDaysInfo.error = (that.addAlert.numDays === '' || that.addAlert.numDays === undefined)

    } else {
      that.numberDaysInfo.error = false;
    }

    if(that.titleAlert.error || that.whenField.error || that.customNotificationField.error || that.contactsField.error || that.numberDaysInfo.error || that.fieldReferenceDate.error){
      errors = true;
    }
    if (that.type === 'processgeneral' && that.statesField) {
        that.statesField.error = !that.addAlert.stateId;
        if (that.statesField.error) {
            errors = true;
        }
    }
    return !errors;
  }

  configureSaveAlert(){
    let that = this.addAlert;
    let newAlert:any = {
        title: that.addAlert.name,
        repeat: that.repeat,
        omitNotifyToCreator: true
    };

    if(that.repeat){
        newAlert.repeatType = that.repeatInterval.period === 'frecuency';
        newAlert.repeatDays = newAlert.repeatType ? parseInt(that.repeatInterval.every) : parseInt(that.repeatInterval.during);
        if(that.repeatInterval.period === 'frecuency'){
            newAlert.repeatTimeUnit = that.repeatInterval.everyTime.listValueId;
            newAlert.repeatOccurrences = parseInt(that.repeatInterval.ending.listValueId);
        }
    }
    if (that.type === 'date') {
        if (!that.addAlert.numDays) {
            that.addAlert.numDays = 0;
        } else {
            newAlert.numDays = parseInt(that.addAlert.numDays);
        }
        newAlert.hour = that.addAlert.hour;
        if (that.addAlert.when) {
            newAlert.dateAlertTypeId = that.addAlert.when;
            if(that.addAlert.when !== 1){
                newAlert.timeUnit = that.addAlert.timeUnit.listValueId;
            }
        } else {
            newAlert.dateAlertTypeId = 1;
        }
        if(that.referenceDate){
            newAlert.referenceDate = that.addAlert.referenceDate;
        }
    } else if (that.type === 'process' || that.type === 'processgeneral') {
        newAlert.processAlertTypeId = parseInt(that.addAlert.when);
        if (that.addAlert.when === '2') {
            newAlert.delayUnits = parseInt(that.addAlert.delayUnits);
            newAlert.delayTimeUnit = that.addAlert.delayTimeUnit.listValueId ? that.addAlert.delayTimeUnit.listValueId : that.addAlert.delayTimeUnit;
            that.customTemplateSwitch = false;
            that.customTemplateEditable = false;
        }
        if (that.repeat && that.repeatInterval.period === 'interval') {
            delete newAlert.repeatOccurrences;
            delete newAlert.repeatTimeUnit;
        }
        if (that.type === 'processgeneral') {
            newAlert.notifyToIdentifying = that.notifyToIdentifying;
            newAlert.customTemplateEditable = that.customTemplateEditable;
            if (newAlert.notifyToIdentifying) {
                if(that.customTemplateSwitch){
                    newAlert.customTemplate = that.customNotificationField.description;
                    newAlert.comments = null;
                }else{
                    newAlert.comments = that.notificationField.description;
                    newAlert.customTemplate = null;
                }
            }
            if (that.statesField) {
                this.getNameSelected(that.addAlert.stateId, newAlert);
                newAlert.stateId = that.addAlert.stateId;
            }
        }
    } else {
        newAlert.documentAlertTypeId = that.addAlert.when;
    }
    this.handleAccept(newAlert);
  }

  getNameSelected(selected:any, newAlert:any){
    let that = this.addAlert;
    for(let state of that.processStates){
        if(state.stateId === selected){
            newAlert.stateName = state.name;
        }
    }
  }

  goNext(step:string){
    let that = this.addAlert;
    if (this.validateFields()) {
        if (step === 'notify') {
            if(that.alert && parseInt(that.addAlert.when) === 2 && parseInt(that.alert.alertTypeId) === 1 && that.customTemplateSwitch){
                that.customTemplateSwitch = false;
                that.customTemplateEditable = false;
            }
            this.findOutAlerts();
            that.stepState = 3;
        } else {
            that.stepState = 2;
        }
    }
  }

  goBack(){
    this.addAlert.stepState--;
  }

  findOutAlerts(){
    let that = this.addAlert;
    that.disabledNotifyToIdentifying = false;
    that.showInfoMessage = false;
    for(let alert of that.concept.alertList){
        if(alert.notifyToIdentifying && alert.alertTypeId === 1 && parseInt(that.addAlert.when) === 1 && alert.stateId === that.addAlert.stateId &&  (!that.alert || that.alert.id !== alert.id)){
            that.disabledNotifyToIdentifying = true;
            that.showInfoMessage = true;
            break;
        }
    }
  }

  intervalSelected(type:string, newValue:any) {
    let that = this.addAlert;
    if (that.repeatInterval != null) {
        that.repeatInterval[type] = newValue;
    }
  }

  receiveContacts(e:any){
    this.addAlert.contactsField.selectContacts = e.contacts;
  }

  contactsClosed() {
    let that = this.addAlert;
    that.showText = !that.showText;
  }

  notifyToIdentifyingChanged(newValue:boolean) {
    let that = this.addAlert;
    that.notifyToIdentifying = newValue;
    that.notificationField.noEditable = !that.notifyToIdentifying;
    this.broadcastService.broadcast('changeEditableFieldRich', {richEditor: that.notificationField});
  }

  changeEditPattern(newValue:boolean) {
    let that = this.addAlert;
    that.repeat = newValue;
  }

  closeAdvice(type:string){
    this.addAlert[type] = false;
  }

  showPreview() {
    let that = this.addAlert;
    let notificationTypeId = that.addAlert.when === '1' ? 'publicAlertsProcess' : 'publicAlertsProcessStay';
    this.modalService.notificationPreview(notificationTypeId, 'concept', that.concept.conceptId);
  }

  getdestinations(){
    let that = this.addAlert;
    if(that.contactsField.selectContacts){
        for(let contact of that.contactsField.selectContacts){
            contact.comments = that.message;
        }
        return that.contactsField.selectContacts;
    }
  }

  handleAccept(newAlert:any) {
    this.activeModal.close({
      result: 'ok',
      alert: newAlert,
      share: this.getdestinations()
    });
  }

  handleDismiss() {
    this.activeModal.close({result:'cancel'});
  }

}
