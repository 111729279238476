import { Injectable } from '@angular/core';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';

@Injectable({
  providedIn: 'root'
})
export class FieldTypeTextParentService {

  constructor(private commonUtilsService: CommonUtilsService,
    private modalService: ModalServiceService,
    private translate: AfTranslateFactory) { }

  configureChanges(changes: any, child:any){
    if(changes['field']) {
      child.fieldTypeText.titleField = child.field.extendedLabel && child.field.extendedLabel !== ''? child.field.extendedLabel : child.field.label;
      child.id = child.field.fieldId? child.field.fieldId : this.commonUtilsService.createId();
      if(child.field.multiline && child.field.minHeight){
          child.fieldTypeText.minHeight = child.field.minHeight;
      }
      if(child.field.fieldContentTypeId === 'titl'){
          child.field.maxLength = 250;
      }
      if(child.skin ==='icam-red' && child.field.evaluatedValue?.hasReferences && !child.fieldTypeText.references){
          child.fieldTypeText.references = true;
          child.showFieldReference.emit();
      }
      if(child.field.evaluatedValue?.documents?.length){
        child.fieldTypeText.voiceRecord.name = child.field.evaluatedValue.documents[0].name
        child.fieldTypeText.acceptAceptation = true;
        child.fieldTypeText.hasVoiceRecord = true;
        child.fieldTypeText.loadFromBack = true
      }
    }

    if((child.preSelectItem ===null || child.preSelectItem ===undefined) && child.field.defaultValue){
        child.fieldTypeText.inputText = child.field.defaultValue;
        child.preSelectItem = child.field.defaultValue
        child.itemSelected.emit({newValue: child.fieldTypeText.inputText});
    }else{
        child.fieldTypeText.inputText = child.preSelectItem;
    }
    if(child.concept){
      child.fieldTypeText.lockedSavedConcept = Boolean((child.field.lockedEdit || child.concept.lockedEdit) && (child.concept?.saved))
    }


  }

  keyChangeInField(e:any, child:any){
    if(e.keyCode ===13 && child.field.addEnter){
        e.preventDefault();
        let newValue={
          newValue: child.fieldTypeText.inputText
        }
        child.itemSelectedEnter.emit(newValue);
    }
  }

  changeInField(e: any, child: any){
    let newValue={
      from: e.target.name,
      newValue: e.target.value
    }
    this.changeInFieldEmit(newValue, child);
  }

  changeInFieldEmit(newValue: any, child: any){
    child.itemSelected.emit(newValue);
    if(child.field.clean === true){
        child.fieldTypeText.inputText = '';
    }
  }

  openTextareaModal(child:any){
    this.modalService.expandTextarea(child.fieldTypeText.inputText, child.field)
      .subscribe((result:any)=>{
        if(result.result === 'ok'){
          child.fieldTypeText.inputText = result.inputText;
          this.changeInFieldEmit({newValue: result.inputText, from:''}, child);
        }
      })
  }

  resetInput(child: any){
    child.fieldTypeText.inputText = '';
    child.itemSelected.emit({newValue: child.fieldTypeText.inputText});
  }

  showReference(e:any, child:any){
    e.stopPropagation();
    child.fieldTypeText.references = !child.fieldTypeText.references;
    child.showFieldReference.emit();
  }

  openModalRelatedInfo(e:any, fieldRelatedInfo:any, child:any){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    child.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  openRecordVoice(child:any){
    if(child.fieldTypeText.voiceRecord.player){
      this.modalService.adviceModal(this.translate.instant('record-audio-modal.new-record-question'), 'info:questionRecord:question')
        .subscribe((result:any)=>{
          if(result.result ==='ok'){
            this.recordVoice(child)
          }
        })

    }else{
      this.recordVoice(child)
    }
  }
  recordVoice(child:any){
    this.modalService.recordAudioModal(child.concept)
    .subscribe((result:any)=>{
      if(result.result ==='ok'){
        child.fieldTypeText.hasVoiceRecord = true;
        child.fieldTypeText.voiceRecord = {
          player: result.player,
          text: result.text,
          maxTime: result.maxTime,
          secondsRecord: result.secondsRecord,
          name: result.name,
          recordingFile: result.recordingFile,
          file: result.file
        }
        child.fieldTypeText.inputText = result.text;
        child.fieldTypeText.audioLoaded = true;
        child.itemSelected.emit({newValue:child.fieldTypeText.inputText});
        child.saveVoiceRecord.emit({
          acceptAceptation: child.fieldTypeText.acceptAceptation,
          voiceRecordFile: child.fieldTypeText.voiceRecord.file,
          name: child.fieldTypeText.voiceRecord.name
        })
      }
    })
  }
}
