<div class="report-record-detail">
    <header-nav></header-nav>
    <div class="common__block-window-view">
        <div class="common__block-button-close-window">
            <button (click)="changeViewWindow()"
                class="common__back-link">
                <span [afTranslate]="'report-record-detail.close-general-report'">_Cerrar informe general</span>
                <i class="lf-icon-close common__back-link-icon"></i>
            </button>
        </div>
        <div class="common__container-window-view">
            <div class="report-record-detail__block-container">
                <div class="report-record-detail__header">
                    <p class="float-start">
                      <span class="report-record-detail__header-title">{{report.title}}</span>
                      <span class="reports__new-version reports__new-version--concept-tab-report-record"
                      [ngShow]="archived"
                      [afTranslate]="'reports.archived'"></span>
                    </p>

                    <div class="common__options-block report-record-detail__subtitle-al"
                        [ngClass]="{'report-record-detail__subtitle-report-record-archived':archived}"
                        ngbDropdown
                        [open]="reportRecordDetail.showList">
                        <button class="common__selected-option common__selected-option--weight-normal"
                            ngbDropdownToggle>
                            <span class="evaluations-list__select-language">{{reportRecordDetail.selectedView?.name}}</span>
                            <i class="lf-icon-angle-down common__select-option-icon-angle-down"></i>
                        </button>
                        <div class="common__menu-block-dropdown dropdown-menu"
                            ngbDropdownMenu
                            role="menu">
                            <div class="common__block-option-list">
                                <div class="common__language-item"
                                    *ngFor="let view of reportRecordDetail.reporDetailView"
                                    (click)="changeViewTable(view)">
                                    <span class="report-record-detail__text-option-view">{{view.name}}</span>
                                    <div class="report-record-detail__bubble-count"
                                        [ngShow]="reportRecordDetail.documentationList?.length && view.viewOption === 'documentation'">
                                        {{reportRecordDetail.documentationList?.length}}
                                    </div>
                                    <i class="lf-icon-check-round-full common__language-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="report-record-detail__header-block-buttons"
                        [ngShow]="reportRecordDetail.selectedView?.viewOption === 'detail' && reportRecordDetail.userPermissions !== 'none'">
                        <button class="report-record-detail__header-button"
                            [ngClass]="{'report-record-detail__header-button--disabled':archived}"
                            [ngHide]="reportRecordDetail.from ==='189'"
                            [disabled]="archived"
                            (click)="updateReport()">
                            <span [afTranslate]="'report-table.refresh'">_Actualizar datos de informe</span>
                            <i class="lf-icon-refresh report-record-detail__icon-button-header"></i>
                        </button>
                        <button class="report-record-detail__header-button report-record-detail__header-button--no-first"
                            (click)="exportReport()"
                            [ngShow]="reportRecordDetail.userPermissions !== 'none'">
                            <span [afTranslate]="'report-table.export'">_Exportar informe</span>
                            <i class="lf-icon-send-excel report-record-detail__icon-button-header"></i>
                        </button>
                    </div>
                </div>
                <div [ngSwitch]="reportRecordDetail.selectedView.viewOption">
                    <report-record-detail *ngSwitchCase="'detail'"
                        [rows]="rows"
                        [columns]="columns"
                        [reportfields]="reportfields"
                        [report]="report">
                    </report-record-detail>
                    <report-record-documentation *ngSwitchDefault
                        [rows]="rows"
                        [columns]="columns"
                        [reportfields]="reportfields"
                        [report]="report"
                        [documentationList]="reportRecordDetail.documentationList">
                    </report-record-documentation>
                </div>
            </div>
        </div>
    </div>
    <compliance-footer></compliance-footer>
</div>
<pdf-table class="common__pdf-hidde-element"
    *ngIf="!report.excelCharts"
    [configuration]="reportRecordDetail.configurationPdf"></pdf-table>
