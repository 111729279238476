<div ngbDropdown
  [autoClose]="'outside'">
  <div class="filter-by-list__block-header"
      [attr.disabled]="(filterByList.list.length === 0 && !(showMyOption && showMyOption.visible))?true:null">
      <div class="common__cell-block common__cell-block--padding-right">
        <div ngbDropdownToggle>
          <p class="common__table-header-text common__table-header-text--ellipsis-filter-order"
              [ngClass]="{'common__table-header-text--custom': isLanding}">
              <span-tooltip [text]="filterByList.title"></span-tooltip>
          </p>
          <i class="lf-icon-filter-1 common__filter-icon"
              [ngClass]="{'lf-icon-filter-applied common__filter-icon--active': item.hasFilter,
                'common__filter-icon--disabled': filterByList.list.length === 0 && !(showMyOption && showMyOption.visible)}"
              [attr.disabled]="(filterByList.list.length === 0 && !(showMyOption && showMyOption.visible))?true:null">
          </i>
        </div>
        <i class="common__order-by lf-icon-arrow-down"
            [ngClass]="{'lf-icon-arrow-up':filterOrder === filterOrder && !order,
                'common__order-by--active': filterOrder === filterOrder}"
            (click)="orderColumn(filterOrder)"
            [ngShow]="filterByList.item.orderCol"></i>
      </div>
  </div>
  <div [class]="'common__select-list-with-input filter-by-list__select-list-with-input common__select-list-with-input-filter '
        +(topStyle? 'common__select-list-with-input--top-'+topStyle:'')"
      [ngStyle]="{width: widthClass}"
      ngbDropdownMenu
      aria-labelledby="dropdownBasic2"
      [ngHide]="filterByList.list.length === 0 && !(showMyOption && showMyOption.visible)">
      <div [ngShow]="type ==='listFind' || type === 'specialListFind'"
          class="common__multiselect-dropdown-item common__multiselect-dropdown-item--with-input">
          <input type="text"
              class="common__dropdown-inner-input"
              [placeholder]="'common.search-name'| afTranslate"
              [(ngModel)]="filterByList.inputFilter">
          <i class="common__search-icon"
              [ngClass]="filterByList.inputFilter ===''?'lf-icon-search':'lf-icon-close-search common__search-icon--reset'"
              (click)="resetFilter($event)"></i>
      </div>
      <ul class="common__select-list-with-input-by-list">
          <li class="filter-by-list__list-user filter-by-list__list-user--my-option"
              ngbDropdownItem
              [ngShow]="showMyOption?.visible"
              (click)="selectElement($event, showMyOption)">
              <i class="common__multiselect-select-icon common__multiselect-select-icon--big"
                  [ngClass]="showMyOption?.selected?'lf-icon-check-full':'lf-icon-box-inactive'">
              </i>
              <span [afTranslate]="filterByList.item.myOptionsTitle?filterByList.item.myOptionsTitle:'historic-concept.my-actions'">_Mis acciones</span>
          </li>
          <li class="filter-by-list__list-user"
              ngbDropdownItem
              *ngFor="let source of filterByList.list | orderBy:filterByList.orderBy | byWord:filterByList.inputFilter:['name']; let index = index"
              (click)="selectElement($event, source)"
              [ngClass]="{'filter-by-list__list-user--active': source.selected}"
              [ngShow]="(source.name || source.nameTranslate) && (!showMyOption || (showMyOption && source.name.trim().toLowerCase() !== filterByList.userLogin.fullName.trim().toLowerCase()))">
              <i class="common__multiselect-select-icon common__multiselect-select-icon--big"
                  [ngClass]="source.selected?'lf-icon-check-full':'lf-icon-box-inactive'">
              </i>
              <i [class]="'lf-icon-step-final filter-by-list__icon-circle filter-by-list__icon-circle--'+(source.name | colors:'icon-filter-list')"
                  [ngShow]="filterByList.item.iconCircle"></i>
              <p class="filter-by-list__list-filter-text"
                  [ngClass]="{'common__line-through':source.isDeleted}">
                  <span-tooltip [text]="source.nameTranslate?source.nameTranslate:source.name"
                    [isDeleted]="source.isDeleted"></span-tooltip>
              </p>
          </li>
      </ul>
  </div>
</div>
