import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ReportRecordDetailService } from '../utils/report-record-detail.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ReportRecordDetailComponent } from '../report-record-detail/report-record-detail.component';

@Component({
  selector: 'report-record-default',
  templateUrl: './report-record-default.component.html'
})

export class ReportRecordDefaultComponent implements OnInit {
  archived: boolean = this.route.snapshot.data['archived']==='archived'
  reportRecordDetail: any = {
    viewTableHtml: 'detail',
    openFocusDetail: false,
    orderOperationDates: true,
    searchDetail: '',
    searchMethod: '',
    searchName: '',
    dateOrder: '',
    filter: [],
    filterReportRecordDetailList: [],
    filterDocumentationList: [],
    findFilters: [],
    reporDetailView: [
      {
          name: this.translate.instant('report-record-detail.detail-report'),
          viewOption: 'detail'
      },
      {
          name: this.translate.instant('common.documentation'),
          viewOption: 'documentation'
      }
    ]
  };
  constructor(private route:ActivatedRoute,
    private reportRecordDetailService: ReportRecordDetailService,
    private translate: AfTranslateFactory) {}

  @Input() rows:any = this.route.snapshot.data['rows'];
  @Input() columns:any = this.route.snapshot.data['columns'];
  @Input() report:any = this.route.snapshot.data['report'];
  @Input() reportfields:any;
  @ViewChild(ReportRecordDetailComponent)
  reportRecordDetailComponent: ReportRecordDetailComponent;
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  ngOnInit(): void {
    $('body').addClass('report-record-detail-body');
    this.reportRecordDetailService.getDocumentationList(this);
    let dataSession: any = sessionStorage.getItem('dashboardReportsSession');
    dataSession = JSON.parse(dataSession);
    if(dataSession){
      this.reportRecordDetail.from = dataSession.reportId;
    }
    this.reportRecordDetail.selectedView = this.reportRecordDetail.reporDetailView[0]

  }

  changeViewTable(view:any){
    this.dropdown.close();
    this.reportRecordDetail.selectedView = view;
    this.reportRecordDetail.viewTableHtml = this.reportRecordDetail.selectedView.viewOption
  }

  changeViewWindow(){
    this.reportRecordDetailService.changeViewWindow(this)
  }

  updateReport(){
    this.reportRecordDetailService.updateReport(this)
  }

  exportReport(){
    let filterReportRecordDetailList: any = this.reportRecordDetailComponent.reportRecordDetail.filterReportRecordDetailList;
    this.reportRecordDetailService.exportReport(this, filterReportRecordDetailList)
  }

}
