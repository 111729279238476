import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'field-type-email',
  templateUrl: './field-type-email.component.html'
})
export class FieldTypeEmailComponent implements OnChanges {
  isLanding: boolean = this.customLanding.isLanding();
  skin: string = this.isLanding?'landing-custom':this.globalCfg.skin;;
  fieldTypeEmail: any ={
    inputText: ''
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory) { }
  @Input() field: any = null;
  @Input() preSelectItem: any = null;
  @Input() concept: any = null;

  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnChanges(changes:SimpleChanges){
    let that: any = this.fieldTypeEmail;
    if(this.field){
      that.field = this.field;
      that.type = that.field.fieldTypeId;
      that.titleField = that.field.extendedLabel && that.field.extendedLabel !== ''? that.field.extendedLabel : that.field.label;
      if(!that.field.id || that.field.generateId){
        that.field.id = this.commonUtilsService.createId();
      } else if(that.field.fieldId){
        that.field.id = that.field.fieldId;
      }
    }

    // if(changes['preSelectItem'] && changes['preSelectItem'].currentValue){
    //   let preSelectItem: any = changes['preSelectItem'].currentValue
      if((this.preSelectItem ===null || this.preSelectItem ===undefined) && !that.field.defaultValue){
          that.inputText = '';
      }else if((this.preSelectItem ===null || this.preSelectItem ===undefined) && that.field.defaultValue){
          that.inputText = that.field.defaultValue;
          this.preSelectItem = that.field.defaultValue;
          this.itemSelected.emit({newValue:that.inputText});
      }else{
          that.inputText = this.preSelectItem;
      }
    // }
  }

  validateEmail(){
    this.fieldTypeEmail.field.error = this.fieldTypeEmail.inputText?!(this.commonUtilsService.validateMail(this.fieldTypeEmail.inputText)):false;
    if(this.concept){
      this.configureErrorConcept();
    }
    this.itemSelected.emit({newValue:this.fieldTypeEmail.inputText});
  }

  configureErrorConcept(){
    this.fieldTypeEmail.field.errorInForm = this.fieldTypeEmail.field.error;
    this.commonUtilsService.configureConceptErrorForm(this.fieldTypeEmail.field, this.concept);
  }

  resetEmailText(){
    this.fieldTypeEmail.inputText = "";
    this.fieldTypeEmail.field.error = false;
    if(this.concept){
      this.configureErrorConcept();
    }
  }
  openModalRelated(fieldRelatedInfo:any, e: any){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    this.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }
}

