import html2canvas from 'html2canvas';

import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { NgbDropdown, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'results-report',
  templateUrl: './results-report.component.html'
})
export class ResultsReportComponent implements OnInit, OnDestroy {
  skin: string = this.globalCfg.skin;
  subscribers : any = {};
  resultsReport: any = {
    orderTableType: 'indexPosition',
    disggregatedList: [],
    orderTableAsc: false,
    disggregateSelected: {
        selected: false,
        label: this.translate.instant('results-report.disaggregated-data')
    },
    totalQuestionsWithGraphic: "00",
    numberQuestion: "01",
    openSelectGraphic: false,
    loadingData: true,
    hideBlock: false,
    showNoneResults: false,
    isModal: false,
    changeResponsive: false,
    configurateReport: {
        type: 'poll'
    },
    exportFields: []
  }

  constructor(private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private broadcastService: BroadcastService,
    private translate: AfTranslateFactory,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService) { }

  @Input() concept:any = null;
  @Output() launchAction = new EventEmitter();
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>
  @ViewChildren(NgbTooltip)
  tooltips: QueryList<NgbTooltip>

  ngOnInit(): void {
    this.resultsReport.loaderImg = this.commonUtilsService.configureLoaderImg();
    this.subscribers.openMenuBroadcast = this.broadcastService.subscribe('openMenu', (data)=>{
      this.resultsReport.changeResponsive = (window.innerWidth <= 1624 && window.innerWidth >= 1367 && data.closeMenu);
      this.selectTitleTable(this.resultsReport.selectedField, this.resultsReport.changeResponsive);
    })

    this.getGraphicFields();
  }

  getGraphicFields(){
      this.apiService.get('polls/graphicfields', this.concept.conceptId)
          .then((data:any)=>{
              if(data.length){
                  for(let i =0 ; i < data.length; i++){
                    let item:any = data[i]
                      this.extractTodisggregatedList(item);
                      item.step = i+1;
                      item.prevStep = i-1;
                      item.numberQuestion = i < 9? "0" + (i + 1).toString() : (i + 1).toString();
                      if(i === 0){
                          this.resultsReport.selectedField = item;
                          this.resultsReport.configurateReport.numericalScale = this.resultsReport.selectedField.numericalScale;
                          this.selectTitleTable(this.resultsReport.selectedField, this.resultsReport.changeResponsive);
                          this.getGraphicData();
                          this.getAnsweredField(this.resultsReport.selectedField);
                      }
                  }
                  this.resultsReport.graphicFields = data;
                  this.resultsReport.totalQuestionsWithGraphic = this.resultsReport.graphicFields.length < 9? "0"+(this.resultsReport.graphicFields.length).toString() : String(this.resultsReport.graphicFields.length)

              }else{
                  this.resultsReport.numberQuestion = "00";
                  this.resultsReport.loadingData = false;
                  this.resultsReport.hideBlock = true;
                  this.resultsReport.showNoneResults = true;
              }

          }, (errorData:any)=>{
              this.resultsReport.numberQuestion = "00";
              this.resultsReport.loadingData = false;
              this.resultsReport.hideBlock = true;
          })
  }

  extractTodisggregatedList(data:any){
      if(data.isDisaggregated){
          this.resultsReport.disggregatedList.push({
              label: data.label,
              fieldId: data.fieldId,
              listValues: data.listValues,
              isBoolean: (data.fieldTypeId ==='bool' || data.fieldTypeId ==='boolp')
          })
      }
  }

  getGraphicData(){
      let that: any = this.resultsReport;
      let listValueId:any = 0;
      if(that.disggregateSelected.isBoolean){
          listValueId = that.disggregateListValueSelect.text === 'Si'? 1 : 0;
      }else if(that.disggregateSelected.selected){
          listValueId =  that.disggregateListValueSelect.listValueId
      }
      let url:string = 'polls/graphicdata/'+this.concept.conceptId+'/'+ that.selectedField.fieldId;
      url += that.disggregateSelected.selected?'/'+listValueId:''
      this.apiService.get(url)
          .then((data:any)=>{
              that.numberQuestion = that.selectedField.numberQuestion;
              this.configureDonutGraphicData(data, that.selectedField);
          }, (errorData:any)=>{
              that.loadingData = true;
          })
  }

  getAnsweredField(selectedField:any){
      this.apiService.get('polls/answered/'+this.concept.conceptId, selectedField.fieldId)
          .then((data:any)=>{
              selectedField.answered = data.answered;
              selectedField.notAnswered = data.notAnswered;
          }, (errorData:any)=>{})
  }

  configureDonutGraphicData(data:any, selectedField:any){
      let that: any = this.resultsReport;
      that.donutGraphsData={
          data:[],
          colors:[],
          legend:[],
          amount: 0
      }
      let mostAnsweredPercentage:any = 0;
      let optionMostAnswered:any = [];
      for(let i = 0; i < data.length; i++){
          let item: any = data[i];
          item.indexPosition = parseInt('0000'+[i]);
          item.percentage = Math.round(item.percentage);
          that.donutGraphsData.amount+= item.answers;
          that.donutGraphsData.colors.push(item.color);
          that.donutGraphsData.data.push(item.answers);
          that.donutGraphsData.legend.push(item.label);
          if(item.percentage > mostAnsweredPercentage){
              mostAnsweredPercentage = item.percentage;
              optionMostAnswered = [];
              optionMostAnswered.push(item.label);
          }else if(item.percentage === mostAnsweredPercentage){
              optionMostAnswered.push(item.label);
          }

      }
      that.donutGraphsData.original = data;
      that.donutGraphsData.multiple = selectedField.multiple;
      that.donutGraphsData.graphicId = this.concept.conceptId;
      that.donutGraphsData.graphicCaption = this.translate.instant('results-report.number-responses');//'Nº de respuestas';
      selectedField.graphicData = that.donutGraphsData;
      this.geOverflowHeight();
      this.getTextOptionMoreSelect(optionMostAnswered, mostAnsweredPercentage, selectedField);
  }

  getTextOptionMoreSelect(optionMostAnswered:any, mostAnsweredPercentage:any, selectedField:any){
      let optionMoreSelected:any = '';
      if(mostAnsweredPercentage === 0){
          optionMoreSelected = this.translate.instant('results-report.no-answered');
      }else if(optionMostAnswered.length > 1){
          optionMoreSelected = this.translate.instant('results-report.percentage-tie').replace('{percentage}', mostAnsweredPercentage);
          for(let i = 0; i < optionMostAnswered.length; i++){
              let name:any = optionMostAnswered[i];
              if(i === optionMostAnswered.length - 2){
                  optionMoreSelected = optionMoreSelected.concat('"'+name+'"' + " "+ this.translate.instant('common.and') + " ");
              }else if(i === optionMostAnswered.length - 1){
                  optionMoreSelected = optionMoreSelected.concat('"'+name+'"');
              }else{
                  optionMoreSelected = optionMoreSelected.concat('"'+name+'"' + ', ');
              }
          }
      }else{
          optionMoreSelected = this.translate.instant('results-report.percentage-answered').replace('{percentage}', mostAnsweredPercentage);
          optionMoreSelected = optionMoreSelected.replace('{optionSelected}', '"'+optionMostAnswered[0]+'"');
      }
      selectedField.optionMoreSelected = optionMoreSelected;
      this.resultsReport.loadingData = false;
  }

  changeFieldSelected(fieldSelected:any){
      let that: any = this.resultsReport;
      this.closeDropdown('graphics-index');
      if(that.selectedField.fieldId !== fieldSelected.fieldId){
          that.selectedField = fieldSelected;
          that.numberQuestion = fieldSelected.numberQuestion;
          that.configurateReport.numericalScale = that.selectedField.numericalScale;
          $('#bar-graph__donut-block-'+this.concept.conceptId).remove();
          $('#horizontal-poll-graph__graph-'+this.concept.conceptId).remove();
          this.selectTitleTable(fieldSelected, that.changeResponsive);
          if(!that.selectedField.graphicData){
              this.getGraphicData();
              this.getAnsweredField(that.selectedField);
          }else{
              this.geOverflowHeight();
          }
      }
  }

  geOverflowHeight(){
      let heightBlock:any = $('.results-report__answer-block').height();
      let quantityElementHeight:any = this.resultsReport.selectedField.graphicData.original.length * 22;
      this.resultsReport.overflowInfo = Boolean(heightBlock < quantityElementHeight);
  }

  selectTitleTable(fieldSelected:any, changeResponsive:any){
      let that: any = this.resultsReport
      that.titleOptionTable = this.translate.instant('results-report.list-simple-option');
      that.classBlockFlex = 'list-simple';
      if(fieldSelected.fieldTypeId === 'bool' || fieldSelected.fieldTypeId === 'boolp'){
          that.titleOptionTable = this.translate.instant('results-report.boolean');
          that.classBlockFlex = 'bool';
      }else if(fieldSelected.numericalScale){
          that.titleOptionTable = this.translate.instant('customize.numerical-scale');
          that.classBlockFlex = 'numerical-scale';
      }else if(fieldSelected.multiple){
          that.titleOptionTable = this.translate.instant('results-report.list-multiple-option');
          that.classBlockFlex = 'list-multiple';
      }
      if(changeResponsive){
          that.classBlockFlex = that.classBlockFlex.concat('-responsive');
      }
  }

  changeView(){
      this.launchAction.emit({toShow:'evaluation', type: 'changeToShow'});
  }

  exportResultsReport(){
      this.modalService.exportResultReport(this.resultsReport.exportFields, this.concept)
          .subscribe((result:any)=>{
              if(result.result ==='ok'){
                this.modalService.adviceModal(this.translate.instant('results-report.exported-correct').replace('{name}', '"' + result.infoExport.name + '"'), 'info:accept:check-round');
              }
          })
  }

  changeOrder(){
      this.resultsReport.selectedOrder = false;
      if(this.resultsReport.orderTableType !== 'answers'){
          this.resultsReport.orderTableType = 'answers';
          this.resultsReport.selectedOrder = true;
      }else{
          this.resultsReport.orderTableType = 'indexPosition';
      }
      this.resultsReport.orderTableAsc = !this.resultsReport.orderTableAsc;
  }

  maximizeGraph(){
      this.modalService.viewExtendedReport(this.resultsReport.selectedField);
  }

  selectDisggregated(disggregate:any){
      this.resultsReport.disggregateSelected = disggregate;
      this.resultsReport.disggregateSelected.selected = true;
      this.resultsReport.disggregateListValueSelect = disggregate.listValues[0];
      this.closeDropdown('disggregated-select');
      this.getGraphicData();
  }
  selectDisggregatedListValue(value:any){
      this.resultsReport.disggregateListValueSelect = value;
      this.closeDropdown('disggregated-list-value');
      this.getGraphicData();
  }

  unSelectDisggregated(){
      this.resultsReport.disggregateSelected.selected = false;
      this.resultsReport.disggregateSelected.label = this.translate.instant('results-report.disaggregated-data')
      this.resultsReport.disggregateListValueSelect = {};
      this.getGraphicData();
  }

  saveAsPng(){
    let documentObject:any = document;
    let that = this;
    that.resultsReport.disabledSaveAsPng = true;
    that.resultsReport.disableTooltip = true;
    let tooltip = this.selectTooltip('result-report-'+this.resultsReport.selectedField?.fieldId)
    tooltip.close();
    html2canvas(documentObject.querySelector('#results-report-block-info-graph-'+this.resultsReport.selectedField?.graphicData?.graphicId))
      .then(function(canvas) {
          canvas.toBlob((blob:any)=>{
              let data = [new ClipboardItem({ [blob.type]: blob })];
              navigator.clipboard.write(data)
                  .then(()=>{}, (err)=>{})
          });
          that.resultsReport.copiedTooltip = true;
          that.resultsReport.disableTooltip = false;
          if(!tooltip.isOpen()){
            tooltip.open();
          }
          setTimeout(()=>{
              tooltip.close();
              that.resultsReport.copiedTooltip = false;
              that.resultsReport.disabledSaveAsPng = false;
          }, 3000)
      })
  }

  selectTooltip(id:any){
    let tooltip: any = this.tooltips.find((x:any)=>{
      return x._elementRef.nativeElement.id == id;
    });
    return tooltip;
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  ngOnDestroy(): void {
    this.commonUtilsService.OnDestroy(this.subscribers);
  }
}
