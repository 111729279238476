<div class="concept-tab"
  [ngShow]="conceptTab.activeConcept || conceptTab.selectedConcept">
  <div class="concept-tab__tab-items-block">
      <ul class="concept-tab__tabs-list">
          <li class="concept-tab__concept-list"
              *ngFor="let concept of conceptTab.list | afLimitTo:conceptTab.limitTo : conceptTab.begin; let index = index"
              matTooltip="{{concept.title}}"
              matTooltipPosition="below"
              matTooltipClass="below"
              [matTooltipDisabled]="!showTooltip('concept-header-'+concept.conceptObjectId)"
              [ngClass]="activeClass(concept)"
              (click)="conceptSelect(concept)">
              <div class="concept-tab__concept-list-text-block"
                  [ngClass]="{'concept-tab__concept-list-text-block--inactive':!concept.active}">
                  <p [id]="'concept-header-'+concept.conceptObjectId"
                    class="concept-tab__concept-list-text-title common__ellipsis"
                    [ngClass]="{'concept-tab__concept-list-text-title--active':concept.active}">
                      <span [ngShow]="concept.titlePrefix">{{concept.titlePrefix}}</span>
                      <span [ngShow]="concept.titlePrefix && concept.title"> / </span>
                      <span [id]="'span-concept-header-'+concept.conceptObjectId">{{concept.title}}</span>
                      <span class="reports__new-version reports__new-version--concept-tab"
                          [ngShow]="conceptTab.archived"
                          [afTranslate]="'reports.archived'"></span>
                      <button class="lf-icon-edit concept-tab__button-rename-concept"
                          [ngShow]="showEditNameButton(concept)"
                          (click)="renameEvaluatedConcept(concept)"
                          matTooltip="{{conceptTab.titleModal}}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </button>
                  </p>
                  <i class="lf-icon-close concept-tab__close-icon"
                      [ngClass]="{'concept-tab__close-icon--active':concept.active}"
                      (click)="closeConcept(concept)"
                      [ngShow]="!(index === 0 && concept.productTypeId === 2 && !conceptTab.parentConcept?.parentId)"></i>
                  <svg class="concept-tab__triangle-block">
                      <polygon [ngShow]="!concept.active"
                          class="concept-tab__triangle-color"
                          points="0,0 37,57 0,57" />
                      <line x1="0" x2="38" y1="0" y2="58"
                          class="concept-tab__triangle-line"></line>
                  </svg>

              </div>
          </li>
      </ul>
      <div class="concept-tab__block-button-tabs"
          ngbDropdown
          [autoClose]="'outside'"
          [open]="conceptTab.showAllConcepts"
          (click)="closeListMenu('dropdown')"
          [ngShow]="conceptTab.list?.length > 6">
          <button class="lf-icon-double-arrow-right concept-tab__button-more-tabs"
              ngbDropdownToggle>
          </button>
          <div class="concept-tab__menu-all-concepts"
              ngbDropdownMenu
              role="menu">
              <h5 class="concept-form__title-form-subgroup"
                  [afTranslate]="'concept-tab.open-concepts'">
                  Conceptos Abiertos</h5>
              <i class="lf-icon-close concept-form__close-icon"
                  (click)="closeListMenu()"></i>
              <div class="concept-tab__menu-all-block"
                  [ngShow]="conceptTab.showAllConcepts">
                  <ul class="concept-tab__menu-all-concepts-list">
                      <li class="concept-tab__block-list-concept"
                          *ngFor="let item of conceptTab.list; let index = index"
                          (click)="conceptSelect(item)">
                          <div class="common__table-display">
                              <div class="common__table-cell-tooltip">
                                  <i [class]="item.iconList"
                                      [ngClass]="{'concept-tab__icon-element-list': item.active || index === 0}"></i>
                                  <div class="concept-tab__cell-block">
                                    <parragraph-tooltip [text]="item.title"></parragraph-tooltip>
                                  </div>
                                  <i class="lf-icon-arrow-right concept-tab__icon-directions"></i>
                              </div>
                          </div>
                          <div class="concept-tab__line-bottom-block"></div>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="concept-tab__other-buttons-top">
          <div class="concept-tab__block-other-buttons"
              [ngShow]="conceptTab.selectedConcept?.showButtons">
              <button class="concept__no-evaluated"
                  [ngHide]="userPermissions ==='none' || conceptTab.productTypeId === 4  || conceptTab.activeConcept?.productId === 'blanqueo'"
                  (click)="changeConcept('noEvaluated')">
                  <i class="concept__no-evaluated-icon"
                      [ngClass]="!conceptTab.selectedConcept?.evaluated?'lf-icon-check-empty':'lf-icon-box-inactive'"></i>
                  <span class="concept__no-evaluated-text"
                    [afTranslate]="'common.not-evaluated'"></span>
              </button>
              <button class="concept__tab-button concept__tab-button--back"
                  [ngShow]="conceptTab.selectedConcept?.showBackButton"
                  (click)="changeConcept('back')"
                  matTooltip="{{'concept.back-button-tooltip' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above"
                  [matTooltipDisabled]="showTooltipTabButton('back')">
                  <i class="lf-icon-double-arrow-left concept__tab-button-icon"></i>
                  <span class="concept__tab-button-text"
                    [afTranslate]="'common.behind'"></span>
              </button>
              <button class="concept__tab-button concept__tab-button--next"
                  [ngShow]="conceptTab.selectedConcept?.showNextButton"
                  (click)="changeConcept('next')"
                  matTooltip="{{'concept.next-button-tooltip' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above"
                  [matTooltipDisabled]="showTooltipTabButton('next')"
                  [disabled]="conceptTab.selectedConcept?.disabledNextButton">
                  <span class="concept__tab-button-text"
                    [afTranslate]="'common.next'"></span>
                  <i class="lf-icon-double-arrow-right concept__tab-button-icon"></i>
              </button>
          </div>
      </div>
  </div>
  <div class="concept-tab__subtab-block">
      <ul class="concept-tab__subtab-list-items"
          [ngSwitch]="conceptTab.subMenu">
          <div *ngSwitchCase="'general'">
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='general'}"
                  (click)="selectTab('general')">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'historic.general'">General</p>
                  <button class="concept-tab__share-button"
                      [ngHide]="conceptTab.productTypeId === 4"
                      [ngClass]="{'concept-tab__share-button--show':conceptTab.showShareInstance}"
                      matTooltip="{{conceptTab.shareConceptTooltip}}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      (click)="openShareInstance($event)">
                      <i class="lf-icon-share concept-tab__share-icon"></i>
                  </button>
              </li>
              <li class="concept-tab__list"
                  [ngHide]="conceptTab.viewMode || conceptTab.productTypeId === 4 || conceptTab.selectedConcept?.poll"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='documentation'}"
                  (click)="selectTab('documentation')">
                  <p class="concept-tab__list-text "
                      [afTranslate]="'common.documentation'">Documentación </p>
                  <span class="concept-tab__amount-info"
                      [ngClass]="{'concept-tab__amount-info--active': conceptTab.selectedConcept?.documentation.totalDocuments > 0 && conceptTab.selectedConcept?.subTab==='documentation'}"
                      [ngShow]="conceptTab.selectedConcept?.documentation.totalDocuments > 0">{{conceptTab.selectedConcept?.documentation.totalDocuments}}</span>

              </li>
              <li class="concept-tab__list"
                  [ngHide]="conceptTab.viewMode || conceptTab.productTypeId === 4 || conceptTab.selectedConcept?.poll || conceptTab.activeConcept?.productId === 'bpt002'"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='task'}"
                  (click)="selectTab('task')">
                  <p class ="concept-tab__list-text"
                      [afTranslate]="'common.tasks'">Tareas </p>
                  <span class="concept-tab__amount-info"
                      [ngClass]="{'concept-tab__amount-info--active': conceptTab.selectedConcept?.tasks > 0 && conceptTab.selectedConcept?.subTab==='task'}"
                      [ngShow]="conceptTab.selectedConcept?.tasks">{{conceptTab.selectedConcept?.tasks}}</span>
              </li>
              <li class="concept-tab__list"
                  [ngHide]="conceptTab.viewMode || conceptTab.productTypeId === 4 || conceptTab.selectedConcept?.poll"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='alerts'}"
                  (click)="selectTab('alerts')">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'common.alerts'">Alertas </p>
                  <span class="concept-tab__amount-info"
                      [ngClass]="{'concept-tab__amount-info--active': conceptTab.selectedConcept?.alerts > 0 && conceptTab.selectedConcept?.subTab==='alerts'}"
                      [ngShow]="conceptTab.selectedConcept?.alerts">{{conceptTab.selectedConcept?.alerts}}</span>
              </li>
              <li class="concept-tab__list"
                  [ngHide]="conceptTab.viewMode || conceptTab.productTypeId === 4"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='historic'}"
                  (click)="selectTab('historic')">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'common.historic'">Histórico </p>
              </li>
          </div>
          <div *ngSwitchCase="'reports'">
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='reports'}"
                  (click)="selectTab('reports')">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'common.reports'">Informes</p>
              </li>
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='documentation'}"
                  (click)="selectTab('documentation')">
                  <p class="concept-tab__list-text "
                      [afTranslate]="'common.documentation'">Documentación </p>
                  <span class="concept-tab__amount-info"
                      [ngClass]="{'concept-tab__amount-info--active': conceptTab.selectedConcept?.documents > 0 && conceptTab.selectedConcept?.subTab==='documentation'}"
                      [ngShow]="conceptTab.selectedConcept?.documents">{{conceptTab.selectedConcept?.documents}}</span>

              </li>
          </div>
          <div *ngSwitchDefault>
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='form' || conceptTab.selectedConcept?.subTab==='form-process'}"
                  (click)="selectTab(conceptTab.fromForm)">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'historic.general'">General</p>
              </li>
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='process'}"
                  (click)="selectTab('process')"
                  [ngShow]="conceptTab.showProcces && !conceptTab.selectedConcept?.poll">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'concept-tab.process'">Proceso</p>
              </li>
              <li class="concept-tab__list"
                  [ngClass]="{'concept-tab__list--active':conceptTab.selectedConcept?.subTab==='template'}"
                  (click)="selectTab('template')"
                  [ngShow]="!conceptTab.selectedConcept?.poll">
                  <p class="concept-tab__list-text"
                      [afTranslate]="'predefined-templates.templates'">_Plantillas</p>
              </li>
          </div>
          <div class="concept-tab__menu-header"
              [ngShow]="conceptTab.showTongue">
              <ul class="concept-tab__menu-ul">
                  <li class="concept-tab__menu-li"
                      (click)="showConceptOrReport($event)"
                      [ngShow]="(conceptTab.selectedConcept?.description && conceptTab.subMenu !== 'reports') && conceptTab.productTypeId !== 4"
                      matTooltip="{{'common.info-concept' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                      <i class="lf-icon-information"></i>
                  </li>
                  <li class="concept-tab__menu-li"
                      (click)="openRelatedInfo(conceptTab.selectedConcept?.relatedInformation)"
                      matTooltip="{{'common.related-info' | afTranslate }}"
                      matTooltipPosition="above"
                      matTooltipClass="above"
                      [ngShow]="conceptTab.selectedConcept?.relatedInformation?.length && !(conceptTab.subMenu === 'form' || conceptTab.productTypeId === 4)">
                      <i class="lf-icon-search-text"></i>
                  </li>
                  <li class="concept-tab__menu-li"
                      [ngClass]="{'concept-tab__menu-li--disabled': conceptTab.archived}"
                      [ngShow]="conceptTab.subMenu === 'form' && userPermissions !=='none' && conceptTab.productTypeId !== 4"
                      (click)="renameEvaluatedConcept()"
                      matTooltip="{{conceptTab.titleModal}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                      <i class="lf-icon-template-user"></i>
                  </li>

                  <li class="concept-tab__menu-li"
                      [ngShow]="conceptTab.productTypeId === 4"
                      (click)="printConcept()"
                      matTooltip="{{'common.print' | afTranslate}}"
                      matTooltipPosition="above"
                      matTooltipClass="above">
                      <i class="lf-icon-print"></i>
                  </li>
                  <li class="concept-tab__menu-li"
                      [ngShow]="conceptTab.productTypeId === 4"
                      (click)="favoriteMark()">
                      <i class="folder__evaluation-concept-publish folder__evaluation-concept-publish--menu-li"
                          matTooltip="{{conceptTab.favorite}}"
                          matTooltipPosition="above"
                          matTooltipClass="above"
                          [ngClass]="{'lf-icon-favorite-full folder__evaluation-concept-publish--published':conceptTab.selectedConcept?.isFavorite,
                            'lf-icon-favorite': !conceptTab.selectedConcept?.isFavorite}"></i>
                  </li>
              </ul>
          </div>
          <div class="concept-tab__hours"
            [ngShow]="conceptTab.activeConcept?.conceptNamedKey === 'Consulta'">
              <button [class]="'concept-tab__button-hours concept-tab__button-hours--'+conceptTab.activeConcept?.colorAlert"
                  matTooltip="{{conceptTab.activeConcept?.tooltipAlertHour}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
                  <i class="lf-icon-warning concept-tab__icon-hours"></i>
                  <span class="concept-tab__text-hours">{{conceptTab.activeConcept?.textAlertHour}}</span>
              </button>
          </div>
          <div [ngShow]="conceptTab.activeConcept?.productId === 'blanqueo' && conceptTab.activeConcept?.addToScoring?.riesgo"
              class="concept-tab__scoring-block">
              <i class="lf-icon-step-final concept-tab__icon-circle-scoring"
                  [ngStyle]="{'color': conceptTab.activeConcept?.addToScoring?.color}"></i>
              <span>{{conceptTab.activeConcept?.addToScoring?.riesgo}}</span>
          </div>
          <div class="concept-tab__hours"
              [ngShow]="conceptTab.activeConcept?.conceptNamedKey === 'Cliente'">
              <i class="lf-icon-alert concept-window-tab__icon-block-alert"
                  [ngShow]="conceptTab.activeConcept?.clientStateId === 2"></i>
              <button class="concept-tab__button-hours concept-tab__button-hours--red"
                  [ngShow]="conceptTab.activeConcept?.clientStateId === 3">
                  <i class="lf-icon-close-round concept-tab__icon-hours"></i>
                  <span class="concept-tab__text-hours"
                      [afTranslate]="'common.not-supported'">_No admitido</span>
              </button>
              <button class="concept-tab__button-hours concept-tab__button-hours--grey"
                  [ngShow]="conceptTab.activeConcept?.clientStateId === 4">
                  <i class="lf-icon-calendar-cross concept-tab__icon-hours"></i>
                  <span class="concept-tab__text-hours"
                      [afTranslate]="'common.no-active'">_No activo</span>
              </button>
          </div>
      </ul>
      <hr class="concept-tab__separate-line">
  </div>
</div>
