import { Component, Input, OnInit } from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'report-graph',
  templateUrl: './report-graph.component.html'
})
export class ReportGraphComponent implements OnInit {
  reportGraph:any = {
    esgHeatMapData: [],
    hourStates:[
      {
          days: '00',
          hours: '20',
          minutes: '22',
          color: '#C43741'
      },{
          days: '15',
          hours: '23',
          minutes: '07',
          color: '#F1BC3E'
      },{
          days: '00',
          hours: '00',
          minutes: '00',
          color: '#F1923E'
      },{
          days: '00',
          hours: '17',
          minutes: '11',
          color: '#77D166'
      }
    ]

  }
  constructor(private apiService: ApiServiceService,
    private context: ContextFactory,
    private translate: AfTranslateFactory) { }

  @Input() report: any = null;
  @Input() configurateReport: any = null;
  @Input() showResidualOption: Boolean = false;

  ngOnInit(): void {
    let that: any = this.reportGraph;
    that.configurateReport = this.configurateReport;
    that.report = this.report;

    if(that.report.reportId === 205 || that.report.reportId === 206 || that.report.reportId === 207){
      this.callEsgHeatMaps(1);
    }else{
      if(that.configurateReport.type === 4){
          this.getGraphtsTaskInfo();
          this.getGraphtsTaskAnualInfo();
          this.getOtherGraphsInfo();
      }else if(that.configurateReport.type === 6){
          that.isGradient = true;
          this.getBarGraphCovid();
      }else if(that.configurateReport.type === 1 && that.configurateReport.subType === 2){
          this.getDonutGraphCovid();
      }else if(that.configurateReport.type === 2 && that.configurateReport.excelCharts === 3){
          this.getStakedGraphInfo();

      }else if (that.configurateReport.excelCharts === 2) {
          this.getOtherGraphsInfo();
      }else {
          if(that.report.reportId === 180){
            this.getDrawHeatMapResidualInfo();
            this.getOtherGraphsResidualInfo();
          }
          this.getDrawHeatMapInfo();
          this.getOtherGraphsInfo();

      }
    }
  }

  callEsgHeatMaps(index:any){
    this.apiService.get('esg/heatmap/'+this.reportGraph.report.reportObjectId, index)
    .then((data:any)=>{
        this.reportGraph.esgHeatMapData.push({
          graph: data,
          title: this.getEsgTitle(index),
          xAxxis:this.getXAxxis(index),
          yAxxis: this.getYAxxis(index)
        });
        index++
        if(index < 5){
          this.callEsgHeatMaps(index)
        }
    })
  }

  getEsgTitle(index:number){
    switch(index){
      case 1:
        return this.translate.instant('report-graph.risk-material-incident');
      case 2:
        return this.translate.instant('report-graph.stakeholder-impact');
      case 3:
        return this.translate.instant('report-graph.risk-impact');
      default:
        return this.translate.instant('report-graph.opportunity-impact');
    }
  }

  getXAxxis(index:number){
    switch(index){
      case 1:
        return this.translate.instant('report-graph.axxis-magnitude');
      default:
        return this.translate.instant('report-graph.axxis-probability');
    }
  }

  getYAxxis(index:number){
    switch(index){
      case 1:
      case 2:
        return this.translate.instant('report-graph.axxis-severite');
      default:
        return this.translate.instant('report-graph.axxis-magnitude');
    }
  }

  getDrawHeatMapInfo(){
      this.apiService.get('reportobjects/risksmapchart',this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphData = {
                graph: data,
                xAxxis: this.translate.instant('report-graph.axxis-impact'),
                yAxxis: this.translate.instant('report-graph.axxis-probability')
              }

          })
  }

  getOtherGraphsInfo(){
      this.apiService.get('reportobjects/mapdoughnut', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.otherGraphsData = this.configureData(data);
          })
  }

  getDrawHeatMapResidualInfo(){
      this.apiService.get('reportobjects/risksmapchartresidual',this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphResidualData ={
                graph: data,
                graphicId: 'residual',
                xAxxis: this.translate.instant('report-graph.axxis-impact'),
                yAxxis: this.translate.instant('report-graph.axxis-probability')
              }

          })
  }

  getOtherGraphsResidualInfo(){
      this.apiService.get('reportobjects/mapdoughnutresidual', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.otherGraphsResidualData = this.configureData(data);
              this.reportGraph.otherGraphsResidualData.graphicId = 'residual'
          })
  }




  getGraphtsTaskInfo(){
      this.apiService.get('reportobjects/type4graphic1', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.reportGraph.graphTaskData = [];
              this.reportGraph.graphTaskData.titles = [];
              this.reportGraph.graphTaskData.tipology = data;
              data.forEach((item:any) => {
                this.reportGraph.graphTaskData.titles.push(item.typology)
              });
          })
  }

  getGraphtsTaskAnualInfo(){
      this.apiService.get('reportobjects/type4graphic3', this.reportGraph.report.reportObjectId)
          .then((data)=>{
              this.reportGraph.graphTaskAnualData=[];
              this.reportGraph.graphTaskAnualData.month=data;
          },(error:any)=>{})
  }

  getBarGraphCovid(){
      this.apiService.get('reportobjects/type6chartdata', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
            this.reportGraph.graphData = data;
          },(error)=>{})
  }

  getDonutGraphCovid(){
      this.apiService.get('reportobjects/type1subtype2chartdata', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
              this.configureDonutCovidData(data);
          },(error:any)=>{})

  }

  configureDonutCovidData(data:any){
    this.reportGraph.donutCovidGraphsData={
          data:[],
          colors:[],
          legend:[],
          amount: 0
      }
      data.forEach((item:any) => {
        this.reportGraph.donutCovidGraphsData.amount+= item.dataY;
        this.reportGraph.donutCovidGraphsData.colors.push(item.color);
        this.reportGraph.donutCovidGraphsData.data.push(item.dataY);
      });
      this.reportGraph.donutCovidGraphsData.original = data;
  }

  configureData(data:any){
    let item: any={
          data:[],
          colors:[],
          legend:[],
          amount: 0
      }
      data.sourceData.forEach((sourceData:any) => {
        if(sourceData.count){
          item.amount+= sourceData.count;
          item.colors.push(sourceData.color);
          item.data.push(sourceData.count);
      }
      });

      item.original = data.sourceData;
      item.graphicCaption = data.graphicCaption;
      item.legendCaption = data.legendCaption;
      return item;
  }

  getStakedGraphInfo(){
      this.apiService.get('reportobjects/stackedcolumns/', this.reportGraph.report.reportObjectId)
          .then((data:any)=>{
            this.reportGraph.stackedGraphData = data;
          }, (errorData:any)=>{})
  }

  saveAsPng(){
    let documentObject:any = document;
    this.reportGraph.disabledSaveAsPng = true;
    let that = this;
    html2canvas(documentObject.querySelector('.report-graphs'))
      .then(function(canvas) {
          canvas.toBlob((blob:any)=>{
              let data = [new ClipboardItem({ [blob.type]: blob })];
              navigator.clipboard.write(data)
                  .then(()=>{}, (err)=>{})
          });
          setTimeout(()=>{
              that.reportGraph.disabledSaveAsPng = false;
          }, 3000)
      })
  }

}
