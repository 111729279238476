<label class="common__input-block"
  [for]="'field-type-list-'+fieldTypeList.id"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block--custom-field-fixed':isLanding && !fieldTypeList.field.sequentialView,
      'common__show-alert': fieldTypeList.field.error && !fieldTypeList.field.sequentialView,
      'common__input-no-editable':fieldTypeList.field.noEditable}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      *ngIf="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': fieldTypeList.field.error,
      'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="fieldTypeList.field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView">
          </tooltip-law>
      </p>
  </div>
  <div [class]="'common__label-block common__'+fieldTypeList.classWidthField"
      [ngHide]="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable">
      <span class="common__input-text"
          [ngClass]="{'common__input-text--required': fieldTypeList.field.error,
                  'common__input-text--no-editable': fieldTypeList.field.noEditable}">{{fieldTypeList.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="fieldTypeList.field.required">*</span>
      <div class="common__info-icon-block"
          [ngHide]="fieldTypeList.field.noEditable || !fieldTypeList.field.description">
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
          [ngShow]="fieldTypeList.field.references && fieldTypeList.field.references.length && !fieldTypeList.field.noEditable">
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="fieldTypeList.field.relatedInfo && fieldTypeList.field.relatedInfo.length && !fieldTypeList.field.poll && !fieldTypeList.field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(fieldTypeList.field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
      <div class="field-type-list__info-block"
          [ngShow]="isLanding"
          (click)="fieldTypeList.showLessInfo = !fieldTypeList.showLessInfo">
          <span [afTranslate]="fieldTypeList.showLessInfo ? 'field-type-list.more-info' : 'field-type-list.less-info'"></span>
          <i [ngClass]="fieldTypeList.showLessInfo ? 'lf-icon-add-empty' : 'lf-icon-less-empty'"></i>
      </div>
  </div>
  <div [ngClass]="{'common__input-poll':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView && !fieldTypeList.field.isHighlight && !fieldTypeList.field.numericalScale,
          'common__show-alert': fieldTypeList.field.error && fieldTypeList.field.sequentialView && !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight,
          'field-type-list__block-list-elements':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}">
      <div class="float-end">
          <i class="lf-icon-connected-data field-type-text__icon-references field-type-text__icon-references--less-top"
              [ngShow]="fieldTypeList.field.evaluatedValue?.hasReferences && !fieldTypeList.field.poll"
              (click)="showReference($event)"
              matTooltip="{{'common.see-dependencies' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left"></i>
      </div>
      <div class="field-type-list__block-template">
        <div class="field-type-list__block-buttons">
          <div class="field-type-list__block-inner-buttons"
              *ngFor="let listValue of listValues; let index = index">
              <div class="field-type-list__list-isbutton"
                  [ngClass]="{'field-type-list__list-isbutton--selected': listValue.selected,
                      'field-type-list__list-isbutton--disabled': (fieldTypeList.field.noEditable || listValue.disabled) && !listValue.selected,
                      'field-type-list__list-isbutton--less-info': !isLanding || fieldTypeList.showLessInfo}">
                    <div class="field-type-list__list-content"
                        [ngClass]="{'field-type-list__list-content--less-info': !isLanding || fieldTypeList.showLessInfo}">
                        <img class="field-type-list__coming-icon"
                            src="../../assets/img/rotulo-disponible-proximamente.png"
                            alt="Disponible próximamente"
                            *ngIf="isLanding &&listValue.comingSoonButton && !fieldTypeList.showLessInfo"/>
                        <i [class]="listValue.icon + ' field-type-list__title-icon'"
                            [ngClass]="{'field-type-list__title-icon--less-info': !isLanding || fieldTypeList.showLessInfo,
                                        'field-type-list__title-icon--disabled': (fieldTypeList.field.noEditable || listValue.disabled) && !listValue.selected,
                                        'field-type-list__title-icon--selected': listValue.selected}"
                            [ngShow]="!(isLanding && listValue.comingSoonButton && !fieldTypeList.showLessInfo)"></i>
                        <p [id]="'field-select-button-title-'+index"
                            class="field-type-list__title"
                            [ngClass]="{'field-type-list__title--less-info': !isLanding || fieldTypeList.showLessInfo,
                                        'field-type-list__title--disabled': (fieldTypeList.field.noEditable || listValue.disabled) && !listValue.selected}"
                            matTooltip="{{listValue.text}}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            [matTooltipDisabled]="showTooltipHeight('field-select-button-title', index)">
                            <span [id]="'span-field-select-button-title-'+index">{{listValue.text}}</span>
                        </p>
                        <ul class="field-type-list__list-elements"
                            *ngIf="isLanding"
                            [ngHide]="fieldTypeList.showLessInfo"
                            [ngClass]="{'field-type-list__list-elements--disabled': (fieldTypeList.field.noEditable || listValue.disabled) && !listValue.selected}">
                            <li class="field-type-list__list-elements-mobile"
                                [ngClass]="{'field-type-list__list-elements-mobile--show': listValue.showLessInfo,
                                            'field-type-list__list-elements--level-2': element.startsWith('-')}"
                                *ngFor="let element of listValue.elements">{{element.startsWith('-') ? element.substr(1) : element}}</li>
                        </ul>
                    </div>
                  <!-- </div> -->
                  <button class="field-type-list__button"
                      [ngClass]="{'field-type-list__button--selected': (!fieldTypeList.field.noEditable && !listValue.disabled) && listValue.selected,
                                  'field-type-list__button--disabled': (fieldTypeList.field.noEditable || listValue.disabled) && !listValue.selected,
                                  'field-type-list__button--selected-disabled':listValue.selected && (fieldTypeList.field.noEditable || listValue.disabled)}"
                      (click)="selectedButtonField(listValue)"
                      [disabled]="(fieldTypeList.field.noEditable || listValue.disabled)">
                      <i class="field-type-list__icon-button"
                          [ngClass]="isLanding?'lf-icon-check-round':'lf-icon-check'"></i>
                      <span>SELECCIONAR</span>
                  </button>
              </div>
          </div>
        </div>
      </div>
  </div>
  <div class="field-item__blocker-box"
      *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
</label>
