import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'filter-by-list',
  templateUrl: './filter-by-list.component.html'
})
export class FilterByListComponent implements OnChanges{
  isLanding: any = this.route.snapshot.data['isLanding'];
  skin: string = this.isLanding?'landing-custom' : this.globalCfg.skin;
  filterByList: any = {
    inputFilter: '',
    userLogin: this.context.user,
    orderBy: 'name',
    name: '',
    id: this.commonUtilsService.createId(),
    list : [],
    // openList: false
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private context: ContextFactory,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory,
    private route: ActivatedRoute) { }
  @Input() type: string = '';
  @Input() topStyle: string = '';
  @Input() item:any = null;
  @Input() list: any = null;
  @Input() filterOrder: any = null;
  @Input() order: any = null;
  @Input() showMyOption: any = null;
  @Input() widthClass: string = '';
  // @Input() custom: any = null;
  @Input() selected: any = false;
  @Input() textColumn: any;

  @Output() filterElementEmit = new EventEmitter();
  @Output() orderCol = new EventEmitter();

  ngOnChanges(): void {

    if(this.item){
      this.filterByList.title = this.textColumn?this.item[this.textColumn]:this.item.name;
      if (this.item.orderBy) {
          this.filterByList.orderBy = this.item.orderBy;
      }
      this.filterByList.item = this.item
    }
    if(this.list && this.item){
        this.filterByList.list = this.list
        this.configureName()
    }
  }

  configureName(){
    this.filterByList.list.forEach((item:any) => {
      item.nameToShow = item.nameTranslate? item.nameTranslate: item.name
    });
  }

  selectElement(e:any, source:any){
    e.stopPropagation();
    e.preventDefault();
    this.filterElementEmit.emit({source: source})
  }

  resetFilter(e:any){
    e.stopPropagation();
    e.preventDefault();
    this.filterByList.inputFilter = '';
  }

  orderColumn(type:any){
      this.orderCol.emit({type: type});
  }


}
