import moment from 'moment';
import { Component,Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

@Component({
  selector: 'listen-record',
  templateUrl: './listen-record.component.html'
})
export class ListenRecordComponent {
  custom: any = this.customLanding.getCustom();
  startAudio: boolean = false;
  initInterval: number = 0;
  percentTime: number = 0;
  recordedTime: string = '00:00';
  interval: any;
  startTime: any;
  constructor(
    private translate: AfTranslateFactory,
    private activeModal: NgbActiveModal,
    private customLanding: CustomLandingFactory) { }

  @Input() voiceRecord:any;
  @Input() isLanding:boolean;


  audioDownLoad(){
    const url = URL.createObjectURL(this.voiceRecord.recordingFile);
	  const anchor = document.createElement("a");
	  anchor.download = this.voiceRecord.name
	  anchor.href = url;
	  anchor.click();
  }

  playRecordVoice(){
    this.startAudio = !this.startAudio;
    if(this.startAudio){
      this.initInterval = 0;
      this.initStartAudio()
    }else{
      this.stopInterval();
    }
  }

  initStartAudio(){
    this.startTime = moment();
    this.voiceRecord.player.play();
    this.interval = setInterval(() => {
      let currentTime:any = moment();
      let diffTime = moment.duration(currentTime.diff(this.startTime));
      this.recordedTime = this.toString(diffTime.minutes()) + ":" + this.toString(diffTime.seconds());
      this.percentTime = this.initInterval * 100 / this.voiceRecord.secondsRecord;
      if(this.percentTime === 100){
        this.stopInterval()
      }else{
        this.initInterval++
      }
    }, 1000);
    this.startAudio = true;
  }

  stopInterval(){
    this.startAudio = false;
    this.initInterval = 0;
    this.voiceRecord.player.pause();
    this.voiceRecord.player.currentTime = 0;
    this.percentTime = 0;
    this.recordedTime = '00:00'
    clearInterval(this.interval)
  }

  private toString(value:any) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }


  handleAccept(optionSelect:string='ok') {
    this.stopInterval()
    this.activeModal.close({result: optionSelect})
  }
}
