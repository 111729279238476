import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiPropertiesFactory } from '@shared/factories/api-properties/api-properties.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'corporative-information-modal',
  templateUrl: './corporative-information-modal.component.html'
})
export class CorporativeInformationModalComponent implements OnInit {
  skin: string = this.globalCfg.skin;
  limitPasswordChar:any = 100;
  corporativeInformationModal: any = {
    accept: this.translate.instant('common.ok'),
    errorLimitPassword: this.translate.instant('corporative-information-modal.pass-error').replace('{characters}', this.limitPasswordChar),
    clientId: this.route.snapshot.params['clientId'],
    companyCopy: null,
    showOtherFields: true,
    showMoreData: false,
    stepState: 1,
    mailAuth: '',
    itsConfigurate: false,
    message: '',
    modify: false,
    logoName: '',
    noGlobal: false,
    notifyDisabled: false,
    customTypeSelect: '0',
    footerDesignType: {
        multiple: false,
        required: true,
        label: this.translate.instant('global-customization.design-footer')
    },
    addFields: null,
    fileName: [],
    colorList: ['#AF9F01', '#B87300', '#BC5901', '#C10F0B', '#830065', '#4A006F', '#001478', '#01417F', '#009598', '#00773B', '#008B07', '#000000', '#FFF201', '#FFB001', '#EB6F00', '#FF3734', '#D011A2', '#9470C0', '#003FAE', '#3B92D6', '#00D2D6', '#00B165', '#00CF2A', '#898989'],
    company: {
        color: '',
        footerDesignType: null,
        youtube: '',
        twitter: '',
        facebook: '',
        linkedin: '',
        phone: '',
        showLogo: '',
        customEmail:false,
        logo: null,
        name: '',
        smtpServer: '',
        smtpPort: null,
        smtpUser: '',
        smtpPassword: '',
        smtpFrom: '',
        smtpssl: false,
        logoFile: null
    },
    tinyMceOptions: {
        height: 290,
        typeView: 'white',
        placeholder: this.translate.instant('corporative-information-modal.placeholder'),
        toolbar: 'forecolor bold italic underline alignleft aligncenter alignright link unlink'
    },
    clientFavicon: {
        id: 'favicon-corporative-information',
        label: this.translate.instant('corporative-information-modal.favicon-label'),
        description: this.translate.instant('corporative-information-modal.favicon-description'),
        accept:'.png,.ico,.jpg,.jpeg'
    },
    color: {
        id:"principal-color",
        label: this.translate.instant('global-customization.principal-color'),
        required: true,
        type:'principal-color'
    },
    textColor: {
        id: 'text-color-customization',
        label: this.translate.instant('global-customization.foreground-color'),
        required: true
    },
    fieldsExports: {
        multiple: false,
        type: 'modal'
    },
    youtubeLink: {
        id: "youtube-link",
        label: 'Youtube',
        type: 'link'
    },
    twitterLink: {
        id: "twitter-link",
        label: 'Twitter',
        type: 'link'
    },
    facebookLink: {
        id: "facebook-link",
        label: 'Facebook',
        type: 'link'
    },
    linkedinLink: {
        id: "linkedin-link",
        label: 'Linkedin',
        type: 'link'
    },
    phoneReference: {
        id: "phone-reference-customization",
        label: this.translate.instant('global-customization.reference-phone'),
        fieldTypeId: 'num'
    },
    clientLogo: {
        id: '0',
        label:this.translate.instant('corporative-information-modal.logo'),
        description: null,
        required: true
    },
    sMTPServerField: {
        id: "incoming-server-input",
        label: this.translate.instant('corporative-information-modal.output-server'),
        required: true
    },
    sMTPPortField: {
        id: "port-server-imap",
        fieldTypeId: 'num',
        label:  this.translate.instant('corporative-information-modal.port'),
        required: true,
        positive: true
    },
    sMTPUserField: {
        id: "user-mail",
        label: this.translate.instant('corporative-information-modal.user-smpt'),
        required: true
    },
    sMTPPasswordField: {
        id: "user-pass",
        label: this.translate.instant('login-block.password'),
        required: true,
        visible: false
    },
    sMTPFromField: {
        id: "user-from",
        label: this.translate.instant('corporative-information-modal.mail-from'),
        required: true
    },
    authMailField:{
      required: true,
      label: this.translate.instant('common.email-account')
    },
    footerDesignTypeList: [
      {
          text: this.translate.instant('global-customization.footer-white'),//'_Fondo blanco con texto e iconos negros.',
          listValueId: 1
      },
      {
          text: this.translate.instant('global-customization.footer-black'),//'_Fondo negro con texto e iconos blancos.',
          listValueId: 2
      },
      {
          text: this.translate.instant('global-customization.footer-principal-color'),//'_Fondo del color principal con texto e iconos en blanco o negro.',
          listValueId: 3
      }
    ],
    steps:[
      {name:this.translate.instant('corporative-information-modal.wizard-step-1')},  //"marca y aspecto grafico"
      {name: this.translate.instant('corporative-information-modal.wizard-step-2')},  //"pie de pagina"
      {name: this.translate.instant('corporative-information-modal.wizard-step-3')}, //"Correo personalizado"
      {name: this.translate.instant('corporative-information-modal.custom-title')}
    ],
    customEmailType: {
      multiple: false,
      required: true,
      type: 'modal',
      label: this.translate.instant('corporative-information-modal.configuration-type')
    },
    customEmailTypeOptions: [{
          listValueId:'0',
          text: this.translate.instant('corporative-information-modal.smtp')
      },{
          listValueId:'1',
          text: this.translate.instant('corporative-information-modal.office-auth')
      }
    ]
  }

  constructor(
    private globalCfg: GlobalCfgFactory,
    private translate: AfTranslateFactory,
    private route:ActivatedRoute,
    private commonUtilsService: CommonUtilsService,
    private apiService: ApiServiceService,
    private modalService: ModalServiceService,
    private activeModal: NgbActiveModal,
    private loader: LoaderFactory,
    private apiProperties: ApiPropertiesFactory
  ) { }

  @Input() concept:any;
  @Input() corporativeInformation:any;
  @Input() clientId:any;

  ngOnInit(): void {
    let that = this.corporativeInformationModal;
    if(this.corporativeInformation && this.corporativeInformation.publishConcept){
        that.corporativeInformation = this.corporativeInformation;
        that.company = this.corporativeInformation.publishConcept;
        if(that.corporativeInformation.allLogos){
            that.allLogos = that.corporativeInformation.allLogos;
            that.company.logoFile = this.convertToFormData(that.corporativeInformation.allLogos);
        }
        if(that.corporativeInformation.faviconImg){
            that.company.faviconImg = that.corporativeInformation.faviconImg;
        }
        if(!that.corporativeInformation.globalConfiguration){
            that.noGlobal = true;
        }
        if(that.company.color !== null && that.company.footerDesignType !== null && that.company.color !== ""){
            that.notifyDisabled = false;
        }else{
            that.notifyDisabled = true;
        }
        that.message = that.corporativeInformation.publishConcept.footer;
        if(that.corporativeInformation.configurated){
            if(that.corporativeInformation.logoImage !== null && that.corporativeInformation.logoImage.indexOf('data:image') === -1 && that.corporativeInformation.logoImage !== ""){
                that.company.logo = "data:image/png;base64," + that.corporativeInformation.logoImage;
                that.company.name = '';
                that.showLogo = true;
            }else{
                that.company.logo = that.corporativeInformation.logoImage;
            }
        }else{
            if(that.corporativeInformation.logoImage){
                that.company.logo = that.corporativeInformation.logoImage;
                that.company.name = '';
                that.corporativeInformation.logo = '';
                if(that.corporativeInformation.logoImage.indexOf('data:image') === -1 && that.corporativeInformation.logoImage !== ""){
                    that.company.logo = "data:image/png;base64," + that.corporativeInformation.logoImage;
                    that.company.name = '';
                    that.showLogo = true;
                }else{
                    that.company.logo = that.corporativeInformation.logoImage;
                }
            }else{
                this.getLogo();
            }
        }
        that.companyCopy = cloneDeep(that.company);
        if(that.company.customEmail && that.company.oauthUser !=='' && that.company.oauthUser !== null){
          that.initAuthProcess = true;
          that.customTypeSelect = '1';
          that.mailAuth = that.company.oauthUser;
          that.itsConfigurate = !that.company.oauthReconfigNeeded;
          if(that.company.oauthReconfigNeeded){
            that.company.oauthUser = ''
          }
        }

        if(that.company.smtpUser && !that.company.smtpFrom && this.commonUtilsService.validateMail(that.company.smtpUser)){
          that.company.smtpFrom = cloneDeep(that.company.smtpUser);
          that.modify = true
        }
     
        if(that.corporativeInformation.titleInfo){
          that.languagesApp = cloneDeep(that.corporativeInformation.titleInfo.languagesApp);
          that.languagesSelected = cloneDeep(that.corporativeInformation.titleInfo.selectedLanguages);
          this.checkSelected();
        }
      
        
        that.dataTreatment = {
            fieldId:'landing-title-concept',
            fieldTypeFrontId: 'text',
            label: this.translate.instant('corporative-information-modal.custom-title-field'),
            maxLength: 80,
            preSelectItem: that.languagesSelected[0].preSelectItem
        }
    }else{
        that.modify = true;
        if(!that.corporativeInformation.globalConfiguration){
            that.noGlobal = true;
        }
        that.notifyDisabled = true;
        this.getLogo();
    }
  }

    convertToFormData(logos:any) {
        let fd : any = new FormData();
        let logo : any = this.commonUtilsService.baseToFormdata(logos.logo, logos.logo);
        let logo320X55 : any = this.commonUtilsService.baseToFormdata(logos.logo320X55);
        let logo260X45 : any = this.commonUtilsService.baseToFormdata(logos.logo260X45);
        let logo290X50 : any = this.commonUtilsService.baseToFormdata(logos.logo290X50);
        fd.append('file', logo);
        fd.append('file', logo320X55);
        fd.append('file', logo260X45);
        fd.append('file', logo290X50);
        return fd;
    }

    configureLogo(company:any, logo:any){
        if(logo !== null && typeof logo === 'string' && logo.indexOf('data:image') === -1 && logo !== ""){
            company.logo = "data:image/png;base64," + logo;
            company.name = '';
            this.corporativeInformationModal.showLogo = true;
        }else{
            company.logo = logo;
        }
    }

    getLogo(){
        this.apiService.get('clients', this.clientId)
            .then((data:any)=>{
                if(data.logo !== ""){
                    this.corporativeInformationModal.allLogos = this.configureLogos(data);
                    this.corporativeInformationModal.company.allLogos = cloneDeep(this.corporativeInformationModal.allLogos);
                    this.corporativeInformationModal.company.logoFile = this.convertToFormData(this.corporativeInformationModal.company.allLogos);
                    this.corporativeInformationModal.company.logo = this.corporativeInformationModal.allLogos.logo;
                    this.corporativeInformationModal.showLogo = true;
                    this.corporativeInformationModal.companyCopy = cloneDeep(this.corporativeInformationModal.company);
                }
            }, (errorData:any)=>{})
    }

    configureLogos(data:any){
        return {
            logo: this.commonUtilsService.setLogo(data.logo, data.logo),
            logo320X55: this.commonUtilsService.setLogo(data.logo320X55,data.logo),
            logo260X45: this.commonUtilsService.setLogo(data.logo260X45,data.logo),
            logo290X50: this.commonUtilsService.setLogo(data.logo290X50,data.logo)
        }
    }

    checkSelected(){
      let hasSelect: boolean = false;
      for(let languageSelect of this.corporativeInformationModal.languagesSelected){
        if(languageSelect.active){
          hasSelect = true;
          break;
        }
      }
      if(!hasSelect){
        this.corporativeInformationModal.languagesSelected[0].active = true
      }
    }

    openFileInput(clickClass:string){
        setTimeout(()=>{
            $('#'+ clickClass).click()
        })
    }
    itemSelectedOn(e: any, type: string){
      this.itemSelected(type, e.newValue);
    }

  itemSelected(type:string, newValue:any){
    let that = this.corporativeInformationModal;
    switch(type){
      case 'color':
      case 'textColor':
          that.company[type] = newValue.value.hex;
          if(that.company[type] !== '' || that.company[type] !== null){
              that[type].error = false;
          }
          break;
      case 'logo':
          this.commonUtilsService.setLogo(newValue.img);
          that.company.logo = newValue.img;
          that.company.name = newValue.name;
          that.company.logoFile = newValue.files;
          that.company.listLogos = newValue.listLogos;
          that.allLogos ={
              logo:newValue.listLogos[0].html.toDataURL('image/png'),
              logo320X55: newValue.listLogos[2].html.toDataURL('image/png'),
              logo260X45: newValue.listLogos[3].html.toDataURL('image/png'),
              logo290X50: newValue.listLogos[1].html.toDataURL('image/png'),
          }
          that.modifyLogos = true;
          break;
      case 'favicon':
          that.company.faviconName = newValue.name;
          that.company.faviconImg = newValue.img;
          that.company.faviconFile = newValue.file;
          that.modifyFavicon = true;
          break;
      case 'smtpServer':
      case 'smtpPort':
      case 'smtpUser':
      case 'smtpPassword':
      case 'smtpFrom':
      case 'smtpssl':
      case 'customEmail':
          if(type === 'smtpServer'){
              that.sMTPServerField.error = false;
              that.errorServer = newValue?.length > 50?that.errorServer:false;
          }else if(type === 'smtpPort'){
              that.sMTPPortField.error = false;
              that.errorPort = newValue.toString()?.length > 5?that.errorPort:false;
            }else if(type === 'smtpUser'){
              that.sMTPUserField.error = false;
              that.errorUser = newValue?.length > 50?that.errorUser:false;
              if(!that.company.smtpFrom && this.commonUtilsService.validateMail(newValue)){
                  that.company.smtpFrom = cloneDeep(newValue);
                  that.sMTPFromField.error = false;
                  that.errorFrom = newValue?.length > 50?that.errorFrom:false;
                  that.sMTPFromField.errorField = !newValue || newValue?.length > 50?that.sMTPFromField.errorField:false;
              }
          }else if(type === 'smtpPassword'){
              that.sMTPPasswordField.error = false;
              that.errorPass = newValue?.length > this.limitPasswordChar?that.errorPass:false;
          }else if(type === 'smtpFrom'){
              that.errorFrom = newValue?.length > 50?that.errorFrom:false;
              that.sMTPFromField.errorField = !newValue || newValue?.length > 50?that.sMTPFromField.errorField:false;

          }
          that.company[type] = newValue;
          if(this.changesInCorprativeInformation()){
              that.modify = true;
              that.accept = this.translate.instant('common.save');
          }else{
              that.accept = this.translate.instant('common.ok');
              that.modify = false;
          }
          break;
      default:
          that.company[type] = newValue;
    }
    if(that.company.color !== null && that.company.footerDesignType !== null && that.company.color !== ""){
        that.notifyDisabled = false;
    }
  }

    changesInCorprativeInformation(){
        let valueToReturn: any = false
        let copy: any = this.corporativeInformationModal.companyCopy;
        if(!copy){
        valueToReturn = true;
        }else if(copy && this.corporativeInformationModal.company){
            for(let property in copy){
                if(copy[property] !== this.corporativeInformationModal.company[property] && property !== 'logoFile'){
                valueToReturn = true;
                }
            }
        }
        return valueToReturn;
    }

  showPreview() {
    let data:any = {
        customizations: this.getModel()
    };
    this.modalService.notificationPreview('publicConceptObjectNew', 'concept', this.concept.conceptId, data);
  }

  getModel() {
    let company: any = this.corporativeInformationModal.company;
    return {
        color: company.color,
        textColor: company.textColor,
        footerDesignType: company.footerDesignType,
        youtube: company.youtube?company.youtube:'',
        twitter: company.twitter?company.twitter:'',
        facebook: company.facebook?company.facebook:'',
        linkedin: company.linkedin?company.linkedin:'',
        phone: company.phone,
        smtpServer: company.smtpServer,
        smtpPort: company.smtpPort,
        smtpUser: company.smtpUser,
        smtpPassword: company.smtpPassword,
        smtpFrom: company.smtpFrom,
        customEmail: company.customEmail,
        oauthAccessToken: company.oauthAccessToken,
        oauthUser: company.oauthUser,
        showLogoInFooter: company.showLogoInFooter,
        smtpssl: company.smtpssl,
        conceptId: this.concept.conceptId,
        sharedPublicConceptConfigurationId: company.sharedPublicConceptConfigurationId,
        footer: this.corporativeInformationModal.message?this.corporativeInformationModal.message : '',
        logoDataUri: company.logo,
        logoHeaderDataUri: this.corporativeInformationModal.allLogos.logo260X45,
        logoFooterDataUri: this.corporativeInformationModal.allLogos.logo320X55

    }
  }

    openMoreData(){
        let height:any = $('.corporative-information-modal__form-block').height()
        !this.corporativeInformationModal.showMoreData? $('.corporative-information-modal__form-block').animate({ scrollTop: height-30 }, 'slow') : $('.corporative-information-modal__form-block').animate({ scrollBottom: height }, 'slow');
        this.corporativeInformationModal.showMoreData = !this.corporativeInformationModal.showMoreData;
    }

    nextStep(){
        if(this.validateFieldStep(this.corporativeInformationModal.stepState)){
            if(this.corporativeInformationModal.stepState === 2){
                this.corporativeInformationModal.company.footer = this.corporativeInformationModal.message? this.corporativeInformationModal.message : '';
                this.corporativeInformationModal.modify = this.changesInCorprativeInformation()
                this.corporativeInformationModal.accept = this.corporativeInformationModal.modify? this.translate.instant('common.save') : this.translate.instant('common.ok');
            }
            this.corporativeInformationModal.stepState++;
        }
    }

    validateFieldStep(step:any){
        let company: any = this.corporativeInformationModal.company;
        let that: any = this.corporativeInformationModal;
        switch(step){
            case 1:
                this.corporativeInformationModal.color.error = !this.commonUtilsService.checkIsHexadecimalColor(company.color);
                this.corporativeInformationModal.textColor.error = !this.commonUtilsService.checkIsHexadecimalColor(company.textColor);
                this.corporativeInformationModal.footerDesignType.error = (!company.footerDesignType);
                this.corporativeInformationModal.clientLogo.error = (company.logo === '' || company.logo === undefined)
                return !(this.corporativeInformationModal.clientLogo.error || this.corporativeInformationModal.footerDesignType.error || this.corporativeInformationModal.textColor.error || this.corporativeInformationModal.color.error);
            case 2:
              return true;
            case 3:
              if(that.company.customEmail){
                if(that.customTypeSelect === '0'){
                    return this.validateFieldSMTP();
                }else{
                  if (that.mailAuth === '' && company.oauthUser === ''){
                    that.authMailField.error = true;
                  }else  if(that.company.oauthAccessToken ==='' || that.company.oauthAccessToken === null || that.company.oauthReconfigNeeded){
                    that.authAccessTokenError = true;
                  }
                  return !Boolean(that.authMailField.error || that.authAccessTokenError)
                }

              }
              return true
            case 4:
              return true
            default:
              return false;
        }
    }

    prevStep(){
        this.corporativeInformationModal.stepState--;
    }

    glConfig(){
        this.modalService.adviceModal(this.translate.instant('corporative-information-modal.global-question'), 'info:question:question').subscribe((result:any) => {
              if(result.result === 'ok'){
                this.corporativeInformationModal.corporativeInformation.globalConfiguration.global = true;
                this.corporativeInformationModal.corporativeInformation.globalConfiguration.conceptId = this.concept.conceptId;
                this.activeModal.close({
                    result : 'global'
                })
              }
          });
    }

    validateFieldSMTP() {
        let company: any = this.corporativeInformationModal.company;
        if(company.smtpServer !== '' || company.smtpPort || company.smtpUser !== '' ||  company.smtpPassword !== ''){
            this.corporativeInformationModal.sMTPServerField.error = company.smtpServer?.length > 50;
            this.corporativeInformationModal.errorServer = (this.corporativeInformationModal.sMTPServerField.error && company.smtpServer?.length > 50);
            this.corporativeInformationModal.sMTPPortField.error = !(company.smtpPort && parseInt(company.smtpPort) < 65535);
            this.corporativeInformationModal.errorPort = (this.corporativeInformationModal.sMTPPortField.error && company.smtpPort && parseInt(company.smtpPort)>= 65535);
            this.corporativeInformationModal.sMTPUserField.error = !(company.smtpUser !== null && company.smtpUser !== '' && company.smtpUser.length <= 50);
            this.corporativeInformationModal.errorUser = (this.corporativeInformationModal.sMTPUserField.error && company.smtpUser?.length > 50);
            this.corporativeInformationModal.sMTPPasswordField.error = company.smtpPassword?.length > this.limitPasswordChar;
            this.corporativeInformationModal.errorPass = (this.corporativeInformationModal.sMTPPasswordField.error && company.smtpPassword?.length > this.limitPasswordChar);
            this.corporativeInformationModal.sMTPFromField.error = !this.commonUtilsService.validateMail(company.smtpFrom);
            this.corporativeInformationModal.errorFrom = company.smtpFrom?.length > 50;
            this.corporativeInformationModal.sMTPFromField.errorField = !company.smtpFrom || this.corporativeInformationModal.errorFrom?true:false;
            let errorFrom = this.corporativeInformationModal.sMTPFromField.error || this.corporativeInformationModal.sMTPFromField.errorField
            return (!this.corporativeInformationModal.sMTPServerField.error && !this.corporativeInformationModal.sMTPPortField.error && !this.corporativeInformationModal.sMTPUserField.error && !this.corporativeInformationModal.sMTPPasswordField.error && !errorFrom);
        }else{
            this.corporativeInformationModal.sMTPServerField.error = true;
            this.corporativeInformationModal.sMTPPortField.error = true;
            this.corporativeInformationModal.sMTPUserField.error = true;
            this.corporativeInformationModal.sMTPPasswordField.error = true;
            this.corporativeInformationModal.sMTPFromField.error = true;
            return false;
        }
    }

    handleDismiss(type:string = 'cancel') {
      this.corporativeInformation.publishConcept =  this.corporativeInformationModal.companyCopy;
      this.activeModal.close({
              result: type,
              titleInfo: this.corporativeInformationModal.languagesSelected
      });
    }

    handleAccept() {
        if(this.corporativeInformationModal.modify || this.corporativeInformationModal.modifyLogos || this.corporativeInformationModal.modifyFavicon){
          if (this.corporativeInformationModal.company.customEmail) {
            if(this.corporativeInformationModal.customTypeSelect==='0'){
              this.corporativeInformationModal.company.oauthUser = null;
              this.corporativeInformationModal.company.oauthScope = null;
              this.corporativeInformationModal.company.oauthAccessToken = null;
              this.corporativeInformationModal.company.oauthRefreshToken = null;
              this.validationOk();
            }else{
              if(this.corporativeInformationModal.company.oauthUser !==''){
                this.corporativeInformationModal.company.smtpServer = null;
                this.corporativeInformationModal.company.smtpPort = null;
                this.corporativeInformationModal.company.smtpUser = null;
                this.corporativeInformationModal.company.smtpPassword = null;
                this.corporativeInformationModal.company.smtpFrom = null;
                this.validationOk();
              }
            }
          } else {
            this.corporativeInformationModal.company.smtpServer = null;
            this.corporativeInformationModal.company.smtpPort = null;
            this.corporativeInformationModal.company.smtpUser = null;
            this.corporativeInformationModal.company.smtpPassword = null;
            this.corporativeInformationModal.company.smtpFrom = null;
            this.corporativeInformationModal.company.oauthUser = null;
            this.corporativeInformationModal.company.oauthScope = null;
            this.corporativeInformationModal.company.oauthAccessToken = null;
            this.corporativeInformationModal.company.oauthRefreshToken = null;
            this.validationOk();
          }
        }else{
            this.handleDismiss('not-modify');
        }
    }

    validationOk() {
      let company: any = this.corporativeInformationModal.company;
      company.youtube = company.youtube && company.youtube.indexOf("https://") === -1? 'https://' + company.youtube : company.youtube;
      company.twitter = company.twitter && company.twitter.indexOf("https://") === -1? 'https://' + company.twitter : company.twitter;
      company.facebook = company.facebook && company.facebook.indexOf("https://") === -1? 'https://' + company.facebook : company.facebook;
      company.linkedin = company.linkedin && company.linkedin.indexOf("https://") === -1? 'https://' + company.linkedin : company.linkedin;
      company.logoFile= company.logoFile=== undefined? '' : company.logoFile;
      this.activeModal.close({
          result: 'ok',
          logo: company.logoFile,
          logoImage: company.logo,
          listLogos: company.listLogos,
          faviconImg: company.faviconImg,
          faviconFile: company.faviconFile,
          publishConcept: this.getModel(),
          new: company.global,
          titleInfo: this.corporativeInformationModal.languagesSelected
      });
  }

  checkSMTP(company:any) {
    let model : any = {
        server: company.smtpServer,
        port: company.smtpPort,
        login: company.smtpUser,
        password: company.smtpPassword,
        from: company.smtpFrom,
        ssl: company.smtpssl
    };

    this.loader.openLoader('corporative-information.check-smtp-loader');
    this.apiService.update('publicconceptcfg/smtp-check', undefined, model)
      .then((error:any)=>{
        this.loader.closeLoader('corporative-information.check-smtp-loader');
        if (error !== null) {
          let errorCode = error.errorCode.substr(0, 1).toLowerCase() + error.errorCode.substr(1);
          let message = this.translate.instant('global-customization.smtp-validation.' + errorCode);
          if (!message) {
            message = this.translate.instant('global-customization.smtp-validation.unexpected');
          }
          this.modalService.adviceModal(message, 'error:accept:warning')
      }else{
        this.handleAccept();
      }
    }).catch(()=> {
        this.modalService.adviceModal(this.translate.instant('global-customization.smtp-validation.unexpected'), 'error:accept:warning');
        this.loader.closeError();
    });
  }

  selectLanguage(selectedLanguage:any){
    for(let i = 0; i < this.corporativeInformationModal.languagesApp.length; i++){
        let language:any = this.corporativeInformationModal.languagesApp[i];
        language.indexListLanguage = i;
        if(language.listValueId === selectedLanguage){
            language.selected = true;
            language.preSelectItem = '';
            language.defaultOption = false;
            language.titleText = !language.titleText?'':language.titleText;
            let newSelectedLanguage : any = cloneDeep(language);
            this.changeActiveLanguagesSelected(newSelectedLanguage, true);
            break;
        }
    }
  }

  changeActiveLanguagesSelected(selectedLanguage:any, addSelected:boolean = false){
      for(let [index, languageSelect] of this.corporativeInformationModal.languagesSelected.entries()){
        this.corporativeInformationModal.languagesSelected[index].active = false;
      }
      selectedLanguage.active = true;
      this.corporativeInformationModal.errorActive = selectedLanguage.active && selectedLanguage.error;
      this.corporativeInformationModal.dataTreatment.preSelectItem = selectedLanguage.preSelectItem;
      this.corporativeInformationModal.dataTreatment.error = selectedLanguage.error;
      if(addSelected){
          this.corporativeInformationModal.languagesSelected.push(selectedLanguage);
      }
  }

  removeActiveLanguage(selectedLanguage:any){
    let that: any = this.corporativeInformationModal;
    for(let [index, language] of that.languagesSelected.entries()){
        if(language.listValueId === selectedLanguage.listValueId){
            that.languagesSelected.splice(index, 1);
            break;
        }
    }
    if(selectedLanguage.active && that.languagesSelected.length){
        let newSelected : any = that.languagesSelected[that.languagesSelected.length-1];
        newSelected.active = true;
        that.dataTreatment.preSelectItem = newSelected.preSelectItem;
        that.dataTreatment.description = newSelected.textDescription;
        that.dataTreatment.error = newSelected.error;
        that.errorActive = newSelected.error;
    }
    if(!that.languagesSelected.length){
      for(let languageAppe of that.languagesApp.entries()){
        if(languageAppe.listValueId === this.apiProperties.defaultLanguage){
            this.selectLanguage(languageAppe);
            that.languagesSelected[0].defaultOption = true;
        }
      }
      that.dataTreatment.preSelectItem = '';
    }
  }

  changeField(newValue:any){
    this.corporativeInformationModal.dataTreatment.preSelectItem = newValue;
    for(let language of this.corporativeInformationModal.languagesSelected){
        if(language.active){
            language.preSelectItem = this.corporativeInformationModal.dataTreatment.preSelectItem;
            language.error = this.corporativeInformationModal.errorActive && !language.textDescription;
            this.corporativeInformationModal.dataTreatment.error = language.error;
            this.corporativeInformationModal.errorActive = language.error;
        }
    }
  }

  notSelectLanguage(language:any){
    for(let languageSelected of this.corporativeInformationModal.languagesSelected){
        if(language.listValueId === languageSelected.listValueId){
            return false;
        }
    }
    return true;
  }

  selectcustomType(newValue:any){
    this.corporativeInformationModal.customTypeSelect = newValue
  }

  selectEmail(newValue:any){
    this.corporativeInformationModal.mailAuth = newValue
  }

  initAuth(){
    this.corporativeInformationModal.authMailField.error = false;
    this.corporativeInformationModal.init = false;
    if( this.commonUtilsService.validateMail(this.corporativeInformationModal.mailAuth)){
        this.corporativeInformationModal.initAuthProcess = true;
        this.corporativeInformationModal.state = uuidv4();
        if(!this.corporativeInformationModal.init){
          window.addEventListener("message", (event:any)=>{
            if(!this.corporativeInformationModal.init){
              this.corporativeInformationModal.init = true;
              this.messageReceived(event, this)
            }
          });
        }

        window.removeEventListener("message",(event:any)=>{})
        let oauthFlowUrl = this.apiProperties.apiURL + "/utils/oauth/flow?state=" + this.corporativeInformationModal.state + "&user=" + this.corporativeInformationModal.mailAuth;
        window.open(oauthFlowUrl, "CentinelaOauthDialog", "popup=1, width=600px, height=500px");
    }else{
        this.corporativeInformationModal.authMailField.error = true;
    }
  }


  messageReceived(event:any, child:any) {
    let apiOrigin = child.apiProperties.apiURL.replace("/api/", "");
    if (event.origin == apiOrigin && child.corporativeInformationModal.state === event.data.state) {
        child.corporativeInformationModal.company.oauthUser = cloneDeep(child.corporativeInformationModal.mailAuth);
        child.corporativeInformationModal.itsConfigurate = true;
        child.corporativeInformationModal.company.oauthScope = event.data.scope;
        child.corporativeInformationModal.company.oauthAccessToken = event.data.access_token;
        child.corporativeInformationModal.company.oauthRefreshToken = event.data.refresh_token;
        child.corporativeInformationModal.mailAuth = '';
        child.corporativeInformationModal.authAccessTokenError = false;
        child.corporativeInformationModal.modify = true;
        child.corporativeInformationModal.company.oauthReconfigNeeded = false;
    }

  }

  deleteOauth(){
    this.corporativeInformationModal.company.oauthUser = null;
    this.corporativeInformationModal.company.oauthScope = null;
    this.corporativeInformationModal.company.oauthAccessToken = null;
    this.corporativeInformationModal.company.oauthRefreshToken = null;
    this.corporativeInformationModal.mailAuth = '';
    this.corporativeInformationModal.itsConfigurate = false;
  }
  saveCorporativeInformation(){
    if(this.corporativeInformationModal.company.customEmail && this.corporativeInformationModal.customTypeSelect==='0'){
      this.checkSMTP(this.corporativeInformationModal.company)
    }else{
      this.handleAccept()
    }
  }
}
