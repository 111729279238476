<div class="field-type-rich-text__container">
    <div class="field-type-rich-text__menu-share"
        *ngIf="productTypeId === 4 && !isLanding">
        <i class="lf-icon-kebab-menu common__icon-ellipsis field-type-rich-text__menu-kebab-icon"></i>
        <ul class="field-type-rich-text__menu-share-list">
            <li class="field-type-rich-text__menu-options">
                <i class="lf-icon-share field-type-rich-text__menu-share-icon"
                    (click)="shareField(field)"></i>
            </li>
        </ul>
    </div>
    <div [class]="'common__input-block field-type-rich-text__rich-block field-type-rich-text__rich-block--'+field.type"
          [ngClass]="{'field-type-rich-text__rich-block--sequential': field.sequentialView && !field.noEditable,
            'field-type-rich-text__rich-block--ishighlight': field.isHighlight}"
          [attr.disabled]="true">
        <div class="common__label-block">
            <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error,
                        'common__input-text--no-editable-':field.noEditable}">{{fieldTypeRichText.titleField}}</span>
            <i class="lf-icon-bookmarks-active field-type-rich-text__rich-text-icon"
                [ngShow]="!isLanding && field.isHighlight"></i>
            <span class="field-type-rich-text__rich-text-custom-icon"
                [ngShow]="isLanding && field.isHighlight">
                <i class="lf-icon-important"></i>
            </span>
            <div class="common__tooltip-law-block"
                [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
                <i class="lf-icon-search-text field-item__icon-related-info"
                    (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                    matTooltip="{{'common.related-info' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                </i>
            </div>
        </div>
        <div class="field-type-rich-text__rich-text"
            [id]="'field-type-rich-'+field.fieldId"
              [ngClass]="{'field-type-rich-text__rich-text': !isLanding,
                'field-type-rich-text__rich-text--ishighlight': !isLanding && field.isHighlight,
                'field-type-rich-text__rich-text--nohighlight': !isLanding && !field.isHighlight,
                'field-type-rich-text__rich-text-custom': isLanding,
                'field-type-rich-text__rich-text-custom--ishighlight': isLanding && field.isHighlight,
                'field-type-rich-text__rich-text-custom--nohighlight': isLanding && !field.isHighlight}"
              [innerHTML]="fieldTypeRichText.descriptionPadding | trustHtml">
        </div>
    </div>
</div>
<div class="field-type-text__footer"
  *ngIf="field.analyzeWithSibila && !isLanding">
    <div class="field-type-text__footer-button"
         [ngClass]="{'field-type-text__footer-button--disabled': field.description == ''}"
         (click)="analyzeWithSibila()">
        <i class="lf-icon-sibila field-type-text__footer-button-icon"></i>
        <span class="field-type-text__footer-button-text"
          [afTranslate]="'field-type-text.sibila'"></span>
    </div>
</div>
