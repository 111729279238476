<div [class]="'schedule schedule--'+typeView"
    [ngClass]="{'schedule--concursal': schedule.concursalQueryProduct,
            'schedule--search': schedule.showEventsList}">
    <div class="common__header-new-version">
        <i class="lf-icon-label-calendar common__header-icon-new-version"></i>
        <span class="common__header-title-new-version"
            [afTranslate]="'schedule.widget-title'">Agenda</span>
        <div class="common__options-block"
            ngbDropdown>
            <button class="common__selected-option"
                ngbDropdownToggle>
                <span class="evaluations-list__select-language">{{schedule.selectedView.label}}</span>
                <i class="lf-icon-angle-down common__select-option-icon-angle-down common__select-option-icon-angle-down--box"></i>
            </button>
            <div class="common__menu-block-dropdown dropdown-menu"
                ngbDropdownMenu
                role="menu">
                <div class="common__block-option-list">
                    <div class="common__language-item"
                        *ngFor="let view of schedule.views"
                        (click)="changeView(view)">
                        <span>{{view.label}}</span>
                        <i class="lf-icon-check-round-full common__language-icon"></i>
                    </div>
                </div>
            </div>
        </div>
        <button class="lf-icon-open-window common__header-button-add-new-version"
            *ngIf="typeView === 'normal'"
            (click)="openBigSchedule()"
            matTooltip="{{'schedule.widget-title' | afTranslate}}"
            matTooltipPosition="left"
            matTooltipClass="left">
        </button>
    </div>

    <div [class]="'schedule__body schedule__body--'+typeView"
        [ngClass]="{'schedule__body--concursal': schedule.concursalQueryProduct}">
        <div class="schedule__calendar-wrapper"
            [ngHide]="schedule.showEventsList">
            <h2 class="schedule__title">{{schedule.start | convertDate:schedule.selectedView.code ==='timeGridDay'?'day-week': 'full-date'}}</h2>
            <h3 [ngHide]="schedule.selectedView.code === 'dayGridMonth'"
                class="schedule__calendar-subtitle">{{schedule.start | convertDate:schedule.selectedView.filter:schedule.end}}</h3>
                <div *ngIf="schedule.showCalendar"
                    [ngSwitch]="schedule.selectedView.code">
                    <full-calendar *ngSwitchCase="'timeGridDay'"
                        #calendar
                        [options]="schedule.calendarOptions">
                    </full-calendar>
                    <full-calendar *ngSwitchCase="'timeGridWeek'"
                        #calendar
                        [options]="schedule.calendarOptions">
                    </full-calendar>
                    <full-calendar *ngSwitchDefault
                        #calendar
                        [options]="schedule.calendarOptions">
                    </full-calendar>
                </div>

        </div>
        <div [ngShow]="schedule.showEventsList">
            <header class="schedule__details-header">
                <h2 class="schedule__details-title">
                    {{schedule.eventsListTitle}}
                </h2>
                <button class="lf-icon-close schedule__details-close common__icon-button"
                    (click)="closeListView()">
                </button>
                <div [ngShow]="schedule.showCalendar">
                    <h2 class="schedule__title schedule__title--event">{{schedule.start | convertDate:schedule.selectedView.code ==='timeGridDay'?'day-week': 'full-date'}}</h2>
                    <h3 [ngHide]="schedule.selectedView.code === 'dayGridMonth'"
                        class="schedule__calendar-subtitle schedule__calendar-subtitle--event">{{schedule.start | convertDate:schedule.selectedView.filter:schedule.end}}</h3>
                </div>

            </header>

            <ul class="schedule__details-list" [ngStyle]="{'height': typeView === 'window' ? '824px': '570px'}">
                <li *ngIf="schedule.eventsList && !schedule.eventsList.length">
                    <div class="schedule-event schedule__event--meeting">
                        <h4 class="schedule-event__title" [afTranslate]="'schedule.results-not-found'">_No se han encontrado resultados</h4>
                    </div>
                </li>  
                <li *ngFor="let event of schedule.eventsList">
                    <schedule-event [originalEvent]="event"
                        [searchText]="schedule.searchText">
                    </schedule-event>
                </li>
            </ul>
        </div>
        <div class="schedule__error"
            [ngShow]="schedule.error"
            [afTranslate]="'schedule.error-loading'">No se ha podido cargar la agenda</div>
    </div>
    <div class="schedule__block-filters">
        <ul class="common__select-list-with-input-by-list-schedule">
            <span class="schedule__list-event-title"
                [afTranslate]="'schedule.show-events'">_Mostrar estos eventos:</span>
            <li class="schedule__list-event"
                *ngFor="let event of schedule.optionsEvents"
                (click)="selectElement($event, event)">
                <i class="common__multiselect-select-icon schedule__multiselect-icon"
                    [ngClass]="event.selected?'lf-icon-check-full':'lf-icon-box-inactive'">
                </i>
                <span-tooltip class="schedule__event-option-text"
                  [text]="event.name"></span-tooltip>
            </li>
        </ul>
    </div>
    <div class="common__block-footer-table">
        <div class="common__block-search">
            <div *ngIf="schedule.showSearch">
                <input type="text"
                    class="common__search-input"
                    [placeholder]="'common.search' | afTranslate"
                    [formControl]="schedule.searchText"
                    (keyup)="doSearch()"
                    [focusWhen]="schedule.search">
                    <button class="lf-icon-close-search common__icon-button common__icon-button-search common__icon-button-search--open"
                        (click)="closeListView()">
                    </button>
            </div>
            <button class="common__icon-button "
                *ngIf="!schedule.showSearch"
                (click)="openSearch()">
                <span class="common__text-search"
                    [afTranslate]="'common.search'">
                    _Buscar
                </span>
                <i class="lf-icon-search common__icon-button-search"></i>
            </button>
        </div>
    </div>
</div>
