<div>
  <div class="field-type-rich-text__container">
    <div class="common__input-block field-type-rich-text__rich-editable-block"
      [ngClass]="{'common__input-no-editable': field.noEditable,
                'common__show-alert field-type-rich-text__rich-editable-block-required': field.error,
                'field-type-text__sibila':field.analyzeWithSibila && !isLanding,
                'common__input-block--custom-field': isLanding}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__label-block common__label-block--one-icon">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                        'common__input-text--no-editable':field.noEditable}">{{fieldTypeRichText.titleField}}</span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__tooltip-law-block"
              [ngShow]="field.references && field.references.length && !field.noEditable">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block"
              [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
      </div>
      <i class="lf-icon-microphone field-type-text__mic-icon"
        [ngClass]="{'field-type-text__mic-icon--landing':isLanding,
          'field-type-text__mic-icon--no-editable':field.noEditable && !isLanding,
          'field-type-text__mic-icon--no-editable-landing':isLanding && field.noEditable}"
        [ngShow]="field.isVoiceRecord && (skin === 'dark-blue' || skin === 'gm-law')"
        (click)="openRecordVoice()">
      </i>
      <button class="lf-icon-expand common__input-icon common__input-icon-active"
          [ngClass]="{'field-type-rich-text__rich-editable-icon': isLanding}"
          (click)="openTextareaModal()"
          [disabled]="field.noEditable"></button>
      <div class="field-type-rich-text__rich-editable-editor">
          <rich-editor class="common__input-textarea-special"
              [ngClass]="{'common__input-content-alert': field.error,
                'field-type-rich-text__has-audio-landing':fieldTypeRichText.hasVoiceRecord}"
              [tinyMceOptions]="fieldTypeRichText.tinyMceOptions"
              [modelText]="field.description"
              (emitValue)="modifyField($event)">
          </rich-editor>
      </div>
      <i class="common__delete-content-icon lf-icon-close"
          [ngHide]="field.description === '' || field.noEditable"
          [ngClass]="{'field-type-text__delete-content-icon-textarea--has-voice-record':fieldTypeRichText.hasVoiceRecord}"
          (click)="resetInput()">
      </i>
      <div *ngIf="field.noEditable && field.isVoiceRecord && field.evaluatedValue.bitValue"
        class="field-type-text__transcription-block"
        [ngClass]="{'field-type-text__transcription-block--landing':isLanding}">
        <p class="field-type-text__transcription-text"
          [ngClass]="{'field-type-text__transcription-text--landing':isLanding}"
          [afTranslate]="'record-audio-modal.audio-transcription'"></p>
      </div>
      <div class="field-item__blocker-box field-item__blocker-box--rich"
        *ngIf="field.error && field.blocker">
          <div class="field-item__blocker-triangle"></div>
          <i class="lf-icon-warning field-item__blocker-icon"></i>
          <p class="field-item__blocker-text"
            [afTranslate]="'field-items.blocker-field'">campo bloqueante</p>
      </div>
      <div class="field-type-text__audio-box"
        [ngClass]="{'field-type-text__audio-box--no-editable':field.noEditable,
          'field-type-text__audio-box--landing': isLanding,
          'field-type-text__audio-box--landing-acceptation':fieldTypeRichText.acceptAceptation && isLanding}"
        *ngIf="fieldTypeRichText.hasVoiceRecord">
        <p class="field-type-text__record-info"
          [ngClass]="{'field-type-text__record-info--no-editable':field.noEditable,
            'field-type-text__record-info--landing':isLanding}">
          <span class="field-type-text__record-info field-type-text__record-info--text"
            [ngClass]="{'field-type-text__record-info--no-editable':field.noEditable,
            'field-type-text__record-info--landing':isLanding}"
            [afTranslate]="'record-audio-modal.recorder'"></span>
            {{fieldTypeRichText.voiceRecord.name}}
        </p>
        <div class="field-type-text__audio-block"
          [ngClass]="{'field-type-text__audio-block--accept-check': fieldTypeRichText.acceptAceptation && !fieldTypeRichText.loadFromBack}">
          <i class="field-type-text__audio-block-play"
            [ngClass]="{'lf-icon-stop' : startAudio && fieldTypeRichText.audioLoaded,
              'lf-icon-play': !startAudio,
              'lf-icon-refresh field-type-text__refresh-animation':startAudio && !fieldTypeRichText.audioLoaded,
              'field-type-text__audio-block-play--landing': isLanding}"
            (click)="playRecordVoice()"></i>
          <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--field">
            <div class="record-audio-modal__record-background-line record-audio-modal__record-background-line--active"
              [progressBar]="percentTime">
            </div>
          </div>
          <i class="lf-icon-download field-type-text__audio-block-download"
            [ngClass]="{'field-type-text__audio-block-download--landing': isLanding}"
            *ngIf="(!fieldTypeRichText.acceptAceptation || (fieldTypeRichText.loadFromBack && fieldTypeRichText.audioLoaded)) && !fieldTypeRichText.lockedSavedConcept"
            (click)="audioDownLoad()"></i>
          <i class="lf-icon-trash field-type-text__audio-block-delete"
            [ngClass]="{'field-type-text__audio-block-delete--landing': isLanding}"
            *ngIf="!(fieldTypeRichText.acceptAceptation || field.noEditable)"
            (click)="resetInput()"></i>
        </div>
      </div>
    </div>

    <div class="field-type-text__footer"
      *ngIf="field.analyzeWithSibila && !isLanding">
      <div class="field-type-text__footer-button"
          [ngClass]="{'field-type-text__footer-button--disabled': field.description == ''}"
          (click)="analyzeWithSibila()">
          <i class="lf-icon-sibila field-type-text__footer-button-icon"></i>
          <span class="field-type-text__footer-button-text"
            [afTranslate]="'field-type-text.sibila'"></span>
      </div>
    </div>
  </div>
  <label class="field-type-text__accept-check-label"
    *ngIf="fieldTypeRichText.hasVoiceRecord"
    [ngClass]="{'field-type-text__accept-check-label--no-editable':field.noEditable,
    'field-type-text__accept-check-label--error':field.errorInAcceptAceptation,
    'field-type-text__accept-check-label--landing': isLanding}">
    <i class="record-audio-modal__accept-terms-icon"
      [ngClass]="{'lf-icon-check-full':fieldTypeRichText.acceptAceptation,
        'lf-icon-box-inactive':!fieldTypeRichText.acceptAceptation,
        'record-audio-modal__accept-terms-icon--no-editable':field.noEditable,
        'record-audio-modal__accept-terms-icon--error':field.errorInAcceptAceptation,
        'record-audio-modal__accept-terms-icon--landing': isLanding,
        'record-audio-modal__accept-terms-icon--landing-error':field.errorInAcceptAceptation && isLanding}"
        (click)="acceptAceptationTerms()"></i>
    <p class="record-audio-modal__accept-terms-text">
      <span class="record-audio-modal__accept-terms-text record-audio-modal__accept-terms-text--check"
        [ngClass]="{'record-audio-modal__accept-terms-text--no-editable':field.noEditable,
          'record-audio-modal__accept-terms-text--required':field.errorInAcceptAceptation,
          'record-audio-modal__accept-terms-text--check-landing': isLanding,
          'record-audio-modal__accept-terms-text--landing-error':field.errorInAcceptAceptation && isLanding}"
        >{{field.voiceRecordOkText}}</span>
      <span class="record-audio-modal__accept-terms-text record-audio-modal__accept-terms-text--required">*</span>
    </p>
  </label>
</div>
