<div class="protection-data-modal">
  <div class="common__modal-header-block">
      <i [class]="'common__icon-header '+ protectionDataModal.icon"></i>
      <span class="common__text-header">{{protectionDataModal.title}}</span>
      <i class="lf-icon-close common__icon-close-modal"
        (click)="handleDismiss()"></i>
  </div>
  <form class="protection-data-modal__body-form">
      <p class="common__title-block-modal">{{protectionDataModal.description}}</p>
      <div class="protection-data-modal__block-fields">
          <div class="protection-data-modal__block-translations"
              [ngClass]="{'protection-data-modal__block-translations--error': protectionDataModal.errorActive}">
              <button class="protection-data-modal__block-translation-option"
                  [ngClass]="{'protection-data-modal__block-translation-option--selected': languageSelected.active && !languageSelected.error,
                    'protection-data-modal__block-translation-option--selected-error': languageSelected.active && languageSelected.error,
                    'protection-data-modal__block-translation-option--error': !languageSelected.active && languageSelected.error}"
                  *ngFor="let languageSelected of protectionDataModal.languagesSelected"
                  (click)="changeActiveLanguagesSelected(languageSelected)">
                  <span [ngShow]="protectionDataModal.languagesSelected.length < 6">{{languageSelected.text}}</span>
                  <span [ngShow]="protectionDataModal.languagesSelected.length >= 6">{{languageSelected.appLanguage}}</span>
                  <i class="lf-icon-close protection-data-modal__icon-close-language"
                      [ngShow]="!languageSelected.defaultOption"
                      (click)="removeActiveLanguage(languageSelected)"></i>
                  <svg class="protection-data-modal__triangle-block"
                      [ngClass]="{'protection-data-modal__triangle-block--selected': languageSelected.active,
                        'protection-data-modal__triangle-block--selected-error': languageSelected.active && languageSelected.error,
                        'protection-data-modal__triangle-block--error': !languageSelected.active && languageSelected.error}">
                      <polygon points="0,0 21,30 0,30" />
                  </svg>
              </button>
              <div ngbDropdown [ngHide]="protectionDataModal.languagesSelected.length === protectionDataModal.languagesApp.length">
                  <button class="protection-data-modal__select-language-button" ngbDropdownToggle>
                      <span [afTranslate]="'protection-data-modal.add-translation'"></span>
                      <i class="lf-icon-translate protection-data-modal__icon-translate"></i>
                  </button>
                  <div class="protection-data-modal__menu-select-languages dropdown-menu" ngbDropdownMenu>
                      <div class="common__block-option-list header-nav__block-option-list">
                          <div class="common__language-item"
                              *ngFor="let language of protectionDataModal.languagesApp"
                              [ngShow]="notSelectLanguage(language)"
                              (click)="selectLanguage(language.listValueId)">
                              <span>{{language.text}}</span>
                              <span>({{language.specific}})</span>
                              <i class="lf-icon-check-round-full common__language-icon"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <rich-editor class="protection-data-modal__show-text-area"
            [modelText]="protectionDataModal.dataTreatment.description"
            (emitValue)="protectionDataModal.dataTreatment.description = $event.model">
          </rich-editor> -->
          <field-type-rich-text-editable
              [field]="protectionDataModal.dataTreatment">
          </field-type-rich-text-editable>
      </div>
      <div class="common__buttons-block common__buttons-block--modal">
          <button type="reset" class="common__button common__button--clean"
              (click)="handleDismiss()" [afTranslate]="'common.cancel'"></button>
          <button type="submit" class="common__button common__button--active"
              (click)="validateFields()"
              [afTranslate]="'common.save'"></button>
      </div>
  </form>
</div>
