<div class="field-type-clients-selector"
    [ngClass]="{'common__input-block common__input-block--fixed': field.withLabel,
            'common__show-alert common__show-alert--fixed': field.error && field.withLabel}">
    <div class="common__label-block"
        *ngIf="fieldTypeClientsSelector.field.withLabel">
        <span class="common__input-text"
            [ngClass]="{'common__input-text--required': field.error}">{{field.label}}</span>
        <span class="common__text-required"
            [ngShow]="field.required">*</span>
    </div>
    <div class="field-type-clients-selector__find-client"
        [ngClass]="{'field-type-clients-selector__find-client--with-label': field.withLabel}">
        <div class="field-type-clients-selector__search-box">
            <div class="field-type-clients-selector__alphabet-button"
                [ngHide]="fieldTypeClientsSelector.findOrganizations"
                *ngFor="let letter of fieldTypeClientsSelector.alphabetList; let first = first"
                (click)="chooseLetter(letter)"
                [attr.disabled]="letter.itemsCount === 0?true:null"
                [ngClass]="{'field-type-clients-selector__alphabet-button--selected':letter.selected,
                    'field-type-clients-selector__alphabet-button--disabled':letter.itemsCount === 0,
                    'field-type-clients-selector__alphabet-button--first':first}">
                    <span-tooltip class="field-type-clients-selector__text"
                        [text]="letter.text"></span-tooltip>
            </div>
            <p [ngShow]="fieldTypeClientsSelector.findOrganizations"
                class="field-type-clients-selector__search-text"
                [afTranslate]="'result-search.search-result'"></p>
        </div>
        <label for="field-type-clients-selector"
            class="field-type-clients-selector__find-client-label"
            [ngClass]="{'field-type-clients-selector__find-client-label--focused': fieldTypeClientsSelector.labelFocus,
                'field-type-clients-selector__find-client-label--with-label': field.withLabel}">
            <input id="field-type-clients-selector"
                class="common__input-content field-type-clients-selector__find-client-input"
                type="text"
                autocomplete="off"
                [(ngModel)]="fieldTypeClientsSelector.searchClient"
                [ngModelOptions]="{standalone: true}"
                placeholder="{{'common.search-client' | afTranslate}}"
                (keydown.enter)="changeList($event)"
                (focus)="fieldTypeClientsSelector.labelFocus = !fieldTypeClientsSelector.labelFocus;"
                (blur)="fieldTypeClientsSelector.labelFocus = !fieldTypeClientsSelector.labelFocus;">
            <i class="field-type-clients-selector__search-icon"
                [ngClass]="!fieldTypeClientsSelector.findOrganizations?'lf-icon-search':'lf-icon-close-search field-type-clients-selector__search-icon--hover'"
                (click)="findOrDelete()">
            </i>
        </label>
    </div>
    <p [ngShow]="fieldTypeClientsSelector.filterList.length && !fieldTypeClientsSelector.license"
        class="field-type-clients-selector__alert-result field-type-clients-selector__alert-result--license"
        [afSiteTerms]="'alert-license'">Has superado el número permitido de clientes con implantación. Si deseas ampliar tu suscripción o más información sobre la misma, puedes contactar con nosotros a través del 91 210 80 00 o clientes@lefebvre.es
    </p>
    <div class="field-type-clients-selector__list-info-client"
        [ngClass]="{'field-type-clients-selector__list-info-client--hidden': !fieldTypeClientsSelector.finishLoader}">
        <div [ngShow]="fieldTypeClientsSelector.errorInSearch || fieldTypeClientsSelector.noOrganizations">
            <div class="field-type-clients-selector__alert-result"
                [afTranslate]="fieldTypeClientsSelector.noOrganizations?'new-evaluation.no-results':'common.less-characters'"></div>
        </div>
        <ul class="field-type-clients-selector__client-list-block"
            [ngHide]="fieldTypeClientsSelector.errorInSearch || fieldTypeClientsSelector.noOrganizations">
            <li class="field-type-clients-selector__client-block"
                *ngFor="let client of fieldTypeClientsSelector.filterList | afOrderBy: fieldTypeClientsSelector.byword:false"
                (click)="selectClient(client)"
                [ngHide]="client.affiliated && fieldTypeClientsSelector.searchClient ===''">
                <div class="field-type-clients-selector__client-box field-type-clients-selector__client-box"
                        [ngClass]="{'field-type-clients-selector__client-box--selected': client.selected,
                          'field-type-clients-selector__client-box--disabled': client.disabled && !client.parentName}">
                    <div class="field-type-clients-selector__client-bubble-box"
                            [ngClass]="{'field-type-clients-selector__client-bubble-box--selected': client.selected,
                                  'field-type-clients-selector__client-bubble-box--disabled': client.disabled && !client.parentName,
                                  'field-type-clients-selector__client-bubble-box--disabled-red': client.disabled && !client.parentName && (client.clientStateId === 2 || client.clientStateId === 3)}">
                        <div class="field-type-clients-selector__border-icon"
                                [ngShow]="client.logo && !client.selected">
                            <img class="field-type-clients-selector__client-logo"
                                    alt="Logo del cliente"
                                    [src]="client.logo">
                        </div>
                        <p class="field-type-clients-selector__client-initials"
                            [ngShow]="!client.logo && !client.selected">{{client.initials}}</p>
                        <i class="lf-icon-check field-type-clients-selector__select-client-icon field-type-clients-selector__select-client-icon--check"
                            [ngShow]="client.selected">
                        </i>
                        <i class="lf-icon-close field-type-clients-selector__select-client-icon field-type-clients-selector__select-client-icon--close"
                            [ngShow]="client.selected">
                        </i>
                    </div>
                    <div class="field-type-clients-selector__client-info-block">
                        <p class="field-type-clients-selector__client-name"
                            [ngClass]="{'field-type-clients-selector__client-name--afiliates':client.children.length,
                                'field-type-clients-selector__client-name--selected': client.selected && client.parentName === '',
                                'field-type-clients-selector__client-name--selected-parent': client.selected && client.parentName !== '',
                                'field-type-clients-selector__client-name--disabled': client.disabled && !client.parentName}">
                            <span-tooltip [text]="client.nameSelected"></span-tooltip>
                        </p>
                        <p class="field-type-clients-selector__parent-select"
                            [ngClass]="{'field-type-clients-selector__parent-select--selected': client.selected}"
                            [ngShow]="client.parentName !=='' && (!client.children.length || client.selected)">
                            <i class="lf-icon-map-marker field-type-clients-selector__parent-select-icon"></i>
                            <span-tooltip [text]="client.parentName"></span-tooltip>
                        </p>
                        <div [ngShow]="client.children.length && !client.selected"
                              ngbDropdown
                              [open]="client.openFilials"
                              autoClose="'outside'">
                            <button class="field-type-clients-selector__filials-button"
                                ngbDropdownToggle
                                (click)="$event.stopPropagation()">
                                <i class="field-type-clients-selector__filials-button-icon"
                                    [ngClass]="client.openFilials?'lf-icon-less-full':'lf-icon-add-full'"></i>
                                <span [afTranslate]="'new-evaluation.filials'"
                                        [ngHide]="client.openFilials"></span>
                            </button>
                            <div *ngIf="client.openFilials"
                                    class="field-type-clients-selector__filials-triangle">
                            </div>
                            <ul class="field-type-clients-selector__list-filials"
                                ngbDropdownMenu
                                aria-labelledby="dropdownBasic2">
                                <li *ngFor="let child of client.children | orderBy: 'name'">
                                    <div class="field-type-clients-selector__select-filial"
                                        [ngClass]="{'field-type-clients-selector__select-filial--disabled': child.disabled}"
                                        (click)="selectChild($event, child)">
                                        <i class="field-type-clients-selector__angle-icon"
                                            [ngShow]="child.children.length"
                                            [ngClass]="child.open?'lf-icon-angle-down field-type-clients-selector__angle-icon--open':'lf-icon-angle-right field-type-clients-selector__angle-icon--close'"
                                            (click)="openChild($event,child)"></i>
                                        <p class="filials__afiliated-name"
                                            [ngClass]="{'filials__afiliated-name--disabled':child.disabled,
                                              'filials__afiliated-name--disabled-red':child.disabled && (child.clientStateId === 2 || child.clientStateId === 3)}">
                                            <span-tooltip [text]="child.name"></span-tooltip>
                                        </p>
                                        <i class="lf-icon-check-round-full common__select-icon"></i>
                                    </div>
                                    <filials *ngIf="child.children.length && child.open"
                                      [child]="child"
                                      (openChild)="openChildOn($event)"
                                      (selectChild)="selectChildOn($event)" >
                                    </filials>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li [ngShow]="fieldTypeClientsSelector.selectedLetter.itemsCount > fieldTypeClientsSelector.selectedLetter.results.length"
                class="field-type-clients-selector__more-organizations-block">
                <button class="field-type-clients-selector__more-organizations-button"
                    (click)="getMore()">
                    <span [afTranslate]="'common.more-organizations'"></span>
                    <i class="lf-icon-angle-down field-type-clients-selector__more-organizations-icon"></i>
                </button>
            </li>
        </ul>
    </div>
</div>
