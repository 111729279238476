<label class="common__input-block field-type-list__input-block"
  [for]="'field-type-list-'+fieldTypeList.id"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--poll-view':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView,
      'common__input-block--custom-field':isLanding && !fieldTypeList.field.sequentialView,
      'common__show-alert': fieldTypeList.field.error && !fieldTypeList.field.sequentialView,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-search':!fieldTypeList.showList && fieldTypeList.field.hiddenPill}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      *ngIf="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': fieldTypeList.field.error,
          'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="fieldTypeList.field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView">
          </tooltip-law>
      </p>
  </div>
  <div [class]="'common__label-block common__'+fieldTypeList.classWidthField"
      *ngIf="!(field.sequentialView && !fieldTypeList.field.noEditable)">
      <span class="common__input-text"
          [ngClass]="{'common__input-text--required': fieldTypeList.field.error,
                  'common__input-text--no-editable': fieldTypeList.field.noEditable}">{{fieldTypeList.titleField}}</span>
      <span class="common__text-required"
          [ngShow]="fieldTypeList.field.required">*</span>
      <div class="common__info-icon-block"
          [ngHide]="fieldTypeList.field.noEditable || !fieldTypeList.field.description">
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"></tooltip-info>
      </div>
      <div class="common__tooltip-law-block"
          [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
          [ngShow]="fieldTypeList.field.references && fieldTypeList.field.references.length && !fieldTypeList.field.noEditable">
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field">
          </tooltip-law>
      </div>
      <div class="common__tooltip-law-block"
          [ngShow]="fieldTypeList.field.relatedInfo?.length && !fieldTypeList.field.poll && !fieldTypeList.field.noEditable">
          <i class="lf-icon-search-text field-item__icon-related-info"
              (click)="openModalRelatedInfo(fieldTypeList.field.relatedInfo, $event)"
              matTooltip="{{'common.related-info' | afTranslate}}"
              matTooltipPosition="above"
              matTooltipClass="above">
          </i>
      </div>
  </div>
  <div [ngClass]="{'common__input-poll':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView && !fieldTypeList.field.isHighlight && !fieldTypeList.field.numericalScale,
          'common__show-alert': fieldTypeList.field.error && fieldTypeList.field.sequentialView && !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight,
          'field-type-list__block-list-elements':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}">
      <div class="float-end">
          <i class="lf-icon-connected-data field-type-text__icon-references field-type-text__icon-references--less-top"
              [ngShow]="fieldTypeList.field.evaluatedValue?.hasReferences && !fieldTypeList.field.poll"
              (click)="showReference($event)"
              matTooltip="{{'common.see-dependencies' | afTranslate}}"
              matTooltipPosition="left"
              matTooltipClass="left">
          </i>
      </div>
      <div class="field-type-list__block-template">
        <div class="common__bubble-count"
          [ngClass]="{'common__bubble-count--right-6 common__bubble-count--disabled': fieldTypeList.field.noEditable}"
          [ngShow]="!fieldTypeList.field.hiddenPill || (fieldTypeList.listElementSelect.length && fieldTypeList.field.hiddenPill && !fieldTypeList.hiddenPillAllOption)">
          {{fieldTypeList.listElementSelect.length}}
        </div>
          <div class="common__dropdown-initial">
              <i class="lf-icon-link field-type-list__icon-link"
                  *ngIf="fieldTypeList.field.fieldTypeId ==='l-fo'">
              </i>
              <i class="common__select-arrow"
                  [ngClass]="{'lf-icon-angle-up':fieldTypeList.showList,
                      'lf-icon-angle-down':!fieldTypeList.showList,
                      'common__select-arrow--no-editable': fieldTypeList.field.noEditable,
                      'common__select-arrow--l-co':fieldTypeList.field.fieldTypeId ==='l-fo'}"
                  [id]="'dropdown-field-list-'+fieldTypeList.id"
                  data-bs-toggle="dropdown"
                  (click)="fieldTypeList.showList = !fieldTypeList.showList">
              </i>
              <ul class="common__multiselect-block"
                  [ngClass]="{'common__multiselect-block--search':fieldTypeList.field.hiddenPill && fieldTypeList.listElementSelect.length}">
                  <li class="common__multiselect-item"
                      [ngClass]="{'common__multiselect-item-juri':listElement.referenceId,
                          'common__multiselect-item--search':fieldTypeList.field.hiddenPill,
                          'common__multiselect-item--custom':isLanding}"
                      *ngFor="let listElement of fieldTypeList.listElementSelect">
                      <tooltip-law
                        [ngShow]="fieldTypeList.listElementSelect"
                        [references]="fieldTypeList.references"
                        class="field-type-text__tooltip-block field-type-text__tooltip-block--list-lco-simple"
                        *ngIf="fieldTypeList.listElementSelect?.length && listElement.referenceId"></tooltip-law>
                      <div [ngClass]="{'field-type-list__block-list':listElement.referenceId}">
                          <p class="common__multiselect-text field-type-list__block-text"
                              [ngClass]="{'common__multiselect-text-juri':listElement.referenceId,
                                  'field-type-list__block-text--search':fieldTypeList.field.hiddenPill,
                                  'field-type-list__block-text--numelements': listElement.numElements || listElement.numElements === 0,
                                  'common__line-through':listElement.isDeleted}"
                              matTooltip="{{listElement.text +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                              matTooltipPosition="below"
                              matTooltipClass="below"
                              [matTooltipDisabled]="!listElement.isDeleted">
                              {{listElement.text}}
                          </p>
                          <p class="field-type-list__block-number"
                              [ngShow]="listElement.numElements || listElement.numElements === 0" >
                              ({{listElement.numElements}})
                          </p>
                      </div>
                      <i class="common__multiselect-delete-icon common__multiselect-delete-icon--multiple"
                          [ngHide]="fieldTypeList.field.noEditable"
                          [ngClass]="{'common__multiselect-delete-icon--search':fieldTypeList.field.hiddenPill,
                            'lf-icon-close': !isLanding,
                            'lf-icon-close-round common__multiselect-delete-icon--custom':isLanding}"
                          (click)="addOrDeleteSelectedValue($event, listElement)"></i>
                  </li>
              </ul>
              <ul class="dropdown-menu common__select-list field-type-list__list-block"
                  [ngClass]="{'common__select-list--no-shadow':fieldTypeList.field.noShadow,
                      'field-type-list__list-block--xs': fieldTypeList.field.xs,
                      'field-type-list__list-block--extended': fieldTypeList.field.hiddenPill && fieldTypeList.listElementSelect.length,
                      'field-type-list__list-block--custom': isLanding}">
                  <li class="common__find-dropdown-block"
                      [ngHide]="disabledSearch">
                      <div class="field-item__find-position"
                          *ngIf="!fieldTypeList.addNew; else elseBlock">
                          <div class="field-type-other__block-input-search"
                              [ngClass]="{'field-type-other__block-input-search--block-100': !(fieldTypeList.field.isOpen && !fieldTypeList.public) || isLanding}">
                              <input class="common__find-in-dropdown"
                                  [(ngModel)]="fieldTypeList.findDropdown"
                                  [placeholder]="'clients-picker.search' | afTranslate"
                                  (click)="catchEvent($event)"
                                  (keyup)="changeList()">
                              <i class="common__find-dropdown-icon-find"
                                  [ngClass]="{'lf-icon-search':fieldTypeList.findDropdown === '',
                                      'lf-icon-close-search': fieldTypeList.findDropdown !==''}"
                                  (click)="resetSearch($event)"></i>
                          </div>
                          <button class="lf-icon-sum common__button-checked"
                              [ngShow]="fieldTypeList.field.isOpen && !fieldTypeList.public && !isLanding"
                              (click)="createNewValue($event)"></button>
                      </div>
                      <ng-template #elseBlock>
                        <div class="field-type-list__add-new-block">
                          <div class="field-type-other__block-input-search field-type-other__block-input-search--multiple field-type-other__block-input-search--add-color">
                                <input class="common__find-in-dropdown common__find-in-dropdown--create-list"
                                  [(ngModel)]="fieldTypeList.newValue"
                                  [placeholder]="'field-type-list.new-element'| afTranslate"
                                  (click)="catchEvent($event)">
                          </div>
                          <div class="field-type-list__block-buttons-addnew">
                            <button class="lf-icon-close common__button-close"
                                (click)="resetListValue($event)"></button>
                            <button class="lf-icon-check common__button-checked"
                                (click)="insertNewValue($event)"></button>
                          </div>
                        </div>
                      </ng-template>
                  </li>
                  <li class="field-type-list__select-list"
                      [ngClass]="{'field-type-list__select-list--tree':field.isTree}">
                      <div class="field-type-list__block-element-list"
                          *ngIf="fieldTypeList.filterList.length > 10"
                          (click)="toggleAll($event)">
                          <div class="common__select-all">
                              <i class="field-item__icon-check field-item__icon-check--multiple"
                                  [ngClass]="{
                                      'lf-icon-check-full': fieldTypeList.markAll,
                                      'lf-icon-box-inactive': !fieldTypeList.markAll,
                                      'field-item__icon-check--custom': fieldTypeList.isLanding
                                }"></i>
                              <div class="common__cell-block field-type-list__multiple-text-list">
                                  <p class="common__ellipsis common__paragraph-0">
                                      <span [afTranslate]="'common.toggle-all'"></span>
                                  </p>
                              </div>
                              <i class="common__select-icon common__select-icon--list"
                                  [ngClass]="fieldTypeList.isLanding ? 'lf-icon-check-round common__select-icon--custom' : 'lf-icon-check-round-full'"
                                  [ngHide]="fieldTypeList.isLanding"></i>
                          </div>
                      </div>
                      <div *ngFor="let listValue of fieldTypeList.filterList | byWord:fieldTypeList.findDropdown:[fieldToFind] | afOrderBy: fieldTypeList.orderList:fieldTypeList.orderAsc; let first = first;let index = index"
                          (click)="addOrDeleteSelectedValue($event, listValue)"
                          class="field-type-list__block-element-list"
                          [ngHide]="checkListValue(listValue.type)">
                          <div class="common__select-item common__select-item--list"
                              [ngClass]="{'common__select-item--tree':fieldTypeList.field.isTree,
                                  'common__select-item--check-all':fieldTypeList.field.typeList === 'checkAll' && listValue.listValueId === 'all',
                                  'field-type-list__disabled-item':listValue.disabled && fieldTypeList.field.typeList !== 'checkAll',
                                  'field-type-list__disabled-item-all':listValue.disabled && fieldTypeList.field.typeList === 'checkAll'}"
                              [ngHide]="listValue.deleteError">
                              <i [class]="(listValue.extension | extensionDocument) + ' common__select-info-icon'"
                                  [ngShow]="listValue.extension"></i>
                              <i [ngShow]="listValue.children && listValue.children.length"
                                  class="float-start"
                                  [ngClass]="{'lf-icon-angle-down':listValue.show,
                                    'lf-icon-angle-right':!listValue.show}"
                                  (click)="showChildren($event, listValue)"></i>
                              <i class="field-item__icon-check field-item__icon-check--multiple"
                                  [ngHide]="listValue.children && listValue.children.length"
                                  [ngClass]="{'lf-icon-check-full': listValue.selected,
                                      'lf-icon-box-inactive': !listValue.selected,
                                      'field-item__icon-check--custom':isLanding}"></i>
                              <i [class]="(listValue.type | taskTypeIcons:'reference') + ' field-type-list__law-icon'"
                                  [ngShow]="fieldTypeList.field.fieldTypeId ==='l-fo'"></i>
                              <div class="common__cell-block field-type-list__multiple-text-list"
                                  [ngClass]="{'field-type-list__multiple-text-list--numelements': (listValue.numElements && listValue.numElements < 10) || listValue.numElements === 0,
                                      'field-type-list__multiple-text-list--numelements-middle': listValue.numElements && listValue.numElements >= 10 && listValue.numElements < 100,
                                      'field-type-list__multiple-text-list--numelements-big': listValue.numElements && listValue.numElements >= 100,
                                      'field-type-list__multiple-text-list--user-value': listValue.isUserValue}">
                                  <p class="common__ellipsis common__paragraph-0"
                                      [ngClass]="{'field-type-list__item-text':fieldTypeList.field.fieldTypeId ==='l-fo',
                                          'common__line-through':listValue.isDeleted}">
                                      <span-tooltip [text]="listValue.text"
                                        [isDeleted]="listValue.isDeleted"></span-tooltip>
                                  </p>
                              </div>
                              <p class="field-type-list__block-number field-type-list__block-number--list"
                                  [ngShow]="listValue.numElements || listValue.numElements === 0">
                                  ({{listValue.numElements}})
                              </p>
                              <i class="lf-icon-configuration field-type-list__is-user-value"
                                  [ngClass]="{'field-type-list__is-user-value--custom':isLanding}"
                                  [ngShow]="listValue.isUserValue">
                              </i>
                              <i class="common__select-icon common__select-icon--list"
                                  [ngClass]="isLanding?'lf-icon-check-round':'lf-icon-check-round-full'"
                                  [ngHide]="listValue.disabled || (isLanding)">
                              </i>
                              <i class="lf-icon-editable-form common__select-icon"
                                  [ngShow]="listValue.isUserValue"
                                  (click)="editListValueElement($event, listValue)"></i>
                              <i class="lf-icon-trash common__select-icon"
                                  [ngShow]="listValue.isUserValue"
                                  (click)="deleteListValue($event, listValue)"></i>
                          </div>
                          <div class="common__select-item common__select-item--error"
                              [ngShow]="listValue.deleteError">
                              <i class="lf-icon-lock common__select-info-icon"></i>
                                  <span [afTranslate]="'field-type-list.message-info'">No es posible eliminar este valor porque ya se ha utilizado en otros formularios.</span>
                              <button class="common__error-button-list"
                                  (click)="aceptNotDelete($event, listValue)"
                                  [afTranslate]="'common.ok'">Aceptar</button>
                          </div>
                          <div class="field-type-list__tree-list"
                              *ngIf="listValue.children && listValue.children.length && listValue.show">
                              <tree-list-multiple [field]="fieldTypeList.field"
                                [findDropdown]="fieldTypeList.findDropdown"
                                [fieldToFind]="fieldToFind"
                                [filterList]="listValue.children"
                                (treeMultipleEmit)="treeMultipleOn($event)">
                              </tree-list-multiple>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="field-item__blocker-box"
      *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
</label>
<div [ngShow]="field.uniqueLastSelect && field.multiline && fieldTypeList.listElementSelect?.length">
  <div class="field-type-list__block-tree-selected"
      *ngFor="let itemSelected of fieldTypeList.treeSelectedElement"
      [ngShow]="itemSelected.selectedTree || itemSelected.selected">
      <div class="field-type-list__text-tree-selected"
        [ngClass]="{'field-type-list__text-tree-selected--selected': !itemSelected.children?.length}">
        <span [ngShow]="itemSelected.selected">-</span>
        <span-tooltip [text]="itemSelected.text"></span-tooltip>
        <tree-selected-multiple *ngIf="itemSelected.children?.length"
          class="field-type-list__block-tree-selected-multiple"
          [list]="itemSelected.children"></tree-selected-multiple>
      </div>
  </div>
</div>
