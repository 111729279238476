import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ConceptStateFactory } from '@shared/factories/concept-state/concept-state.factory';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';

@Component({
  selector: 'customize-fields',
  templateUrl: './customize-fields.component.html'
})
export class CustomizeFieldsComponent implements OnChanges {
  skin: string = this.globalCfg.skin;
  customizeFields: any = {
    maxlengthInput: 150
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private broadcastService: BroadcastService,
    private conceptState: ConceptStateFactory) { }

  @Input() field: any = null;
  @Input() typeField: string = '';
  @Input() visibilityItems: any = null;
  @Input() disabled: any = null;
  @Input() allowPublicShare: any = null;
  @Input() fieldContainers: any = null;
  @Input() isPrivate: any = null;
  @Input() hiddenInLandings: boolean = false;
  @Input() reportList: any = null;
  @Input() templatesList: any = null;
  @Input() concept: any = {};
  @Input() isMultiple: any = null;
  @Input() pollObject: any = null;
  @Input() fieldOriginList: any = null;
  @Input() contentTypeList: any = null;

  @Output() openField = new EventEmitter();
  @Output() launchAction = new EventEmitter();


  ngOnChanges(changes: SimpleChanges): void {
    let that: any = this.customizeFields;
    if(changes['field'] && changes['field'].currentValue){
      that.field = changes['field'].currentValue;
      this.getIcons();
    }
    if(this.disabled){
        if(that.field.instantiated && that.field.fieldTypeId !== 'bool' && that.field.fieldTypeId !== 'rich'){
            that.field.noEditable = true;
            that.field.disabled = true;
        }
        if(!that.field.instantiated){
            that.field.noEditable = false;
            that.field.disabled = false;
        }
    }
  }

  getIcons(){
    let that: any = this.customizeFields;
      that.field.fieldTypeIdPainted = that.field.fieldTypeId;
      switch(that.field.fieldTypeId){
          case 'text':
              that.field.icon = "lf-icon-text-field";
              break;
          case 'rich':
              that.field.icon = "lf-icon-text-edit";
              break;
          case 'list':
              that.field.icon = "lf-icon-dropdown";
              break;
          case 'listp':
              that.field.icon = "lf-icon-dropdown-weight";
              that.field.fieldTypeIdPainted = 'list';
              break;
          case 'l-co':
              that.field.icon = "lf-icon-connected-data";
              break;
          case 'num':
              that.field.icon = "lf-icon-hashtag";
              break;
          case 'date':
              that.field.icon = "lf-icon-calendar";
              break;
          case 'bool':
              that.field.icon = "lf-icon-switch-right";
              break;
          case 'boolp':
              that.field.icon = "lf-icon-switch-right-weight";
              that.field.fieldTypeIdPainted = 'bool';
              break;
          case 'formu':
              that.field.icon = "lf-icon-calculator";
              break;
          case 'formp':
              that.field.icon = "lf-icon-calculator-weight";
              that.field.fieldTypeIdPainted = 'formu';
              break;
          case 'file':
              that.field.icon = "lf-icon-up-file";
              break;
          case 'money':
              that.field.icon = "lf-icon-coin";
              break;
          case 'user':
              that.field.icon = "lf-icon-user";
              break;
          case 'txchk':
              that.field.icon = "lf-icon-check-empty";
              break;
          case 'templ':
              that.field.icon = "lf-icon-textbooks";
              break;
          case 'alert':
              that.field.icon="lf-icon-notification";
              break;
          case 'task':
              that.field.icon = "lf-icon-task";
              break;
      }
  }

  showField(){
      if(!this.customizeFields.field.openFirstTime){
          this.customizeFields.field.openFirstTime = true;
      }
      this.openField.emit({field: this.customizeFields.field});
      this.configureDrag();
  }

  configureDrag(){
      this.broadcastService.broadcast('isOpenField', {
          open: this.customizeFields.field.open
      })
  }

  fieldChange(e:any){
    let change:any = e.change;
      change.field.open = true;
      if(change.type === 'save' ){
        this.launchAction.emit({
            action:{
                action:'save',
                field: change.field
            }
        })
      }
      this.customizeFields.field = change.field;
      this.showField();
  }

  deleteField(){
    this.launchAction.emit({
        action:{
            action:'delete',
            field: this.customizeFields.field
        }
    })
  }

  modifyField(){
    this.conceptState.add(this.customizeFields.field);
  }
}
