import moment from 'moment';

import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { cloneDeep } from 'lodash';
import { StateManagementFactory } from '@shared/factories/state-management/state-management.factory';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { FilterColumnService } from '@shared/services/filter-column/filter-column.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { ReportVersionFactory } from '@shared/factories/report-version/report-version.factory';

@Component({
  selector: 'dashboard-reports',
  templateUrl: './dashboard-reports.component.html'
})

export class DashboardReportsComponent implements OnInit, OnChanges, OnDestroy {
  skin: string = this.globalCfg.skin;
  urlPart: string //= this.configureUrl();
  subscribers : any = {};
  evaluationId:any = this.route.snapshot.params['evaluationId'];
  timeOptionSelected: number = 2;
  type:string =  'dashboard-reports';
  firstCallByArea: any = null;
  firstCallByDate: any = null;
  firstCallByStatus: any = null;
  firstCallReportsDetail: any = null;
  clientId: number = 0;
  confidentialUrl: any;
  dashboardReports: any = {
    activeMonth: null,
    areaTitle: this.translate.instant('dashboard-reports.areas-concerned'),
    calendarOptionList: [
      {
          id: 0,
          text: this.translate.instant('repeat-pattern.weekly')
      },{
          id: 1,
          text: this.translate.instant('repeat-pattern.monthly')
      },{
          id: 2,
          text: this.translate.instant('dashboard-reports.quarterly')
      },{
          id: 3,
          text: this.translate.instant('repeat-pattern.annual')
      },{
          id: 4,
          text: this.translate.instant('dashboard-reports.last-fifth-days')
      }
    ],
    complaintOptionList: this.configureOptionList(),
    dateDetails: null,
    donutOptions: {
      subType: 1,
      type: 'be'
    },
    elementWordFilter: '',
    evaluationListFilter: [],
    fromDate: moment().startOf('quarter'),
    isDate: false,
    monthSelect: null,
    numBoxes: 0,
    reportOptionList: [],
    reportsByAreaData:null,
    reportsByDateData: null,
    reportsByStatusData: null,
    reportConfidential: {},
    reportsDetail:null,
    resolutionPercentage: 0,
    selectActive: [],
    selectComplaintOption: null,
    selectedChannel: null,
    selectedChannelName: this.translate.instant('dashboard-reports.filter-channel'),
    showDropdownGeneralReport: false,
    stateTitle: this.translate.instant('dashboard-reports.status'),
    summaryReports: {},
    timeOptions: [
      {
          id: 1,
          text:this.translate.instant('schedule.view-buttons.day'),
      },{
          id: 2,
          text:this.translate.instant('schedule.view-buttons.week2'),
          selected: true
      },{
          id: 3,
          text:this.translate.instant('schedule.view-buttons.month'),
      },{
          id: 4,
          text:this.translate.instant('dashboard-reports.year'),
      }
    ],
    toDate: moment().endOf('quarter'),
    todayEvolution: null,
    userWithPermissions: false,
    tooltipText: this.skin === 'icam-red'? this.translate.instant('dashboard-reports.query') : this.translate.instant('dashboard-reports.complaints'),
  }

  constructor(private globalCfg: GlobalCfgFactory,
      private translate: AfTranslateFactory,
      private route: ActivatedRoute,
      private apiService: ApiServiceService,
      private loader: LoaderFactory,
      private stateManagement: StateManagementFactory,
      private router: Router,
      private modalService: ModalServiceService,
      private filterColumnService: FilterColumnService,
      private broadcastService:BroadcastService,
      private commonUtilsService: CommonUtilsService,
      private context: ContextFactory,
      private reportVersion: ReportVersionFactory) {
  }

  @Input() evaluationList: any = [];
  @Input() evaluationInfo: any = null;
  @Input() client: any = null;
  @Input() productSelect: string = '';
  @Input() userPermissions: string = 'none';
  @Input() from: string = '';
  @Output() launchActionEmit = new EventEmitter();
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>

  ngOnInit(): void {
    this.subscribers.reloadDashboardBroadcast = this.broadcastService.subscribe("reloadDashboard", (data:any) => {
      this.getFiltersDate(this.clientId, this.evaluationId);
    });
    this.changeDays(this.dashboardReports.toDate.diff(this.dashboardReports.fromDate, 'days'));

  }

  ngOnChanges(changes: SimpleChanges): void {
    let changesProduct : any = changes['productSelect'] && changes['productSelect'].currentValue !== changes['productSelect'].previousValue;
    if(changesProduct){
      if(this.skin==='icam-red'){
        this.dashboardReports.textDate = 'dashboard-reports.query-of';
      }else{
        this.dashboardReports.textDate = this.productSelect === 'blanqueo'?'dashboard-reports.report-from':'dashboard-reports.report-of';
      }
      this.urlPart = this.configureUrl();
      this.dashboardReports.specialGraph = this.from==='folder' || this.skin==='icam-red' || this.productSelect === 'blanqueo';
      this.configureConfidentialAccess();
    }
    if(this.from === 'folder'){
      if (this.skin === 'steel-blue') {
        this.dashboardReports.complaintOptionList.push({
            id: 3,
            text: this.translate.instant('dashboard-reports.general-sumary')
        })
        this.dashboardReports.selectComplaintOption = this.dashboardReports.complaintOptionList[3];
      } else {
          this.dashboardReports.complaintOptionList.push({
              id: 4,
              text: this.translate.instant('dashboard-reports.general-sumary')
          })
          this.dashboardReports.selectComplaintOption = this.dashboardReports.complaintOptionList[2];
      }
      if(this.evaluationInfo){
        this.dashboardReports.selectedChannel = this.evaluationInfo;
      }
    }else{
      this.dashboardReports.selectComplaintOption = this.dashboardReports.complaintOptionList[0];
    }

    if(this.client || changesProduct){
      this.clientId = this.client? this.client.clientId :  0;
      if(this.from==="folder"){
        this.getSummaryReports(this.dashboardReports.fromDate.format('YYYYMMDD') + '/' + this.dashboardReports.toDate.format('YYYYMMDD') + '/' + this.evaluationId);
        this.getDaysEvolution();
      }else{
          this.evaluationId = 0;
      }
      this.getFiltersDate(this.clientId, this.evaluationId);
    }

    if(this.evaluationList){
        this.dashboardReports.evaluationListFilter = this.evaluationList;
    }
  }

  configureConfidentialAccess(){
    let optionList : any = [
      {
          id: 0,
          text:this.translate.instant('dashboard-reports.generate-report'),
          action: 'generateNew'
      },{
          id: 1,
          text:this.translate.instant('dashboard-reports.view-report-list'),
          action: 'viewList'
      },{
        id: 2,
        text:this.translate.instant('documentation-list.manage-confident'),
        action: 'confidential'
      }
    ]
    if(this.skin === 'dark-blue' || this.skin === 'gm-law' || (this.skin !== 'icam-red' && this.from === 'dashboard')){
        this.getConfidentialAccess(optionList);
    }
  }

  getConfidentialAccess(optionList: any){
      this.confidentialUrl = this.getConfidentialUrlId();
      this.apiService.get('reports/confidentialaccess/'+this.confidentialUrl+'/config')
        .then((data:any)=> {
              this.dashboardReports.reportConfidential.confidential = data.confidential;
              if(data.confidential && data.hasAccess){
                  this.getUsersConfidentialAccess();
              }
              if(data.hasAccess && this.productSelect !== 'blanqueo'){
                  this.dashboardReports.showDropdownGeneralReport = true;
                  for(let optionItem of optionList){
                      switch(optionItem.action){
                          case 'generateNew':
                              if(this.userPermissions !== 'none' && data.hasAccess){
                                  this.dashboardReports.reportOptionList.push(optionItem);
                              }
                              break;
                          case 'viewList':
                              this.dashboardReports.reportOptionList.push(optionItem);
                              break;
                          case 'confidential':
                              if(this.skin === 'dark-blue' && this.userPermissions !== 'none' && data.hasAccess){
                                this.dashboardReports.reportOptionList.push(optionItem);
                              }
                              break;
                      }
                  }
              }
            }, (errorData:any)=>{})
  }

  getUsersConfidentialAccess(){
    this.apiService.get('reports/confidentialaccess/'+this.confidentialUrl)
      .then((data:any)=> {
          this.dashboardReports.reportConfidential.confidentialList = data;
      }, (errorData:any)=>{})
  }

  getConfidentialUrlId(){
      let reportId : any = 0;
      let idType : any = this.from==='folder'?'evaluation':'product';
      let id : any = this.from==='folder'? this.evaluationId : this.productSelect;
      if(this.skin === 'dark-blue'){
          reportId = this.from === 'folder'? this.reportVersion.folderReportVersion :  this.reportVersion.dashboardReportVersion;
      }
      return reportId+'/'+idType+'/'+id;
  }

  configureUrl(){
    switch(this.skin){
      case 'steel-blue':
          let url = this.productSelect === 'blanqueo'?this.productSelect+'/':'be/wh/';
          return url;
      case 'icam-red':
          return 'icam/';
      default:
          return this.productSelect+'/';
    }
  }

  configureOptionList(){
    let firstItem: any;
    if(this.productSelect === 'blanqueo'){
        firstItem = this.translate.instant('dashboard-reports.operations-by-date')
    }else{
        firstItem = 'icam-red'? this.translate.instant('dashboard-reports.query-date') : this.translate.instant('dashboard-reports.complaint-date')
    }
    let list: any = [
      {
          id: 0,
          text: firstItem
      },{
          id: 2,
          text: this.skin === 'icam-red'? this.translate.instant('dashboard-reports.query-status') : this.translate.instant('dashboard-reports.complaint-status')
      }
    ]
    if(this.skin ==='steel-blue'){
      list.splice(1, 0,{
          id: 1,
          text: this.translate.instant('dashboard-reports.complaint-area')
      })
    }
    return list;
  }

  changeDays(days:number){
      this.dashboardReports.timeOptions.forEach((timeOption:any) => {
        timeOption.hidden = false;
        timeOption.first = false;
      });

      this.dashboardReports.timeOptions[0].first = true;
      if(days > 45 && days <= 315){
        this.dashboardReports.timeOptions[0].hidden = true;
        this.dashboardReports.timeOptions[1].first = true;
      }else if(days > 315 && days <= 1350){
          this.dashboardReports.timeOptions[0].hidden = true;
          this.dashboardReports.timeOptions[1].hidden = true;
          this.dashboardReports.timeOptions[2].first = true;
      }else if(days > 1350){
          this.dashboardReports.timeOptions[0].hidden = true;
          this.dashboardReports.timeOptions[1].hidden = true;
          this.dashboardReports.timeOptions[2].hidden = true;
          this.dashboardReports.timeOptions[3].first = true;
      }
  }


  getSummaryReports(details:string) {
    this.apiService.get(this.urlPart + 'dashboard/graphic1channel', details)
        .then((data:any)=> {
            let fixedData:any = {};
            for (let propertyName in data[0]) {
                let propertyValue = data[0][propertyName];
                switch (propertyName) {
                    //INICIO: Caja doble 'Validacion'
                    case 'validacion':
                        if (typeof fixedData.validacion != 'object') {
                            fixedData.validacion = {};
                        }
                        fixedData.validacion.upValue = propertyValue;
                        break;
                    case 'denunciaNoValidada':
                        if (typeof fixedData.validacion != 'object') {
                            fixedData.validacion = {};
                        }
                        fixedData.validacion.downName = propertyName;
                        fixedData.validacion.downValue = propertyValue;
                        break;
                    //FIN: Caja doble 'Validacion'
                    default:
                        fixedData[propertyName] = propertyValue;
                }
            }
            this.dashboardReports.summaryReports = fixedData;
            this.dashboardReports.summaryReportsList = Object.entries(fixedData).map(([key, value]) => ({key,value}));
            this.dashboardReports.resolutionPercentage = 0;
            if (this.dashboardReports.summaryReports.numberOfComplaints !== 0) {
                if (this.dashboardReports.summaryReports.withReportSent != null) {
                    this.dashboardReports.resolutionPercentage = ((this.dashboardReports.summaryReports.withReportSent * 100) / this.dashboardReports.summaryReports.numberOfComplaints).toFixed(1);
                } else if (this.dashboardReports.summaryReports.archivo != null) {
                    this.dashboardReports.resolutionPercentage = ((this.dashboardReports.summaryReports.archivo * 100) / this.dashboardReports.summaryReports.numberOfComplaints).toFixed(1);
                }
            }
        }, (errorData:any)=>{})
  }

  getDaysEvolution(){
    this.apiService.get(this.urlPart+'dashboard/todaydetails', this.evaluationId)
    .then((data:any)=>{
        this.dashboardReports.todayEvolution = data;
    }, (errorData:any)=>{})
  }

  getFiltersDate(clientId:number, evaluationId:number){
    this.getReportsByDate(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+this.timeOptionSelected+'/'+clientId+'/'+evaluationId);
    this.getReportSelectDate(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+clientId+'/'+evaluationId)
    if(this.productSelect !== 'blanqueo'){
      this.getReportsByStatus(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+clientId+'/'+evaluationId);
      if(this.skin ==='steel-blue'){
          this.getReportsByArea(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+clientId+'/'+evaluationId);
      }
      this.getDetails(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+clientId+'/'+evaluationId)
    }
  }

  getReportsByDate(details:string){

    this.loader.openLoader('dashboard-reports-change-date-data');
    this.apiService.get(this.urlPart+'dashboard/graphic1/'+details, false)
        .then((data:any)=>{
            if (data) {
              this.dashboardReports.reportsByDateData = {
                  data : this.setDate(data),
                  type: this.timeOptionSelected,
                  configuration: this.productSelect === 'blanqueo'?'Operations':'Complaints',
                  productSelect: this.productSelect
              }
              if (this.firstCallByDate === null) {
                  this.firstCallByDate = cloneDeep(this.dashboardReports.reportsByDateData);
              }
            }
              this.loader.closeLoader(['dashboard-reports-change-date-data']);

        }, (errorData)=>{
            this.loader.closeError();
        })
  }

  setDate(list:any){
    if(list.length ===1){
      list[0].date = this.dashboardReports.fromDate;
      list[0].toDate = this.dashboardReports.toDate;
    }else{
      for (let i = 0; i < list.length; i++) {
        switch(this.timeOptionSelected){
          case 1:
            list[i].date = moment((""+ list[i].day).substr(4,2)+'-'+(""+list[i].day).substr(6,2)+'-'+ (""+list[i].day).substr(0,4) , "MM-DD-YYYY");
            list[i].toDate = list[i].date;
            break;
          case 2:      
            list[i].date = moment(moment().year(list[i].year).week(list[i].week).day(1).format("MM-DD-YYYY"));
            list[i].toDate = moment(moment().year(list[i].year).week(list[i].week).day(7).format("MM-DD-YYYY"));
            this.setFirstAndLastDates(list, i);
            break;
          case 3:
            list[i].date = moment((""+list[i].month).substr(4,2)+'-01-'+ (""+list[i].month).substr(0,4) , "MM-DD-YYYY");
            list[i].toDate = moment(list[i].date).endOf('month');
            this.setFirstAndLastDates(list, i);
            break;
          default:
            list[i].date = moment('01-01-'+ list[i].year, "MM-DD-YYYY");
            list[i].toDate = moment(list[i].date).endOf('year');
            this.setFirstAndLastDates(list, i);
            break;
        }
      }
    }
    return list;
  }

  setFirstAndLastDates(list: any, index: number, ) {
    if (index === 0) list[index].date = moment(this.dashboardReports.fromDate);
    if (list.length - 1 === index) list[index].toDate = moment(this.dashboardReports.toDate);
  }

  getReportSelectDate (details:string){
    this.loader.openLoader('dashboard-reports-change-date-data');
    this.apiService.get(this.urlPart+'dashboard/totals/'+details, false)
        .then((data: any)=> {
            this.dashboardReports.dateDetails= data;
            this.configureActive(this.dashboardReports.dateDetails);
            this.loader.closeLoader(['dashboard-reports-change-date-data']);
        }, (errorData:any)=>{
            this.loader.closeError();
        })
  }

  configureActive(details:any){
    this.dashboardReports.activeMonth = cloneDeep(details);
    this.dashboardReports.selectActive = this.configureListActive(details);
    this.dashboardReports.numBoxes = Object.keys(this.dashboardReports.activeMonth).length ;
    if(this.skin === 'icam-red'){
      this.dashboardReports.numBoxes--;
    }
    this.dashboardReports.monthSelect = moment((""+details.month).substr(4,2)+'-01-'+ (""+details.month).substr(0,4)).format('MMMM');
    this.dashboardReports.isDate = true;
  }

  configureListActive(details:any){
    let list: any = []
    for (const [key, value] of Object.entries(details)) {
      list.push({
        name: key,
        value: value
      })
    }
    return list
  }

  getReportsByStatus(details:string){
    this.apiService.get(this.urlPart+'dashboard/graphic3/'+details,false)
        .then((data: any)=> {
            if (data) {
                data.forEach((item:any) => {
                 item.label = item.state;
                 item.count = item.complaints;
                });
                this.dashboardReports.reportsByStatusData = this.configureData(data);
                if (this.firstCallByStatus === null) {
                    this.firstCallByStatus = cloneDeep(this.dashboardReports.reportsByStatusData);
                }
            }
        }, function(errorData){

        })
  }

  configureData(data:any){
    let dataGraph:any = {
        data:[],
        colors:[],
        legend:[],
        amount: 0
    }
    data.forEach((item: any) => {
      dataGraph.amount+= item.count;
      dataGraph.colors.push(item.color);
      dataGraph.data.push(item.count);
      dataGraph.legend.push(item.label)
    });
    dataGraph.original = data;
    dataGraph.graphicCaption = this.skin==='icam-red'?this.translate.instant('dashboard-reports.num-queries') : this.translate.instant('dashboard-reports.num-complaints');
    dataGraph.legendCaption = data.legendCaption;
    return dataGraph;
  }

  getReportsByArea(details:string ){
    this.apiService.get(this.urlPart+'dashboard/graphic2/'+details, false)
        .then((data:any)=>{
            if (data) {
              data.forEach((item:any) => {
                  item.label = item.area;
                  item.count = item.complaints;
              });
              this.dashboardReports.reportsByAreaData = this.configureData(data);
              if (this.dashboardReports.firstCallByArea === null) {
                this.dashboardReports.firstCallByArea = cloneDeep(this.dashboardReports.reportsByAreaData);
              }
            }
        }, (errorData:any)=>{})
  }

  getDetails(details:string){
    this.apiService.get(this.urlPart+'dashboard/graphic2details/'+details, false)
      .then((data:any)=>{
          this.dashboardReports.reportsDetail = data;
          if(this.firstCallReportsDetail === null){
              this.firstCallReportsDetail = cloneDeep(this.dashboardReports.reportsDetail);
          }
          if(this.dashboardReports.selectComplaintOption.id !== 0){
              this.configureActive(this.dashboardReports.reportsDetail[0]);
          }
      }, function(errorData){

      })
  }

  selectChannel(channel:any){
      this.dashboardReports.selectedChannel = channel;
      this.clientId = channel.clientId;
      this.dashboardReports.selectedChannelName = channel.name;
      this.getFiltersDate(this.clientId, channel.evaluationId);
  }

  selectComplaint(complaint:any){
      this.dashboardReports.selectComplaintOption = complaint;
      let configureActive = complaint.id === 0? this.dashboardReports.dateDetails : this.dashboardReports.reportsDetail[0]
      this.configureActive(configureActive);
  }
  unSelectChannel(e:any){
      if(this.dashboardReports.selectedChannel !== null){
          e.stopPropagation();
          this.dashboardReports.selectedChannel = null;
          this.clientId = 0;
          this.evaluationId = 0;
          this.getFiltersDate(this.clientId, this.evaluationId);
          this.dashboardReports.isDate = true;
          this.dashboardReports.selectedChannelName = this.translate.instant('dashboard-reports.filter-channel');
      }
  }
  launchActionList(option: any){
      let reportIdSelected = 0;
      switch(option.action){
          case 'viewList':
              this.loader.openLoader('open-list-reports');
              this.stateManagement.setStateUrl(window.location.pathname);
              if(this.productSelect === 'denunc'){
                  reportIdSelected = this.from === 'folder'? this.reportVersion.folderReportVersion :  this.reportVersion.dashboardReportVersion;
                  this.router.navigate(['report-list']);
                  if(this.from==='folder'){
                    sessionStorage.removeItem('reportlistfrom');
                  }
              } else {
                  this.skin === 'icam-red'? this.router.navigate(['reports', 14]):this.router.navigate(['reports-evaluation']);
              }
              this.setIdsToSessionStorage(reportIdSelected);
              break;
          case 'generateNew':
              this.skin === 'icam-red'? this.newReportIcam() : this.newReport() ;
              break;
          case 'confidential':
            this.openModalConfidential();
            break;
          default:
              break;
      }
  }

  setIdsToSessionStorage(reportIdSelected: any){
      let itemStorage = {
          from: this.from,
          reportId: reportIdSelected,
          productId: this.productSelect,
          evaluationId: this.from==='folder'?this.evaluationId:null
      }
      sessionStorage.setItem('dashboardReportsSession', JSON.stringify(itemStorage));
  }

  newReportIcam(){
    this.launchActionEmit.emit({action:{action:'newReport'}});
  }

  newReport(){
      let report:any = {
          evaluationId: 0,
          clientId: 0
      };
      if(this.client && this.client.clientId){
          report.clientId = this.client.clientId;
      }
      if(this.dashboardReports.selectedChannel){
          report = this.dashboardReports.selectedChannel;
      }
      report.reportId = 6;
      let typeReport = 'report';
      let title = '';
      if(this.productSelect === 'denunc'){
          report.reportId = this.from === 'folder'? this.reportVersion.folderReportVersion :  this.reportVersion.dashboardReportVersion;
          report.predefined = true;
          typeReport = 'report-denunc';
          title = this.from === 'folder'?this.translate.instant('report-incidents-list.register-complaint'):this.translate.instant('report-incidents-list.global-register-reports');
      }
      let newItem : any = {
          title:  this.translate.instant('new-report.new-report'),
          type: 'report',
          specialType: typeReport,
          titleConcept: title,
          conceptId: report.reportId,
          conceptObjectId: null,
          listFolders: [],
          icon: 'lf-icon-analysis',
          rename: false
      }
      this.modalService.renameModal(newItem)
          .subscribe((result:any)=>{
              if(result.result === 'ok'){
                  this.createNewReport(report, result.name);
              }
            })
  }

  createNewReport(report:any, name:string){
    let itemSelected : any = {
        type: this.skin ==='steel-blue'?'new-report-be':'new-report',
        item: report,
        name: name,
        fromDate: this.dashboardReports.fromDate.format('YYYYMMDD'),
        toDate: this.dashboardReports.toDate.format('YYYYMMDD'),
        interval: this.timeOptionSelected,
        origin: 'dashboard-graph',
        itsCompany: Boolean(this.from!=="folder")
    }

    this.modalService.informativeLoadingModal(itemSelected).subscribe((result:any)=>{
      if(result.result === 'ok'){
        if(this.productSelect === 'denunc'){
          this.stateManagement.setStateUrl(window.location.pathname);
          this.router.navigate(['report-detail', result.item.reportObjectId])
        }else{
          this.loader.closeLoader(['report-open-report']);
          this.modalService.adviceModal(this.translate.instant('reports-list.report-create') + " '" + name + "'" + this.translate.instant('dashboard.ok-created'),'info:accept:check-round')
        }
        this.setIdsToSessionStorage(result.item.reportId);
      }else{
        this.modalService.adviceModal(this.translate.instant('dashboard.error-report-create'), 'error:accept:warning')
      }
    })
  }

  openModalConfidential(){
    this.modalService.manageConfidentialModal(this.dashboardReports.reportConfidential).subscribe((result:any) => {
      if(result.result === 'ok'){
        this.loader.openLoader('reports-confidential');
        this.confidentReport(result);
      }
    });
  }

  confidentReport(resultModal: any){
    let list : any = resultModal.share;
    let confidential : any = resultModal.confidential.toString();
    let confidentialOfUserConnected : any = resultModal.confidentialOfUserConnected;
    this.apiService.patch('reports/setconfidential/'+this.confidentialUrl, confidential, list)
        .then((data:any)=>{
            this.dashboardReports.reportConfidential.confidential = !!resultModal.confidential;
            let text : string = 'manage-confidential-modal.confident-uncheck'
            if(this.dashboardReports.reportConfidential.confidential){
                text = 'manage-confidential-modal.confident-ok';
            }else{
              this.dashboardReports.reportConfidential.confidentialList = [];
            }
            if(this.dashboardReports.reportConfidential.confidential && !confidentialOfUserConnected){
                this.dashboardReports.showDropdownGeneralReport = false;
            }
            this.loader.closeLoader(['reports-confidential']);
            this.modalService.adviceModal(this.translate.instant(text), 'info:accept:check-round');
        },(errorData:any)=>{
            this.loader.closeError();
            this.modalService.adviceModal(this.translate.instant('manage-confidential-modal.error-confident'), 'error:accept:warning');
        })
  }

  getListIcon(key:string) {
    switch(key){
        case 'numberOfComplaints':
            return 'inbox';
        case 'recepcion':
        case 'sent':
            return 'news-communication';
        case 'registro':
        case 'received':
            return 'step-done';
        case 'tramitacion':
        case 'underInvestigation':
            return 'review';
        case 'comunicacion':
        case 'withDraftFinalDecision':
            return 'requester';
        case 'resolucion':
            return 'law';
        case 'archivo':
        case 'withReportSent':
            return 'document';
        default:
          return ''
    }
  }

  getValueUpIfObject(data:any) {
    return typeof data === 'object'? data.upValue : data;
  }

  prevent(e:any){
    e.preventDefault();
    e.stopPropagation();
  }

  filterChannels(){
    let filterList : any = [];
    filterList = this.filterColumnService.filterByWord(this.dashboardReports.elementWordFilter,  this.evaluationList, 'name');
    this.dashboardReports.evaluationListFilter = filterList;
  }

  changeCalendar(dates:any){
    if(dates.type === 'restore'){
        this.dashboardReports.fromDate = moment().startOf('quarter');
        this.dashboardReports.toDate = moment().endOf('quarter');
        this.changeDays(this.dashboardReports.toDate.diff(this.dashboardReports.fromDate, 'days'));
        let timeOptionSelected : any = this.timeOptionSelected === 1?1:this.timeOptionSelected-1;
        this.selectTimeOption(this.dashboardReports.timeOptions[timeOptionSelected]);
    }else if(dates.type === 'apply'){
        this.dashboardReports.fromDate = dates.from;
        this.dashboardReports.toDate = dates.to;
        this.changeTimeOption();
    }
    this.closeDropdown('from-to-calendar');
  }

  closeDropdown(type: any){
    let dropdown: any = this.dropdowns.find((x:any)=>{
      return x._elementRef.nativeElement.id == type;
    });
    dropdown.close();
  }

  changeTimeOption(){
    let option : any = null;
    let diff : any = this.dashboardReports.toDate.diff(this.dashboardReports.fromDate, 'days');
    if(diff >= 1 && diff < 32){
        option = this.dashboardReports.timeOptions[0];
    }else if(diff >= 32 && diff < 101){
        option = this.dashboardReports.timeOptions[1];
    }else if(diff >= 101 && diff < 1000){
        option = this.dashboardReports.timeOptions[2];
    }else{
        option = this.dashboardReports.timeOptions[3]
    }

    this.selectTimeOption(option)
  }

  selectTimeOption(option:any){
    this.dashboardReports.timeOptions.forEach((timeOption: any) => {
      timeOption.selected = false;
      if(timeOption.id === option.id){
        timeOption.selected = true;
        this.timeOptionSelected = timeOption.id
      }
    });

    let clientId = this.clientId;
    let evaluationId = this.evaluationId;
    if(this.dashboardReports.selectedChannel){
        clientId = this.dashboardReports.selectedChannel.clientId;
        evaluationId = this.dashboardReports.selectedChannel.evaluationId;
    }
    this.getFiltersDate(clientId, evaluationId);

    if(this.from==="folder"){
        this.getSummaryReports(this.dashboardReports.fromDate.format('YYYYMMDD')+'/'+this.dashboardReports.toDate.format('YYYYMMDD')+'/'+evaluationId);
        this.getDaysEvolution();
    }
  }

  getTranslate(key:any){
      return key ==='numberOfComplaints' && this.skin === 'icam-red'? 'dashboard-reports.numberOfComplaintsIcam' : 'dashboard-reports.'+key;
  }

  ngOnDestroy(){
      this.commonUtilsService.OnDestroy(this.subscribers);
  }

}
