<div class="results-report">
    <div class="results-report__header-block">
        <button class="results-report__back-button"
            (click)="changeView()">
            <i class="lf-icon-arrow-back results-report__arrow-back-icon"></i>
            <span class="results-report__arrow-back-text results-report__arrow-back-text--extra"
                [afTranslate]="'results-report.exit-results-report'">_de informe de resultados</span>
        </button>
        <div class="results-report__info-header"
            [ngShow]="!resultsReport.showNoneResults">
            <div class="results-report__info-total-poll">
                <span [afTranslate]="'results-report.total-poll'">Total de encuestas</span>
                <span class="results-report__number-poll">{{concept.amountEvaluatedConcept}}</span>
                <div class="results-report__other-info-poll"
                    [ngHide]="concept.publicShared">
                    <span class="results-report__number-poll results-report__number-poll--pending">{{concept.pending}}</span>
                    <span class="results-report__number-poll results-report__number-poll--answered">{{concept.answered}}</span>
                </div>
            </div>
            <div class="results-report__info-date-expirate">
                <i class="lf-icon-calendar results-report__icon-calendar"></i>
                <div class="results-report__block-dates">
                    <span [ngShow]="!concept.pollBeginDate">Sin Inicio</span>
                    <span [ngShow]="concept.pollBeginDate">{{concept.pollBeginDate | convertDate:'without-time'}}</span>
                    <span>-</span>
                    <span [ngShow]="!concept.pollExpirationDate"
                        [afTranslate]="'results-report.unlimited'">_Sin límite</span>
                    <span [ngShow]="concept.pollExpirationDate">{{concept.pollExpirationDate | convertDate:'without-time'}}</span>
                </div>
            </div>
        </div>
        <button class="lf-icon-send-excel results-report__icon-send-excel"
            (click)="exportResultsReport()"
            [ngShow]="!resultsReport.showNoneResults"
            matTooltip="{{'results-report.export-results-report' | afTranslate}}"
            matTooltipPosition="left"
            matTooltipClass="left"></button>
    </div>
    <div class="results-report__body-block"
        [ngShow]="!resultsReport.hideBlock">
        <div class="results-report__dropdown-block">
            <div class="results-report__block-graphics-index"
                ngbDropdown
                id="graphics-index"
                [open]="resultsReport.openSelectGraphic">
                <div class="results-report__count-questions">
                    <span>{{resultsReport.numberQuestion}}</span>
                    <span class="results-report__separator-count">/</span>
                    <span>{{resultsReport.totalQuestionsWithGraphic}}</span>
                </div>
                <div class="results-report__select-graphics-index"
                    ngbDropdownToggle>
                    <span [afTranslate]="'results-report.graphics-index'">_Índice de gráficos</span>
                    <i class="results-report__icon-open-select"
                        [ngClass]="resultsReport.openSelectGraphic?'lf-icon-angle-up':'lf-icon-angle-down'"></i>
                </div>
                <div ngbDropdownMenu
                    role="menu"
                    class="results-report__block-field-graphics">
                    <ul class="results-report__block-list-fields">
                        <li *ngFor="let fieldGraphic of resultsReport.graphicFields"
                            class="results-report__element-list-fields"
                            (click)="changeFieldSelected(fieldGraphic)">
                            <div class="results-report__element-list-text">
                                <span>{{fieldGraphic.numberQuestion}}</span><span>.</span>
                                <span>{{fieldGraphic.label}}</span>
                            </div>
                            <i class="lf-icon-arrow-right results-report__icon-directions"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngShow]="resultsReport.disggregatedList.length"
                [ngClass]="{'results-report__disggregate-block':resultsReport.disggregateSelected?.selected}">
                <p class="results-report__disgregated-title"
                    [ngShow]="resultsReport.disggregateSelected?.selected"
                    [afTranslate]="'results-report.disaggregated-data'"></p>
                <div>
                    <i class="lf-icon-close-round results-report__disggregate-close-icon"
                        [ngShow]="resultsReport.disggregateSelected?.selected"
                        (click)="unSelectDisggregated()">
                    </i>
                    <div class="results-report__block-graphics-index results-report__block-graphics-index--disaggregated"
                        [ngShow]="resultsReport.disggregateSelected?.selected"
                        ngbDropdown
                        id="disggregated-list-value"
                        [open]="resultsReport.openSelectGraphic">
                        <div class="results-report__select-graphics-index"
                            ngbDropdownToggle>
                            <span>{{resultsReport.disggregateListValueSelect?.text}}</span>
                            <i class="results-report__icon-open-select"
                                [ngClass]="resultsReport.openSelectGraphic?'lf-icon-angle-up':'lf-icon-angle-down'"></i>
                        </div>
                        <div ngbDropdownMenu
                            role="menu"
                            class="results-report__block-field-graphics results-report__block-field-graphics--disaggregated">
                            <ul class="results-report__block-list-fields">
                                <li *ngFor="let value of resultsReport.disggregateSelected?.listValues"
                                    class="results-report__element-list-fields results-report__element-list-fields--disaggregated"
                                    (click)="selectDisggregatedListValue(value)">
                                    <div class="common__language-item results-report__disggregated-item">
                                        <span>{{value.text}}</span>
                                        <i class="lf-icon-check-round-full common__language-icon"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="results-report__block-graphics-index results-report__block-graphics-index--disaggregated"
                        ngbDropdown
                        id="disggregated-select"
                        [open]="resultsReport.openSelectGraphic">
                        <div class="results-report__select-graphics-index"
                            ngbDropdownToggle>
                            <span>{{resultsReport.disggregateSelected?.label}}</span>
                            <i class="results-report__icon-open-select"
                                [ngClass]="resultsReport.openSelectGraphic?'lf-icon-angle-up':'lf-icon-angle-down'"></i>
                        </div>
                        <div ngbDropdownMenu
                            role="menu"
                            class="results-report__block-field-graphics results-report__block-field-graphics--disaggregated">
                            <ul class="results-report__block-list-fields">
                                <li *ngFor="let disggregated of resultsReport.disggregatedList"
                                    class="results-report__element-list-fields results-report__element-list-fields--disaggregated"
                                    (click)="selectDisggregated(disggregated)">
                                    <div class="common__language-item results-report__disggregated-item">
                                        <span>{{disggregated.label}}</span>
                                        <i class="lf-icon-check-round-full common__language-icon"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="results-report__block-global-question"
            [ngShow]="!resultsReport.loadingData">
            <div class="results-report__block-questions">
                <div class="results-report__header-question">
                    <div class="results-report__name-group-poll">
                        <span>{{resultsReport.selectedField?.groupName}}</span>
                    </div>
                    <i class="lf-icon-angle-right results-report__icon-angle-separator"
                        [ngShow]="resultsReport.selectedField?.subGroupName !== ''"></i>
                    <div class="results-report__name-group-poll">
                        <span [ngShow]="resultsReport.selectedField?.subGroupName !== ''">{{resultsReport.selectedField?.subGroupName}}</span>
                    </div>
                </div>
                <div class="results-report__block-title-question">
                    <span>{{resultsReport.selectedField?.label}}</span>
                </div>
                <div class="results-report__block-option-more-selected">
                    <span>{{resultsReport.selectedField?.optionMoreSelected}}</span>
                </div>
            </div>
            <div class="results-report__block-graphics">
                <button class="lf-icon-angle-left results-report__icon-change-question results-report__icon-change-question--left"
                    [ngClass]="{'results-report__icon-change-question--active':resultsReport.selectedField?.step > 1}"
                    (click)="changeFieldSelected(resultsReport.graphicFields[resultsReport.selectedField?.prevStep])"
                    [disabled]="1 >= resultsReport.selectedField?.step"></button>
                <button class="lf-icon-angle-right results-report__icon-change-question results-report__icon-change-question--right"
                    [ngClass]="{'results-report__icon-change-question--active':resultsReport.graphicFields?.length > resultsReport.selectedField?.step}"
                    (click)="changeFieldSelected(resultsReport.graphicFields[resultsReport.selectedField?.step])"
                    [disabled]="resultsReport.selectedField?.step >= resultsReport.graphicFields?.length"></button>
                <div [class]="'results-report__block-flex-graphs results-report__block-flex-graphs--'+resultsReport.classBlockFlex">
                    <div class="results-report__block-info-graph"
                        [id]="'results-report-block-info-graph-'+resultsReport.selectedField?.graphicData?.graphicId">
                        <svg class="results-report__triangle">
                            <line x1="0" y1="12" x2="11" y2="0" class="results-report__triangle-left" />
                            <line x1="22" y1="12" x2="11" y2="0" class="results-report__triangle-right" />
                            <line x1="1" y1="12" x2="21" y2="12" class="results-report__triangle-bottom" />
                        </svg>
                        <button class="lf-icon-sort-by results-report__order-icon "
                            [ngClass]="{'results-report__order-icon--selected': resultsReport.selectedOrder}"
                            (click)="changeOrder()"></button>
                        <div class="results-report__block-table">
                            <div class="results-report__info-graph-table-header">
                                <div class="results-report__info-graph-cols results-report__info-graph-cols--options-header">
                                    <p class="results-report__header-text">{{resultsReport.titleOptionTable}}</p>
                                </div>
                                <div class="results-report__info-graph-cols results-report__info-graph-cols--answers">
                                    <p class="results-report__header-text"
                                        [afTranslate]="'results-report.answers'"></p>
                                </div>
                            </div>

                            <div class="results-report__answer-block"
                                [ngClass]="{'results-report__answer-block--overflow': resultsReport.overflowInfo}">
                                <div class="results-report__info-graph-table-body"
                                    [ngClass]="{'results-report__info-graph-table-body--overflow': resultsReport.overflowInfo}"
                                    *ngFor="let answers of resultsReport.selectedField?.graphicData?.original | afOrderBy: resultsReport.orderTableType:resultsReport.orderTableAsc">
                                    <div class="results-report__info-graph-cols results-report__info-graph-cols--icon-color">
                                        <i class="lf-icon-step-final results-report__dot-icon"
                                            [ngStyle]="{'color':answers.color}"></i>
                                    </div>
                                    <div class="results-report__info-graph-cols results-report__info-graph-cols--options-body">
                                        <span [ngShow]="resultsReport.selectedField?.numericalScale"
                                            class="results-report__poll results-report__poll--question"
                                            [afTranslate]="'common.punctuation'">_Puntuación</span>
                                        <span class="results-report__poll results-report__poll--question">{{answers.label}}</span>
                                    </div>
                                    <div class="results-report__info-graph-cols results-report__info-graph-cols--answers">
                                        <div class="results-report__poll results-report__poll--percent">{{answers.percentage}}%</div>
                                        <div class="results-report__poll results-report__poll--answer">{{answers.answers}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="results-report__bottom-info-block">
                            <i class="lf-icon-questionnaire results-report__poll-amount-icon"></i>
                            <div class="results-report__bottom-info-block-text">
                                <p class="results-report__poll-amount-text results-report__poll-amount-text--left">
                                    <span [afTranslate]="'results-report.answered'">_Respondido</span>:
                                    <span>{{resultsReport.selectedField?.answered}}</span>
                                </p>
                                <p class="results-report__poll-amount-text">
                                    <span [afTranslate]="'results-report.omitted'">_Omitido</span>:
                                    <span>{{resultsReport.selectedField?.notAnswered}}</span>
                                </p>
                            </div>
                            <button class="horizontal-poll-graph__capture horizontal-poll-graph__capture--result-report"
                                (click)="saveAsPng()"
                                [disabled]="resultsReport.disabledSaveAsPng"
                                [id]="'result-report-'+resultsReport.selectedField?.fieldId"
                                matTooltip="{{(resultsReport.copiedTooltip?'results-report.image-captured':'results-report.capture-image') | afTranslate}}"
                                matTooltipPosition="above"
                                matTooltipClass="above"
                                [matTooltipDisabled]="resultsReport.disableTooltip">
                            <i class="lf-icon-all"></i>
                        </button>
                        </div>
                    </div>

                    <div class="results-report__block-bar-graph"
                        [ngClass]="{'results-report__block-bar-graph--multiple': resultsReport.selectedField?.multiple}"
                        *ngIf="resultsReport.selectedField?.fieldTypeId === 'list' || resultsReport.selectedField?.fieldTypeId === 'listp'">
                        <horizontal-poll-graph *ngIf="resultsReport.selectedField?.graphicData && resultsReport.selectedField?.graphicData?.amount > 0"
                            [graphData]="resultsReport.selectedField?.graphicData"
                            [isModal]="resultsReport.isModal"
                            [fromComponent]="'resultReports'">
                        </horizontal-poll-graph>
                        <button [ngShow]="resultsReport.selectedField?.graphicData?.original.length >= 20"
                            class="results-report__maximize-modal-button"
                            [ngClass]="{'results-report__maximize-modal-button--multiple': resultsReport.selectedField?.multiple}"
                            (click)="maximizeGraph()">
                            <i class="lf-icon-zoom-in results-report__maximize-modal-icon"></i>
                        </button>

                    </div>
                    <div class="results-report__block-donut-graph"
                        *ngIf="!resultsReport.selectedField?.multiple && (resultsReport.selectedField?.graphicData && resultsReport.selectedField?.graphicData?.amount > 0)">
                        <donut-graph [graphData]="resultsReport.selectedField?.graphicData"
                            [configurateReport]="resultsReport.configurateReport"
                            [from]="'resultReports'">
                        </donut-graph>
                    </div>
                </div>
            </div>
        </div>
        <div class="results-report__spinner-loader"
            [ngShow]="resultsReport.loadingData">
            <img [src]="resultsReport.loaderImg"
                alt="spinner"/>
        </div>
    </div>
    <div class="results-report__body-block-without-results"
        [ngShow]="resultsReport.showNoneResults">
        <p class="folder__no-concept-text"
            [afTranslate]="'results-report.without-results'">_Esta encuesta no tiene informe de resultados</p>
    </div>
</div>

