<div class="filter-header">
  <i class="lf-icon-close filter-header__icon-selected"
      (click)="resetFilterClient()"
      [ngShow]="filterHeader.selectedClient"></i>
  <div class="common__dropdown common__dropdown--filter-header"
      ngbDropdown
      [placement]="'bottom-right'">
      <button class="filter-header__button-filter"
          ngbDropdownToggle>
          <i class="lf-icon-filter-2 filter-header__icons-v2"></i>
          <span class="filter-header__text-v2 common__ellipsis"
              [afTranslate]="'dashboard.client-filter'"
              [ngShow]="!filterHeader.selectedClient">_Filtrar por organización</span>
          <span class="filter-header__text-v2 filter-header__text-v2--client common__ellipsis"
              [ngShow]="filterHeader.selectedClient">{{filterHeader.selectedClientName}}</span>
      </button>
      <ul class="common__select-list filter-header__select-list-client"
          ngbDropdownMenu
          role="menu">
          <li class="filter-header__search"
              (click)="$event.stopPropagation()">
              <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                  <input class="common__find-in-dropdown"
                      type="text"
                      [(ngModel)]="filterHeader.searchClient"
                      [placeholder]="'filter-header.search' | afTranslate">
                  <i class="common__find-dropdown-icon-find"
                      [ngClass]="{'lf-icon-search':filterHeader.searchClient === '',
                          'lf-icon-close-search': filterHeader.searchClient !==''}"
                      (click)="resetSearch()"></i>
              </div>
          </li>
          <ul class="filter-header__list-products-scroll">
              <li *ngFor="let child of filterHeader.filterClientList | byWord: filterHeader.searchClient : filterHeader.byword | afOrderBy: filterHeader.byword:false"
                  [ngHide]="child.parentId && filterHeader.searchClient ===''">
                  <div class="common__select-item filter-header__list-items-v2"
                    (click)="selectClient(child)">
                      <i class="filter-header__angle-icon"
                          [ngShow]="child.children && child.children.length && filterHeader.searchClient ===''"
                          [ngClass]="child.open?'lf-icon-angle-down':'lf-icon-angle-right'"
                          (click)="openChild($event,child)">
                      </i>
                      <div class="filter-header__block-name-child">
                          <span class="filter-header__name-child">{{child.name}}</span>
                          <span [ngShow]="child.parentId && filterHeader.searchClient !==''"
                              class="filter-header__name-parent">({{child.parentName}})</span>
                      </div>
                      <i class="lf-icon-check-round-full common__select-icon common__select-icon--color"></i>
                  </div>
                  <div class="filter-header__child-padding"
                    [ngShow]="child.children && child.children.length && child.open && filterHeader.searchClient ===''">
                      <filials [child]="child"
                        (openChildEmit)="openOrSelectOn($event, 'open')"
                        (selectChildEmit)="openOrSelectOn($event, 'select')" >
                      </filials>
                  </div>
              </li>
          </ul>
      </ul>
  </div>
</div>
