<div class="customize-fields">
  <div class="customize-concept__group-header customize-concept__group-header--fields"
      [ngClass]="{'customize-concept__group-header--highlight': customizeFields.field.isHighlight}">
          <div class="customize-concept__group-header-left">
              <i class="customize-concept__show-inner-icon customize-concept__show-inner-icon--fields"
                  [ngClass]="customizeFields.field.open?'lf-icon-angle-up':'lf-icon-angle-down'"
                  (click)="showField()"></i>
              <i class="lf-icon-step-done customize-fields__field-private"
                  [ngShow]="(isPrivate || customizeFields.field.private) && concept.allowPublicShare"></i>
              <input type="text"
                    [id]="'customize-field-'+customizeFields.field.fieldId"
                    class="customize-concept__group-name customize-concept__group-name--input customize-concept__group-name--fields"
                    [ngClass]="{'customize-concept__group-name--field-private':isPrivate || customizeFields.field.private}"
                    [(ngModel)]="customizeFields.field.label"
                    [disabled]="!customizeFields.field.open || concept.disabled"
                    [maxLength]="customizeFields.maxlengthInput"
                    (blur)="modifyField()">

          </div>
          <div class="customize-concept__group-header-right">
              <i class="lf-icon-kebab-menu customize-concept__group-icon customize-concept__group-icon--more-field"
                  [ngShow]="!customizeFields.field.open"></i>
              <i [class]="'lf-icon-arrows customize-concept__group-icon customize-concept__group-icon--arrow customize-concept__group-icon--fields handle'+typeField"
                  [id]="'handle'+typeField"
                  [ngClass]="{'customize-concept__group-icon--no-delete':customizeFields.field.instantiated}"
                  [ngShow]="!customizeFields.field.open && !concept.disabled">
              </i>
          </div>
          <div class="customize-concept__fieldcontainer-menu customize-concept__fieldcontainer-menu--field"
                [ngShow]="!customizeFields.field.open">
              <i class="customize-concept__menu-icon customize-concept__menu-icon--writing"
                  [ngClass]="concept.disabled?'lf-icon-visible':'lf-icon-editable-form'"
                  (click)="showField()"></i>
              <i class="lf-icon-trash customize-concept__menu-icon customize-concept__menu-icon--delete"
                  [ngClass]="{'customize-concept__icon-delete-opened':customizeFields.field.open}"
                  [ngShow]="!customizeFields.field.instantiated && !concept.disabled"
                  (click)="deleteField()"></i>
          </div>
      </div>
      <div class="customize-concept__group-body customize-concept__group-body--field"
            *ngIf="customizeFields.field.openFirstTime && customizeFields.field.open"
            [ngSwitch]="customizeFields.field.fieldTypeIdPainted">
          <customize-field-text *ngSwitchCase="'text'"
              [visibilityItems]="visibilityItems"
              [contentTypeList]="contentTypeList"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [isMultiple]="isMultiple"
              [field]="customizeFields.field"
              [reportList]="reportList"
              [concept]="concept"
              (changeInField)="fieldChange($event)">
          </customize-field-text>
          <customize-field-num *ngSwitchCase="'num'"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-num>
          <customize-field-list *ngSwitchCase="'list'"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-list>
          <customize-field-text-rich *ngSwitchCase="'rich'"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-text-rich>
          <customize-field-date *ngSwitchCase="'date'"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-date>
          <customize-field-money *ngSwitchCase="'money'"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-money>
          <customize-field-bool *ngSwitchCase="'bool'"
              [pollObject]="pollObject"
              [visibilityItems]="visibilityItems"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-bool>
          <customize-field-file *ngSwitchCase="'file'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-file>
          <customize-field-formula *ngSwitchCase="'formu'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [reportList]="reportList"
              [concept]="concept"
              [fieldContainers]="fieldContainers"
              (changeInField)="fieldChange($event)">
          </customize-field-formula>
          <customize-field-other *ngSwitchCase="'l-co'"
              [fieldOriginList]="fieldOriginList"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-other>
          <customize-field-user *ngSwitchCase="'user'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-user>
          <customize-field-txchk *ngSwitchCase="'txchk'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [visibilityItems]="visibilityItems"
              [field]="customizeFields.field"
              [concept]="concept"
              [reportList]="reportList"
              (changeInField)="fieldChange($event)">
          </customize-field-txchk>
          <customize-field-template *ngSwitchCase="'templ'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              [templatesList]="templatesList"
              (changeInField)="fieldChange($event)">
            </customize-field-template>
            <customize-field-task *ngSwitchCase="'task'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              (changeInField)="fieldChange($event)">
            </customize-field-task>
            <customize-field-alert *ngSwitchCase="'alert'"
              [isPrivate]="isPrivate"
              [hiddenInLandings]="hiddenInLandings"
              [field]="customizeFields.field"
              [concept]="concept"
              (changeInField)="fieldChange($event)">
            </customize-field-alert>
      </div>
      <i [class]="'customize-fields__field-type-icon '+customizeFields.field.icon"></i>
</div>
