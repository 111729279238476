<div class="field-type-list-extended">
  <label class="common__input-block common__input-block--fixed"
      [for]="'field-type-list-extended-'+field.fieldId"
      [ngClass]="{'common__input-block--poll-view':field.poll && !field.noEditable && field.sequentialView,
          'common__show-alert': field.error && !(isLanding && field.poll && !field.noEditable && field.sequentialView),
          'common__input-no-editable':field.noEditable,
          'common__input-block--custom-field':isLanding && !field.sequentialView}"
      [attr.disabled]="field.noEditable?true:null">
      <div class="common__input-text-poll"
          *ngIf="field.sequentialView && !field.noEditable"
          [ngClass]="{'common__input-text-poll--error': field.error,
              'field-item__cell-block--custom':isLanding  }">
          <p class="field-type-text__custom-sequential-label">{{fieldTypeListExtended.titleField}}
              <span class="common__text-required common__text-required--bool"
                  [ngShow]="field.required">*</span>
              <tooltip-info [description]="field.description"
                  [field]="field"
                  [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView"></tooltip-info>
              <tooltip-law [references]="field.references"
                  [field]="field"
                  [ngShow]="field.references?.length"
                  [isSequentialPoll]="field.poll && !field.noEditable && field.sequentialView">
              </tooltip-law>
          </p>
      </div>
      <div class="common__label-block"
          [ngClass]="field.multiple && fieldTypeListExtended.listElementSelect.length && listValues?.length > 10?'common__label-block--one-icon-bubble':'common__label-block--one-icon'"
          *ngIf="!(field.sequentialView && !field.noEditable)">
          <span class="common__input-text"
              [ngClass]="{'common__input-text--required': field.error,
                  'common__input-text--no-editable':field.noEditable}">
              {{fieldTypeListExtended.titleField}}
          </span>
          <span class="common__text-required"
              [ngShow]="field.required">*</span>
          <div class="common__info-icon-block common__info-icon-block--visible"
              [ngHide]="field.noEditable || !field.description">
              <tooltip-info [description]="field.description"
                  [field]="field"></tooltip-info>
          </div>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngClass]="{'common__tooltip-law-block--landing-custom':isLanding}"
              [ngShow]="field.references && field.references.length && !field.noEditable">
              <tooltip-law [references]="field.references"
                  [field]="field">
              </tooltip-law>
          </div>
          <div class="common__tooltip-law-block common__tooltip-law-block--visible"
              [ngShow]="field.relatedInfo && field.relatedInfo.length && !field.poll && !field.noEditable">
              <i class="lf-icon-search-text field-item__icon-related-info"
                  (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                  matTooltip="{{'common.related-info' | afTranslate}}"
                  matTooltipPosition="above"
                  matTooltipClass="above">
              </i>
          </div>
      </div>
      <div>
          <div class="common__bubble-count"
              [ngClass]="{'common__bubble-count--disabled': field.noEditable}"
              [ngShow]="field.multiple && fieldTypeListExtended.listElementSelect.length && listValues?.length > 10">
              {{fieldTypeListExtended.listElementSelect.length}}
          </div>
          <i class="common__input-icon common__input-icon--color"
              [ngShow]="!(field.poll && !field.noEditable && field.sequentialView)"
              [ngClass]="field.multiple?'lf-icon-practical-cases field-type-list-extended__input-icon-custom-square':'lf-icon-radio-buttons-list field-type-list-extended__input-icon-custom-radio'"></i>
      </div>
      <div [ngClass]="{'common__input-poll--list': field.poll && !field.noEditable && field.sequentialView,
                'common__input-poll--list-extend': field.poll && !field.noEditable && field.sequentialView && listValues?.length > 10,
                'common__input-poll': field.poll && !field.noEditable && field.sequentialView,
                'common__show-alert': field.error && field.sequentialView}">
          <ul class="common__multiselect-block field-type-list-extended__multiselect-block"
              [ngShow]="field.multiple && listValues?.length > 10">
              <li class="common__multiselect-item field-type-list-extended__item-selected"
                  [ngClass]="{'field-type-list-extended__item-selected--disabled': field.noEditable,
                      'field-type-list-extended__item-selected-multi-custom': isLanding}"
                  [ngStyle]="{width: fieldTypeListExtended.widthElement}"
                  *ngFor="let listElement of fieldTypeListExtended.listElementSelect">
                  <p class="common__multiselect-text field-type-list__block-text field-type-list-extended__block-text">{{listElement.text}}</p>
                  <i class="common__multiselect-delete-icon common__multiselect-delete-icon--multiple-extended"
                      [ngHide]="field.noEditable"
                      [ngClass]="{'lf-icon-close': !isLanding,
                          'lf-icon-close-round field-type-list-extended__icon-close-custom': isLanding }"
                      (click)="addOrDeleteSelectedValue($event, listElement)"></i>
              </li>
          </ul>
          <div class="field-type-list-extended__block-simple-list"
              [ngShow]="!field.multiple && listValues?.length > 10">
              <div class="field-type-list-extended__simple-list"
                  [ngClass]="{'field-type-list-extended__item-selected-simple-custom': isLanding}"
                  [ngShow]="preSelectItem && fieldTypeListExtended.simpleList !== ''">
                  <p class="common__multiselect-text field-type-list__block-text field-type-list-extended__block-text">
                      {{fieldTypeListExtended.simpleList}}
                  </p>
                  <i class="common__multiselect-delete-icon common__multiselect-delete-icon--simple"
                      [ngClass]="{'lf-icon-close': !isLanding,
                          'lf-icon-close-round field-type-list-extended__icon-close-custom': isLanding }"
                      (click)="addOrDeleteSelectedValue($event)"
                      [ngShow]="!field.noEditable">
                  </i>
              </div>
          </div>
          <div class="field-item__find-position  field-type-list-extended__find-position"
              [ngClass]="{'common__block-input-custom':isLanding}"
              [ngShow]="listValues?.length > 10 && !field.noEditable">
                  <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                      <input class="common__find-in-dropdown"
                          [ngClass]="{'common__find-in-dropdown--custom-list':isLanding}"
                          [(ngModel)]="fieldTypeListExtended.findDropdown"
                          [placeholder]="('clients-picker.search' | afTranslate)"
                          (click)="catchEvent($event)">
                      <i class="common__find-dropdown-icon-find"
                          [ngClass]="{'lf-icon-search':fieldTypeListExtended.findDropdown === '',
                              'lf-icon-close-search': fieldTypeListExtended.findDropdown !=='',
                              'common__find-dropdown-icon-find--extend':isLanding}"
                          (click)="resetSearch($event)"></i>
                  </div>
          </div>
          <div class="field-type-list-extended__margin-disabled-selected"
              [ngShow]="listValues?.length > 10 && field.noEditable && (preSelectItem && (fieldTypeListExtended.simpleList !== '' || fieldTypeListExtended.listElementSelect.length))"></div>
          <div class="field-type-list-extended__block-list"
              [ngClass]="{'field-type-list-extended__block-list--ten-elements': listValues?.length > 10,
              'field-type-list-extended__block-list--five-elements': field.sequentialView && !field.noEditable,
              'field-type-list-extended__block-list--modal': field.type === 'modal',
              'field-type-list-extended__block-list--custom':isLanding}">
              <div [ngClass]="{'field-type-list-extended__multiple-list':isLanding}"
                  [ngShow]="!field.multiple"
                  *ngFor="let listSimple of listValues | byWord:fieldTypeListExtended.findDropdown:[fieldToFind] | afOrderBy: fieldTypeListExtended.orderList:false">
                  <button class="field-type-list-extended__label-list"
                      [ngClass]="{'field-type-list-extended__label-list--disabled':field.noEditable}"
                      (click)="changeSelected(listSimple)"
                      [disabled]="field.noEditable">
                      <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                          [ngClass]="{'lf-icon-radio-button-active':listSimple.selected,
                          'lf-icon-step-first':!listSimple.selected,
                          'field-type-list-extended__icon-container--disabled':field.noEditable,
                          'field-type-list-extended__icon-container--poll': field.poll && !field.noEditable && field.sequentialView,
                          'field-type-list-extended__icon-container--custom':isLanding}">
                      </i>
                      <span class="field-type-list-extended__text-element-list"
                          [ngClass]="{'field-type-list-extended__text-element-list--disabled': field.noEditable,
                          'field-type-list-extended__text-element-list--poll': field.poll && !field.noEditable && field.sequentialView,
                          'field-type-list-extended__text-element-list--custom':isLanding}">
                          {{listSimple.text}}
                      </span>
                  </button>
              </div>
              <div class="field-type-list-extended__multiple-list field-type-list-extended__multiple-list--all"
                    *ngIf="field.multiple && listValues?.length > 10">
                  <button class="field-type-list-extended__label-list"
                      (click)="toggleAll()"
                      [disabled]="field.noEditable">
                      <i class="field-type-list-extended__icon-container"
                          [ngClass]="{'lf-icon-check-full':fieldTypeListExtended.markAll,
                            'lf-icon-box-inactive':!fieldTypeListExtended.markAll,
                            'field-type-list-extended__icon-container--disabled':field.noEditable,
                            'field-type-list-extended__icon-container--poll': field.poll && !field.noEditable && field.sequentialView,
                            'field-type-list-extended__icon-container--custom':isLanding}">
                      </i>
                      <span class="field-type-list-extended__text-element-list"
                          [ngClass]="{'field-type-list-extended__text-element-list--disabled': field.noEditable,
                            'field-type-list-extended__text-element-list--poll': field.poll && !field.noEditable && field.sequentialView,
                            'field-type-list-extended__text-element-list--custom':isLanding}"
                          [afTranslate]="'common.toggle-all'">_Marcar/desmarcar todas las opciones</span>
                  </button>
              </div>
              <div class="field-type-list-extended__multiple-list"
                  [ngClass]="{'field-type-list-extended__multiple-list--all': listMultiple.typeOption === 'all'}"
                  [ngShow]="field.multiple"
                  *ngFor="let listMultiple of listValues | byWord:fieldTypeListExtended.findDropdown:[fieldToFind] | afOrderBy:fieldTypeListExtended.orderList:false">
                  <button class="field-type-list-extended__label-list"
                      [ngClass]="{'field-type-list-extended__label-list--disabled':field.noEditable}"
                      (click)="changeMultipleSelected(listMultiple)"
                      [disabled]="field.noEditable">
                      <i class="field-type-list-extended__icon-container"
                          [ngClass]="{'lf-icon-check-full':listMultiple.selected,
                          'lf-icon-box-inactive':!listMultiple.selected,
                          'field-type-list-extended__icon-container--disabled':field.noEditable,
                          'field-type-list-extended__icon-container--poll': field.poll && !field.noEditable && field.sequentialView,
                          'field-type-list-extended__icon-container--custom':isLanding}">
                      </i>
                      <span class="field-type-list-extended__text-element-list"
                          [ngClass]="{'field-type-list-extended__text-element-list--disabled': field.noEditable,
                          'field-type-list-extended__text-element-list--poll': field.poll && !field.noEditable && field.sequentialView,
                          'field-type-list-extended__text-element-list--custom':isLanding}">
                          {{listMultiple.text}}
                      </span>
                  </button>
              </div>
          </div>
      </div>
      <div class="field-item__blocker-box"
        *ngIf="field.error && field.blocker">
        <div class="field-item__blocker-triangle"></div>
        <i class="lf-icon-warning field-item__blocker-icon"></i>
        <p class="field-item__blocker-text"
          [afTranslate]="'field-items.blocker-field'">campo bloqueante
        </p>
    </div>
  </label>
</div>
