<label class="common__input-block"
  [for]="'field-type-list-'+fieldTypeList.id"
  [ngClass]="{'common__input-block--show-references': fieldTypeList.referencesClick && fieldTypeList.field.evaluatedValue.conceptDataId !== null,
      'common__input-block-open':fieldTypeList.showList,
      'common__input-block--color field-type-list__input-block': !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight && !fieldTypeList.field.isTypeButton,
      'common__input-block--fixed field-type-list__input-block-numerical-scale':fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight || fieldTypeList.field.isTypeButton,
      'common__input-block--poll-view':fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView,
      'common__input-no-editable':fieldTypeList.field.noEditable,
      'field-type-list__input-block-custom-numerical-scale':(fieldTypeList.field.numericalScale || fieldTypeList.field.isHighlight) && isLanding}"
  [attr.disabled]="fieldTypeList.field.noEditable?true:null">
  <div class="common__input-text-poll"
      *ngIf="fieldTypeList.field.sequentialView && !fieldTypeList.field.noEditable"
      [ngClass]="{'common__input-text-poll--error': fieldTypeList.field.error,
      'field-item__cell-block--custom':isLanding }">
      <p class="field-type-text__custom-sequential-label">{{fieldTypeList.titleField}}
          <span class="common__text-required common__text-required--bool"
              [ngShow]="fieldTypeList.field.required">*</span>
          <tooltip-info [description]="fieldTypeList.field.description"
              [field]="fieldTypeList.field"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView"></tooltip-info>
          <tooltip-law [references]="fieldTypeList.field.references"
              [field]="fieldTypeList.field"
              [ngShow]="fieldTypeList.field.references?.length"
              [isSequentialPoll]="fieldTypeList.field.poll && !fieldTypeList.field.noEditable && fieldTypeList.field.sequentialView">
          </tooltip-law>
      </p>
  </div>

  <ul class="field-type-text__sequential-question-list">
    <div *ngIf="!fieldTypeList.field.multiple && !fieldTypeList.field.numericalScale && !fieldTypeList.field.isHighlight">
      <li class="field-type-text__sequential-question-item field-type-text__sequential-question-item--list"
          [ngClass]="{'common__show-alert': fieldTypeList.field.error && fieldTypeList.field.sequentialView}"
          *ngFor="let listValue of fieldTypeList.filterList | byWord:fieldTypeList.findDropdown:[fieldToFind] | afOrderBy: fieldTypeList.orderList:fieldTypeList.orderAsc; let first = first;let index = index"
          (click)="selectItem(listValue, $event)"
          [ngHide]="listValue.type && listValue.type.toLowerCase()==='back' && !listValue.show">
          <i class="field-type-text__sequential-question-icon"
              [ngClass]="{'lf-icon-radio-button-active':listValue.selected,
                'lf-icon-step-first':!listValue.selected}"
              [ngStyle]="{'color':listValue.selected?custom.color:'#898989'}"></i>
          <p class="common__ellipsis field-type-text__sequential-question-text field-type-text__sequential-question-text--list">
              <span-tooltip [text]="listValue.text"></span-tooltip>
          </p>
      </li>
    </div>
    <div *ngIf="fieldTypeList.field.isHighlight"
        class="field-type-list__highlight-block-sequential">
        <div class="field-type-list__block-template">
          <div class="common__dropdown-initial"
            ngbDropdown
            [placement]="'bottom-right'"
            (openChange)="fieldTypeList.showList = !fieldTypeList.showList">
            <i class="lf-icon-link field-type-list__icon-link field-type-list__icon-link"
                *ngIf="fieldTypeList.field.fieldTypeId ==='l-fo'">
            </i>
            <i class="common__select-arrow"
                [ngClass]="{'lf-icon-angle-up':fieldTypeList.showList,
                    'lf-icon-angle-down':!fieldTypeList.showList,
                    'common__select-arrow--no-editable': fieldTypeList.field.noEditable,
                    'common__select-arrow--l-co':fieldTypeList.field.fieldTypeId ==='l-fo'}"
                ngbDropdownToggle></i>
            <tooltip-law
                [ngShow]="fieldTypeList.references?.length"
                [references]="fieldTypeList.references"
                [field]="field"
                class="field-type-text__tooltip-block field-type-text__tooltip-block--list-lco-simple"
                *ngIf="fieldTypeList.references[0]?.referenceId"></tooltip-law>
            <div class="field-type-list__text-selected-simple"
                [ngClass]="{'field-type-list__text-selected-simple--numelements': (fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements < 10) || fieldTypeList.selectedItemToList.numElements === 0,
                    'field-type-list__text-selected-simple--numelements-middle': fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements >= 10 && fieldTypeList.selectedItemToList.numElements < 100,
                    'field-type-list__text-selected-simple--numelements-big': fieldTypeList.selectedItemToList.numElements && fieldTypeList.selectedItemToList.numElements >= 100,
                    'common__input-content-alert': fieldTypeList.field.error,
                    'common__input-content-no-editable': fieldTypeList.field.noEditable}">
                <p class="common__ellipsis common__paragraph-0">
                    <span-tooltip [text]="fieldTypeList.selectItemName"></span-tooltip>
                </p>
            </div>
          <p class="field-type-list__block-number field-type-list__block-number--selected "
              [ngShow]="fieldTypeList.selectedItemToList.numElements || fieldTypeList.selectedItemToList.numElements === 0">({{fieldTypeList.selectedItemToList.numElements}})</p>
          <i class="common__delete-content-icon"
              [ngClass]="{'lf-icon-close-round': isLanding,
                'lf-icon-close': !isLanding}"
              [ngShow]="fieldTypeList.selectItemName !=='' && !fieldTypeList.field.noEditable"
              (click)="resetItem()"></i>
          <ul class="dropdown-menu common__select-list field-type-list__list-block"
              [ngClass]="{'common__select-list--no-shadow':fieldTypeList.field.noShadow,
                  'field-type-list__list-block--tree-list':fieldTypeList.field.isTree,
                  'field-type-list__list-block--xs': fieldTypeList.field.xs,
                  'field-type-list__list-block--custom': isLanding}"
              ngbDropdownMenu
              role="menu">
              <li class="common__find-dropdown-block"
                  ngbDropdownItem
                  [ngShow]="fieldToFind">
                  <div class="field-item__find-position">
                      <div class="field-type-other__block-input-search"
                          [ngClass]="{'field-type-other__block-input-search--block-100': !(fieldTypeList.field.isOpen && !fieldTypeList.public)}">
                          <input class="common__find-in-dropdown"
                              [ngClass]="{'common__find-in-dropdown--custom-list':isLanding}"
                              [(ngModel)]="fieldTypeList.findDropdown"
                              [placeholder]="'clients-picker.search' | afTranslate"
                              (click)="catchEvent($event)"
                              (keyup)="changeList()">
                          <i class="common__find-dropdown-icon-find"
                              [ngClass]="{'lf-icon-search':fieldTypeList.findDropdown === '',
                                  'lf-icon-close-search': fieldTypeList.findDropdown !==''}"
                              (click)="resetSearch($event)"></i>
                      </div>
                  </div>
              </li>
              <li class="field-type-list__select-list"
                  [ngClass]="{'field-type-list__select-list--tree':field.isTree}">
                  <div *ngFor="let listValue of fieldTypeList.filterList | byWord:fieldTypeList.findDropdown:[fieldToFind] | afOrderBy: fieldTypeList.orderList:fieldTypeList.orderAsc; let first = first;let index = index"
                      (click)="selectItem(listValue, $event)"
                      class="field-type-list__block-element-list"
                      [ngHide]="listValue.type && listValue.type.toLowerCase()==='back' && !listValue.show">
                      <div class="common__select-item common__select-item--list"
                          [ngClass]="{'common__select-item--tree':fieldTypeList.field.isTree,
                          'common__select-item--disabled': listValue.disabled,
                          'common__select-item--custom-first':isLanding && first}"
                          [ngHide]="listValue.deleteError"
                          [attr.disabled]="listValue.disabled?true:null">
                          <i [ngShow]="fieldTypeList.field.isTree &&  listValue.children && listValue.children.length"
                              [ngClass]="{'lf-icon-angle-down':listValue.show ,
                                'lf-icon-angle-right':!listValue.show}"
                              (click)="showChildren($event, listValue)"></i>
                          <i [class]="(listValue.extension | extensionDocument) + ' common__select-info-icon'"
                              *ngIf="listValue.extension"></i>
                          <i [class]="(listValue.type | taskTypeIcons:'reference') + ' field-type-list__law-icon'"
                              *ngIf="fieldTypeList.field.fieldTypeId ==='l-fo'"></i>
                          <div class="common__cell-block field-type-list__simple-text-list"
                              [ngClass]="{'field-type-list__simple-text-list--numelements': (listValue.numElements && listValue.numElements < 10) || listValue.numElements === 0,
                                  'field-type-list__simple-text-list--numelements-middle': listValue.numElements && listValue.numElements >= 10 && listValue.numElements < 100,
                                  'field-type-list__simple-text-list--numelements-big': listValue.numElements && listValue.numElements >= 100,
                                  'field-type-list__multiple-text-list--user-value': listValue.isUserValue}">
                              <p class="common__ellipsis common__paragraph-0"
                                  [ngClass]="{'field-type-list__item-text':fieldTypeList.field.fieldTypeId ==='l-fo',
                                      'common__line-through':listValue.isDeleted}">
                                  <span-tooltip [text]="listValue.text"
                                    [isDeleted]="listValue.isDeleted"></span-tooltip>
                              </p>
                          </div>
                          <p class="field-type-list__block-number field-type-list__block-number--list"
                              [ngShow]="listValue.numElements || listValue.numElements === 0">({{listValue.numElements}})</p>
                          <i class="lf-icon-configuration field-type-list__is-user-value"
                              [ngShow]="listValue.isUserValue"
                              [ngClass]="{'field-type-list__is-user-value--custom':isLanding}"></i>
                          <i class="common__select-icon common__select-icon--list"
                              [ngClass]="isLanding?'lf-icon-check-round':'lf-icon-check-round-full'"
                              [ngHide]="listValue.disabled ||(isLanding && listValue.isUserValue)"></i>
                          <i class="lf-icon-editable-form common__select-icon"
                              [ngShow]="listValue.isUserValue"
                              (click)="editListValueElement($event, listValue)"></i>
                          <i class="lf-icon-trash common__select-icon"
                              [ngShow]="listValue.isUserValue"
                              (click)="deleteListValue($event, listValue)"></i>
                      </div>
                      <div class="common__select-item common__select-item--error"
                          [ngShow]="listValue.deleteError">
                          <i class="lf-icon-lock common__select-info-icon"></i>
                              <span [afTranslate]="'field-type-list.message-info'">No es posible eliminar este valor porque ya se ha utilizado en otros formularios.</span>
                          <button class="common__error-button-list "
                              (click)="acceptNotDelete($event, listValue)"
                              data-[afTranslate]="'common.ok'">Aceptar</button>
                      </div>
                  <!-- <div class="field-type-list__tree-list"
                          *ngIf="listValue.children.length && listValue.show">
                          <tree-simple></tree-simple>
                      </div> -->
                  </div>
              </li>
          </ul>
      </div>
    </div>
    </div>
  </ul>
  <div class="field-item__blocker-box"
    *ngIf="fieldTypeList.field.error && fieldTypeList.field.blocker">
    <div class="field-item__blocker-triangle"></div>
    <i class="lf-icon-warning field-item__blocker-icon"></i>
    <p class="field-item__blocker-text"
      [afTranslate]="'field-items.blocker-field'">campo bloqueante
    </p>
  </div>
</label>
