<div class="field-type-contacts">
    <div class="field-type-contacts__label-block">
        <label class="common__input-block common__input-block--custom-list"
            [for]="'field-type-contacts-simple-custom-'+id"
            [ngClass]="{'common__input-block--open-custom': fieldTypeContacts.userList,
              'common__input-block--show-references': fieldTypeContacts.references && field.evaluatedValue.conceptDataId !== null,
              'common__show-alert': field.error,
              'common__input-no-editable': field.noEditable}"
            [attr.disabled]="field.noEditable?true:null">
            <div class="common__label-block common__label-block--one-icon"
                [ngClass]="{'common__label-block--four-icon-bubble': field.typeConfiguration === 'sign' || field.typeConfiguration === 'meeting'}">
                <span class="common__input-text common__input-text"
                    [ngClass]="{'common__input-text--required': field.error,
                            'common__input-text--no-editable': field.noEditable}">
                            <span>{{fieldTypeContacts.titleField}}</span>
                            <span *ngIf="field.label2"
                                class="common__input-text--secondary">{{field.label2}}</span>
                </span>
                <span class="common__text-required"
                    [ngShow]="field.required">*</span>
                <div class="common__info-icon-block common__info-icon-block"
                    [ngHide]="field.noEditable || !field.description">
                    <tooltip-info description="field.description"
                        [field]="field"></tooltip-info>
                </div>
                <div class="common__tooltip-law-block"
                    [ngShow]="field.references && field.references.length && !field.noEditable">
                    <tooltip-law [references]="field.references"
                        [field]="field">
                    </tooltip-law>
                </div>
                <div class="common__tooltip-law-block"
                    [ngShow]="field.relatedInfo && field.relatedInfo.length">
                    <i class="lf-icon-search-text field-item__icon-related-info"
                        (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                        [ngbTooltip]="'common.related-info' | afTranslate">
                    </i>
                </div>
            </div>
            <div class="field-type-contacts__block-contacts">
                <div class="float-end">
                    <i class="lf-icon-connected-data field-type-text__icon-references"
                        [ngShow]="field.evaluatedValue && field.evaluatedValue.hasReferences"
                        (click)="showReference($event)"
                        [ngbTooltip]="'common.see-dependencies' | afTranslate"
                        placement="left"
                        container="body"></i>
                    <i class="lf-icon-user field-type-contacts__icon-label"
                        [ngClass]="{'field-type-contacts__icon-label--disabled': field.noEditable}"
                        [ngShow]="!fieldTypeContacts.userList"
                        (click)="openContactList()"></i>
                </div>
                <div class="field-type-contacts__block-elements field-type-contacts__block-elements--simple">
                    <ul class="field-type-contacts__multiselect-block">
                        <li class="common__multiselect-item common__multiselect-item--contacts"
                            *ngFor="let contact of fieldTypeContacts.contactListSelected ; let index = index">
                            <div *ngIf="field.typeConfiguration !== 'field-user'">
                                <p class="field-type-contacts__item-selected"
                                    [ngClass]="{'common__line-through': contact.isDeleted}"
                                    [ngbTooltip]="contact.fullName + (contact.isDeleted ? ' ('+ ('common.inactive-user' | afTranslate)+')' : '')"
                                    placement="bottom"
                                    [disableTooltip]="showTooltip('field-type-contacts-multiple-'+index, contact)"
                                    container="body"
                                    id="{{'field-type-contacts-multiple-'+ index }}">
                                    <span id="{{'span-field-type-contacts-multiple-'+index}}">{{contact.fullName}}</span>
                                </p>
                                <i class="lf-icon-close-round common__delete-content-icon common__delete-content-icon--contacts common__delete-content-icon--contact-visibility"
                                    (click)="deleteContact($event, index)"
                                    [ngShow]="!field.noEditable"></i>
                            </div>
                            <div *ngIf="field.typeConfiguration === 'field-user'">
                                <div class="field-type-contacts__block-contact-name">
                                  <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--no-padding-left"
                                      [ngClass]="{'common__line-through':contact.isDeleted}"
                                      [ngbTooltip]="contact.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'"
                                      placement="bottom"
                                      [disableTooltip]="!contact.isDeleted"
                                      container="body">{{contact.fullName}}</p>
                                </div>
                                <div class="field-type-contacts__block-contact-name">
                                    <i class="lf-icon-mail field-type-contacts__icon-selected-custom"
                                        [ngShow]="contact.email"></i>
                                    <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--icon-custom">{{contact.email}}</p>
                                </div>
                                <div class="field-type-contacts__block-contact-phone">
                                    <i class="lf-icon-call field-type-contacts__icon-selected-custom"
                                        [ngShow]="contact.phoneNumber1"></i>
                                    <p class="common__multiselect-text common__multiselect-text--custom-contact common__multiselect-text--icon-custom">{{contact.phoneNumber1}}</p>
                                </div>
                                <i class="common__delete-content-icon lf-icon-close-round common__delete-content-icon--contacts common__delete-content-icon--contact-visibility"
                                    (click)="deleteContact($event, index)"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common__select-list common__select-list--list-block"
                [ngShow]="fieldTypeContacts.userList">
                <div class="field-type-contacts__body-simple-contacts-custom">
                  <div class="common__block-input-custom common__block-input-custom--black common__block-input-custom--contact-simple">
                      <div class="field-type-other__block-input-search field-type-other__block-input-search--block-100">
                          <input class="common__find-in-dropdown common__find-in-dropdown--custom-list"
                            [(ngModel)]="fieldTypeContacts.searchContact"
                            placeholder="{{'common.search' | translate}}"
                            (click)="catchEvent($event)">
                          <i class="common__find-dropdown-icon-find field-type-list-extended__icon-find-custom"
                              [ngClass]="{'lf-icon-search':fieldTypeContacts.findDropdown === '',
                                  'lf-icon-close-search': fieldTypeContacts.findDropdown !==''}"
                              (click)="resetSearch($event)"></i>
                      </div>
                  </div>
                  <div class="field-type-contacts__block-buttons-show-custom">
                      <button class="lf-icon-favorite field-type-contacts__icon-show-custom"
                          [ngClass]="{'field-type-contacts__icon-show-custom--selected': fieldTypeContacts.viewItems.id === 2}"
                          [ngShow]="fieldTypeContacts.usersList.length"
                          [ngbTooltip]="'users-admin.main.user' | afTranslate"
                          placement="top"
                          container="body"
                          (click)="showOnly(2, $event)"></button>
                      <button class="lf-icon-user field-type-contacts__icon-show-custom"
                          [ngClass]="{'field-type-contacts__icon-show-custom--selected': fieldTypeContacts.viewItems.id === 3}"
                          [ngShow]="fieldTypeContacts.contactsList.length"
                          [ngbTooltip]="'users-admin.main.contact' | afTranslate"
                          placement="top"
                          container="body"
                          (click)="showOnly(3, $event)"></button>
                  </div>
                  <div class="field-type-contacts__block-buttons-show-custom field-type-contacts__block-buttons-show-custom--exit-contact">
                      <button class="lf-icon-close-round field-type-contacts__icon-show-custom field-type-contacts__icon-show-custom--close"
                          [ngbTooltip]="'common.close' | afTranslate"
                          placement="top"
                          container="body"
                          (click)="resetContacts()"></button>
                  </div>
                  <div class="field-type-contacts__inner-box-custom"
                      [ngSwitch]="fieldTypeContacts.state">
                      <div *ngSwitchCase="'list'">
                          <div [ngShow]="fieldTypeContacts.usersAndContactsFilList.length">
                              <ul class="field-type-contacts__contact-book">
                                  <li *ngFor="let user of fieldTypeContacts.usersAndContactsFilList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: fieldTypeContacts.byWord:false | unique: 'uniqueValueId'"
                                      class="field-type-contacts__contact-list-to-share-custom"
                                      [ngShow]="(user.contactId && fieldTypeContacts.viewItems.id === 3) || (user.userId && fieldTypeContacts.viewItems.id===2) || fieldTypeContacts.viewItems.id === 0"
                                      (click)="addSimpleItem(user)">
                                      <i class="field-type-contacts__icon-contact-custom field-type-contacts__icon-contact-custom--simple"
                                          [ngClass]="{'lf-icon-user-special':user.userId, 'lf-icon-user':user.contactId}"></i>
                                      <span class="field-type-contacts__text-contact"
                                          [ngClass]="{'common__line-through':user.isDeleted}"
                                              [ngbTooltip]="user.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'"
                                              placement="bottom"
                                              container="body"
                                              [disableTooltip]="!user.isDeleted">{{user.fullName}}</span>
                                  </li>
                              </ul>
                          </div>
                          <div [ngHide]="fieldTypeContacts.usersAndContactsFilList.length">
                              <p class="field-type-contacts__error-text-custom"
                                  [afTranslate]="'common.no-results-found'">No se encuentran resultados</p>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </label>
    </div>
</div>
