import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { LoaderFactory } from '@shared/factories/loader/loader.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';
import { ApiServiceService } from '@shared/services/api-service/api-service.service';
import { BroadcastService } from '@shared/services/broadcast/broadcast.service';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import $ from 'jquery';

@Component({
  selector: 'concept-tab',
  templateUrl: './concept-tab.component.html'
})
export class ConceptTabComponent implements OnChanges {

  subscribers: any = {};

  constructor( private userPermisions: UserPermisionsFactory,
    private globalCfg: GlobalCfgFactory,
    private route: ActivatedRoute,
    private translate: AfTranslateFactory,
    private loader: LoaderFactory,
    private modalService: ModalServiceService,
    private apiService: ApiServiceService,
    private broadcastService: BroadcastService,
    private commonUtilsService: CommonUtilsService) { }

  @Input() subMenu: string = '';
  @Input() list: any = [];
  @Input() activeConcept: any = null;
  @Input() parentConcept: any = null;
  @Input() hasOpenEvaluationTask: any = null;
  @Input() fullMenu: any = null;
  @Input() dirty: any = null;
  @Input() task: string = '';

  @Output() launchAction = new EventEmitter();

  skin: string = this.globalCfg.skin;
  userPermissions: any = this.userPermisions.getPermissions();
  to: string = this.route.snapshot.params['to'] === 'tasks'?'task':this.route.snapshot.params['to'];
  evaluationId: any = this.route.snapshot.params['evaluationId'];
  currentComponent: string = this.route.snapshot.data['componentName'];
  conceptTab:any = {
    archived: this.route.snapshot.data['archived'] === 'archived',
    showTongue: false,
    firstLoad: true,
    selectedConcept: null,
    hasProcesses: this.skin === 'icam-red'?false:this.globalCfg.caps.processes,
    viewMode: this.route.snapshot.params['conceptObjectId'] === 'view',
    active: false,
    notify: '12',
    minWidthActive: 270,
    limitTo: 6,
    openOptions: false,
    productTypeId: '',
    conceptTab: null,
    shareConceptTooltip: this.translate.instant("concept-tab.share-concept"),
    titleModal: this.translate.instant('rename-modal.name-concept-rename')
  };

  ngOnChanges(changes: SimpleChanges) {
    let that: any = this.conceptTab;
    if(changes['subMenu'] && changes['subMenu'].currentValue){
      let subMenu: string = changes['subMenu'].currentValue
      that.showConceptOrReportIcon = subMenu === 'reports'? this.translate.instant("concept-tab.report-template") : this.translate.instant("concept-tab.concept-template");
      that.titleModal = subMenu === 'form'?  this.translate.instant('new-evaluation.edit-propierties') :  this.translate.instant('rename-modal.name-instance-rename')
      that.subMenu = subMenu
    }
    if(this.list?.length){
      let list: any = this.list;
      for(let i = 0; i < list.length; i++){
        let concept:any = list[i];
        concept.showTooltip = false;
        concept.visibilityHeaderText = false;
        if(i === 0){
            concept.iconList = "lf-icon-map-marker";
        }else if(concept.active){
            concept.iconList = "lf-icon-editable-form";
        }else{
            concept.iconList = '';
        }
        if(concept.active){
            that.selectedConcept = concept;
            this.calculateLimits(i);
            if(!concept.subTab){
                this.selectTab(that.subMenu);
            }
        }
      }
      that.list = list;
    }
    if(that.selectedConcept !== null && that.selectedConcept.reportId){
      that.titleModal = this.translate.instant('rename-modal.name-report-rename');
    }else if(that.selectedConcept !== null && that.selectedConcept.conceptObjectId){
      that.titleModal = this.translate.instant('rename-modal.name-instance-rename');
    }
    if(that.selectedConcept){
        that.productTypeId = that.selectedConcept.productTypeId;
        let icamRedNotPersonalized: boolean = !(this.skin === 'icam-red' && that.subMenu === 'reports' && that.list[0].reportObjectId != 'personalizado')
        let notFormAndrelated: boolean = that.selectedConcept?.relatedInformation?.length && that.subMenu !== 'form';
        let descriptionAndNotReport: boolean =  that.selectedConcept?.description && that.subMenu !== 'reports'
        let formNotPermissions: boolean = that.subMenu === 'form' && this.userPermissions !== 'none';
        that.showTongue = that.subMenu !== 'form-process' && that.activeConcept?.productId !== 'blanqueo' && icamRedNotPersonalized
                          && (that.productTypeId === 4 || (that.productTypeId !== 4 &&  formNotPermissions || descriptionAndNotReport || notFormAndrelated));
        this.closeLoaders();
        that.selectedConcept.favorite = that.selectedConcept.isFavorite? this.translate.instant('common.delete-favorite'): this.translate.instant('common.add-favorite');
        // if(this.to === 'documentation'){
        //     this.selectTab(this.to);
        // }
    }
    if(changes['activeConcept'] && changes['activeConcept'].currentValue){
      let activeConcept:any = changes['activeConcept'].currentValue;
      if(this.to === 'form-process' || this.to === 'template' || this.to === 'process-back'){
        that.showProcces = !activeConcept?.withProcess;
        that.fromForm = 'form-process';
      }else{
        that.fromForm = 'form';
        that.showProcces = that.hasProcesses;
      }
      that.activeConcept = activeConcept;
      that.showShareInstance = activeConcept.productId !== 'bpt002' && this.userPermissions === 'all' && this.subMenu !== 'reports';
    }
  }

  calculateLimits(index:any){
    this.conceptTab.begin = index-(this.conceptTab.limitTo-1) >=0? index-(this.conceptTab.limitTo-1) : 0;
  }

  closeLoaders(){
    let time = this.conceptTab.selectedConcept.conceptId && this.conceptTab.selectedConcept.evaluatedFields?.length>100?(this.conceptTab.selectedConcept.evaluatedFields?.length/100)*1000:100;
    let that = this;
    setTimeout(function () {
        that.loader.closeLoader(['concept-tab-refresh-list', 'concept-init-concept', 'folder-open-concept','folder-on-init', 'front-login-login', 'evaluation-menu-menu-action', 'evaluation-header-draft-evaluation', 'concept-precharged-info', 'evaluation-info-tree']);
    },time);
  }

  conceptSelect(concept:any){
      if(this.conceptTab.list.length > 1 && this.conceptTab.selectedConcept.conceptObjectId !== concept.conceptObjectId){
          for(let i = 0; i < this.conceptTab.list.length; i++){
            this.conceptTab.list[i].active = false;
          }

          concept.active = true;
          this.conceptTab.selectedConcept = concept;
          this.launchAction.emit({
            type:'selectConcept',
            item: concept
          });
          this.closeListMenu();
      }
  }

  renameEvaluatedConcept(concept:any = null){
      if(!this.conceptTab.archived){
          if(!concept){
              concept = this.conceptTab.selectedConcept;
          }
          this.subMenu ==='form'? this.renameEdit(concept) : this.renameNoEdit(concept);
      }
  }

  renameNoEdit(concept:any){
      let newItem = {
          title: concept.reportId?this.translate.instant('rename-modal.name-report-rename'):this.translate.instant('rename-modal.name-instance-rename'),
          type: concept.reportId?'report': 'concept',
          titleConcept: concept.title,
          conceptId: concept.reportId? concept.reportId: concept.conceptId,
          conceptObjectId: concept.reportId? concept.reportObjectId: concept.conceptObjectId,
          listFolders: [],
          rename: true
      }
      this.modalService.renameModal(newItem).subscribe((result:any)=>{
        if(result.result ==='ok'){
          this.loader.openLoader('concept-tab-rename-modal')
          this.changeConceptName(result.name);
        }
      })
  }

  renameEdit(concept:any){
      let option = {
          title: this.translate.instant('basic-data-modal.modify-data'),
          titleConcept: concept.title,
          type: 'edit'
      }
      concept.noEditablePoll = false;
      if(concept && !concept.poll){
          this.checkFields(concept);
      }
      this.modalService.basicDataModal(concept, option).subscribe((result:any)=>{
        if(result.result === 'ok'){
          this.loader.openLoader('concept-tab-refresh-list')
          this.changeConceptNameWithDescription(result);
        }
      })
  }

  checkFields(concept:any){
      loopCheckFields:
      for(let i:number = 0; i < concept.fieldContainers.length; i++){
          let container:any = concept.fieldContainers[i];
          for(let j:number = 0; j < container.fields.length; j++){
              let field:any = container.fields[j];
              if(field.fieldTypeId === 'l-co'
                  || field.fieldTypeId === 'formu'
                  || field.fieldTypeId === 'file'
                  || field.fieldTypeId === 'user'){
                  concept.noEditablePoll = true;
                  break loopCheckFields;
              }
          }
          for(let k:number = 0; k < container.subContainers.length; k++){
              let subContainer:any = container.subContainers[k];
              for(let m:number = 0; m < subContainer.fields.length; m++){
                  let subfield:any = subContainer.fields[m];
                  if(subfield.fieldTypeId === 'l-co'
                      || subfield.fieldTypeId === 'formu'
                      || subfield.fieldTypeId === 'file'
                      || subfield.fieldTypeId === 'user'){
                      concept.noEditablePoll = true;
                      break loopCheckFields;
                  }
              }
          }
      }
  }

  changeConceptName(name:any){
    let selectedConcept: any = this.conceptTab.selectedConcept;
      let type: string = 'concepto';
      let url: string = 'conceptobjects/' + selectedConcept.conceptObjectId
      if(selectedConcept.reportId){
          type = 'informe';
          url = 'reportobjects/' + (isNaN(selectedConcept.reportObjectId) ? selectedConcept.reportId : selectedConcept.reportObjectId);
      }

      this.apiService.patch(url, 0,{ title: name })
          .then((data:any)=>{
              selectedConcept.title = name;
              if(type === 'concepto' && selectedConcept.conceptNamedKey === 'Consulta'){
                this.checkConceptObjectTitle(selectedConcept.fieldContainers);
              }
              this.loader.closeLoader(['concept-tab-rename-modal']);
              this.modalService.adviceModal(this.translate.instant('concept-tab.change-name-ok'), 'info:accept:check-round')
          }, (errorData:any)=>{
              this.loader.closeError();
              let text:string = errorData.description==='UqConstraint'? this.translate.instant('concept-tab.error-same-name') : this.translate.instant('concept-tab.error-name');
              this.modalService.adviceModal(text, 'error:accept:warning');
          });
  }

  checkConceptObjectTitle(container:any){
    for(let i: number = 0; i < container.length; i++){
      container[i].fields.forEach((field:any) => {
        if(field.fieldContentTypeId === 'titl'){
          this.broadcastService.broadcast('changeFieldFromConceptTab', {field: field, newValue: this.conceptTab.selectedConcept.title});
        }
      });
      if(container[i].subContainers && container[i].subContainers.length){
        this.checkConceptObjectTitle(container[i].subContainers);
      }
    }
  }

  changeConceptNameWithDescription(result:any){
      let selectedConcept: any = this.conceptTab.selectedConcept;
      this.apiService.patch('concepts/'+this.evaluationId+'/rename',this.conceptTab.selectedConcept.conceptId, result.concept)
        .then((data:any)=>{
            selectedConcept.title = result.concept.title;
            selectedConcept.description = result.concept.description;
            selectedConcept.allowPublicShare = result.concept.allowPublicShare;
            selectedConcept.lockedEdit = result.concept.lockedEdit;
            selectedConcept.stateLockedEdit = result.concept.stateLockedEdit;
            selectedConcept.anonymousAccess = result.concept.anonymousAccess;
            selectedConcept.poll = result.concept.poll;
            selectedConcept.sequentialView = result.concept.sequentialView;
            selectedConcept.communicationChannel = result.concept.communicationChannel;
            if(result.changedCommunicationChannel || result.changedAllowPublicShare){
              this.launchAction.emit({
                type: 'savedPropertiesConcept',
                changedCommunicationChannel:result.changedCommunicationChannel,
                changedAllowPublicShare:result.changedAllowPublicShare
              });
            }
            this.broadcastService.broadcast('changeConceptFromEvaluation', {concept: selectedConcept, action: 'change', type: 'conceptId'});
            let text:string = this.conceptTab.subMenu === 'form' ? this.translate.instant('basic-data-modal.confirm-ok') : this.translate.instant('concept-tab.change-concept-name') + ' ' + this.translate.instant('concept-tab.message-left-menu');
            this.loader.closeLoader('concept-tab-refresh-list');
            this.modalService.adviceModal(text, 'info:accept:check-round').subscribe((result:any)=>{
              if(result.result ==='ok'){
                if(this.currentComponent === 'editConcept'){
                  let pollObject:any = {
                      pollValue: result.concept.poll,
                      pollstate: 'change',
                  }
                  this.changeConcept(pollObject)
                }
              }
            })
        }, (errorData:any)=>{
            this.loader.closeError()
            let text:any = errorData.description==='UqConstraint'? 'concept-tab.error-same-name' : 'concept-tab.error-name'
            this.modalService.adviceModal(this.translate.instant(text), 'error:accept:warning');
        })
  }

  openShareInstance(e:any){
      e.stopPropagation();
      e.preventDefault();
      this.modalService.shareInstance(this.conceptTab.activeConcept, this.hasOpenEvaluationTask).subscribe((result:any)=>{
        if(result.result==='ok'){
          switch(result.option){
            case 'evaluation':
                this.showShareInfo();
                break;
            case 'query':
                this.loader.openLoader('share-instance-contacts')
                this.shareInstance(result.shared);
                break;
          }
        }
      })
  }

  showShareInfo(){
    if(!this.hasOpenEvaluationTask){
      this.launchAction.emit({type: 'newTask'});
    }
  }

  shareInstance(shared:any){
      this.apiService.add('conceptobjects/'+ this.conceptTab.activeConcept.conceptObjectId+ '/shareread', shared)
          .then((data:any)=>{
              this.loader.closeLoader(['share-instance-contacts']);
              this.modalService.adviceModal(this.translate.instant('concept-tab.confirm-share-instance'), 'info:accept:check-round');
          }, (errorData:any)=>{
              this.loader.closeError();
              this.modalService.adviceModal(this.translate.instant('concept-tab.error-share-instance'), 'error:accept:warning');
          })
  }

  showConceptOrReport(e:any){
      e.stopPropagation();
      this.modalService.conceptInfo(this.conceptTab.selectedConcept)
  }

  selectTab(selected:any) {
      if (selected !== this.conceptTab.selectedConcept.subTab) {
          this.doSelectTab(selected);
      }
  }

  doSelectTab(selected:any) {
      this.launchAction.emit({
        type: 'selectTab',
        item: selected
      });
  }

  closeConcept(concept:any){
      this.launchAction.emit({
        type:'closeConcept',
        item: concept
      });
  }

  printConcept(){
      this.launchAction.emit({
        type:'print',
        item: this.conceptTab.selectedConcept
      });
  }

  favoriteMark(){
      this.conceptTab.selectedConcept.isFavorite = !this.conceptTab.selectedConcept.isFavorite;
      this.conceptTab.favorite = this.conceptTab.selectedConcept.isFavorite? this.translate.instant('common.delete-favorite') : this.translate.instant('common.add-favorite');
      this.launchAction.emit({
        type: 'favorite',
        item: this.conceptTab.selectedConcept
      });
  }


  showTooltip(type:any){
      return this.commonUtilsService.showTooltip(type)
  }

  showTooltipTabButton(buttonMod:any) {
      return !$('.concept__tab-button--' + buttonMod + '-' + this.skin + ' .concept__tab-button-text').is(':visible');
  }

  changeConcept(change:any){
      this.launchAction.emit({
        type:'changeEvaluatedConcept',
        change: change
      });

  }

  closeListMenu(type:any = ''){
      setTimeout(()=>{
          if(type !== 'dropdown'){
              this.conceptTab.showAllConcepts = false;
          }
          this.conceptTab.showHeader = false;
          this.conceptTab.closeSpinner = false;
      });
  }

  activeClass(concept:any){
      if(concept.active){
          return this.conceptTab.list.length < 6? 'concept-tab__concept-list--active-'+this.conceptTab.list.length : 'concept-tab__concept-list--active-max';
      }else{
          return 'concept-tab__concept-list--inactive';
      }
  }

  openRelatedInfo(relatedInfo:any){
      this.modalService.relatedInformationModal(relatedInfo)
  }

  showEditNameButton(concept: any){
    return Boolean(concept.active && this.currentComponent !== 'editConcept' && this.userPermissions !== 'none' && this.conceptTab.productTypeId !== 4  && this.skin !== 'icam-red' && this.conceptTab.activeConcept?.productId !== 'blanqueo' && !this.conceptTab.archived);

  }
}
