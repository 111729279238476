<div class="picker-visibility common__min-scroll"
  [ngClass]="{'picker-visibility--container':type === 'container'}"
  (click)="pickerVisibility.columnFilter = null">
  <header class="picker-visibility__header"
      [ngClass]="{'picker-visibility__header--container':type === 'container'}">
      <div class="picker-visibility__header-block">
          <div class="float-start picker-visibility__tab"
              [ngHide]="readonly"
              [ngClass]="{'picker-visibility__tab--selected': view==='full',
                'picker-visibility__tab--selected-container': view==='full' && type === 'container'}"
              (click)="changeView('full')">
              <span [afTranslate]="'picker-visibility.list-view'">Listado</span>
          </div>
          <div class="float-start picker-visibility__tab"
              [ngClass]="{'picker-visibility__tab--selected': view==='selected',
                'picker-visibility__tab--selected-container': view==='selected' && type === 'container'}"
              (click)="changeView('selected')">
              <span [afTranslate]="'picker-visibility.selected-view'">Selección</span>
              <div class="field-type-contacts__select-box"
                  [ngClass]="{'field-type-contacts__select-box--active': view === 'selected',
                          'field-type-contacts__select-box--container':type === 'container' && view !== 'selected'}" >{{selectedCount}}</div>
          </div>

          <div class="picker-visibility__block-buttons-save">
              <button class="lf-icon-close common__button-close"
                  (click)="close(pickerVisibility.previousSelectedItems, pickerVisibility.previousOrCondition)">
              </button>
              <button class="lf-icon-check common__button-checked"
                  [ngClass]="{'common__button-checked--container':type === 'container'}"
                  (click)="close(pickerVisibility.selectedItems, orCondition)">
              </button>
          </div>
          <div class="picker-visibility__block-header-search">
              <input class="common__find-in-dropdown common__find-in-dropdown--picker-visibility"
                  [(ngModel)]="pickerVisibility.findDropdown"
                  placeholder="{{'clients-picker.search' | afTranslate}}">
              <i class="common__find-dropdown-icon-find common__find-dropdown-icon-find--picker-visibility"
                  (click)="resetFilter($event)"
                  [ngClass]="pickerVisibility.findDropdown ===''?'lf-icon-search':'lf-icon-close-search'">
              </i>
          </div>
      </div>
  </header>
  <main class="picker-visibility__main">
      <div class="picker-visibility__block-table-pagination">
          <div class="picker-visibility__table"
              [ngHide]="pickerVisibility.editingItem">
              <div class="picker-visibility__table-header">
                  <div class="picker-visibility__table-col picker-visibility__table-col--field">
                      <filter-by-list [type]="'listFind'"
                          [item]="pickerVisibility.fieldFilter"
                          [list]="pickerVisibility.fieldLabelList"
                          [topStyle]="'24'"
                          (filterElementEmit)="selectTypeOperation($event, 'fieldLabel')">
                      </filter-by-list>
                  </div>
                  <div class="picker-visibility__table-col picker-visibility__table-col--value">
                      <p class="common__table-header-text"
                          [afTranslate]="'common.value'">Valor</p>
                  </div>
                  <div class="picker-visibility__table-col picker-visibility__table-col--include">
                      <p class="common__table-header-text"
                          [afTranslate]="'picker-visibility.include-column'">Incluir</p>
                  </div>
              </div>
              <div class="picker-visibility__table-body">
                  <div class="common__table-row"
                      *ngFor="let item of pickerVisibility.filteredItems | byWord:pickerVisibility.findDropdown:['fieldLabel', 'valueText'] | afOrderBy:['key']:false | afLimitTo:pickerVisibility.configPagination.pageSize:pickerVisibility.configPagination.pageSize*(pickerVisibility.currentPage - 1)">
                      <div class="picker-visibility__table-col picker-visibility__table-col--field">
                          <div class="common__cell-block">
                            <parragraph-tooltip [text]="item.fieldLabel"></parragraph-tooltip>
                          </div>
                      </div>
                      <div class="picker-visibility__table-col picker-visibility__table-col--value">
                          <div class="common__cell-block">
                            <parragraph-tooltip [text]="item.valueText"></parragraph-tooltip>
                          </div>
                      </div>
                      <div class="picker-visibility__table-col picker-visibility__table-col--include">
                          <i class="common__switch-icon"
                              [ngClass]="!!pickerVisibility.selectedItems[item.key]?'lf-icon-switch-right common__switch-icon--active':'lf-icon-switch-left common__switch-icon--inactive'"
                              (click)="toggleItem(item)"></i>
                      </div>
                  </div>
              </div>
          </div>
          <div class="concept-list__pagination-block concept-list__pagination-block--visibility"
              [ngShow]="pickerVisibility.filteredItems?.length > pickerVisibility.configPagination.pageSize && !pickerVisibility.editingItem">
              <pagination-control [collectionSize]="pickerVisibility.filteredItems?.length"
                  [config]="pickerVisibility.configPagination"
                  [(currentPage)]="pickerVisibility.currentPage"></pagination-control>
          </div>
          <div [ngShow]="pickerVisibility.editingItem"
              class="picker-visibility__edit-item-layer">
              <h5 class="picker-visibility__editing-title ">{{pickerVisibility.conditionTitle}}</h5>
              <div class="picker-visibility__condition-block">
                  <div class="picker-visibility__condition-item">
                      <field-type-list-simple [field]="pickerVisibility.operatorOne"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.operator1"
                          [listValues]="pickerVisibility.operators"
                          (itemSelected)="itemSelected($event, 'operator1')">
                      </field-type-list-simple>
                  </div>
                  <div class="picker-visibility__condition-item"
                      [ngSwitch]="pickerVisibility.editingItem?.dataType"
                      [ngClass]="{'picker-visibility__condition-item--date':pickerVisibility.editingItem?.dataType ==='date'}">
                      <field-type-number *ngSwitchDefault
                          [field]="pickerVisibility.valueOne"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.value1"
                          (itemSelected)="itemSelected($event, 'value1')">
                      </field-type-number>
                      <field-type-date *ngSwitchCase="'date'"
                          [field]="pickerVisibility.valueOne"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.value1"
                          (itemSelected)="itemSelected($event, 'value1')">
                      </field-type-date>
                  </div>
                  <div class="picker-visibility__condition-item"
                      [ngShow]="pickerVisibility.operators?.length !== 2">
                      <field-type-list-simple [field]="pickerVisibility.operatorTwo"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.operator2"
                          [listValues]="pickerVisibility.operators"
                          (itemSelected)="itemSelected($event, 'operator2')">
                      </field-type-list-simple>
                  </div>
                  <div class="picker-visibility__condition-item"
                      [ngSwitch]="pickerVisibility.editingItem?.dataType"
                      [ngShow]="pickerVisibility.operators?.length !== 2"
                      [ngClass]="{'picker-visibility__condition-item--date': pickerVisibility.editingItem?.dataType ==='date'}">
                      <field-type-number *ngSwitchDefault
                          [field]="pickerVisibility.valueTwo"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.value2"
                          (itemSelected)="itemSelected($event, 'value2')">
                      </field-type-number>
                      <field-type-date *ngSwitchCase="'date'"
                          [field]="pickerVisibility.valueTwo"
                          [preSelectItem]="pickerVisibility.editingItem?.condition?.value2"
                          (itemSelected)="itemSelected($event, 'value2')">
                      </field-type-date>
                  </div>
                  <div class="picker-visibility__condition-buttons">
                      <button class="lf-icon-close common__button-close"
                          (click)="pickerVisibility.editingItem = null"></button>
                      <button class="lf-icon-check common__button-checked"
                          (click)="editOk()"></button>
                  </div>
              </div>
          </div>
      </div>

  </main>
  <footer class="picker-visibility__footer">
      <div class="picker-visibility__footer-info">
          <div [afTranslate]="'picker-visibility.condition-advise-1'">Indica si las condiciones de visibilidad seleccionadas son de tipo AND (todas las condiciones marcadas tienen que ser ciertas) o de tipo OR (basta con que una de las alternativas sea cierta).</div>
          <div [afTranslate]="'picker-visibility.condition-advise-2'">El valor por defecto será AND.</div>
      </div>
      <div class="picker-visibility__footer-buttons">
          <div class="btn-group picker-visibility__footer-options">
              <button type="button"
                  class="picker-visibility__border-button picker-visibility__border-button--left"
                  [disabled]="readonly"
                  [ngClass]="{'picker-visibility__border-button--active': !orCondition}"
                  (click)="orCondition = false">and</button>
              <button type="button"
                  class="picker-visibility__border-button picker-visibility__border-button--right"
                  [disabled]="readonly"
                  [ngClass]="{'picker-visibility__border-button--active': orCondition}"
                  (click)="orCondition = true">or</button>
          </div>
      </div>
  </footer>
</div>
