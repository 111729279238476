<div class="horizontal-poll-graph">
    <div [id]="'horizontal-poll-graph-'+horizontalPollGraph.graphData.graphicId"></div>
    <button [ngShow]="fromComponent==='resultReports'"
        class="horizontal-poll-graph__capture"
        (click)="saveAsPng()"
        [disabled]="horizontalPollGraph.disabledSaveAsPng"
        matTooltip="{{(horizontalPollGraph.copiedTooltip?'results-report.image-captured':'results-report.capture-image') | afTranslate}}"
        matTooltipPosition="above"
        matTooltipClass="above"
        [matTooltipDisabled]="horizontalPollGraph.disableTooltip">
        <i class="lf-icon-all"></i>
    </button>
</div>
