<div class="attachments-template attachments-template--custom">
    <div class="attachments-template__custom-header">
        <i class="attachments-template__custom-add-icon"
            [ngClass]="type==='additional'? 'lf-icon-information': 'lf-icon-shield'"></i>
        <p class="attachments-template__custom-header-title box-protection-data__custom-header-title"
          [afTranslate]="type==='additional'? 'channel-configuration.additional-info': 'protection-data-modal.data-protection-information'">
        </p>
    </div>
    <div class="box-protection-data__block-form-info">
        <div class="box-protection-data__header-info">
            <span [afTranslate]="type==='additional'? 'protection-data-modal.want-know': 'protection-data-modal.processing-personal-data'"></span>
        </div>
        <div [innerHTML]="protectionData"
            class="box-protection-data__text-info">
        </div>
    </div>
  </div>
