  <div [ngClass]="{'field-item__global-block': !fieldTypeBool.field.poll,
    'field-item__global-block--highlight-landing-custom': fieldTypeBool.field.isHighlight}">
      <div class="field-item__field-type-bool field-item__field-type-bool--custom"
          [ngClass]="{'field-item__field-type-bool--is-highlight-custom': fieldTypeBool.field.isHighlight,
            'field-item__field-type-bool--is-highlight-poll': fieldTypeBool.field.isHighlight && fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView}">
          <div [ngClass]="{'field-type-bool__input-text-bool': !(fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView),
                  'common__input-text-poll':fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView,
                  'common__input-text-poll--error': fieldTypeBool.field.error}">
              <div [ngShow]="fieldTypeBool.field.sequentialView && !fieldTypeBool.field.noEditable ">
                  <p class="field-type-text__custom-sequential-label">{{fieldTypeBool.titleField}}
                      <span class="common__text-required common__text-required--bool"
                          [ngShow]="fieldTypeBool.field.required">*</span>
                      <tooltip-info [description]="fieldTypeBool.field.description"
                          [field]="fieldTypeBool.field"
                          [isSequentialPoll]="fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView"></tooltip-info>
                      <tooltip-law [references]="fieldTypeBool.field.references"
                          [field]="fieldTypeBool.field"
                          [ngShow]="fieldTypeBool.field.references.length"
                          [isSequentialPoll]="fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView">
                      </tooltip-law>
                  </p>
              </div>
              <div class="field-item__text-label"
                  [ngHide]="fieldTypeBool.field.sequentialView && !fieldTypeBool.field.noEditable "
                  [ngClass]="{'field-item__text-label--is-highlight-custom': fieldTypeBool.field.isHighlight,
                      'field-item__text-label--poll':fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView}">
                  <span [ngClass]="{'common__input-text-poll--error': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}">{{fieldTypeBool.titleField}}</span>
                  <span class="common__text-required common__text-required--bool"
                      [ngShow]="fieldTypeBool.field.required">*</span>
                  <div class="common__info-icon-block common__info-icon-block--visible common__info-icon-block--landing-custom"
                      [ngHide]="!fieldTypeBool.field.description || (fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView)">
                      <tooltip-info [description]="fieldTypeBool.field.description"
                          [field]="fieldTypeBool.field"></tooltip-info>
                  </div>
                  <div class="common__tooltip-law-show common__tooltip-law-show--custom"
                      [ngShow]="fieldTypeBool.field.references.length">
                      <tooltip-law [references]="fieldTypeBool.field.references"
                          [field]="fieldTypeBool.field">
                      </tooltip-law>
                  </div>
                  <div class="common__tooltip-law-show common__tooltip-law--custom">
                      <i class="lf-icon-search-text field-item__icon-related-info"
                          (click)="openModalRelatedInfo(fieldTypeBool.field.relatedInfo, $event)"
                          [ngShow]="fieldTypeBool.field.relatedInfo.length  && !fieldTypeBool.field.poll"
                          matTooltip="{{'common.related-info' | afTranslate }}"
                          matTooltipPosition="above"
                          matTooltipClass="above">
                      </i>
                  </div>

              </div>
          </div>
          <div class="field-type-bool__field-bool-answer"
              [ngClass]="{'field-type-bool__field-bool-answer--poll':fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView}">
              <div class="field-item__options-block-custom"
                  [ngClass]="{'field-item__options-block--poll':fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView}"
                  [ngShow]="!(fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView)">
                  <div class="field-item__option-bool-custom"
                      [ngClass]="{'field-item__option-bool-custom--green':fieldTypeBool.field.withColor === 1,
                        'field-item__option-bool-custom--red':fieldTypeBool.field.withColor === 2,
                        'field-item__option-bool-custom--disabled':fieldTypeBool.field.noEditable,
                        'field-item__option-bool-custom--error': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}"
                        (click)="selectOption(true)">
                      <p class="field-item__text-bool-custom"
                      [afTranslate]="'common.yes'"></p>
                      <i class="field-item__icon-bool-custom"
                          [ngClass]="fieldTypeBool.preSelectItem === true?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                  </div>
                  <div class="field-item__option-bool-custom field-item__option-bool-custom--right"
                      [ngClass]="{'field-item__option-bool-custom--green':fieldTypeBool.field.withColor === 2,
                        'field-item__option-bool-custom--red':fieldTypeBool.field.withColor === 1,
                        'field-item__option-bool-custom--disabled':fieldTypeBool.field.noEditable,
                        'field-item__option-bool-custom--error': fieldTypeBool.field.error && fieldTypeBool.preSelectItem === null}"
                        (click)="selectOption(false)">
                      <p class="field-item__text-bool-custom"
                          [afTranslate]="'common.no'"></p>
                      <i class="field-item__icon-bool-custom"
                          [ngClass]="fieldTypeBool.preSelectItem === false?'lf-icon-radio-button-active':'lf-icon-step-first'"></i>
                  </div>
              </div>
              <ul class="field-type-text__sequential-question-list"
                  [ngShow]="fieldTypeBool.field.poll && !fieldTypeBool.field.noEditable && fieldTypeBool.field.sequentialView">
                  <li class="field-type-text__sequential-question-item"
                      [ngClass]="{'field-type-text__sequential-question-item--green':fieldTypeBool.field.withColor === 1,
                        'field-type-text__sequential-question-item--red':fieldTypeBool.field.withColor === 2,
                        'common__show-alert': fieldTypeBool.field.error && fieldTypeBool.field.sequentialView}"
                      (click)="selectOption(true)">
                      <i class=" field-type-text__sequential-question-icon"
                          [ngClass]="fieldTypeBool.preSelectItem === true?'lf-icon-radio-button-active':'lf-icon-step-first'"
                          [ngStyle]="{'color':fieldTypeBool.preSelectItem === true && !fieldTypeBool.field.noEditable?custom.color:'#898989'}"></i>
                      <p class="field-type-text__sequential-question-text"
                          [afTranslate]="'common.yes'"></p>
                  </li>
                  <li class="field-type-text__sequential-question-item"
                      [ngClass]="{'field-type-text__sequential-question-item--green':fieldTypeBool.field.withColor === 2,
                          'field-type-text__sequential-question-item--red':fieldTypeBool.field.withColor === 1,
                          'common__show-alert': fieldTypeBool.field.error && fieldTypeBool.field.sequentialView}"
                      (click)="selectOption(false)">
                      <i class="field-type-text__sequential-question-icon"
                          [ngClass]="fieldTypeBool.preSelectItem === false?'lf-icon-radio-button-active':'lf-icon-step-first'"
                          [ngStyle]="{'color':fieldTypeBool.preSelectItem === false && !fieldTypeBool.field.noEditable?custom.color:'#898989'}"></i>
                      <p class="field-type-text__sequential-question-text"
                          [afTranslate]="'common.no'"></p>
                  </li>
              </ul>
          </div>
      </div>
  </div>
