<div [class]="'add-alert generate-document ' + addAlert.type">
  <div class="common__modal-header-block">
      <i class="lf-icon-notification-new common__icon-header"></i>
      <span class="common__text-header">{{addAlert.title}}</span>
      <i class="lf-icon-close common__icon-close-modal" (click)="handleDismiss()"></i>
  </div>
  <div class="add-alert__wizard-steps" *ngIf="addAlert.steps.length">
      <wizard-steps [steps]="addAlert.steps" [state]="addAlert.stepState"></wizard-steps>
  </div>
  <div class="add-alert__configuration-step" [ngShow]="addAlert.stepState === 1">
      <div [ngHide]="addAlert.editPattern">
          <form class="add-alert__alert-form"
              [ngClass]="{'add-alert__reference-date': addAlert.referenceDate}">
              <field-type-text-simple [field]="addAlert.titleAlert"
                                  [preSelectItem]="addAlert.addAlert.name"
                                  (itemSelected)="itemName('name', $event.newValue)">
              </field-type-text-simple>
              <field-type-date *ngIf="addAlert.referenceDate"
                  [field]="addAlert.fieldReferenceDate"
                  [preSelectItem]="addAlert.addAlert.referenceDate"
                  (itemSelected)="itemSelected('referenceDate', $event.newValue)">
              </field-type-date>
              <field-type-list-simple *ngIf="addAlert.designType === 'process'"
                      [field]="addAlert.statesField"
                      [listValues]="addAlert.processStates"
                      [preSelectItem]="addAlert.addAlert.stateId"
                      (itemSelected)="itemSelected('stateId', $event.newValue)">
              </field-type-list-simple>
              <field-type-list-simple *ngIf="addAlert.designType === 'date' && addAlert.chargedAlertTypes"
                                  [field]="addAlert.whenField"
                                  [listValues]="addAlert.whenList"
                                  [preSelectItem]="addAlert.addAlert.when"
                                  (itemSelected)="itemSelected('when', $event.newValue)">
              </field-type-list-simple>
              <field-type-list-extended *ngIf="addAlert.designType === 'document' && addAlert.chargedAlertTypes"
                      [field]="addAlert.whenField"
                      [listValues]="addAlert.whenList"
                      [preSelectItem]="addAlert.addAlert.when"
                      (itemSelected)="itemSelected('when', $event.newValue)">
              </field-type-list-extended>
              <div class="add-alert__periodicity-block"
                  [ngClass]="{'add-alert__periodicity-block--process': addAlert.designType === 'process'}"
                  [ngShow]="(addAlert.addAlert.when && addAlert.designType === 'date') || addAlert.designType === 'process'">
                  <div class="add-alert__label-block add-alert__label-block--margin-8">
                      <span class="common__input-text" [afTranslate]="'add-alert.notification-moment'"></span>
                      <span class="common__text-required">*</span>
                  </div>
                  <i class="lf-icon-radio-buttons-list establish-pattern__periodicity-list-icon"
                    [ngShow]="addAlert.designType === 'process'"></i>

                  <!--Propiedades de la alerta de fecha-->
                  <div class="add-alert__options-notification-moment"
                      *ngIf="addAlert.designType ==='date'"
                      [ngSwitch]="addAlert.addAlert.when">
                      <div *ngSwitchCase="1">
                          <span class="add-alert__option-text" [afTranslate]="'add-alert.warning-exact-day'"></span>
                          <input-time [input]="addAlert.addAlert.hour"
                            (output)="itemSelected('hour', $event.newValue)">
                          </input-time>
                      </div>
                      <div *ngSwitchDefault>
                          <span class="add-alert__option-text" [afTranslate]="'add-alert.warn'"></span>
                          <input-number [preSelectValue]="addAlert.addAlert.numDays"
                            (selectedValue)="itemSelected('numDays', $event.newValue)">
                          </input-number>
                          <select-simple class="add-alert__select-simple-repeat"
                              [selectItem]="addAlert.addAlert.timeUnit"
                              [listValues]="addAlert.frecuencyList"
                              (itemSelectedEmit)="itemSelected('timeUnit', $event)"
                              width-ul="130">
                          </select-simple>
                          <span class="add-alert__option-text"
                            [afTranslate]="addAlert.addAlert.when === 2 ? 'add-alert.at-before' : 'add-alert.at-later'">
                          </span>
                          <input-time [input]="addAlert.addAlert.hour"
                            (output)="itemSelected('hour', $event.newValue)">
                          </input-time>
                      </div>
                  </div>
                  <!--Fin propiedades de la alerta de fecha-->

                  <!--Propiedades de la alerta de proceso-->
                  <div *ngIf="addAlert.designType === 'process'">
                      <label for="process-step-selected" class="add-alert__list-option-interval"
                          [ngClass]="{'add-alert__list-option-interval--no-selected': addAlert.addAlert.when != 1}">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="addAlert.addAlert.when == 1 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
                          <input type="radio" id="process-step-selected" class="establish-pattern__input-radio" name="period" value="1"
                              [(ngModel)]="addAlert.addAlert.when">
                          <span class="add-alert__option-text add-alert__option-text--first" [afTranslate]="'add-alert.next-preselect'"></span>
                      </label>
                      <label for="process-next-period" class="add-alert__list-option-interval"
                          [ngClass]="{'add-alert__list-option-interval--no-selected': addAlert.addAlert.when != 2}">
                          <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                              [ngClass]="addAlert.addAlert.when == 2 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
                          <input type="radio" id="process-next-period" class="establish-pattern__input-radio" name="period" value="2"
                              [(ngModel)]="addAlert.addAlert.when">
                              <span class="add-alert__option-text add-alert__option-text--first" [afTranslate]="'add-alert.exceeded-period'"></span>
                          <div class="add-alert__inputs-process-next">
                              <input-number [preSelectValue]="addAlert.addAlert.delayUnits"
                                  (selectedValue)="itemSelected('delayUnits', $event.newValue)"
                                  [disabled]="addAlert.addAlert.when != 2">
                              </input-number>
                              <select-simple class="add-alert__select-simple-repeat"
                                  [selectItem]="addAlert.addAlert.delayTimeUnit"
                                  [listValues]="addAlert.frecuencyList"
                                  (itemSelectedEmit)="itemSelected('delayTimeUnit', $event)"
                                  [disabled]="addAlert.addAlert.when != 2"
                                  width-ul="130">
                              </select-simple>
                          </div>
                      </label>
                  </div>
                  <!--Fin propiedades de la alerta de proceso-->

                </div>
              <div>
                  <field-type-switch [label]="'add-alert.repeat'|afTranslate"
                                       [checked]="addAlert.repeat"
                                       (callback)="changeEditPattern($event.value)">
                  </field-type-switch>
                  <div [ngShow]="addAlert.repeat"
                    class="add-alert__periodicity-block add-alert__periodicity-block--repeat-patterns">
                      <p class="establish-pattern__periodicity-block-title">
                          <span [afTranslate]="'repeat-pattern.establish-pattern-title'"></span>
                          <span class="common__text-required">*</span>
                      </p>
                      <i class="lf-icon-radio-buttons-list establish-pattern__periodicity-list-icon"></i>
                      <div>
                          <label for="period-interval" class="add-alert__list-option-interval"
                              [ngClass]="{'add-alert__list-option-interval--no-selected': addAlert.repeatInterval.period !== 'interval'}">
                              <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                                  [ngClass]="addAlert.repeatInterval.period === 'interval' ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
                              <input type="radio"
                                id="period-interval"
                                class="establish-pattern__input-radio"
                                name="period"
                                value="interval"
                                [(ngModel)]="addAlert.repeatInterval.period"
                                [ngModelOptions]="{standalone: true}">
                              <span class="add-alert__option-text add-alert__option-text--first"
                                [afTranslate]="'add-alert.repeat-during'"></span>
                              <input-number [preSelectValue]="addAlert.repeatInterval.during"
                                  (selectedValue)="intervalSelected('during', $event.newValue)"
                                  [disabled]="addAlert.repeatInterval.period !== 'interval'">
                              </input-number>
                              <span class="add-alert__option-text" [afTranslate]="'repeat-pattern.days'"></span>
                          </label>
                          <label for="period-frecuency"
                              class="add-alert__list-option-interval"
                              [ngClass]="{'add-alert__list-option-interval--no-selected': addAlert.repeatInterval.period !== 'frecuency'}">
                              <i class="field-type-list-extended__icon-container field-type-list-extended__icon-container--radio"
                                  [ngClass]="addAlert.repeatInterval.period === 'frecuency' ? 'lf-icon-radio-button-active' : 'lf-icon-step-first'"></i>
                              <input type="radio" id="period-frecuency" class="establish-pattern__input-radio" name="period" value="frecuency"
                                  [(ngModel)]="addAlert.repeatInterval.period">
                              <span class="add-alert__option-text add-alert__option-text--first" [afTranslate]="'repeat-pattern.repeat-every'"></span>
                              <input-number [preSelectValue]="addAlert.repeatInterval.every"
                                  (selectedValue)="intervalSelected('every', $event.newValue)"
                                  [disabled]="addAlert.repeatInterval.period !== 'frecuency'">
                              </input-number>
                              <select-simple class="add-alert__select-simple-repeat"
                                  [selectItem]="addAlert.repeatInterval.everyTime"
                                  [listValues]="addAlert.frecuencyList"
                                  (itemSelectedEmit)="intervalSelected('everyTime', $event)"
                                  [disabled]="addAlert.repeatInterval.period !== 'frecuency'"
                                  width-ul="130">
                              </select-simple>
                              <select-simple class="add-alert__select-simple-repeat add-alert__select-simple-repeat--options"
                                  [selectItem]="addAlert.repeatInterval.ending"
                                  [listValues]="addAlert.frecuencyRepeatList"
                                  (itemSelectedEmit)="intervalSelected('ending', $event)"
                                  [disabled]="addAlert.repeatInterval.period !== 'frecuency'"
                                  width-ul="155">
                              </select-simple>
                          </label>
                      </div>
                  </div>
              </div>
          </form>
          <div class="common__buttons-block common__buttons-block--modal-relative"
            [ngClass]="{'add-alert__buttons-block': addAlert.referenceDate}">
              <button class="common__button common__button--clean"
                (click)="handleDismiss()"
                [afTranslate]="'common.cancel'">
                _cancelar
              </button>
              <button class="common__button common__button--active"
                (click)="goNext('next')"
                [afTranslate]="'common.next'">
                _Siguiente
              </button>
          </div>
      </div>
  </div>
  <div class="add-alert__configuration-step" [ngShow]="addAlert.stepState === 2">
      <form class="add-alert__form-share">
        <p class="corporative-information-modal__information-mail"
          [afTranslate]="'add-alert.addressee-description'">
          _escoge uno o más destinatarios con los que quieres compartir esta alerta
        </p>
        <field-type-contacts-multiple *ngIf="addAlert.contactsField"
            [selectContextUser]="!configuration?.alert"
            [field]="addAlert.contactsField"
            [concept]="addAlert.concept"
            (sendContacts)="receiveContacts($event)"
            (contactsClosed)="contactsClosed()">
        </field-type-contacts-multiple>
        <rich-editor [ngShow]="addAlert.showText"
            class="common__input-textarea-special common__input-textarea-special--with-margin-bottom"
            [tinyMceOptions]="addAlert.tinyMceOptions"
            [modelText]="addAlert.message"
            (emitValue)="addAlert.message = $event.model">
        </rich-editor>

      </form>
      <div class="common__buttons-block common__buttons-block--modal" >
          <button class="common__button common__button--clean"
            (click)="handleDismiss()"
            [afTranslate]="'common.cancel'">
            _Cancelar
          </button>
          <button class="common__button common__button--clean"
            (click)="goBack()"
            [afTranslate]="'common.previous'">
            _Anterior
          </button>
          <button class="common__button common__button--active"
            *ngIf="!addAlert.notifyStep"
            (click)="publishAlert()"
            [afTranslate]="'common.finish'">
            _Finalizar
          </button>
          <button class="common__button common__button--active"
            *ngIf="addAlert.notifyStep"
            (click)="goNext('notify')"
            [afTranslate]="'common.next'">
            _Siguiente
          </button>
      </div>
  </div>
  <div class="add-alert__configuration-step" [ngShow]="addAlert.stepState === 3">
      <form class="add-alert__form-share">
        <p class="corporative-information-modal__information-mail"
          [afTranslate]="'add-alert.notification-description'">
          _Enviar una notificación al solicitante es opcional y se puede configurar según tus preferencias. Si prefieres no enviarla, pulsa "Finalizar".
        </p>
        <div class="add-alert__notify-switches">
            <field-type-switch [label]="'add-alert.send-notification'|afTranslate"
                                [checked]="addAlert.notifyToIdentifying"
                                [disabled]="addAlert.disabledNotifyToIdentifying"
                                (callback)="notifyToIdentifyingChanged($event.value)">
            </field-type-switch>
            <field-type-switch class="add-alert__no-first-switch" [ngShow]="addAlert.notifyToIdentifying && addAlert.addAlert.when == 1"
                                [label]="'add-alert.use-custom-template'|afTranslate"
                                [checked]="addAlert.customTemplateSwitch"
                                (callback)="addAlert.customTemplateSwitch = $event.value">
            </field-type-switch>
        </div>
        <field-type-rich-text-editable [ngShow]="!addAlert.customTemplateSwitch"
            class="add-alert__text-rich"
            [field]="addAlert.notificationField"></field-type-rich-text-editable>
        <field-type-rich-text-editable *ngIf="addAlert.snippetsLoaded"
            [ngShow]="addAlert.customTemplateSwitch"
            class="add-alert__text-rich"
            [field]="addAlert.customNotificationField"></field-type-rich-text-editable>
        <field-type-switch [ngShow]="addAlert.customTemplateSwitch"
                            [label]="'add-alert.allow-edit-template'|afTranslate"
                            [checked]="addAlert.customTemplateEditable"
                            (callback)="addAlert.customTemplateEditable = $event.value">
        </field-type-switch>
        <advice-message [ngShow]="addAlert.disabledNotifyToIdentifying && addAlert.showInfoMessage"
            [icon]="'warning'"
            [type]="'warning'"
            [text]="addAlert.warningText"
            [typeClass]="'no-margin'"
            (closeAdvice)="closeAdvice('showInfoMessage')">
        </advice-message>
      </form>
      <div class="common__buttons-block common__buttons-block--modal" [ngShow]="addAlert.showText">
          <div class="add-alert__preview" [ngShow]="!addAlert.customTemplateSwitch">
              <i class="lf-icon-visible add-alert__icon-visible" (click)="showPreview()"></i>
              <span (click)="showPreview()" [afTranslate]="'add-alert.notification-preview'"></span>
          </div>
          <button type="reset" class="common__button common__button--clean" (click)="handleDismiss()" [afTranslate]="'common.cancel'"></button>
          <button type="submit" class="common__button common__button--clean" (click)="goBack()" [afTranslate]="'common.previous'"></button>
          <button type="submit" class="common__button common__button--active" (click)="publishAlert()" [afTranslate]="'common.finish'"></button>
      </div>
  </div>
</div>
