<div class="field-type-contacts">
  <div class="field-type-contacts__label-block">
      <label class="common__input-block"
          [ngClass]="{'common__input-block--show-references': fieldTypeContacts.references && field.evaluatedValue.conceptDataId !== null,
              'common__show-alert': field.error,
              'common__input-no-editable': field.noEditable}"
          [for]="'field-type-contacts-simple'+id"
          [attr.disabled]="field.noEditable?true:null">
          <div class="common__label-block common__label-block--one-icon"
            [ngClass]="{'common__label-block--four-icon-bubble': field.typeConfiguration === 'sign' || field.typeConfiguration === 'meeting'}">
              <span class="common__input-text"
                [ngClass]="{'common__input-text--required': field.error,
                          'common__input-text--no-editable': field.noEditable}">
                          <span>{{fieldTypeContacts.titleField}}</span>
                          <span *ngIf="field.label2"
                            class="common__input-text--secondary">{{field.label2}}</span>
                      </span>
              <span class="common__text-required"
                  [ngShow]="field.required">*</span>
              <div class="common__info-icon-block common__info-icon-block"
                  [ngHide]="field.noEditable || !field.description">
                  <tooltip-info [description]="field.description"
                      [field]="field"></tooltip-info>
              </div>
              <div class="common__tooltip-law-block"
                  [ngShow]="field.references && field.references.length && !field.noEditable">
                  <tooltip-law [references]="field.references"
                      [field]="field"></tooltip-law>
              </div>
              <div class="common__tooltip-law-block"
                  [ngShow]="field.relatedInfo && field.relatedInfo.length">
                  <i class="lf-icon-search-text field-item__icon-related-info"
                    (click)="openModalRelatedInfo(field.relatedInfo, $event)"
                    matTooltip="{{'common.related-info' | afTranslate}}"
                    matTooltipPosition="above"
                    matTooltipClass="above">
                  </i>
              </div>
          </div>
          <div class="field-type-contacts__block-contacts">
              <div class="float-end">
                  <i class="lf-icon-connected-data field-type-text__icon-references"
                      *ngIf="field.evaluatedValue && field.evaluatedValue.hasReferences"
                      (click)="showReference($event)"
                      matTooltip="{{'common.see-dependencies' | afTranslate}}"
                      matTooltipPosition="left"
                      matTooltipClass="left">
                  </i>
                  <i class="lf-icon-user common__input-icon common__input-icon-active"
                      [ngClass]="{'common__input-icon-active--disabled': field.noEditable}"
                      (click)="openContactList()"></i>
              </div>
              <div class="field-type-contacts__block-elements"
                  [ngClass]="{'field-type-contacts__block-elements--simple': field.fieldType === 'simple'}">
                  <ul class="field-type-contacts__multiselect-block">
                      <li class="common__multiselect-item common__multiselect-item--contacts"
                          *ngFor="let contact of fieldTypeContacts.contactListSelected; let index = index">
                          <div *ngIf="field.typeConfiguration !== 'field-user' && field.typeConfiguration !== 'roles' && field.typeConfiguration !== 'user-role'">
                              <p class="field-type-contacts__item-selected"
                                  [ngClass]="{'common__line-through': contact.isDeleted}"
                                  matTooltip="{{contact.fullName + (contact.isDeleted ? ' ('+ ('common.inactive-user' | afTranslate)+')' : '')}}"
                                  matTooltipPosition="below"
                                  matTooltipClass="below"
                                  [matTooltipDisabled]="showTooltip('field-type-contacts-multiple', index)"
                                  id="{{'field-type-contacts-multiple-'+ index }}">
                                  <span id="{{'span-field-type-contacts-multiple-'+index}}">{{contact.fullName}}</span>
                                </p>
                              <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple common__multiselect-delete-icon--simple-contact"
                                  (click)="deleteContact($event, index)"
                                  [ngShow]="!field.noEditable"></i>
                          </div>
                          <div *ngIf="field.typeConfiguration === 'field-user' || field.typeConfiguration === 'roles' || field.typeConfiguration === 'user-role'">
                              <div class="field-type-contacts__block-contact-name">
                                  <p class="common__multiselect-text common__multiselect-text--no-padding-left"
                                      [ngClass]="{'common__line-through':contact.isDeleted}"
                                      matTooltip="{{contact.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                      matTooltipPosition="below"
                                      matTooltipClass="below"
                                      [matTooltipDisabled]="!contact.isDeleted">{{contact.fullName}}
                                  </p>
                              </div>
                              <div class="field-type-contacts__block-contact-name">
                                  <i class="lf-icon-mail field-type-contacts__icon-selected"></i>
                                  <p class="common__multiselect-text common__multiselect-text--email">{{contact.email}}</p>
                              </div>
                              <div class="field-type-contacts__block-contact-phone">
                                  <i class="lf-icon-call field-type-contacts__icon-selected"
                                      [ngShow]="contact.phoneNumber1"></i>
                                  <p class="common__multiselect-text common__multiselect-text--email">{{contact.phoneNumber1}}</p>
                              </div>
                              <i class="lf-icon-close common__multiselect-delete-icon common__multiselect-delete-icon--multiple common__multiselect-delete-icon--contacts"
                                  (click)="deleteContact($event, index)"></i>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </label>
      <div class="field-type-contacts__contact-block"
          [ngClass]="{'field-type-contacts__contact-block--user':fieldTypeContacts.state === 'addNew',
              'field-type-contacts__contact-block--roles':field.typeConfiguration === 'roles'}"
          *ngIf="fieldTypeContacts.openUserList">
          <div class="field-type-contacts__new-user-contact"
              [ngShow]="fieldTypeContacts.state ==='addNew'">
              <div class="field-type-contacts__add-new-title-block">
                  <i class="lf-icon-user-new field-type-contacts__add-new-icon"></i>
                  <p class="field-type-contacts__add-new-title-text"
                    [afTranslate]="'common.new-contact'">Nuevo contacto</p>
              </div>
              <new-contact [valueId]="fieldTypeContacts.valueId"
                  [option]="fieldTypeContacts.option"
                  (changeView)="changeView()"
                  (addContact)="addContact('contact', $event)">
              </new-contact>
          </div>
          <!-- Esta linea cambia para el campo multiple -->
              <div class="common__input-block common__input-block--border-none"
                  [ngShow]="fieldTypeContacts.state !=='addNew'">
                <div class="field-type-contacts__selected-icons">
                    <div class="field-type-contacts__selection-icons field-type-contacts__selection-icons--simple">
                        <button class="lf-icon-user-new common__button-checked"
                            *ngIf="field.addContacts && fieldTypeContacts.canCreateContacts"
                            (click)="changeState($event, 'addNew')"></button>
                        <button class="lf-icon-close common__button-close"
                            [ngClass]="{'span__auto-ajust': field.addContacts}"
                            (click)="resetContacts()"></button>
                    </div>
                </div>
              <!-- A partir de aqui es donde va el componente de simple o multiple -SIMPLE EN ESTE CASO- -->
                <div class="field-type-contacts__block-header-simple">
                  <div class="common__label-block common__label-block--three-icon">
                      <span class="common__input-text"
                          [ngClass]="{'common__input-text--required': field.error,
                                  'common__input-text--no-editable': field.noEditable}">{{fieldTypeContacts.titleField}}</span>
                      <span class="common__text-required"
                          [ngShow]="field.required && !isLanding">*</span>
                  </div>
                  <div class="field-type-contacts__name-selected">
                      <p class="common__input-content field-type-contacts__item-selected">
                        <span *ngIf="fieldTypeContacts.contactListSelected.length"
                          [ngClass]="{'common__input-content-alert': field.error,
                              'common__line-through':fieldTypeContacts.contactListSelected[0].isDeleted}"
                          matTooltip="{{fieldTypeContacts.contactListSelected[0].fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          [matTooltipDisabled]="!fieldTypeContacts.contactListSelected[0].isDeleted">{{fieldTypeContacts.contactListSelected[0].fullName}}
                        </span>
                      </p>
                  </div>
                </div>
        </div>
        <div class="field-type-contacts__body-contacts-simple"
            [ngShow]="fieldTypeContacts.state !=='addNew'">
            <div class="field-type-contacts__contacts">
                <div [class]="'field-type-contacts__search-simple-block field-type-contacts__search-simple-block--'+fieldTypeContacts.buttonsShow?.length+'-elements'">
                    <input type="text"
                        class="field-type-contacts__search-simple"
                        [placeholder]="'common.search' | afTranslate"
                        [(ngModel)]="fieldTypeContacts.searchContact"
                        (click)="catchEvent($event)"
                        [readonly] ="field.readonly || field.noEditable">
                    <i class="lf-icon-search field-type-contacts__list-find-icon"
                        [ngClass]="fieldTypeContacts.searchContact ===''?'lf-icon-search':'lf-icon-close-search'"
                        (click)="resetSearch($event)"></i>
                </div>

                <div class="field-type-contacts__icons-search"
                    [ngShow]="fieldTypeContacts.buttonsShow?.length > 1">
                    <div *ngFor="let optionHeader of fieldTypeContacts.buttonsShow; let index = index"
                        class="field-type-contacts__icons-contacts"
                        [ngClass]="{'field-type-contacts__icons-contacts--first': index === 0,
                            'field-type-contacts__icons-contacts--last': index === fieldTypeContacts.buttonsShow?.length-1,
                            'field-type-contacts__icons-contacts--selected': fieldTypeContacts.viewItems.id === optionHeader.optionAction}"
                        matTooltip="{{optionHeader.tooltip | afTranslate}}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        (click)="showOnly(optionHeader.optionAction, $event)">
                        <i [class]="'field-type-contacts__icons '+optionHeader.icon"
                            [ngClass]="{'field-type-contacts__icons--role': optionHeader.type === 'productRole'}"></i>
                    </div>
                </div>
                <p class="field-type-contacts__title-contact-list field-type-contacts__title-contact-list--simple">
                    <span [ngShow]="fieldTypeContacts.searchContact !==''"
                      [afTranslate]="'contacts.results-of-search'">Resultados de la busqueda en</span>
                    <span>{{fieldTypeContacts.viewItems.label}}</span>
                </p>
            </div>
            <div class="field-type-contacts__inner-box field-type-contacts__inner-box--simple">
                <div [ngShow]="fieldTypeContacts.usersAndContactsFilList.length  || fieldTypeContacts.productRolesList.length">
                    <ul class="field-type-contacts__contact-book">
                        <li *ngFor="let productRole of fieldTypeContacts.productRolesList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: fieldTypeContacts.byWord:false"
                            class="field-type-contacts__contact-list-to-share field-type-contacts__contact-list-to-share--simple"
                            [ngShow]="fieldTypeContacts.viewItems.id === 4 || fieldTypeContacts.viewItems.id === 0"
                            (click)="addSimpleItem(productRole)">
                            <i class="lf-icon-lead field-type-contacts__icon-contact"></i>
                            <span class="field-type-contacts__text-contact"
                                  matTooltip="{{productRole.usersList}}"
                                  matTooltipPosition="right"
                                  matTooltipClass="right">
                                  {{productRole.fullName}}
                            </span>
                            <i class="lf-icon-check-round-full field-type-contacts__select-icon"></i>
                        </li>
                        <li *ngFor="let user of fieldTypeContacts.usersAndContactsFilList | byWord: fieldTypeContacts.searchContact:fieldTypeContacts.byWord | afOrderBy: fieldTypeContacts.byWord:false | unique: 'uniqueValueId'"
                            class="field-type-contacts__contact-list-to-share field-type-contacts__contact-list-to-share--simple"
                            [ngShow]="(user.contactId && fieldTypeContacts.viewItems.id === 3) || (user.userId && fieldTypeContacts.viewItems.id===2) || fieldTypeContacts.viewItems.id === 0"
                            (click)="addSimpleItem(user)">
                            <i class="field-type-contacts__icon-contact"
                                [ngClass]="{'lf-icon-user-special':user.userId, 'lf-icon-user':user.contactId}"></i>
                            <span class="field-type-contacts__text-contact"
                                [ngClass]="{'common__line-through':user.isDeleted}"
                                matTooltip="{{user.fullName +' ('+ ('common.inactive-user' | afTranslate)+')'}}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                [matTooltipDisabled]="!user.isDeleted">
                                {{user.fullName}}
                            </span>
                            <i class="lf-icon-check-round-full field-type-contacts__select-icon"></i>
                        </li>
                    </ul>
                </div>
                <div [ngHide]="fieldTypeContacts.usersAndContactsFilList.length || fieldTypeContacts.productRolesList.length">
                    <p class="field-type-contacts__error-text"
                      [afTranslate]="'common.no-results-found'">No se encuentran resultados</p>
                </div>
            </div>
        </div>
      </div>
  </div>
</div>
