import { DocumentActionsService } from '@shared/services/document-actions/document-actions.service';
import { Injectable } from '@angular/core';
import { ModalServiceService } from '@shared/services/modal-service/modal-service.service';
import { ContextFactory } from '@shared/factories/context/context.factory';
import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { AfTranslateFactory } from '@shared/modules/translate/factory/translate.factory';

@Injectable({
  providedIn: 'root'
})
export class FieldTypeRichParentService {

  constructor(private modalService: ModalServiceService,
    private context: ContextFactory,
    private documentActions: DocumentActionsService,
    private commonUtilsService: CommonUtilsService,
    private translate: AfTranslateFactory) { }

  configureChanges(child:any){
    if(child.field) {
      child.fieldTypeRichText.titleField = child.field.extendedLabel && child.field.extendedLabel !== ''? child.field.extendedLabel : child.field.label;
    }
    if(child.concept){
      child.productTypeId = child.concept.productTypeId;
    }
  }

  extractRelatedOrReferences(child: any, type:any, description:any){
    if(description.indexOf('<a') !== -1){
        let aSplit:any = description.split('<a ');
        aSplit.forEach((aTag:any, index:any) => {
          if(aTag.indexOf(type) !== -1){
            aTag = aTag.replace('href="#"','');
            aTag = aTag.replace(type, 'id="'+child.field.fieldId+'-i-'+index+'" (click)="openRelated(');
            aTag = aTag.replace('}">','})"><span class="common__tiny-link-span">');
            aTag = aTag.replace('style="color: #551a8b; text-decoration: underline;"', '')
            aSplit[index] = aTag.replace('</a>','</span><i class="'+this.isForms(aTag)+' common__tiny-link-icon"></i></a> ');
          }
        });
        return aSplit.join('<a ');
    }else{
        return description;
    }
  }

  isForms(tag:string){
    return tag.indexOf('related-forms') !== -1? 'lf-icon-forms common__tiny-link-icon--blue-form' : 'lf-icon-open-window common__tiny-link-icon--blue';
  }

  openRelated(child:any, item:any){
    if(item.modal === 'related-information'){
      this.openRelatedModal(item);
    }else if(item.modal === 'related-forms'){
      this.openRelatedForm(item, child);
    }else{
      this.openReferences(item, child);
    }
  }

  openRelatedModal(item:any){
    this.modalService.relatedInformationModal([item]);
  }

  openRelatedForm(item:any, child:any){
    item.type = item.modal;
    item.titleConcept = item.name;
    this.modalService.renameModal(item, child.concept.formList)
      .subscribe((result: any)=>{
        if(result.result==='ok'){
          item.title = result.name;
          child.createForm.emit({form: item})
        }
      })
  }

  openReferences(item:any, child:any){
    let registerItem:any = this.createRegisterItem(child);
    this.modalService.lawInfoModal(registerItem, [item],[item]);
  }

  createRegisterItem(child:any){
    return {
      'Distinct ID': this.context.user.externalId,
      'Client ID': this.context.user.companyExternalId,
      'event':'open legal docs',
      'Concept Name': sessionStorage.getItem('breadcrumbConcept') + child.concept.title,
      'Centinela product': this.context.settings.defaultProduct
    }
  }

  openModalRelatedInfo(e:any, fieldRelatedInfo:any, child:any){
    if(e){
      e.stopPropagation();
      e.preventDefault();
    }
    child.openRelatedInfo.emit({relatedInfo:fieldRelatedInfo});
  }

  analyzeWithSibila(child:any){
    if (child.fieldTypeRichText.description.trim() != '') {
        this.documentActions.doAction('sibila', { text: this.commonUtilsService.jsonEncode(child.field.description)});
    }
  }



  openRecordVoice(child:any){
    if(child.fieldTypeRichText.voiceRecord.player){
      this.modalService.adviceModal(this.translate.instant('record-audio-modal.new-record-question'), 'info:questionRecord:question')
        .subscribe((result:any)=>{
          if(result.result ==='ok'){
            this.recordVoice(child)
          }
        })

    }else{
      this.recordVoice(child)
    }
  }

  recordVoice(child:any){
    this.modalService.recordAudioModal(child.concept)
      .subscribe((result:any)=>{
        if(result.result ==='ok'){
          child.fieldTypeRichText.hasVoiceRecord = true;
          child.fieldTypeRichText.voiceRecord = {
            player: result.player,
            maxTime: result.maxTime,
            text: result.text,
            secondsRecord: result.secondsRecord,
            name: result.name,
            recordingFile: result.recordingFile,
            file: result.file
          }
          child.field.description = result.text;
          child.fieldTypeRichText.audioLoaded = true;
          child.changeInField.emit({newValue: result.text});
          child.saveVoiceRecord.emit({
            acceptAceptation: child.fieldTypeRichText.acceptAceptation,
            voiceRecordFile: child.fieldTypeRichText.voiceRecord.file,
            name: child.fieldTypeRichText.voiceRecord.name
          })
        }
      })
  }

}
