import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';
import { UserPermisionsFactory } from '@shared/factories/user-permisions/user-permisions.factory';
import { BoxCommunicationChannelParentService } from '../utils/box-communication-channel-parent.service';
import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';

@Component({
  selector: 'box-communication-channel-custom',
  templateUrl: './box-communication-channel-custom.component.html'
})
export class BoxCommunicationChannelCustomComponent implements OnChanges, OnDestroy {
  isLanding: boolean = true;
  userPermissions: any = this.permissions.getPermissions();
  custom: any = this.customLanding.getCustom();
  skin: any = this.globalCfg.skin;
  skinsAccepted: boolean = this.skin==='steel-blue' || this.skin==='gm-law' || this.skin==='dark-blue'
  boxCommunicationChannel:any = {
    showCommentBox: false,
    showCommentList: true,
    commentList: [],
    unreadAmountManager: 0,
    unreadAmountApplicant: 0,
    fullScreen: false,
    hasPermissions: this.userPermissions !== 'none',
    disabledButtons: false
  }
  constructor(private globalCfg: GlobalCfgFactory,
    private permissions: UserPermisionsFactory,
    private customLanding: CustomLandingFactory,
    private boxCommunicationChannelParent: BoxCommunicationChannelParentService) {

    }

  @Input() concept : any = null;
  @Input() public : any = null;
  @Input() fullScreenBox : any = null;

  @Output() catchFullScreen = new EventEmitter();

  ngOnChanges(){
    this.boxCommunicationChannelParent.onChanges(this);
  }

  newComment(){
    this.boxCommunicationChannelParent.newComment(this)
  }

  cancelComment(comment:any){
    this.boxCommunicationChannelParent.cancelComment(comment, this)
  }

  createNewComment(text:any){
    this.boxCommunicationChannelParent.createNewComment(text, this)
  }

  closeComment(){
    this.boxCommunicationChannel.showCommentBox = false;
    this.boxCommunicationChannel.showCommentList = true;
    this.boxCommunicationChannel.hasVoiceRecord = false;
    this.boxCommunicationChannel.voiceRecord = {};
    this.boxCommunicationChannel.commentText = '';

  }

  maximizeScreen(keepClassBox: boolean=false){
    this.boxCommunicationChannelParent.maximizeScreen(keepClassBox, this)
  }

  deleteComment(comment:any){
    this.boxCommunicationChannelParent.deleteComment(comment, this)
  }

  editComment(comment:any){
    this.boxCommunicationChannelParent.editComment(comment, this)
  }

  changeComment(comment:any){
    this.boxCommunicationChannelParent.changeComment(comment, this)
  }

  markAsRead(comment:any){
    this.boxCommunicationChannelParent.markAsRead(comment, this)
  }

  openRecordVoice(){
    this.boxCommunicationChannelParent.recordVoice(this);
  }

  listenVoiceRecord(comment:any = null, isLanding: boolean = true){
    let voiceRecorder: any = comment !== null? comment : this.boxCommunicationChannel.voiceRecord
    this.boxCommunicationChannelParent.listenVoiceRecord(voiceRecorder, isLanding, this);
  }
  editTextArea(){
    this.boxCommunicationChannelParent.editTextArea(this);
  }

  ngOnDestroy(){
    this.boxCommunicationChannelParent.ngOnDestroy();
  }
}
