
import { cloneDeep } from 'lodash';

import { Component, Input, OnInit, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';

import { GlobalCfgFactory } from '@shared/factories/global-cfg/global-cfg.factory';
import { CustomLandingFactory } from '@shared/factories/custom-landing/custom-landing.factory';

import { CommonUtilsService } from '@shared/services/common-utils/common-utils.service';
import { FieldTypeListParentService } from '../utils/field-type-list-parent.service';

@Component({
  selector: 'field-type-list-button',
  templateUrl: './field-type-list-button.component.html'
})
export class FieldTypeListButtonComponent implements OnInit, OnChanges, OnDestroy {
  selectionAsArray:boolean = false;
  disabledSearch: boolean = false;
  isLanding = this.customLanding.isLanding();
  skin:string = this.globalCfg.skin;
  fieldTypeList:any = this.fieldTypeListParent.configureFieldTypeList(this);
  custom: any = this.customLanding.getCustom();

  constructor( private globalCfg: GlobalCfgFactory,
    private commonUtilsService: CommonUtilsService,
    private customLanding: CustomLandingFactory,
    private fieldTypeListParent: FieldTypeListParentService) {
  }

  @Input() field:any = null;
  @Input() preSelectItem:any = '';
  @Input() listValues:any = [];
  @Input() listAll:any = [];
  @Input() public: boolean = false;;
  @Input() type:any = '';
  @Input() orderBy:any;
  @Input() fieldToFind:any = '';

  @Output() showFieldReference = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() openRelatedInfo = new EventEmitter();

  ngOnInit(){
    this.fieldTypeListParent.openWatchers(this)
    this.configureButtonType();
  }

  ngOnChanges(): void {
    this.fieldTypeListParent.configureChanges(this);
  }

  configureButtonType(){
    if (this.field){// && vm.field.isTypeButton) {
        if(this.skin == 'icam-red' && this.field.iD_Version0 == 10){
          let isProd: boolean = window.location.href.indexOf('https://centinelaicam.lefebvre.es/') !== -1
            this.listValues[1].disabled = isProd; //WEB-42173: Coming soon
            this.listValues[1].comingSoonButton = isProd;
            let newListValue: any = [
                this.listValues[0],
                this.listValues[1],
                this.listValues[2],
            ];
            if(this.isLanding){
              newListValue = [
                  this.listValues[0],
                  this.listValues[3],
                  this.listValues[2],
                  this.listValues[1],
              ]
            }

            this.listValues = cloneDeep(newListValue);
        }

        for(let i=0; i<this.listValues.length;i++){
            if(this.listValues[i].selected){
                this.fieldTypeList.selectItemId = this.listValues[i].listValueId;
                this.fieldTypeList.showLessInfo = true;

            }
        }
    }
  }

  selectedButtonField(listValue:any) {
    this.fieldTypeListParent.selectedButtonField(listValue, this)
  }

  showTooltipHeight(element:any, id:any){
    return !(this.commonUtilsService.showTooltipHeight(element, id));
  }

  showReference(e:any){
   this.fieldTypeListParent.showReference(e, this);
  }

  openModalRelatedInfo(fieldRelatedInfo:any, e:any){
    this.fieldTypeListParent.openModalRelatedInfo(fieldRelatedInfo, e, this);
  }

  ngOnDestroy(){
      this.fieldTypeListParent.ngOnDestroy();
  }
}
